import React, { useEffect, useState } from "react";

// Material-ui
import { FormControl, Grid, InputLabel, MenuItem, Stack, Typography } from "@mui/material";

// Formik imports
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";

// Project imports
import CustomAutoComplete from "../../../../components/CustomAutoComplete";
import CustomButton from "../../../../components/CustomButton";
import CustomizedDialog from "../../../../components/CustomDialog";
import _ from "lodash";

import * as Yup from "yup";
import Loading from "../../../../assets/images/loading.svg";
// ================== Main Beat Modal ================= //
const BeatAltModal = ({
  openBeatModal,
  setOpenBeataltModal,
  soloBeat,
  // tenant: { tenantUsers },
  master: { countries, stateList, cities },
  onCreateTenantBeatStart,
  onFetchCountriesStart,
  onFetchStateByCountryIdStart,
  onFetchCityByStateIdStart,
  onfetchSoloBeatStart,
  onFetchBeatsDataByIdStart,
  traders,
  onFetchAllBeatsListStart,
}) => {
  // If id is passed inside openBeatModal checking its type it handles update and add functionality respectively
  const isUpdating = typeof openBeatModal === "number";

  useEffect(() => {
    if (isUpdating) {
      onfetchSoloBeatStart(openBeatModal);
    }
  }, [isUpdating, openBeatModal]);
  function validation(value) {
    let error;
    if (value === '' || value===false) {
      error = 'Required!';
    }
    return error;
  }

  // useEffect(() => {
  //   onFetchCountriesStart();
  // }, []);
  // useEffect(() => {
  //   onFetchStateByCountryIdStart();
  // }, []);
  // useEffect(() => {
  //   onFetchCityByStateIdStart();
  // }, []);
  return (
    <>
    { Object.keys(soloBeat).length === 0 && isUpdating == true ?  (
    // <Typography>Wait till the data get Fully Loaded</Typography>
    <CustomizedDialog
      open={openBeatModal}
      handleClose={() => setOpenBeataltModal(false)}
      title={
        <Typography sx={{ color: "#111" }} variant="h3">
          {openBeatModal && isUpdating ? "Update" : "Create"} Beat
        </Typography>
      }
      maxWidth={"xs"}
      fullWidth={false}
    >
      {/* Loading the Data */}
      <img src={Loading}/>
    </CustomizedDialog>
    ) : ( 
    <CustomizedDialog
      open={openBeatModal}
      handleClose={() => setOpenBeataltModal(false)}
      title={
        <Typography sx={{ color: "#111" }} variant="h3">
          {openBeatModal && isUpdating ? "Update" : "Create"} Beat
        </Typography>
      }
      maxWidth={"xs"}
      fullWidth={false}
    >
      <Formik
        initialValues={{
          name: isUpdating ? soloBeat ?.name : "",
          state: isUpdating ? soloBeat ?.state?.id : "",
          add_trader: null,
          // add_user: null,
          country: isUpdating ? soloBeat ?.country?.id : "",
          city: isUpdating ? soloBeat ?.city?.id : "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is Required"),
          state: Yup.string().required("State is Required"),
          add_trader: Yup.array().required("Traders Field cannot be empty"),
          country: Yup.string().required("Country is Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onCreateTenantBeatStart(
            {
              id: isUpdating ? openBeatModal : undefined,
              // id: openBeatModal ? isUpdating : undefined,
              name: values?.name,
              description: "",
              country_id: values?.country,
              state_id: values?.state,
              city_id: values?.city,
              area: "",
              user: 1,
              traders: values?.add_trader?.map((t) => {
                return t.id;
              }),
            },
            () => {
              // onfetchSoloBeatStart();
              onFetchAllBeatsListStart(1, 10, 'desc', 'id');
              setSubmitting(true);
              setOpenBeataltModal(false);
              // onFetchAllBeatsListStart();
            }
          );
        }}
      >
        {({ submitForm, handleSubmit, setFieldValue, values }) => {
          // // eslint-disable-next-line react-hooks/rules-of-hooks
          // React.useEffect(() => {
          //   if (values?.country) {
          //     onFetchCountriesStart(values?.country);
          //   }
          // }, [values?.country]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useEffect(() => {
            if (values?.country) {
              onFetchStateByCountryIdStart(values?.country);
              // console.log(values);
            }
          }, [values?.country]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useEffect(() => {
            if (values?.state) {
              onFetchCityByStateIdStart(values?.state);
              // console.log(values?.state);
            }
          }, [values?.state]);
          // eslint-disable-next-line react-hooks/rules-of-hooks  
          useEffect(() => {
            onFetchCountriesStart();
          }, []);
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1} sx={{ alignItems: "flex-start" }}>
                <Grid container item md={12} xs={12} spacing={2} sx={{mt: "1px"}}>
                  <Grid item xs={6}>
                    <Field
                      // variant="filled"
                      validate={validation}
                      component={TextField}
                      type="string"
                      autoFocus
                      fullWidth
                      name="name"
                      id="name"
                      label="Beat Name"
                      InputProps={{
                        disableUnderline: true,
                        onInput: (e) => {
                          e.preventDefault();
                          const inputValue = e.target.value;
                          const acceptedValue = inputValue.slice(0, 100);
                          e.target.value = acceptedValue;
                        },
                      }}
                      // sx = {{ width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        component={Select}
                        name="country"
                        label="Country"
                        // variant="filled"
                        validate={validation}
                        InputProps={{ disableUnderline: false}}
                        sx = {{ width: "100%"}}
                      >
                        {countries?.length > 0 &&
                          countries?.map((country) => (
                            <MenuItem
                              value={country?.id}
                              key={country?.id}
                            >
                            {country?.name}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        component={Select}
                        name="state"
                        label="State"
                        //variant="filled"
                        validate={validation}
                        InputProps={{ disableUnderline: false}}
                        // sx = {{ width: "100%"}}
                        MenuProps={{ style: { width: "100%" } }}
                        // disableUnderline
                      >
                        {stateList?.length > 0 &&
                          stateList?.map((state) => (
                            <MenuItem
                              value={state?.id}
                              key={state?.id}
                            >
                           {state?.name}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        component={Select}
                        name="city"
                        label="City"
                        //variant="filled"
                        validate={validation}
                        disableUnderline
                      >
                        {cities?.length > 0 &&
                          cities?.map((city) => (
                            <MenuItem
                              value={city?.id}
                              key={city?.id}
                            >
                           {city?.cityname}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      {!_.isEmpty(traders?.allUsers) && (
                        // <Grid item xs={12}>
                          <CustomAutoComplete
                            multiple
                            options={traders?.allUsers}
                            setFieldValue={setFieldValue}
                            name="add_trader"
                            label="Add Trader"
                          />
                        // </Grid>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Stack
                direction={"row"}
                width={"100%"}
                spacing={4}
                sx={{
                  paddingTop: (theme) => theme.spacing(12),
                  paddingBottom: 1,
                }}
              >
                <CustomButton
                  variant="outlined"
                  name="Cancel"
                  sx={{ width: "50%" }}
                  handleClick={() => setOpenBeataltModal(false)}
                />
                <CustomButton
                  name="Save"
                  sx={{ width: "50%" }}
                  handleClick={submitForm}
                />
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </CustomizedDialog>
    )
    }
    </> 
  );
};

export default BeatAltModal;