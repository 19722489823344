import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";

import AddProductIcon from "../../../assets/images/addProduct.svg";
import OrganisationDefaultIMG from "../../../assets/images/organisation-default.svg";
import CustomButton from "../../../components/CustomButton";

import { useNavigate } from "react-router-dom";
import {
  EnhancedTableHead,
} from "../../../components/CustomTable";

import container from "./Organisation.container";

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  isShow: boolean;
  numeric: boolean;
  align?: string;
}

const OrganisationScreen = ({ limit, onFetchAllOrganisationStart, organisationScreen, page: pageNo }) => {
  const navigate = useNavigate();

  type Order = "asc" | "desc";
  const headCells: readonly HeadCell[] = [
    {
      id: "image",
      numeric: false,
      disablePadding: false,
      label: "",
      isShow: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "organisation Name",
      isShow: true,
    },
    {
      id: "databasename",
      numeric: false,
      disablePadding: false,
      label: "Database Name",
      isShow: true,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      isShow: true,
    },
    // {
    //   id: "status",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Status",
    //   isShow: true,
    // }
  ];
  // const dispatch = useDispatch()
  // const [delModal, setDelModal] = React.useState(null);
  const [dense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("databasename" || "name" || "date");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  // const [noOfPages, setNoOfPages] = React.useState([]);

  const [page] = React.useState(0);

  const [rowsPerPage] = React.useState(
    organisationScreen?.page_details?.limit
  );
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = organisationScreen.data?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  React.useEffect(() => {
    onFetchAllOrganisationStart();
  }, []);
  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: any
  // ) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const sortedData = organisationScreen?.data ? organisationScreen?.data.sort(getComparator(order, orderBy)) : [];

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - organisationScreen?.data.length)
      : 0;

  // Function to convert UTC time to IST (Indian Standard Time)
  function convertUtcToIst(utcTimeString) {
    const utcDate = new Date(utcTimeString);

    // Calculate the offset for IST (UTC+5:30)
    const istOffset = 5 * 60 + 30; // 5 hours and 30 minutes

    // Calculate the IST time by adding the offset
    const istTime = new Date(utcDate.getTime() + istOffset * 60 * 1000);

    return istTime.toLocaleString(); // Return IST time as a string
  }
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: (theme) => theme.spacing(4),
        }}
      >
        <Typography variant="h3">Organisation List</Typography>
        <CustomButton
          name="Add Organisation"
          handleClick={() => navigate("/manage/organisation/add-organisation")}
          icon={<img src={AddProductIcon} alt="" />}
        />
      </Box>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={0}
              headCells={headCells}
              isCheckBox={false}
            />

            <TableBody>
              {sortedData?.map((row, index) => {
                // const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                const orgRows = [];
                // Iterate through the dates array and check if beats is not null
                if (row.name !== null) {
                  // Create a new table row for each beat and date
                  orgRows.push(
                    <TableRow
                      hover
                      onClick={() => { }}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}

                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell style={{ padding: "0" }}>
                        {row?.tenant_image ? (
                          <img
                            src={`data:image/jpeg;base64,${row?.tenant_image.file_encoded}`}
                            alt="img"
                            style={{ top: "6px", left: "50px", height: "70px", width: "auto", display: "block", margin: "0 auto", }}
                          />
                        ) : (
                          <img
                            src={OrganisationDefaultIMG}
                            alt="Add Product Icon"
                            style={{ height: "70px", width: "auto", display: "block", margin: "0 auto" }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                        sx={{ paddingLeft: "1rem" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.db_name}</TableCell>
                      <TableCell align="center">{convertUtcToIst(row.created_at)}</TableCell>
                      {/* <TableCell align="left">{row.status}</TableCell> */}
                    </TableRow>
                  );
                  // });
                }

                return orgRows;
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

export default container(OrganisationScreen);
