import React, { Fragment } from "react";

// MUI Imports
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// Project Imports
import CustomButton from "../../../../components/CustomButton";

// Icon Imports
import FilterIcon from "../../../../assets/images/filter.svg";
import CustomAutoComplete from "../../../../components/CustomAutoComplete";
import { useDispatch } from "react-redux";
import _ from "lodash";

const BeatFilter = ({
  onChangeApplyBeatFilter,
  onFetchStateByCountryIdStart,
  onFetchCityByStateIdStart,
  onFetchAllBeatsListStart,
  onFetchBeatsDataByIdStart,
  master: { countries, stateList, cities, roles },
  country,
  state,
  city,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!_.isEmpty(country)) {
      onFetchStateByCountryIdStart(country?.id);
    }
  }, [country]);

  React.useEffect(() => {
    if (!_.isEmpty(state)) {
      onFetchCityByStateIdStart(state?.name);
    }
  }, [state]);

  // React.useEffect(() => {
  //   if (!_.isEmpty(state)) {
  //     onFetchBeatsDataByIdStart(state?.name);
  //   }
  // }, [state]);
  //onFetchBeatsDataByIdStart

  return (
    <Fragment>
      <Box
        sx={{
          width: "89px",
          height: "29px",
          padding: "18px 10px",
          borderRadius: "4px",
          border: "1px solid #45464E",
          display: "flex",
          alignItems: "center",
          gap: "9px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "24px 21px", maxWidth: 317, width: "100%" }}>
          <Typography
            variant="h4"
            sx={{ color: "#1C1D22", lineHeight: "24px", pb: "18px" }}
          >
            Filter
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="country" sx={{ marginBottom: "8px" }}>
                  Country
                </FormLabel>
                <Autocomplete
                  size="small"
                  id="country"
                  options={countries?.length > 0 ? countries : []}
                  autoHighlight
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, value) =>
                    dispatch(onChangeApplyBeatFilter("country", value))
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="city" sx={{ marginBottom: "8px" }}>
                  State
                </FormLabel>

                <Autocomplete
                  size="small"
                  id="country"
                  options={stateList?.length > 0 ? stateList : []}
                  autoHighlight
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, value) =>
                    dispatch(onChangeApplyBeatFilter("state", value))
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="city" sx={{ marginBottom: "8px" }}>
                  City
                </FormLabel>

                <Autocomplete
                  size="small"
                  id="city"
                  options={cities?.length > 0 ? cities : []}
                  autoHighlight
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, value) =>
                    dispatch(onChangeApplyBeatFilter("city", value))
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                name="Filter"
                sx={{ width: "100%", marginTop: "10px" }}
                handleClick={onFetchAllBeatsListStart}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default BeatFilter;
