import * as BeatsType from "./beats.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  allBeats: [],
  soloBeat: {},
  pages: {},
  error: null,
  loading: false,
  name: "",
  beat_id: [],
  country: {},
  state: {},
  city: {},
  page: 1,
  limit: 10,
  selectedBeatId: null,
  setSelectedTraderId: null,
  remainingTraders: [],
};

// Reducer function responsible for managing the authentication state
const beatsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  // console.log("payload---->", payload);
  switch (type) {
    case BeatsType.FETCH_BEATS_LIST_START:
    case BeatsType.CREATE_TENANT_BEAT_START:
    case BeatsType.UPDATE_TENANT_BEAT_START:
    case BeatsType.DELETE_TENANT_BEAT_START:
    case BeatsType.FETCH_TENANT_BEAT_BY_ID_START:
    case BeatsType.TENANT_BEATS_MOVE_TRADERS_START:
      return {
        ...state,
        loading: true,
      };

    case BeatsType.FETCH_BEATS_LIST_SUCCESS:
      return {
        ...state,
        allBeats: payload?.data?.beats,
        pages: payload?.data?.page_details,
        loading: false,
      };
    case BeatsType.ON_CHANGE_APPLY_BEATS_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };

    case BeatsType.CREATE_TENANT_BEAT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BeatsType.UPDATE_TENANT_BEAT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BeatsType.DELETE_TENANT_BEAT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BeatsType.FETCH_TENANT_BEAT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BeatsType.TENANT_BEATS_MOVE_TRADERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BeatsType.FETCH_SOLO_BEAT_SUCCESS:
        return {
          ...state,
          loading: false,
          soloBeat: payload?.data,
        };
    case BeatsType.FETCH_BEATS_LIST_FAILURE:
    case BeatsType.CREATE_TENANT_BEAT_FAILURE:
    case BeatsType.UPDATE_TENANT_BEAT_FAILURE:
    case BeatsType.DELETE_TENANT_BEAT_FAILURE:
    case BeatsType.FETCH_TENANT_BEAT_BY_ID_FAILURE:
    case BeatsType.TENANT_BEATS_MOVE_TRADERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case BeatsType.PUSH_RETAILER_TO_BEAT_FAILURE:
    case BeatsType.PUSH_RETAILER_TO_BEAT_START:
      return {
        ...state,
        loading: true,
      }
    case BeatsType.PUSH_RETAILER_TO_BEAT_SUCCESS:
      return {
        ...state,
        // selectedBeatId: action.payload?.beat_id,
        // setSelectedTraderId: action.payload?.traders,
        loading: false,
      }

    case BeatsType.REMAINING_TRADERS_START:
      return{
        ...state,
        loading: true,
      }
    
    case BeatsType.REMAINING_TRADERS_SUCCESS:
      return{
        ...state,
        remainingTraders: action?.payload?.data?.users,
        loading: false,
      }
      
    case BeatsType.REMAINING_TRADERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    default:
      return state;
  }
};

export default beatsReducer;