import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import AddOrder from "../../../views/Admin/AddOrder";

const Dashboard = () => {
  return <WithLayout layout={Admin} component={AddOrder} />;
};

export default Dashboard;
