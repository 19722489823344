import React, { Fragment } from "react";

// Material-ui
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// Icons import
import FilterIcon from "../../../../assets/images/filter.svg";
import CustomButton from "../../../../components/CustomButton";
import { useDispatch } from "react-redux";
import { changeApplyProductCategoryClearFilter, changeApplyProductClearFilter } from "../../../../store/inventory/inventory.actions";

const CategoryFilterMenu = ({
  onFetchAllFilteredProductsCategoryStart,
  onChangeApplyCategoryFilter,
  name,
  order,
 
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  type Order = "asc" | "desc";
  const [order1, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");

  const clearHandler = (field) => {
    const isAsc = orderBy === field && order1 === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
     dispatch(changeApplyProductCategoryClearFilter());
     onFetchAllFilteredProductsCategoryStart(isAsc ? "asc" : "desc", 'name');
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilter = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    onFetchAllFilteredProductsCategoryStart(isAsc ? "asc" : "desc", 'name');
    handleClose();
  };

  const dispatch = useDispatch();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   onChangeApplyCategoryFilter(name, value);
  //   console.log(name, value, "checking");
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

  if (name === "order") {
    const isValid = /^[0-9]{1,8}$/.test(value);

    if (isValid) {
      dispatch(onChangeApplyCategoryFilter(name, value));
    }
  } 
  else if(name === "name" && value.length > 100){
    const truncatedValue = value.slice(0, 100);
    onChangeApplyCategoryFilter(name, truncatedValue);
  }
  
  else {
    dispatch(onChangeApplyCategoryFilter(name, value));
  }
    // const { name, value } = e.target;
    // dispatch(onChangeApplyProductFilter(name, value));
    // console.log(name, value, "checking");
  };

  return (
    <Fragment>
      <Box
        sx={{
          width: "89px",
          height: "29px",
          padding: "18px 10px",
          borderRadius: "4px",
          border: "1px solid #45464E",
          display: "flex",
          alignItems: "center",
          gap: "9px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "24px 21px", maxWidth: 317, width: "100%" }}>
          <Typography
            variant="h4"
            sx={{ color: "#1C1D22", lineHeight: "24px", pb: "18px" }}
          >
            Filter
          </Typography>

          <Grid container spacing={2}>
           
           
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                  Name
                </FormLabel>
                <TextField
                  hiddenLabel
                  name="name"
                  id="name"
                  value={name}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="order" sx={{ marginBottom: "8px" }}>
                  Order
                </FormLabel>
                <TextField
                  hiddenLabel
                  name="order"
                  id="order"
                  value={order}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            
           
           
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                name="Filter"
                sx={{ width: "100%" }}
                handleClick={handleFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                sx={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Clear Filter"
                handleClick={clearHandler}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};



export default CategoryFilterMenu;
