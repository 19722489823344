import React, { Fragment } from "react";

// Material-ui
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// Icons Imports
import FilterIcon from "../../../../assets/images/filter.svg";

// Project Imports
import CustomButton from "../../../../components/CustomButton";
import { changeApplyDisabledUserClearFilter, changeApplyDisabledUserFilter, changeApplyUserClearFilter, 
  changeApplyUserFilter, fetchAllFilteredDisabledUsersStart, fetchAllFilteredUsersStart, } from "../../../../store/users/users.action";
import { fetchAllFilteredUsers } from "../../../../store/users/users.sagas";
// import { onFetchDisableUsersStart, onFetchEnableUsersStart } from "../../../Admin/Users/Users.container";
import { useDispatch } from "react-redux";

const UserFilter = ({filterName,filters:{f_name,l_name,email,mobile}, onFetchDisableUsersStart, onFetchEnableUsersStart}) => {

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = () => {
    if(filterName==="enabled"){
    // dispatch(fetchAllFilteredUsersStart())
    onFetchEnableUsersStart();
    handleClose()
  }
   
    else{
      // dispatch(fetchAllFilteredDisabledUsersStart())
      onFetchDisableUsersStart();
      handleClose()
    }
    }
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value)
    if(filterName==="enabled")
    dispatch(changeApplyUserFilter(name, value, 'asc', 'name'));
    else
    dispatch(changeApplyDisabledUserFilter(name, value))
    
   
  };
  const clearHandler = ()=>{
    if(filterName==="enabled"){
    dispatch(changeApplyUserClearFilter())
    // dispatch(fetchAllFilteredUsersStart())
    onFetchEnableUsersStart();
    handleClose()}
  
  else{
    dispatch(changeApplyDisabledUserClearFilter())
    // dispatch(fetchAllFilteredDisabledUsersStart())
    onFetchDisableUsersStart();
    handleClose()
  }
}

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <Box
        sx={{
          width: "89px",
          height: "29px",
          padding: "18px 10px",
          borderRadius: "4px",
          border: "1px solid #45464E",
          display: "flex",
          alignItems: "center",
          gap: "9px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "24px 21px", maxWidth: 350, width: "100%" }}>
          <Grid container spacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" mb={"30px"}>
                  Filter
                </Typography>

                
              </Grid>
              
              <Grid item xs={12}>
               
                <Box sx={{ display: "flex", gap: (theme) => theme.spacing(2) }}>
                  <FormControl>
                  <FormLabel id="f_name" sx={{ marginBottom: "8px" }}>
                    First Name
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="f_name"
                    id="f_name"
                    value={f_name}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                  </FormControl>
                  <FormControl>
                  <FormLabel id="l_name" sx={{ marginBottom: "8px" }}>
                    Last Name
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="l_name"
                    id="l_name"
                    value={l_name}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="email" sx={{ marginBottom: "8px" }}>
                    Email
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="email"
                    id="email"
                    value={email}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="mobile" sx={{ marginBottom: "8px" }}>
                    Phone Number
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="mobile"
                    id="mobile"
                    value={mobile}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomButton name="Filter" sx={{ width: "100%" }}  handleClick={handleFilter}/>
              </Grid>
              <Grid item xs={12} >  
              <CustomButton
                sx={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Clear Filter"
                handleClick={clearHandler}
              />
              
             
            </Grid>
            </Grid>
         
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};



export default UserFilter;
