import React, { Fragment, useState } from 'react';
import container from './SalesReport.container';
import { Box, Button, ButtonGroup, Paper, Typography, 
  Popover, Grid, FormControl, Select, FormLabel, } from '@mui/material';
import InputLabel from "@mui/material/InputLabel";
import CustomButton from '../../../components/CustomButton';

import ExportIcon from "../../../assets/images/export.svg";
import FilterIcon from "../../../assets/images/filter.svg";

import { EnhancedTableToolbar, EnhancedTableHead } from '../../../components/CustomTable';
import { CustomPagination } from "../../../components/CustomTable/CustomTable";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from "../../../assets/images/loading.svg";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Calendar from "./components/Calendar";
import HeadColumn from "./components/HeadColumn";
import { searchSalesReportName } from "../../../store/salesReport/salesReport.action";
import CustomViewButton from '../../../components/CustomViewButton';
import TodayAnalyticalView from './components/TodayAnalyticalView';
import YesterdayAnalyticalView from './components/YesterdayAnalyticalView';
import MonthlyAnalyticalView from './components/MonthlyAnalyticalView';
import PerformanceBarView from './components/PerformanceBarView';
import TopOrders from './components/TopOrders';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SalesReportComp = ({
  salesReport: { salesReportData, pageDetails, salesReportSummary,
    salesReportDataToday, salesReportDataYesterday, salesReportDataMonthly,
    barCustomDateData, topOrdersData}, 
  
  onGetSalesReportListStart, onChangeApplySalesReportFilter,
  onGetSalesReportTodayStart, onGetSalesReportYesterdayStart, onGetSalesReportMonthlyStart,
  onGetOrderListForBarStart, onGetHighestPlacedOrderStart}) => {

    const [currentView, setCurrentView] = useState('table');

    const handleViewChange = (view) => {
      setCurrentView(view);
    }

    const currentDate = moment().format("YYYY-MM-DD");

    const [fromDate, setFromDate] = React.useState(currentDate);
    const [toDate, setToDate] = React.useState(currentDate);     

    const [dense, setDense] = React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(pageDetails?.limit || 10);
    const [activePage, setActivePage] = React.useState<any>(1);

    const [noOfPages, setNoOfPages] = React.useState([]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedFilter, setSelectedFilter] = useState('');

    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };
    const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
  
    const handleSelect = (ranges) => {
      setDateRange([ranges.selection]);
      setCustomDateLabel(
        `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
          'MM/DD/YYYY'
        )}`
      );

    };

    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    };

    // const generatePageNumbers = (totalPages) => {
    //   console.log("totalPages:", totalPages); // Add this line for debugging
    //   return Array.from({ length: totalPages }, (_, index) => index + 1);
    // }
    // React.useEffect(() => {
    //   const totalPages = pagedetails?.page;
    //   console.log("totalPages:", totalPages); // Add this line for debugging
    //   const pages = generatePageNumbers(totalPages);
    //   setNoOfPages(pages);
    //   console.log("pages---->", pages);
    // }, [pagedetails]);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(null);

    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [isCustomCalendarOpen, setIsCustomCalendarOpen] = useState(false);    

    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedButton, setSelectedButton] = useState('today');


    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("user_name");

    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // onGetSalesReportListStart(fromDate, toDate, { page: activePage, limit: event.target.value }, );
        onGetSalesReportListStart(fromDate, toDate, activePage, event.target.value, 'asc', 'name' );
    }
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPage = Number(event.target.value);
        setActivePage(newPage);
        onGetSalesReportListStart(fromDate, toDate, pageDetails?.page, pageDetails?.limit, 'asc', 'name');
    }
    
    const showPrevPage = () => {
      const nextPage = activePage - 1;
      setActivePage(activePage - 1);
      onGetSalesReportListStart(fromDate, toDate, nextPage, pageDetails?.limit, 'asc', 'name');
    }

    const showNextPage = () => {
      const nextPage = activePage + 1;
      setActivePage(activePage + 1);
      onGetSalesReportListStart(fromDate, toDate, nextPage, pageDetails?.limit, 'asc', 'name');
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMonthCalendar = (month) => {
        setSelectedMonth(month);
    };

    const handleRadioChange = (event) => {
      const selectedValue = event.target.value;   
      setSelectedFilter(selectedValue);
    };

    const handleFilter = () => {
        handleClose();
        if (dateRange.length === 1) {
          const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
          const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
          onGetSalesReportListStart(selectedStartDate, selectedEndDate, 1, 10, 'asc', 'name');
          setSelectedButton('customRange');
          setFromDate(selectedStartDate);
          setToDate(selectedEndDate);
        }
        setSelectedFilter('');
    };

    const handleClearFilter = () => {
      setSelectedFilter(''); 
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#008E8E", 
        },
      ]);
      setFromDate(currentDate);
      setToDate(currentDate);
      setCustomDateLabel('Custom Date Selection');
      onGetSalesReportListStart(currentDate, currentDate, 1, 10, 'asc', 'name');
      setSelectedButton('today');
    };

    const handleSearchChange = (e) => {
      dispatch(searchSalesReportName("name", e));
      onGetSalesReportListStart(fromDate, toDate, 1, 10, 'asc', 'name');
    };

    React.useEffect(() => {
      const calculatePages = () => {
        const totalPages = Math.ceil(pageDetails?.total / pageDetails?.limit);
        const newPages = [];
        for (let i = 1; i <= totalPages; i++) {
          newPages.push(i);
        }
        setNoOfPages(newPages);
      };
  
      calculatePages();
    }, [pageDetails?.total, pageDetails?.limit]);
    React.useEffect(() => {
        onGetSalesReportListStart(currentDate, currentDate, 1, 10, 'asc', 'name',);
    }, []);
    return(
        // <div>This is Sales Report</div>
      <>
      <Fragment>
          <Box sx = {{display: "flex", mb: 2}}>
            <CustomViewButton
              label="Table View"
              onClick={() => handleViewChange('table')}
              isActive={currentView === 'table'}
            />
            <CustomViewButton
              label="Analytics View"
              onClick={() => handleViewChange('analytics')}
              isActive={currentView === 'analytics'}
            />
          </Box>
      </Fragment>
      {currentView === 'table' && (
        <Fragment>
          <Box
              sx={{
                display: "none",
                justifyContent: "space-between",
                alignItems: "center",
                my: (theme) => theme.spacing(4),
              }}
              >
              <Typography variant="h3"></Typography>
              <CustomButton
                  name="Export"
                  sx={{ width: "122px" }}
                  icon={<img src={ExportIcon} alt="" />}
                />
          </Box>

          <Paper sx={{ width: "100%",}}>
            <EnhancedTableToolbar
              numSelected={0}
              handleChange={handleSearchChange}
              tableHeaderStartComps={
                <Box sx={{display: "flex"}}>
                  <ButtonGroup
                    color="secondary"
                    size="small"
                    sx={{
                      "& > button": {
                        textTransform: "none",
                      },
                    }}
                  >
                    <Button 
                    variant={selectedButton === 'today' ? 'contained' : 'outlined'}
                    sx={{ color: selectedButton === 'today' ? '#fff' : '#8B8D97' }}
                        onClick={() => {
                          const today = moment().format("YYYY-MM-DD");
                          setFromDate(today);
                          setToDate(today);
                          onGetSalesReportListStart(today, today, pageDetails?.page, pageDetails?.limit, 'asc', 'name');
                          setSelectedButton('today')}}
                        >
                      Today
                    </Button>
                    <Button 
                        variant={selectedButton === 'yesterday' ? 'contained' : 'outlined'}
                        sx={{ color: selectedButton === 'yesterday' ? '#fff' : '#8B8D97' }} 
                        onClick={() => {
                        const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
                        setFromDate(yesterday);
                        setToDate(yesterday);
                        onGetSalesReportListStart(yesterday, yesterday, pageDetails?.page , pageDetails?.limit ,'name', 'asc');
                        setSelectedButton('yesterday')
                    }}>Yesterday</Button>
                  </ButtonGroup>
                  <Typography variant = "h5" sx={{ ml: 2, mt: 1 }}>Date: {fromDate} to {toDate}</Typography>
                </Box>
              } 
                  // Filter
              tableHeaderComps = {
                <>
                  <Box
                    sx={{
                    width: "89px",
                    height: "29px",
                    padding: "18px 10px",
                    borderRadius: "4px",
                    border: "1px solid #45464E",
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    <img src={FilterIcon} alt="filter" />
                    <Typography>Filter</Typography>
                  </Box>
                  <Popover 
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}>
                      <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
                        <Grid container spacing={4}>
                          <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h3">
                                Filter
                              </Typography>
                            </Grid>
                            <Grid item xs = {12}>
                                      {/* <RadioGroup
                                          aria-label="filter-options"
                                          name="filter-options"
                                          value={selectedFilter}
                                          onChange={handleRadioChange}
                                          >
                                            <FormControlLabel value="thisWeek" control={<Radio />} label="This Week" />
                                            <FormControlLabel value="lastWeek" control={<Radio />} label="Last Week" />
                                            <FormControlLabel value="thisYear" control={<Radio />} label="This Year" />
                                      </RadioGroup> */}
                                      <Grid item xs={12}>
                                      <>
                                        <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                          Date Selection
                                        </FormLabel>
                                        <Typography
                                          sx={{ 
                                            ml: 2, mt: 1,
                                            width: "185px",
                                            height: "29px",
                                            padding: "18px 10px",
                                            borderRadius: "4px",
                                            border: "1px solid #45464E",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "9px",
                                            cursor: "pointer",
                                         }}
                                          onClick={handleCustomDatePopoverOpen}
                                        >
                                          {customDateLabel}
                                        </Typography>
                                        <Popover
                                        open={customDatePopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCustomDatePopoverClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        >
                                          <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                          <IconButton
                                            aria-label="close"
                                            onClick={handleCustomDatePopoverClose}
                                            sx={{
                                              position: "absolute",
                                              right: 8,
                                              top: 8,
                                              color: (theme) => theme.palette.grey[500],
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                            <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                              <DateRangePicker
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="vertical"
                                                showDateDisplay={false}
                                                editableDateInputs={false}
                                              />
                                            </div>    
                                          </Box>
                                        </Popover>
                                      </>
                                      </Grid>
                                      <Grid item xs = {12} sx={{mb: "6px"}}>
                                      <Grid item xs = {12} sx={{mb: "6px"}} hidden>
                                          <Typography>Custom Date Range Selection</Typography>
                                          <LocalizationProvider dateAdapter={AdapterMoment}>
                                              <DatePicker
                                                views={["day"]}
                                                sx={{ width: 140 }}
                                              //value="singleMonthData"
                                                value={selectedMonth}
                                                slotProps={{ textField: { size: "small" } }}
                                              //onChange={handleFilter}
                                              // onChange={(date) => handleFilter('customRangeData', date.day())}
                                              onChange={handleMonthCalendar}
                                              />
                                          </LocalizationProvider>
                                      </Grid>
                                      </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <CustomButton
                                          variant = "outlined"
                                          name="Clear Filter"
                                          handleClick={handleClearFilter}
                                          sx={{ width: '100%' }}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <CustomButton
                                            name="Filter"
                                            handleClick={handleFilter}
                                            sx={{ width: "100%" }}
                                          />
                                       </Grid>                                        
                                  </Grid>
                              </Grid>
                          </Box>
                      </Popover>
                      </>
                      
                  }
              />
          </Paper>
          <Grid container>
            <Grid item xs={3} md={3}>
              <HeadColumn
                onGetSalesReportListStart={onGetSalesReportListStart}
                fromDate={fromDate}
                toDate={toDate}
                salesReport={{ salesReportData, pageDetails }}
              />
            </Grid>
            <Grid item xs={9} md={9}>
              <Calendar
                fromDate={fromDate}
                toDate={toDate}
                onGetSalesReportListStart = {onGetSalesReportListStart}
                salesReport = {{ salesReportData, pageDetails, salesReportSummary }}
              />
            </Grid>
          </Grid>
          
          <Paper sx={{ width: "100%", mb: 2 }}>
              {/* Pagination */}
              <CustomPagination 
              count={pageDetails?.total}
              rowsPerPage={rowsPerPage}
              page={activePage - 1}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handlePageChange={handlePageChange}
              pageNo={activePage}
              noOfPages={noOfPages}
              activePage={activePage}
              showPrevPage={showPrevPage}
              showNextPage={showNextPage}
              />
          </Paper>
      </Fragment>
      )}
      {currentView === 'analytics' && (
        <Grid container>
          <Grid container lg={8} mr={"15px"}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TodayAnalyticalView 
                  onGetSalesReportTodayStart = {onGetSalesReportTodayStart}
                  salesReportDataToday= {{salesReportDataToday}}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <YesterdayAnalyticalView 
                  onGetSalesReportYesterdayStart = {onGetSalesReportYesterdayStart}
                  salesReportDataYesterday= {{salesReportDataYesterday}}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MonthlyAnalyticalView 
                  onGetSalesReportMonthlyStart = {onGetSalesReportMonthlyStart}
                  salesReportDataMonthly= {{salesReportDataMonthly}}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs = {12} md = {12}>
                <PerformanceBarView 
                  onGetOrderListForBarStart = {onGetOrderListForBarStart}
                  barCustomDateData = {{barCustomDateData}}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container lg={4} spacing={2}>
              <TopOrders 
                onGetHighestPlacedOrderStart = {onGetHighestPlacedOrderStart}
                topOrdersData = {{topOrdersData}}
              />
          </Grid>
        </Grid>
      )} 
      </>
    )
}

export default container(SalesReportComp);