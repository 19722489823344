import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import ReportByDistributorComponent from "../../../views/Admin/ReportByDistributor/ReportByDistributor.component";


// =================== Order Page ================= //
const ReportByDistributor = () => {
  return <WithLayout layout={Admin} component={ReportByDistributorComponent} />;
};


export default ReportByDistributor;