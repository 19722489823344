import { connect } from "react-redux";
import { getSalesReportDetailsListStart, getSalesReportDetailsTodayStart,
    getSalesReportDetailsYesterdayStart, getSalesReportDetailsMonthlyStart, 
    getSalesReportDetailsYearlyBarStart, } from "../../../store/salesreportdetails/salesReportDetails.action";
import { yearlyBeatsListStart } from "../../../store/reportbybeats/reportByBeats.action";
import { yearlyItemListStart } from "../../../store/itemstatisticalreport/itemStatisticalReport.action";
const mapStateToProps = (state) => {
    return{
        salesReportDetails: state.salesReportDetails,
        limit: state.salesReportDetails.limit,

        reportByBeats: state.reportByBeats,

        itemStatisticalReport: state.itemStatisticalReport,
    }
};
const mapDispatchToProps = (dispatch) => ({
    onGetSalesReportDetailsListStart: (fromDate, toDate, page, field, type, limit) => dispatch(getSalesReportDetailsListStart(fromDate, toDate, page, field, type, limit)),
    onGetSalesReportDetailsTodayStart: (fromDate, toDate, type, field) => dispatch(getSalesReportDetailsTodayStart(fromDate, toDate, type, field)),
    onGetSalesReportDetailsYesterdayStart: (fromDate, toDate, type, field) => dispatch(getSalesReportDetailsYesterdayStart(fromDate, toDate, type, field),),
    onGetSalesReportDetailsMonthlyStart: (fromDate, toDate, type, field) => dispatch(getSalesReportDetailsMonthlyStart(fromDate, toDate, type, field)),
    onGetSalesReportDetailsYearlyBarStart: (fromDate, toDate) => dispatch(getSalesReportDetailsYearlyBarStart(fromDate, toDate)),

    // Bar View
    onYearlyBeatsListStart: (fromDate, toDate) => dispatch(yearlyBeatsListStart(fromDate, toDate)),

    //Hollow Pie Chart View
    onYearlyItemListStart: (fromDate, toDate, type, field) => dispatch(yearlyItemListStart(fromDate, toDate, type, field)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;