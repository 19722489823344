import React, { useState } from "react";
import { Grid, Paper, Typography, Popover, Box,
FormControl, Select, MenuItem, FormLabel } from "@mui/material";
import { Chart } from "react-google-charts";
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import CustomButton from "../../../../components/CustomButton";
import FilterIcon from "../../../../assets/images/filter.svg";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PerformanceBarView = ({ onGetOrderListForBarStart,
  barCustomDateData: {barCustomDateData} }) => {

    const today = moment().format("YYYY-MM-DD");
    const startOfYear = moment(today).startOf('year').format("YYYY-MM-DD");

    const [selectedStartDateMonth, setSelectedStartDate] = useState(null);
    const [selectedEndDateMonth, setSelectedEndDate] = useState(null);

    const [fromDate, setFromDate] = React.useState(today);
    const [toDate, setToDate] = React.useState(today);  

    const [selectedFilter, setSelectedFilter] = useState('');

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    };

    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };

    const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
  
    const handleSelect = (ranges) => {
      setDateRange([ranges.selection]);
      setCustomDateLabel(
        `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
          'MM/DD/YYYY'
        )}`
      );

    };

    const handleFilter = () => {
      handleClose();
      if (dateRange.length === 1) {
        const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
        const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
        onGetOrderListForBarStart(selectedStartDate, selectedEndDate);
        setFromDate(selectedStartDate);
        setToDate(selectedEndDate);
      }
      else{
        let formattedStartDate = moment(selectedStartDateMonth).format("YYYY-MM-DD");
        let formattedEndDate = moment(selectedEndDateMonth).format("YYYY-MM-DD");

        if (moment(formattedStartDate).isAfter(formattedEndDate)) {
          [formattedStartDate, formattedEndDate] = [formattedEndDate, formattedStartDate];
        }

        onGetOrderListForBarStart(formattedStartDate, formattedEndDate);
      }
      // setSelectedFilter('');
  };

  const handleClearFilter = () => {
    setSelectedFilter(''); 
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
    setFromDate(today);
    setToDate(today);

    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setCustomDateLabel('Custom Date Selection');
    onGetOrderListForBarStart(today, today);
  };
    

    const totalSales = barCustomDateData?.map((data) => {
      return data?.total_orders_value;
    });
    
    const userNamesList = barCustomDateData?.map((userName) => {
      return userName?.name;
    });

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = {
      chart: {
        title: "",
        subtitle: "",
      },
    };

    const chartData = [
      ['Users', 'Sales'],
      ...userNamesList.map((userName, index) => [userName, totalSales[index]]),
    ];

    const chartData1 = [
      ['Users', 'Sales'],
      ['No Sales', 0],
    ]
    React.useEffect(() => {
      onGetOrderListForBarStart("2023-12-01", "2023-12-31");
      console.log("Performance Bar Date Range---->", startOfYear, today);
    }, [])
    
    return(
        <Grid item container xs={12} md={12}>
            <Paper sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px',  borderRadius: "14px" }}>
            <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            // md={12}
            >
              {/* <div style = {{alignContent: "flex-end"}}></div> */}
                <Box
                  sx={{
                  width: "89px",
                  height: "29px",
                  padding: "18px 10px",
                  borderRadius: "4px",
                  border: "1px solid #45464E",
                  display: "flex",
                  alignItems: "center",
                  gap: "9px",
                  cursor: "pointer",
                  marginTop: "5px"
                  }}
                  onClick={handleClick}
                >
                  <img src={FilterIcon} alt="filter" />
                  <Typography>Filter</Typography>
                </Box>
                <Popover 
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}>
                    
                    <Box sx={{ padding: "24px 21px", width: "100%", maxWidth: 300,  }}>
                      <Grid container spacing={4}>
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={12}>
                              <Typography variant="h3">
                                Filter
                              </Typography>
                          </Grid>
                          <Grid item xs = {12}>
                            <Grid item xs = {12}>
                            <>
                                    <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                      Date Selection
                                    </FormLabel>
                                    <Typography
                                      sx={{ 
                                        ml: 2, mt: 1,
                                        width: "185px",
                                        height: "29px",
                                        padding: "18px 10px",
                                        borderRadius: "4px",
                                        border: "1px solid #45464E",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "9px",
                                        cursor: "pointer",
                                     }}
                                      onClick={handleCustomDatePopoverOpen}
                                    >
                                      {customDateLabel}
                                    </Typography>
                                    <Popover
                                    open={customDatePopoverOpen}
                                    anchorEl={anchorEl}
                                    onClose={handleCustomDatePopoverClose}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    >
                                      <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                        <IconButton
                                          aria-label="close"
                                          onClick={handleCustomDatePopoverClose}
                                          sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                          }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                          <DateRangePicker
                                            ranges={dateRange}
                                            onChange={handleSelect}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            direction="vertical"
                                            showDateDisplay={false}
                                            editableDateInputs={false}
                                          />
                                        </div>    
                                      </Box>
                                    </Popover>
                                  </>
                            </Grid>
                            <Grid item xs={12} hidden>
                              <Typography sx={{ color: "#1C1D22" }}>Month Comparison</Typography>
                              <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                <Typography sx={{fontSize: "14px"}}>Start Month</Typography>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <DatePicker
                                        views={["month"]}
                                        sx={{ width: 140 }}
                                        value={selectedStartDateMonth}
                                        slotProps={{ textField: { size: "small" } }}
                                        onChange={(date) => setSelectedStartDate(date)}
                                      /> 
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                <Typography sx={{fontSize: "14px"}}>End Month</Typography>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <DatePicker
                                        views={["month"]}
                                        sx={{ width: 140 }}
                                        value={selectedEndDateMonth}
                                        slotProps={{ textField: { size: "small" } }}
                                        onChange={(date) => setSelectedEndDate(date)}
                                      />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <CustomButton
                              variant = "outlined"
                              name="Clear Filter"
                              handleClick={handleClearFilter}
                              sx={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <CustomButton
                              name="Filter"
                              handleClick={handleFilter}
                              sx={{ width: "100%" }}
                            />
                           </Grid>                                        
                        </Grid>
                      </Grid>
                    </Box>
                </Popover>
                </Grid>
                <Grid container item md={12}>
                    <Grid md={9}>
                    <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>Best Performance</Typography>
                    </Grid>

                    {/* <Grid md={3}>
                    <Box
                        sx={{
                        width: "89px",
                        height: "29px",
                        padding: "18px 10px",
                        borderRadius: "4px",
                        border: "1px solid #45464E",
                        display: "flex",
                        alignItems: "center",
                        gap: "9px",
                        cursor: "pointer",
                        marginTop: "5px"
                        }}
                      onClick={handleClick}
                        >
                        <img src={FilterIcon} alt="filter" />
                        <Typography>Filter</Typography>
                    </Box>
                    <Popover 
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}>
                        
                        <Box sx={{ padding: "24px 21px", width: "100%", maxWidth: 300,  }}>
                          <Grid container spacing={4}>
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                  <Typography variant="h3">
                                    Filter
                                  </Typography>
                              </Grid>
                              <Grid item xs = {12}>
                                <Grid item xs = {12}>
                                <>
                                        <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                          Date Selection
                                        </FormLabel>
                                        <Typography
                                          sx={{ 
                                            ml: 2, mt: 1,
                                            width: "180px",
                                            height: "29px",
                                            padding: "18px 10px",
                                            borderRadius: "4px",
                                            border: "1px solid #45464E",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "9px",
                                            cursor: "pointer",
                                         }}
                                          onClick={handleCustomDatePopoverOpen}
                                        >
                                          {customDateLabel}
                                        </Typography>
                                        <Popover
                                        open={customDatePopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCustomDatePopoverClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        >
                                          <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                            <IconButton
                                              aria-label="close"
                                              onClick={handleCustomDatePopoverClose}
                                              sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                              }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                              <DateRangePicker
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="vertical"
                                                showDateDisplay={false}
                                                editableDateInputs={false}
                                              />
                                            </div>    
                                          </Box>
                                        </Popover>
                                      </>
                                </Grid>
                                <Grid item xs={12} hidden>
                                  <Typography sx={{ color: "#1C1D22" }}>Month Comparison</Typography>
                                  <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                    <Typography sx={{fontSize: "14px"}}>Start Month</Typography>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <DatePicker
                                            views={["month"]}
                                            sx={{ width: 140 }}
                                            value={selectedStartDateMonth}
                                            slotProps={{ textField: { size: "small" } }}
                                            onChange={(date) => setSelectedStartDate(date)}
                                          /> 
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                    <Typography sx={{fontSize: "14px"}}>End Month</Typography>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <DatePicker
                                            views={["month"]}
                                            sx={{ width: 140 }}
                                            value={selectedEndDateMonth}
                                            slotProps={{ textField: { size: "small" } }}
                                            onChange={(date) => setSelectedEndDate(date)}
                                          />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <CustomButton
                                  variant = "outlined"
                                  name="Clear Filter"
                                  handleClick={handleClearFilter}
                                  sx={{ width: '100%' }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomButton
                                  name="Filter"
                                  handleClick={handleFilter}
                                  sx={{ width: "100%" }}
                                />
                               </Grid>                                        
                            </Grid>
                          </Grid>
                        </Box>
                      </Popover>
                    </Grid> */}
                </Grid>

                <Grid item md={12} sx={{mt: "10px"}}>
                  {barCustomDateData.length > 0 ? (
                    <Chart
                    chartType="Bar"
                    data={chartData}
                    options={options}
                    />
                  ): (
                      <Chart
                        chartType="Bar"
                        data={chartData1}
                        options={options}
                      />
                  )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default PerformanceBarView;