import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import Organisations from "../../../views/Admin/Organisation";

// =================== Visit Report Page ================= //
const Organisation = () => {
  return <WithLayout layout={Admin} component={Organisations} />;
};

export default Organisation;