// React-redux
import { connect } from "react-redux";
// import { reqeustEnableApiData,reqeustDisableApiData } from "../../../store/users/users.action";
import { loadUserBasedOnTenantStart } from "../../../store/auth/auth.actions";
import { updateUserStart } from "../../../store/users/users.action";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  
  onloadUserBasedOnTenantStart: () => {
    dispatch(loadUserBasedOnTenantStart())},
  onupdateUserStart: (value) => dispatch(updateUserStart(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
