import * as OrganisationType from "./organisation.types";
// Get all Organisation List

export const fetchAllOrganisationStart = () => ({
    type:  OrganisationType.GET_ORGANISATION_LIST_START,
  });
  
  export const fetchAllOrganisationSuccess = (result) => ({
    type: OrganisationType.GET_ORGANISATION_LIST_SUCCESS,
    payload: result,
  });
  
  export const fetchAllOrganisationFail = (error) => ({
    type: OrganisationType.GET_ORGANISATION_LIST_FAILURE,
    payload: error,
  });

  // Create Organisation
export const createOrganisationStart = (formData, cb) => ({
  type: OrganisationType.CREATE_ORGANISATION_START,
  payload: { formData, cb },
});

export const createOrganisationSuccess = (result) => ({
  type: OrganisationType.CREATE_ORGANISATION_SUCCESS,
payload: result,  
});

export const createOrganisationFail = (error) => ({
  type: OrganisationType.CREATE_ORGANISATION_FAILURE,
  payload: error,
});