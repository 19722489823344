// Material-ui
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  Skeleton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";

// Icons Import
import SearchIcon from "../../assets/images/search.svg";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Fragment } from "react";

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleChange?: any;
  tableHeaderComps?: any;
  tableHeaderStartComps?: any;
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, tableHeaderComps, handleChange, tableHeaderStartComps } =
    props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box sx={{ flex: "1 1 100%" }}>{tableHeaderStartComps}</Box>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            placeholder="Search"
            id="outlined-size-small"
            size="small"
            sx={{ m: 1, minWidth: 180 }}
            onChange={(e) => handleChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search" />
                </InputAdornment>
              ),
            }}
          />
          {tableHeaderComps}
        </Box>
      )}
    </Toolbar>
  );
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: any;
  orderBy: string;
  rowCount: number;
  headCells?: any;
  isCheckBox?: boolean;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isCheckBox = true,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {isCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              sx={{fontWeight:600}}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells
          ?.filter((headerCell) => headerCell?.isShow === true)
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align ? headCell.align : "center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ paddingLeft: isCheckBox ? 0 : "1rem",fontWeight:600, whiteSpace: "nowrap", height: "60px", 
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableLoader({ rows }) {
  return (
    <TableRow hover>
      {rows &&
        rows?.map((index) => (
          <TableCell component="th" scope="row" padding="none" key={index}>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          </TableCell>
        ))}
      <TableCell component="th" scope="row" padding="none">
        <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      </TableCell>
    </TableRow>
  );
}

export function CustomPagination({
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handlePageChange,
  pageNo,
  noOfPages,
  activePage,
  showPrevPage,
  showNextPage,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TablePagination
        rowsPerPageOptions={[10, 25, 40, { label: "All", value: count }]}
        colSpan={3}
        count={count}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={"Show:"}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "Show",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ style: { display: "none" } }}
        backIconButtonProps={{ style: { display: "none" } }}
        sx={{ padding: 0, borderBottom: "none" }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: (theme) => theme.spacing(3),
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl size="small">
            <Select
              disableUnderline
              value={pageNo}
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              onChange={handlePageChange}
            >
              {noOfPages &&
                noOfPages.length > 0 &&
                noOfPages?.map((pageNo) => (
                  <MenuItem value={pageNo}>{pageNo}</MenuItem>
                ))}
                {/* {noOfPages.map((pageNo) => ( 
                  <MenuItem key={pageNo} value={pageNo}>{pageNo}</MenuItem>
                ))} */}
            </Select>
          </FormControl>
          <Typography variant="h5">
            of {noOfPages.at(-1)}{" "}
            {noOfPages && noOfPages?.length > 1 ? "pages" : "page"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: (theme) => theme.spacing(2),
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={showPrevPage}
              disabled={activePage <= noOfPages[0] || count === 0}
            >
              <ChevronLeftRoundedIcon />
            </IconButton>
            <Typography variant="h5">Previous</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5">Next</Typography>

            <IconButton
              onClick={showNextPage}
              disabled={activePage >= noOfPages.at(-1) || count === 0}
            >
              <ChevronRightRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
