import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import ReStatisticalReportComponent from "../../../views/Admin/RetailerStatisticalReport/ReStatisticalReport.component";


// =================== Order Page ================= //
const ReStatisticalReport = () => {
  return <WithLayout layout={Admin} component={ReStatisticalReportComponent} />;
};


export default ReStatisticalReport;