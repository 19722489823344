import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { tenantInstance } from "../../configs/axiosConfig";
import * as ReportByDistributorAction from "./reportByDistributor.action";
import * as ReportByDistributorType from "./reportByDistributor.types";

export function* getReportByDistributorListAsync( {payload}: any) {
    const { fromDate, toDate } = payload;
    let{ user_id, } = yield select((state) => state.reportByDistributor);
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/report-by-distributor` , {
            filters: {
                user_id,
                name: "",
                beat_name: "",
                from_date: "2023-01-01" , //fromDate, //"2023-10-01", 
                to_date:  "2023-12-31",//toDate, //"2023-10-01", 
            },
            // pages:{
            //     page: 1,
            //     limit: 20,
            // },
            sorting: [
              {
                type: "desc",
                field: "created at"
              }
            ]
        });
        yield put(ReportByDistributorAction.getReportByDistributorSuccess(data));
    }
    catch(error){
        yield put(ReportByDistributorAction.getReportByDistributorFail(error));
    }
}
export function* watchGetReportByDistributorListAsync(){
    yield takeLatest(
        ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_START,
        getReportByDistributorListAsync,
    )
}
export function* reportByDistributorSagas(){
    yield all([
        call(watchGetReportByDistributorListAsync),
    ]);
}