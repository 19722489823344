import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as ReStatisticalReportActions from "./reStatisticalReport.action";
import * as ReStatisticalReportType from "./reStatisticalReport.types";
import { tenantInstance } from "../../configs/axiosConfig";

export function* getReStatisticalReportListAsync( { payload }: any ) {
    const { fromDate, toDate, page, limit, type, field } = payload;
    let{ user_id, name} = yield select((state) => state.reStatisticalReport);
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/retailers-statistical-report` , {
            filters: {
                user_id,
                name,
                format_data: "date-wise",
                beat_name: "",
                from_date: fromDate,
                to_date: toDate,
            },
            pages:{
                page,
                limit,
            },
            sorting: [
              {
                type ,//: "desc",
                field //: "created at"
              }
            ]
        });
        yield put(ReStatisticalReportActions.getReStatisticalReportSuccess(data));
    }
    catch(error){
        yield put(ReStatisticalReportActions.getReStatisticalReportFail(error));
    }
}
export function* watchGetReStatisticalReportAsync(){
    yield takeLatest(
        ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_START,
        getReStatisticalReportListAsync,
    )
}
export function* reStatisticalSagas(){
    yield all([
        call(watchGetReStatisticalReportAsync),
    ]);
}