import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import CalendarTwo from "../../../views/Admin/Visit/components/CalendarTwo";

// =================== Visit Report Page ================= //
const VisitReport = () => {
  return <WithLayout layout={Admin} component={CalendarTwo} />;
};

export default VisitReport;