export const GET_FILTERED_PRODUCTS_BY_TENANT_START =
  "GET_FILTERED_PRODUCTS_BY_TENANT_START";
export const GET_FILTERED_PRODUCTS_BY_TENANT_SUCCESS =
  "GET_FILTERED_PRODUCTS_BY_TENANT_SUCCESS";
export const GET_FILTERED_PRODUCTS_BY_TENANT_FAILURE =
  "GET_FILTERED_PRODUCTS_BY_TENANT_FAILURE";

export const GET_TENANTS_PRODUCTS_LIST_START =
  "GET_TENANTS_PRODUCTS_LIST_START";
export const GET_TENANTS_PRODUCTS_LIST_SUCCESS =
  "GET_TENANTS_PRODUCTS_LIST_SUCCESS";
export const GET_TENANTS_PRODUCTS_LIST_FAILURE =
  "GET_TENANTS_PRODUCTS_LIST_FAILURE";

//
export const FETCH_ALL_ITEMS_START = "FETCH_ALL_ITEMS_START";
export const FETCH_ALL_ITEMS_SUCCESS = "FETCH_ALL_ITEMS_SUCCESS";
export const FETCH_ALL_ITEMS_FAILURE = "FETCH_ALL_ITEMS_FAILURE";
//

export const CREATE_OR_UPDATE_PRODUCT_START = "CREATE_OR_UPDATE_PRODUCT_START";
export const CREATE_OR_UPDATE_PRODUCT_SUCCESS =
  "CREATE_OR_UPDATE_PRODUCT_SUCCESS";
export const CREATE_OR_UPDATE_PRODUCT_FAILURE =
  "CREATE_OR_UPDATE_PRODUCT_FAILURE";

export const FETCH_FILTERED_PRODUCTS_CATEGORY_START =
  "FETCH_FILTERED_PRODUCTS_CATEGORY_START";
export const FETCH_FILTERED_PRODUCTS_CATEGORY_SUCCESS =
  "FETCH_FILTERED_PRODUCTS_CATEGORY_SUCCESS";
export const FETCH_FILTERED_PRODUCTS_CATEGORY_FAILURE =
  "FETCH_FILTERED_PRODUCTS_CATEGORY_FAILURE";

export const GET_TENANTS_CATEGORIES_LIST_START =
  "GET_TENANTS_CATEGORIES_LIST_START";
export const GET_TENANTS_CATEGORIES_LIST_SUCCESS =
  "GET_TENANTS_CATEGORIES_LIST_SUCCESS";
export const GET_TENANTS_CATEGORIES_LIST_FAILURE =
  "GET_TENANTS_CATEGORIES_LIST_FAILURE";

export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const FETCH_PRODUCT_DETAILS_START = "FETCH_PRODUCT_DETAILS_START";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAILURE = "FETCH_PRODUCT_DETAILS_FAILURE";

export const CREATE_OR_UPDATE_CATEGORY_START =
  "CREATE_OR_UPDATE_CATEGORY_START";
export const CREATE_OR_UPDATE_CATEGORY_SUCCESS =
  "CREATE_OR_UPDATE_CATEGORY_SUCCESS";
export const CREATE_OR_UPDATE_CATEGORY_FAILURE =
  "CREATE_OR_UPDATE_CATEGORY_FAILURE";

  //
  export const CREATE_ORDER_START = "CREATE_ORDER_START";
  export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
  export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
  //

export const DELETE_CATEGORY_START = "DELETE_CATEGORY_START";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const FETCH_SOLO_CATEGORY_START = "FETCH_SOLO_CATEGORY_START";
export const FETCH_SOLO_CATEGORY_SUCCESS = "FETCH_SOLO_CATEGORY_SUCCESS";
export const FETCH_SOLO_CATEGORY_FAILURE = "FETCH_SOLO_CATEGORY_FAILURE";

export const ON_CHANGE_APPLY_PRODUCT_FILTER = "ON_CHANGE_APPLY_PRODUCT_FILTER";
export const ON_CHANGE_APPLY_PRODUCT_CLEAR_FILTER = "ON_CHANGE_APPLY_PRODUCT_CLEAR_FILTER"

export const ON_CHANGE_APPLY_CATEGORY_FILTER = "ON_CHANGE_APPLY_PRODUCT_FILTER";

export const ON_CHANGE_APPLY_PRODUCT_CATEGORY_CLEAR_FILTER = "ON_CHANGE_APPLY_PRODUCT_CATEGORY_CLEAR_FILTER"
