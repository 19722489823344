import { connect } from "react-redux";
import { getReStatisticalReportStart } from '../../../store/restatisticalreport/reStatisticalReport.action';

const mapStateToProps = (state) => {
    return{
        reStatisticalReport: state.reStatisticalReport,
      }
};

const mapDispatchToProps = (dispatch) => ({
    onGetReStatisticalReportStart: (fromDate, toDate, page, limit, type, field) => dispatch(getReStatisticalReportStart(fromDate, toDate, page, limit, type, field)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;