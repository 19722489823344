import * as SalseReportType from "./salesReport.types";

export const getSalesReportListStart = (fromDate, toDate, page, limit, type, field,) => {
    return{
    type: SalseReportType.GET_SALES_REPORT_LIST_START,
    payload: { fromDate, toDate, page, limit, type, field,}
    }   
};
  
export const getSalesReportListSuccess = (result) => ({
    type: SalseReportType.GET_SALES_REPORT_LIST_SUCCESS,
    payload: result,
});
  
export const getSalesReportListFail = (error) => ({
    type: SalseReportType.GET_SALES_REPORT_LIST_FAILURE,
    payload: error,
});

//For Search
export const searchSalesReportName = ( key, value ) => ({
    type: SalseReportType.SEARCH_SALES_REPORT_NAME,
    payload: { key, value }
});

// Today
export const getSalesReportTodayStart = (fromDate, toDate) => ({
    type: SalseReportType.GET_SALES_REPORT_TODAY_START,
    payload: { fromDate, toDate }
});
export const getSalesReportTodaySuccess = (result) => ({
    type: SalseReportType.GET_SALES_REPORT_TODAY_SUCCESS,
    payload: result,
});
export const getSalesReportTodayFailure = (error) => ({
    type: SalseReportType.GET_SALES_REPORT_TODAY_FAILURE,
    payload: error,
});

// Yesterday
export const getSalesReportYesterdayStart = (fromDate, toDate) => ({
    type: SalseReportType.GET_SALES_REPORT_YESTERDAY_START,
    payload: { fromDate, toDate }
});
export const getSalesReportYesterdaySuccess = (result) => ({
    type: SalseReportType.GET_SALES_REPORT_YESTERDAY_SUCCESS,
    payload: result,
});
export const getSalesReportYesterdayFailure = (error) => ({
    type: SalseReportType.GET_SALES_REPORT_YESTERDAY_FAILURE,
    payload: error,
});

// Monthly
export const getSalesReportMonthlyStart = (fromDate, toDate) => ({
    type: SalseReportType.GET_SALES_REPORT_MONTHLY_START,
    payload: { fromDate, toDate }
});
export const getSalesReportMonthlySuccess = (result) => ({
    type: SalseReportType.GET_SALES_REPORT_MONTHLY_SUCCESS,
    payload: result,
});
export const getSalesReportMonthlyFailure = (error) => ({
    type: SalseReportType.GET_SALES_REPORT_MONTHLY_FAILURE,
    payload: error,
});

// Bar
export const getOrderListForBarStart = (fromDate, toDate) => ({
    type: SalseReportType.GET_ORDER_LIST_FOR_BAR_START,
    payload: { fromDate, toDate },
});
export const getOrderListForBarSuccess = (result) => ({
    type: SalseReportType.GET_ORDER_LIST_FOR_BAR_SUCCESS,
    payload: result,
});
export const getOrderListForBarFail = (error) => ({
    type: SalseReportType.GET_ORDER_LIST_FOR_BAR_FAILURE,
    payload: error,
});

//Top Orders
export const getHighestPlacedOrderStart = (fromDate, toDate, type, field) => ({
    type: SalseReportType.GET_HIGHEST_PLACED_ORDER_START,
    payload: { fromDate, toDate, type, field },
});
export const getHighestPlacedOrderSuccess = (result) => ({
    type: SalseReportType.GET_HIGHEST_PLACED_ORDER_SUCCESS,
    payload: result,
});
export const getHighestPlacedOrderFail = (error) => ({
    type: SalseReportType.GET_HIGHEST_PLACED_ORDER_FAILURE,
    payload: error,
});