import { connect } from "react-redux";
import { getSalesReportDetailsListStart, getSalesReportDetailsMonthlyStart, getSalesReportDetailsTodayStart, 
    getSalesReportDetailsYearlyBarStart, getSalesReportDetailsYesterdayStart, getHighestPlacedOrderStart,
    getOrderListForBarStart } from "../../../store/salesreportdetails/salesReportDetails.action";

const mapStateToProps = (state) => {
    return{
        salesReportDetails: state.salesReportDetails,
        limit: state.salesReportDetails.limit,
    }
};
const mapDispatchToProps = (dispatch) => ({
    onGetSalesReportDetailsListStart: (fromDate, toDate, page, field, type, limit) => dispatch(getSalesReportDetailsListStart(fromDate, toDate, page, field, type, limit)),
    onGetSalesReportDetailsTodayStart: (fromDate, toDate, type, field) => dispatch(getSalesReportDetailsTodayStart(fromDate, toDate, type, field)),
    onGetSalesReportDetailsYesterdayStart: (fromDate, toDate, type, field) => dispatch(getSalesReportDetailsYesterdayStart(fromDate, toDate, type, field),),
    onGetSalesReportDetailsMonthlyStart: (fromDate, toDate, type, field) => dispatch(getSalesReportDetailsMonthlyStart(fromDate, toDate, type, field)),
    onGetSalesReportDetailsYearlyBarStart: (fromDate, toDate) => dispatch(getSalesReportDetailsYearlyBarStart(fromDate, toDate)),
    onGetHighestPlacedOrderStart: (fromDate, toDate, type, field) => dispatch(getHighestPlacedOrderStart(fromDate, toDate, type, field)),
    onGetOrderListForBarStart: (fromDate, toDate) => dispatch(getOrderListForBarStart(fromDate, toDate)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;