import React, { Fragment,useEffect, useState } from "react";
import { debounce } from "lodash";
// Material-ui
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Formik imports
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";

// Icons imports
import DeleteIcon from "../../../assets/images/delete.svg";
import CountrySelect from "../../../components/CountrySelect";

// Project imports
import CustomButton from "../../../components/CustomButton";
import GlobalSelect from "./components/GlobalSelect";
import CustomizedDialog from "../../../components/CustomDialog";
import container from "./Invite.container";
import { indexOf } from "lodash";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "12px",
  padding: "12px 0 6px 0",
  flex: "70%",
}));

const CardList = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  borderBottom: "1px solid #F1F3F9",
  "& > div:first-child": {
    display: "flex",
    gap: theme.spacing(2),
    "& > img": {
      height: 48,
      width: 48,
    },
    "& > div:first-child": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  "& > div:last-child": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
  },
}));

const users = [
  {
    id: 1,
    name: "Zee Patel",
    email: "zee@gmail.com",
    is: "distributor",
    phone: "+919823455644",
  },
  {
    id: 2,
    name: "Kunal Parmar",
    email: "kunal@gmail.com",
    is: "salesman",
    phone: "+919823455644",
  },
];

const roles = ["Distributor", "Salesman", "Retailer"];

const defaultCountryCode = { code: "IN", label: "India", phone: "91" }; 

const Invite = ({
  onAddInvitationStart,
  onListInvitationStart,
  onAcceptInvitationStart,
  auth: { selectedTenant },
  onFetchTenantRolesStart,
  tenant: { tenantRoles },
  invitation: { invitations },
}) => {
  
  
  const [menu, setMenu] = React.useState([]);
  
  const handleChange = (event: SelectChangeEvent, index: number) => {
   
    const newSelectedMenus = [...menu];
    console.log(menu)
    newSelectedMenus[index] = event.target.value;
   
    setMenu(newSelectedMenus);
 
    
  };

  const [inviteModal, setInviteModal] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [selectedRole, setSelectedRole] = React.useState("salesman");
  const [showAll, setShowAll] = React.useState(false);

  const handleViewAll = () => {
    setShowAll(!showAll);
  };
  // const showAll = false; 
  // const visibleInvitations =  showAll ? invitations : invitations?.slice(0, 5);
  const visibleInvitations = Array.isArray(invitations) ? (showAll ? invitations : invitations.slice(0, 5)) : [];


  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedRole(value);
  };
  const navigate = useNavigate();
  React.useEffect(() => {
    // onListInvitationStart();
    onFetchTenantRolesStart();
    // onListInvitationStart();
    fetchInvitations();
  }, []);
  // const [invitations, setInvitations] = useState([]);/
  const fetchInvitations = () => {
    onListInvitationStart((fetchedInvitations) => {
      invitations(fetchedInvitations);
    });
  };
  return (
    <Fragment>
      <Box display={"flex"} gap={(theme) => theme.spacing(6)}>
        <Box flex={"30%"}>
          <Typography variant="h3">Users</Typography>
          <Typography variant="subtitle1" m="21px 0 27px 0">
            We are growing organisation with more than 40 people in team and to
            invite new people please find below button.
          </Typography>
          <CustomButton
            name="Invite People"
            sx={{ width: "149px" }}
            handleClick={() => setInviteModal(true)}
          />
        </Box>
        <CardWrapper sx={{ height: "auto",paddingBottom:"0" }}>
      {/* Search Feature */}
      <Typography sx={{ textAlign: "right", marginRight: "26px",cursor:'pointer' }} onClick={handleViewAll}>
       {invitations?.length>5?showAll?"View Less":"View All":""} 
      </Typography>
      {visibleInvitations?.map((user, index) => (
        <CardList key={index}>
          <Typography>{user?.mobile}</Typography>
          <Box>
          <Typography>{user?.role_name}</Typography>
            {/* <GlobalSelect
            
              menu={menu[index]}
              handleChange={handleChange}
              items={tenantRoles}
            /> */}
            {/* <img src={DeleteIcon} alt="Delete" height={24} width={24} /> we don't need delete functionality */}
          </Box>
        </CardList>
      ))}
    </CardWrapper>

      </Box>
      {/* {invitations?.length > 0 && <Divider sx={{ m: "38px 0 16px 0" }} />} */}
      {/* <Box>
        {invitations?.length > 0 && (
          <Typography variant="h3">Pending Invites</Typography>
        )}
        <Box
          sx={{
            background: (theme) => theme.palette.background.paper,
            borderRadius: "12px",
            marginTop: "14px",
          }}
        >
          {invitations?.length > 0 &&
            invitations?.map((user, index) => (
              <CardList key={index}>
                <Typography>{user?.phone}</Typography>

                <Box>
                  <CustomButton name="Resend Invite" size="small" />
                  <CustomButton
                    name="Revoke Invite"
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </CardList>
            ))}
        </Box>
      </Box> */}
      <CustomizedDialog
        open={inviteModal}
        handleClose={() => setInviteModal(false)}
        maxWidth={"sm"}
        title={
          <Box>
            <Typography variant="h3">Invite Member</Typography>
          </Box>
        }
      >
        <Box>
          <Formik
            initialValues={{
              // countryCode: null,
              countryCode:  { code: "IN", label: "India", phone: "91" }, // Default country code for India
              defaultCountry: "India (+91)",
              phonenumber: "",
              selectedField: selectedRole,
            }}
            validationSchema={Yup.object().shape({
              countryCode: Yup.object().shape({
                code: Yup.string().required('Country code is required'),
                label: Yup.string().required('Country label is required'),
                phone: Yup.string().required('Country phone is required'),
              }),
              phonenumber: Yup.string()
            .required('Mobile Number is Required')
            .matches(/^\d{10}$/, 'Mobile Number should be exactly 10 digits'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              onAddInvitationStart(
                {
                  mobile: String(
                    `+${values?.countryCode?.phone}` + values?.phonenumber
                  ),  
                  tenant_id: selectedTenant?.id,
                  role_id:
                    values?.selectedField === "salesman"
                      ? 4
                      : values?.selectedField === "retailer"
                      ? 5
                      : values?.selectedField === "distributor"
                      ? 6
                      : null,
                },
                () => {
                  setOpenSnackbar(true);
                  setSubmitting(false);
                  setInviteModal(false);
                  fetchInvitations();
                  navigate("/manage/invite");
                }
              );
            }}
          >
            {({
              isSubmitting,
              values,
              handleSubmit,
              errors,
              setFieldValue,
              submitForm,
            }) => {
              return (
                <Form>
                  <Stack spacing={4}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box flex={"40%"}>
                        <Field name="countryCode">
                          {({
                            field,
                            form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <div>
                              <CountrySelect
                                setFieldValue={setFieldValue}
                                field={field}
                                value={field.value}
                              />

                              {meta.touched && meta.error && (
                                <FormHelperText style={{ color: "red" }}>
                                  {meta.error}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                        </Field>
                      </Box>
                      <Box flex={"60%"}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="number"
                          autoFocus
                          fullWidth
                          name="phonenumber"
                          id="phonenumber"
                          label="Mobile Number"
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="salesman"
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                        value={selectedRole}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            value="salesman"
                            control={<Radio />}
                            label="Salesman"
                          />
                          <Typography variant="h6" pl={4}>
                            Salesman is representative of your organisation and
                            can take order from retailer on behalf of
                            distributor or manufacturer
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            value="retailer"
                            control={<Radio />}
                            label="Retailer"
                          />
                          <Typography variant="h6" pl={4}>
                            Retailer is owner of shop who directly order product
                            or via salesman and sales to end customer also can
                            order directly to manufacturer.
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            value="distributor"
                            control={<Radio />}
                            label="Distributor"
                          />
                          <Typography variant="h6" pl={4}>
                            Retailer is owner of shop who directly order product
                            or via salesman and sales to end customer also can
                            order directly to manufacturer.
                          </Typography>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                    <Stack direction={"row"} width={"100%"} spacing={4}>
                      <CustomButton
                        variant="outlined"
                        name="Cancel"
                        sx={{ width: "50%" }}
                        handleClick={() => setInviteModal(false)}
                      />
                      <CustomButton
                        name="Save"
                        sx={{ width: "50%" }}
                        handleClick={submitForm}
                      />
                    </Stack>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </CustomizedDialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="User Invited"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Fragment>
  );
};

export default container(Invite);
