import { Box, TableContainer, Paper, Table, TableRow, TableHead, TableCell
, TableBody } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState, } from "react";
import { EnhancedTableHead } from '../../../../components/CustomTable';

const Calendar = ({ fromDate, toDate, 
    onGetItemStatisticalReportListStart,
    itemStatisticalReport: {itemStatisticalReportData, itemPageDetails, grandTotalSales, monthTotalSales}}
    ) => {
    const [dense, setDense] = React.useState(false);

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("name");
    const [isMonthView, setIsMonthView] = React.useState(false);

    useEffect(() => {
        const startMoment = moment(fromDate);
        const endMoment = moment(toDate);
    
        const diffInMonths = endMoment.diff(startMoment, 'months');
    
        setIsMonthView(diffInMonths >= 1); 
    }, [fromDate, toDate]);
    
    interface HeadCell {
        disablePadding: boolean;
        id: any;
        label: string;
        isShow: boolean;
        numeric: boolean;
        align?: string;
    }
    const headCells: readonly HeadCell[] = [
        {
            id: "itemcode",
            numeric: false,
            disablePadding: true,
            label: "Item Code",
            isShow: true,
          },
        {
            id: "price",
            numeric: false,
            disablePadding: true,
            label: "Price (In Rs.)",
            isShow: true,
        },
        {
            id: "total_sales",
            numeric: false,
            disablePadding: true,
            label: "Total Sale & Quantity",
            isShow: true,
        },
    ]

    const getColumnsForDateRange = (fromDate, toDate, isMonth = false) => {
        const columns = [];
        const currentDate = new Date(fromDate);
    
        while (currentDate <= new Date(toDate)) {
            const formattedColumn = isMonth
                ? moment(currentDate).format("YYYY-MM")
                : moment(currentDate).format("YYYY-MM-DD");
    
            columns.push({
                id: formattedColumn,
                numeric: false,
                disablePadding: false,
                label: isMonth ? moment(currentDate).format("MMMM") : formattedColumn,
                isShow: true,
            });
    
            if (isMonth) {
                currentDate.setMonth(currentDate.getMonth() + 1);
            } else {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    
        return columns;
    };    
    
    const dateRangeColumns = getColumnsForDateRange(fromDate, toDate);
    const monthRangeColumns = getColumnsForDateRange(fromDate, toDate, true);
    const allColumns = [...headCells, ...(isMonthView ? monthRangeColumns : dateRangeColumns)]; 

    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
        // `console.log("field---->", field);
        onGetItemStatisticalReportListStart(fromDate, toDate, itemPageDetails?.page, itemPageDetails?.limit, isAsc ? "asc" : "desc", field, );
    };
    React.useEffect(() => {
        onGetItemStatisticalReportListStart(fromDate, toDate, 1, 10, 'asc', 'name');
    }, []);
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                                numSelected={0}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={() => {}}
                                onRequestSort={(event, property) => handleRequestSort(property)}
                                rowCount={itemStatisticalReportData?.length}
                                headCells={allColumns}
                                isCheckBox={false}
                            />
                            <TableBody>
                                {itemStatisticalReportData && itemStatisticalReportData?.length > 0 ? (
                                    itemStatisticalReportData?.map((row, index) => {
                                        return(
                                            <TableRow key={index}>
                                                <TableCell align = "center" sx = {{borderTop: "1px solid #ebeded",
                                                    height: "75px", whiteSpace: "nowrap",
                                                }}>
                                                    {row.sku ? row?.sku : "-"}
                                                </TableCell>
                                                <TableCell align = "center" sx = {{borderTop: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                    height: "75px"
                                                }}>
                                                    {row.price !== undefined ? row.price.toFixed(2) : "0.00"}
                                                </TableCell>
                                                <TableCell align = "center" sx = {{borderTop: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                    height: "75px"
                                                }}>
                                                    {row.total_sales ? `${row?.total_sales} (${row?.total_quantity})`: "-"}
                                                </TableCell>
                                                {isMonthView ? (
                                                    monthRangeColumns.map((monthColumn) => (
                                                      <TableCell
                                                          key={monthColumn.id}
                                                          align="center"
                                                          sx={{
                                                              borderTop: "1px solid #ebeded",
                                                              borderRight: "1px solid #ebeded",
                                                              borderLeft: "1px solid #ebeded",
                                                          }}
                                                      >
                                                          {row.sales &&
                                                          row.month_wise_data[monthColumn.id] !== undefined &&
                                                          row.month_wise_data[monthColumn.id] !== null
                                                              ? row.month_wise_data[monthColumn.id]
                                                              : "0"}
                                                      </TableCell>
                                                    ))
                                                    ) : (
                                                      dateRangeColumns.map((dateColumn) => (
                                                        <TableCell
                                                          key={dateColumn.id}
                                                          align="center"
                                                          sx={{
                                                            borderTop: "1px solid #ebeded",
                                                            borderRight: "1px solid #ebeded",
                                                            borderLeft: "1px solid #ebeded",
                                                          }}
                                                        >
                                                          {row.sales &&
                                                            row.sales.find((sale) => sale.date === dateColumn.id) !== undefined
                                                            ? `${row.sales.find((sale) => sale.date === dateColumn.id).total_sales}
                                                            (${row.sales.find((sale) => sale.date === dateColumn.id).total_quantity})`
                                                            : "0"}
                                                        </TableCell>
                                                      ))
                                                    )}
                                            </TableRow>
                                        )
                                    })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center">
                                              {/* <img src={Loading}/> */}
                                              No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            <TableCell
                                key="totalSale"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    // height: "75px",
                                    fontWeight: "bold", 
                                }}
                            >
                                -
                            </TableCell>
                            <TableCell
                                key="totalSale"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                -
                            </TableCell>
                            <TableCell
                                key="totalSale"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                               {grandTotalSales ? `${grandTotalSales?.grand_total_sales} (${grandTotalSales?.grand_total_quantity})` : "0(0)"}
                            </TableCell>

                            {dateRangeColumns.map((dateColumn) => (
                                <TableCell
                                key={dateColumn.id}
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                                >
                               {monthTotalSales &&
                                monthTotalSales.find((sale) => sale.date === dateColumn.id) !== undefined
                                ? `${monthTotalSales.find((sale) => sale.date === dateColumn.id).total_sales}
                                (${monthTotalSales.find((sale) => sale.date === dateColumn.id).total_quantity})`
                                : "0"}
                                </TableCell>
                            ))}
                        </Table>
                    </TableContainer>
                </Box>
            </Box> 
        </Fragment>
    )
}
export default Calendar;