import React from "react";

// Material-ui
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
    centered
  />
))({
  // "& .MuiButtonBase-root:first-child": {
  //   filter: "drop-shadow(0 30px 48px rgba(21, 44, 115, 0.15))",
  //   borderRadius: "50px 0 0 0",
  //   width: "180px",
  //   display: "flex",
  //   alignItems: "flex-end",
  // },
  // "& .MuiButtonBase-root:nth-child(2)": {
  //   filter: "drop-shadow(0 10px 48px rgba(21, 44, 115, 0.15))",
  //   borderRadius: "0 50px 0 0",
  //   width: "180px",
  //   display: "flex",
  //   alignItems: "flex-start",
  // },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    border: "2px solid #05ADAD",
  },
});

interface StyledTabProps {
  label: string;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  color: "#4A5569",
  backgroundColor: "#fff",
  "&.Mui-selected": {
    color: "#05ADAD",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#05ADAD",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
