import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Admin from "../../layouts/Admin";
import OrderScreen from "../../views/Admin/Orders";

// =================== Order Page ================= //
const Orders = () => {
  return <WithLayout layout={Admin} component={OrderScreen} />;
};

export default Orders;
