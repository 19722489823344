import * as TraderTypes from "./traders.types";

// User list based on roles
export const fetchUsersBasedOnRolesStart = (roleId, type, field) => ({
  type: TraderTypes.FETCH_USERS_BASED_ON_ROLE_START,
  payload: { roleId, type, field },
});

export const fetchUsersBasedOnRolesSuccess = (roleId, result) => {
  return {
  type: TraderTypes.FETCH_USERS_BASED_ON_ROLE_SUCCESS,
  payload: { roleId, result },
};
}

export const fetchUsersBasedOnRolesFail = (error) => ({
  type: TraderTypes.FETCH_USERS_BASED_ON_ROLE_FAILURE,
  payload: error,
});

// Apply Filter
export const changeApplyTraderFilter = (key, value, filterValue) => ({
  type: TraderTypes.ON_CHANGE_APPLY_TRADER_FILTER,
  payload: { key, value, filterValue },
});
  // clear trader Filter
  export const changeApplyTraderClearFilter = (filterValue) => ({
    type: TraderTypes.ON_CHANGE_APPLY_TRADER_CLEAR_FILTER,
    payload: { 
  
     data:{ f_name: "",
      email: "",
    
      l_name: "",
      mobile: ""}, 
    f_value:filterValue},
  });
