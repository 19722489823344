import React from "react";
import moment from "moment";
import { Avatar, Grid, Paper, Typography, } from "@mui/material";

const TopOrders = ({onGetHighestPlacedOrderStart,
    topOrdersData: {topOrdersData}}) => {
     
    const today = moment().format("YYYY-MM-DD");

    const [dataLoaded, setDataLoaded] = React.useState(false);

    React.useEffect(() =>{
        // onGetHighestPlacedOrderStart("2023-01-01", "2023-12-21", "desc", "total_orders_value")
        onGetHighestPlacedOrderStart("2024-01-01", today, "desc", "total_orders_value");
    }, [])

    React.useEffect(() => {
      if (topOrdersData) {
        setDataLoaded(true);
      }
    }, [topOrdersData]);

    return(
        <Grid item container xs={12} md={12} lg={12}>
            <Paper sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px', height: "495px", overflowY: "auto", overflowX: "hidden", borderRadius: "14px" }}>
                <Grid item md={12}>
                    <Typography sx={{mt: "20px", ml: "18px", fontSize: "18px", fontWeight: "bold" }}>Highest Placed Orders</Typography>
                </Grid>
                {dataLoaded && topOrdersData.length > 0 ? (
                    <Grid item container ml={"15px"} mt={"8px"}>
                        {topOrdersData.map((user, index) => (
                        <Grid container>
                            <Grid item xs={2} mt={"14px"}>
                            <Avatar sx={{ backgroundColor: "#D9D9D9", fontSize: "12px", color: "#000000", width: 32, height: 32 }}>
                              {index + 1}
                            </Avatar>
                            </Grid>
                            <Grid item xs={6} key={user.id} mt={"14px"}>
                              <Typography sx={{fontSize: "14px",}}>{user.name}</Typography>
                            </Grid>
                            <Grid item xs={4} key={user.id} mt={"14px"} sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "14px",}}>
                                    {user.total_orders_value != null ? user.total_orders_value.toFixed(2) : "0.00"}
                                </Typography>
                            </Grid>
                        </Grid>
                      ))}
                    </Grid>
                ) : (
                    <Grid item container md={12} ml={"15px"} mt={"8px"}>
                        <Grid item xs={2} mt={"14px"}>
                            <Avatar sx={{ backgroundColor: "#D9D9D9", fontSize: "12px", color: "#000000", width: 32, height: 32 }}>
                                1
                            </Avatar>
                        </Grid>

                        <Grid item xs={6} mt={"14px"}>
                            <Typography sx={{fontSize: "14px",}}>0 Orders Made Yet</Typography>
                        </Grid>

                        <Grid item xs={4} mt={"14px"}>
                            <Typography sx={{fontSize: "14px",}}> </Typography>
                        </Grid>
                    </Grid>
                )

                }
            </Paper>
        </Grid>
    )
}

export default TopOrders;