import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import ReportByBeatsComponent from "../../../views/Admin/ReportByBeats/ReportByBeats.component";


// =================== Order Page ================= //
const ReportByBeats = () => {
  return <WithLayout layout={Admin} component={ReportByBeatsComponent} />;
};


export default ReportByBeats;