import * as MasterActions from "./master.actions";
import * as MasterType from "./master.types";

import axiosConfig, { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";

export function* fetchRolesListAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield axiosConfig.get(`/masters/roles/list`);

    yield put(MasterActions.fetchRolesSuccess(data));
  } catch (error) {
    yield put(MasterActions.fetchRolesFail(error));
  }
}

export function* fetchCountriesListAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.get(`/masters/countries`);

    yield put(MasterActions.fetchCountriesSuccess(data));
  } catch (error) {
    yield put(MasterActions.fetchCountriesFail(error));
  }
}

export function* fetchStateByCountryIdAsync({ payload: { id } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/masters/states`, {
      country_id: id,
    });

    yield put(MasterActions.fetchStateByCountryIdSuccess(data));
  } catch (error) {
    yield put(MasterActions.fetchStateByCountryIdFail(error));
  }
}

export function* fetchCitiesByStateIdAsync({ payload: { id } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/masters/cities`, {
      state_id: id,
    });

    yield put(MasterActions.fetchCityByStateIdSuccess(data));
  } catch (error) {
    yield put(MasterActions.fetchCityByStateIdFail(error));
  }
}

//Beats all data with Id
export function* fetchBeatsDataByIdAsync({ payload: { formData, cb } }: any) {
  try {
    const { data } = yield tenantInstance.post(`/tenants/beats/create-update`, {
      ...formData,
    });
    console.log("data--------", data);
    yield put(openAlert(`Beat Added Successfully`, "success"));

    yield put(MasterActions.fetchBeatsDataByIdSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(MasterActions.fetchBeatsDataByIdFail(error));
    console.log("error", error?.response?.data?.message);
    yield put(
      openAlert(
        `${
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Failed to create beat"
        }`,
        "error"
      )
    );
  }
}
//Region End

export function* watchFetchRolesListAsync() {
  yield takeLatest(MasterType.FETCH_ROLES_START, fetchRolesListAsync);
}

export function* watchfetchCountriesListAsync() {
  yield takeLatest(MasterType.FETCH_COUNTRIES_START, fetchCountriesListAsync);
}

export function* watchFetchStateByCountryIdAsync() {
  yield takeLatest(
    MasterType.FETCH_STATE_BY_COUNTRY_ID_START,
    fetchStateByCountryIdAsync
  );
}

export function* watchFetchCitiesByStateIdAsync() {
  yield takeLatest(
    MasterType.FETCH_CITY_BY_STATE_ID_START,
    fetchCitiesByStateIdAsync
  );
}

export function* watchFetchBeatsDataByIdAsync(){
  yield takeLatest(
    MasterType.FETCH_BEATS_DATA_BY_ID_START,
    fetchBeatsDataByIdAsync
  );
}

export function* masterSagas() {
  yield all([
    call(watchFetchRolesListAsync),
    call(watchfetchCountriesListAsync),
    call(watchFetchStateByCountryIdAsync),
    call(watchFetchCitiesByStateIdAsync),
    call(watchFetchBeatsDataByIdAsync),
  ]);
}
