// Redux Saga Import
import { all, call } from "redux-saga/effects";

// Project imports
import { authSagas } from "./auth/auth.sagas";
import { invitationSagas } from "./invitation/invitation.sagas";
import { masterSagas } from "./masters/master.sagas";
import { inventorySagas } from "./inventory/inventory.sagas";
import { orderSagas } from "./orders/orders.sagas";
import { beatSagas } from "./beats/beats.sagas";
import { tenantSagas } from "./tenant/tenant.sagas";
import { excelSagas } from "./excel/excel.sagas";
import { UserSaga } from "./users/users.sagas";
import { traderSagas } from "./traders/traders.sagas";
import { tourScreenSagas } from "./tourScreen/tourScreen.sagas";
import { visitSagas } from "./visitlist/visit.sagas";
import { chartSagas } from "./chart/chart.sagas";
import { attendanceSagas } from "./attendance/attendance.sagas";
import { organisationScreenSagas } from "./organisation/organisation.sagas";
import { salesReportSagas } from "./salesReport/salesReport.sagas";
import { reStatisticalSagas } from "./restatisticalreport/reStatisticalReport.sagas";
import { personSalesReportSagas } from "./personsalesreport/personSalesReport.sagas";
import { reportByBeatsSagas } from "./reportbybeats/reportByBeats.sagas";
import { reportByDistributorSagas } from "./reportbydistributor/reportByDistributor.sagas";
import { salesReportDetailsSagas } from "./salesreportdetails/salesReportDetails.sagas";
import { itemStatisticalReportSagas } from "./itemstatisticalreport/itemStatisticalReport.sagas";
export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(invitationSagas),
    call(masterSagas),
    call(inventorySagas),
    call(orderSagas),
    call(beatSagas),
    call(tenantSagas),
    call(excelSagas),
    call(UserSaga),
    call(traderSagas),
    call(tourScreenSagas),
    call(visitSagas),
    call(chartSagas),
    call(attendanceSagas),
    call(organisationScreenSagas),
    call(salesReportSagas),
    call(reStatisticalSagas),
    call(personSalesReportSagas),
    call(reportByBeatsSagas),
    call(reportByDistributorSagas),
    call(salesReportDetailsSagas),
    call(itemStatisticalReportSagas),
  ]);
}
