import * as PersonSalesReportType from "./personSalesReport.types";

const INITIAL_STATE = {
    personSalesReportData: [],
    personPageDetails: null,
    grandTotalSales: null,
    monthTotalSales: null,
}

const personSalesReportReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch(type){
        case PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_START:
            return {
                ...state,
                loading: true,
            }

        case PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_SUCCESS:
            return {
                ...state,
                personSalesReportData: payload?.data?.data,
                personPageDetails: payload?.data?.page_details,
                grandTotalSales: payload?.data?.grand_total_sale,
                monthTotalSales: payload?.data?.month_date_total_sales,
                loading: true,
            }

        case PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        
        case PersonSalesReportType.SEARCH_PERSON_SALES_NAME:
            const { key, value } = action.payload;
            return {
                ...state,
                personSalesReportData: payload?.data?.data,
                personPageDetails: payload?.data?.page_details,
                grandTotalSales: payload?.data?.grand_total_sale,
                monthTotalSales: payload?.data?.month_date_total_sales,
                [key]: value,
            }
        default:
            return state;
    }
};

export default personSalesReportReducer;