import React, { Fragment } from "react";

// Material-ui
import { Box, Grid, Typography } from "@mui/material";

// Third party libraries
import { useNavigate } from "react-router-dom";

// Project imports
import CustomButton from "../../../components/CustomButton";
import CategoryTable from "./components/CategoryTable";
import ProductTable from "./components/ProductTable";
import { StyledTab, StyledTabs, TabPanel } from "../../../components/CustomTab";
import container from "./Inventory.container";
import CategoryModal from "./components/CategoryModal";

// Icons imports
import AddCategoryIcon from "../../../assets/images/addCategory.svg";
import AddProductIcon from "../../../assets/images/addProduct.svg";
import { useLocation } from "react-router-dom";
interface Product {
  id?: number;
  name: string;
  short_name: string;
  desciption: string;
  image_id?: number;
  category_id: number;
  status_id?: any;
  packsize: string;
  case_pack?: string;
  variant?: string;
  net_weight_in_grams?: string;
  net_weight_per_cv_in_kg?: string;
  sku: string;
  code?: string;
  hsn?: string;
  mrp: number;
  price: number;
  is_active?: boolean;
  scheme_information?: any;
  status?: any;
  categorys?: any;
  image_files?: any;
}

interface Category {
  name: string;
  description: string;
  order?: number;
  id?: number;
}

interface InventoryProps {
  onFetchAllFilteredProductsStart: any;
  onFetchAllTenantProductsStart: any;
  inventory: any;
  onProductDeleteStart: (formData: FormData, cb: () => void) => void;
  onFetchAllFilteredProductsCategoryStart: () => void;
  onFetchAllTenantCategoriesStart: () => void;
  onCreateOrUpdateCategoryStart: (formData: FormData, cb: () => void) => void;
  onFetchSoloCategoryStart: (id: number) => void;
  onCategoryDeleteStart: (formData: FormData, cb: () => void) => void;
  onChangeApplyProductFilter: (formData: FormData) => void;
  onChangeApplyCategoryFilter: (formData: FormData) => void;
  auth: any;
}

const Inventory: React.FC<InventoryProps> = ({
  onFetchAllFilteredProductsStart,
  onFetchAllTenantProductsStart,
  inventory,
  onProductDeleteStart,
  onFetchAllFilteredProductsCategoryStart,
  onFetchAllTenantCategoriesStart,
  onCreateOrUpdateCategoryStart,
  onFetchSoloCategoryStart,
  onCategoryDeleteStart,
  onChangeApplyProductFilter,
  onChangeApplyCategoryFilter,
  auth: { tenantProfile },
}) => {

  const location = useLocation();
  ;
  const [value, setValue] = React.useState(location.state?.tabValue===1?1:0);
  
  const [openAddModal, setOpenAddModal] = React.useState(null);
  const [addPage,setAddPage] = React.useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const menuList = [
    {
      name: "Categories",
      index: 0,
    },
    {
      name: "Products",
      index: 1,
    },
  ];

  // React.useEffect(() => {
  //   onFetchAllTenantProductsStart();
  //   onFetchAllTenantCategoriesStart();
  // }, []);

  return (
    <Fragment>
      <Box marginTop={"23px"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { md: "flex-start", sm: "center", xs: "center" },
            }}
          >
            <Typography variant="h3">
              {value === 0 ? `Categories List` : "Products List"}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              gap: (theme) => theme.spacing(1),
              justifyContent: { md: "flex-end", sm: "center", xs: "center" },
            }}
          >
            {tenantProfile?.role_details?.permissions_details?.some(
              (permission) => permission.module === "category.create"
            ) && (
              <CustomButton
                name="Add Categories"
                sx={{ width: "auto", zIndex: 2 }}
                icon={<img src={AddCategoryIcon} alt="" />}
                handleClick={() => setOpenAddModal(true)}
              />
            )}

            {tenantProfile?.role_details?.permissions_details?.some(
              (permission) => permission.module === "product.create"
            ) && (
              <CustomButton
                name="Add Products"
                handleClick={() => {
                  setAddPage(true)
                  
                  navigate("/manage/inventory/products-add",{ state: { value: true } })}}
                sx={{
                  width: "auto",
                  zIndex: 2,
                  backgroundImage: AddCategoryIcon,
                }}
                icon={<img src={AddProductIcon} alt="" />}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={"10px"} sx={{ width: "100%", zIndex: 1 }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          {menuList?.map((list) => (
            <StyledTab label={list?.name} key={list?.index} />
          ))}
        </StyledTabs>

        <TabPanel value={value} index={0}>
          <CategoryTable
            inventory={inventory}
            onFetchAllFilteredProductsCategoryStart={
              onFetchAllFilteredProductsCategoryStart
            }
            onProductDeleteStart={onProductDeleteStart}
            setOpenAddModal={setOpenAddModal}
            onCategoryDeleteStart={onCategoryDeleteStart}
            onChangeApplyCategoryFilter={onChangeApplyCategoryFilter}
            role_details={tenantProfile?.role_details}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProductTable
            inventory={inventory}
            onProductDeleteStart={onProductDeleteStart}
            onFetchAllFilteredProductsStart={onFetchAllFilteredProductsStart}
            onChangeApplyProductFilter={onChangeApplyProductFilter}
            role_details={tenantProfile?.role_details}
            onFetchAllTenantProductsStart={onFetchAllTenantProductsStart}
          />
        </TabPanel>
      </Box>

      <CategoryModal
        openAddModal={openAddModal}
        setOpenAddModal={setOpenAddModal}
        onCreateOrUpdateCategoryStart={onCreateOrUpdateCategoryStart}
        onFetchSoloCategoryStart={onFetchSoloCategoryStart}
        soloCategory={inventory.soloCategory}
        onFetchAllFilteredProductsCategoryStart={
          onFetchAllFilteredProductsCategoryStart
        }
      />
    </Fragment>
  );
};

export default container(Inventory);
