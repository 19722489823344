import React, { Fragment, } from "react";
import { Box, TableContainer, Paper, Table, TableBody, TableCell, TableRow, } from "@mui/material";
import { EnhancedTableHead } from '../../../../components/CustomTable';

const HeadColumn = ({onGetSalesReportListStart, fromDate, toDate,
    salesReport: { salesReportData, pageDetails}, }) => {
    const [dense, setDense] = React.useState(false);
    
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("user_name");

    interface HeadCell {
        disablePadding: boolean;
        id: any;
        label: string;
        isShow: boolean;
        numeric: boolean;
        align?: string;
    }
    const headCells: readonly HeadCell[] = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "User Name",
            isShow: true,
        },
    ]
    
    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
        onGetSalesReportListStart(fromDate, toDate, pageDetails?.page, pageDetails?.limit, isAsc ? "asc" : "desc", field, );
    };
    return(
        <Fragment>
            <Box sx = {{ overflow: "auto"}}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                              numSelected={0}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={() => {}}
                              onRequestSort={(event, property) => handleRequestSort(property)}
                              rowCount={salesReportData?.length}
                              headCells={headCells}
                              isCheckBox = {false}
                            />
                            <TableBody>
                                {salesReportData && salesReportData.length > 0 ? (
                                    salesReportData?.map((row, index) => {
                                        return(
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded", 
                                                    height: "75px",
                                                }}>
                                                  {row?.name ? row?.name : "-"}
                                                </TableCell>
                                            </TableRow>)
                                        }) ) :
                                            (
                                                <TableRow>
                                                    <TableCell colSpan={headCells.length} align="center">
                                                        No Data Available
                                                    </TableCell>
                                                </TableRow>
                                            )
                                    }
                            </TableBody>
                            <TableCell
                                key="totalSale"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                Total Sale
                            </TableCell>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Fragment>
    )
}

export default HeadColumn;