// Third party library
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Pages imports
import Login from "../pages/Login";
import Dashboard from "../pages/manage/dashboard";
import DashboardSlug from "../pages/manage/dashboard/dashboardItems";

import AddProducts from "../pages/manage/inventory/addProduct";
import UserProfile from "../pages/manage/userProfile";
import Invite from "../pages/manage/invite";
import Inventory from "../pages/manage/inventory";
import Orders from "../pages/manage/orders";
import Beats from "../pages/manage/beats";
import NewBeats from "../pages/manage/newBeats";
import Users from "../pages/manage/users";
import Traders from "../pages/manage/traders";
import SelectTenants from "../pages/manage/selectTenants";
import TourPlan from "../pages/manage/tour";
import AddTourPlan from "../pages/manage/tour/addTour";
import VisitList from "../pages/manage/visit/visitlist";
import VisitReports from "../pages/manage/visit/visitreports";
import Attendance from "../pages/manage/attendance/attendance";
import Setting from "../pages/manage/users/setting";
// Project imports
import ProtectedRoute from "./protectedRoute";
import { useSelector } from "react-redux";
import PublicRoute from "./publicRoute";
import Chart from "../pages/manage/chart/graphicalReport";
import AddOrder from "../pages/manage/createOrder/addOrder";
import SalseReport from "../pages/manage/reports/salesReport";
import Organisation from "../pages/manage/organisation/organisation";
import AddOrganisation from "../pages/manage/organisation/addOrganisation";
import ReStatisticalReport from "../pages/manage/reports/reStatisticalReport";
import PersonSalesReport from "../pages/manage/reports/personSalesReport";
import ReportByBeats from "../pages/manage/reports/reportByBeats";
import ReportByDistributor from "../pages/manage/reports/reportByDistributor";
import SalesReportDetails from "../pages/manage/reports/salesReportDetails";
import ItemStatisticalReport from "../pages/manage/reports/itemStatisticalReport";
import NotFound from "../pages/404";
const Router = () => {
  const { isAuthenticated, authLoading, tenantProfile } = useSelector(
    (state: any) => state.auth
  );
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        {isAuthenticated && <Route path="/" element={<Navigate to="/manage/dashboard" />} />}
        <Route
          path="/"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              authLoading={authLoading}
              tenantProfile={tenantProfile}
            />
          }
        >
          <Route path="/manage/dashboard" element={<Dashboard />} />
          <Route path="/manage/dashboard/:slug" element={<DashboardSlug />} />

          <Route path="/manage/invite" element={<Invite />} />
          {/* <Route path="/manage/products" element={<Products />} /> */}
          <Route path="/manage/inventory" element={<Inventory />} />
          <Route
            path="/manage/inventory/products-add"
            element={<AddProducts />}
          />
          <Route
            path="/manage/inventory/products-update/:id"
            element={<AddProducts />}
          />
          <Route path="/manage/user-profile" element={<UserProfile />} />
          <Route path="/manage/orders" element={<Orders />} />
          <Route path="/manage/new-beats" element={<NewBeats />} />
          <Route path="/manage/beats" element={<Beats />} />
          <Route path="/manage/users" element={<Users />} />
          <Route path="/manage/traders" element={<Traders />} />
          <Route path="/manage/selectTenants" element={<SelectTenants />} />
          <Route path="/manage/tour-plan" element={<TourPlan />} />
          <Route path="/manage/chart/graphical-report" element={<Chart />} />
          <Route
            path="/manage/tour-plan/add-tour-plan"
            element={<AddTourPlan />}
          />
          <Route path="/manage/visit-list" element={<VisitList />} />
          <Route path="/manage/visit-list/visit-reports" element={ <VisitReports />} />
          <Route path="/manage/chart/graphical-report" element={<Chart />} />
          <Route path="/manage/attendance-&-time" element={< Attendance />} />
          {/* <Route path="/manage/orders/create-order" element={< OrderCreate />}/> */}
          <Route path="/manage/users/settings" element={ <Setting />} />
          <Route path="/manage/orders/create-order" element={<AddOrder />} />
          <Route path="/manage/organisation" element={<Organisation />} />
          <Route path="/manage/organisation/add-organisation" element={<AddOrganisation />} />
          <Route path="/manage/sales-reports" element={<SalseReport />} />
          <Route path="/manage/retailers-statistical-reports" element={<ReStatisticalReport/>}/>
          <Route path="/manage/person-sales-report" element={<PersonSalesReport />} />
          <Route path="/manage/report-by-beats" element={<ReportByBeats /> } />
          <Route path="/manage/report-by-distributor" element={<ReportByDistributor />} />
          <Route path="/manage/sales-report-details" element={<SalesReportDetails />} />
          <Route path="/manage/item-statistical-report" element={<ItemStatisticalReport/>} />
        </Route>

        {/* <Route
          element={
            <PublicRoute
              isAuthenticated={isAuthenticated}
              authLoading={authLoading}
            />
          }
        > */}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<></>} />
        {/* </Route> */}
        {/* <Route path="/*" element={<NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
