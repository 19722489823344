import * as BeatsType from "./beats.types";

// Fetch Beats List
export const fetchAllBeatsListStart = (page, limit, type, field) => ({
  type: BeatsType.FETCH_BEATS_LIST_START,
  payload: { page, limit, type, field }
});

export const fetchAllBeatsListSuccess = (result) => ({
  type: BeatsType.FETCH_BEATS_LIST_SUCCESS,
  payload: result,
});

export const fetchAllBeatsListFail = (error) => ({
  type: BeatsType.FETCH_BEATS_LIST_FAILURE,
  payload: error,
});

// Create beat
export const createTenantBeatStart = (formData, cb) => ({
  type: BeatsType.CREATE_TENANT_BEAT_START,
  payload: { formData, cb },
});

export const createTenantBeatSuccess = (result) => ({
  type: BeatsType.CREATE_TENANT_BEAT_SUCCESS,
  payload: result,
});

export const createTenantBeatFail = (error) => ({
  type: BeatsType.CREATE_TENANT_BEAT_FAILURE,
  payload: error,
});

// Update beat
export const updateTenantBeatStart = (formData, cb) => ({
  type: BeatsType.UPDATE_TENANT_BEAT_START,
  payload: { formData, cb },
});

export const updateTenantBeatSuccess = (result) => ({
  type: BeatsType.UPDATE_TENANT_BEAT_SUCCESS,
  payload: result,
});

export const updateTenantBeatFail = (error) => ({
  type: BeatsType.UPDATE_TENANT_BEAT_FAILURE,
  payload: error,
});

// Delete Beat
export const deleteTenantBeatStart = (id, cb) => ({
  type: BeatsType.DELETE_TENANT_BEAT_START,
  payload: { id, cb },
});

export const deleteTenantBeatSuccess = (result) => ({
  type: BeatsType.DELETE_TENANT_BEAT_SUCCESS,
  payload: result,
});

export const deleteTenantBeatFail = (error) => ({
  type: BeatsType.DELETE_TENANT_BEAT_FAILURE,
  payload: error,
});

// Fetch Tenant Beat by id
export const fetchTenantBeatByIdStart = (id) => ({
  type: BeatsType.FETCH_TENANT_BEAT_BY_ID_START,
  payload: id,
});

export const fetchTenantBeatByIdSuccess = (result) => ({
  type: BeatsType.FETCH_TENANT_BEAT_BY_ID_SUCCESS,
  payload: result,
});

export const fetchTenantBeatByIdFail = (error) => ({
  type: BeatsType.FETCH_TENANT_BEAT_BY_ID_FAILURE,
  payload: error,
});

// Tenant Beats move trader
export const tenantBeatsMoveTraderStart = (from, to, id, cb) => ({
  type: BeatsType.TENANT_BEATS_MOVE_TRADERS_START,
  payload: { from, to, id, cb },
});

export const tenantBeatsMoveTraderSuccess = (result) => ({
  type: BeatsType.TENANT_BEATS_MOVE_TRADERS_SUCCESS,
  payload: result,
});

export const tenantBeatsMoveTraderFailure = (error) => ({
  type: BeatsType.TENANT_BEATS_MOVE_TRADERS_FAILURE,
  payload: error,
});

//filter beat for search
export const changeApplyBeatFilter = (key, value) => ({
  type: BeatsType.ON_CHANGE_APPLY_BEATS_FILTER,
  payload: { key, value },
});

// Fetch Solo Beat
export const fetchSoloBeatStart = (id) => ({
  type: BeatsType.FETCH_SOLO_BEAT_START,
  payload: { id },
});

export const fetchSoloBeatSuccess = (result) => ({
  type: BeatsType.FETCH_SOLO_BEAT_SUCCESS,
  payload: result,
});

export const fetchSoloBeatFail = (error) => ({
  type: BeatsType.FETCH_SOLO_BEAT_FAILURE,
  payload: error,
});

//Push Retailers to Beat
export const pushRetailersToBeatStart = (beat_id, traders) => {
  return{
  type: BeatsType.PUSH_RETAILER_TO_BEAT_START,
  payload: { beat_id, traders },
  }
};
export const pushRetailersToBeatSuccess = (result) => ({
  type: BeatsType.PUSH_RETAILER_TO_BEAT_SUCCESS,
  payload: result,
});
export const pushRetailerToBeatFail = (error) => ({
  type: BeatsType.PUSH_RETAILER_TO_BEAT_FAILURE,
  payload: error,
});

//Fetch Remaining Retailers
export const remainingTradorsStart = () => ({
  type: BeatsType.REMAINING_TRADERS_START,
})
export const remaingTradorsSuccess = (result) => ({
  type: BeatsType.REMAINING_TRADERS_SUCCESS,
  payload: result,
});
export const remaingTradorsFail = (error) => ({
  type: BeatsType.REMAINING_TRADERS_FAILURE,
  payload: error,
})
