import * as organisationScreenActions from "./organisation.actions";
import * as organisationScreenType from "./organisation.types";
import tenantInstance from "../../configs/axiosConfig";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";
export function* fetchAllOrganisationAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.get(`/tenants/list`);
    console.log("data", data);
    yield put(organisationScreenActions.fetchAllOrganisationSuccess(data));
  } catch (error) {
    yield put(organisationScreenActions.fetchAllOrganisationFail(error));
  }
}

export function* createOrganisationAsync({
  payload: { formData, cb },
}: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/create`,
      {
        ...formData,
      }
    );
    yield put(
      openAlert(
        `Organisation ${formData?.id ? "Updated" : "Added"} Successfully`,
        "success"
      )
    );

    yield put(organisationScreenActions.createOrganisationSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(organisationScreenActions.createOrganisationFail(error));
    console.log("error", error?.response?.data?.detail);
    yield put(
      openAlert(
        `${error?.response?.data?.detail
          ? error?.response?.data?.detail
          : "Failed to create Organisation"
        }`,
        "error"
      )
    );
  }
}
export function* watchFetchAllOrganisationAsync() {
  yield takeLatest(
    organisationScreenType.GET_ORGANISATION_LIST_START,
    fetchAllOrganisationAsync
  );
}
export function* watchcreateOrganisationAsync() {
  yield takeLatest(
    organisationScreenType.CREATE_ORGANISATION_START,
    createOrganisationAsync
  );
}
export function* organisationScreenSagas() {
  yield all([
    call(watchFetchAllOrganisationAsync),
    call(watchcreateOrganisationAsync),
  ])
}