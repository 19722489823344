import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {

  SEND_DISABLE_USER,
  SEND_ENABLE_USER,
  FETCH_FILTERED_USERS_START,
  FETCH_FILTERED_DISABLE_USERS_START,
  UPDATE_SOLO_USER_START,
  FETCH_SETTING_DATA_START,
  UPDATE_SETTING_START
} from "./users.types";
import {
  fetchAllFilteredUsersFail,
  fetchAllFilteredUsersSuccess,
  sendDisableUserSuccess,
  sendEnableUserSuccess,
  sendDisableUserFail,
  sendEnableUserFail,
  fetchAllFilteredDisabledUsersSuccess,
  fetchAllFilteredDisabledUsersFail,
  updateUserSuccess,
  updateUsersFail,
  fetchSettingDataFail,
  fetchSettingDataSuccess,
  updateSettingSuccess,
  updateSettingFail
} from "./users.action";
import * as UserAction from "./users.action";
import axios from "axios";
import { tenantInstance } from "../../configs/axiosConfig";
import { openAlert } from "../alert/alert.actions";
import * as UserTypes from "./users.types";
//filtered user
export function* fetchAllFilteredUsers({ payload }: any) {
  let {
  enabledUsers:{f_name,email,l_name,mobile,page,limit,}
   
    
  } = yield select((state) => state.user);
  console.log( f_name,
    email,
   
    l_name)
  
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenant/users/list`, {
      filters: {
        f_name,
        email,
        l_name,
        mobile
      },
      pages: {
        page,
        limit,
      },
      sorting: [{
        type: 'asc',
        field: 'name',
      }]
    });
    yield put(fetchAllFilteredUsersSuccess(data));
  } catch (error) {
    yield put(fetchAllFilteredUsersFail(error));
  }
}

export function* fetchAllFilteredDisableUsers({ payload }: any) {
  let {
    disabledUsers:{f_name,email,l_name,mobile,page,limit}
   
    
  } = yield select((state) => state.user);
  console.log( f_name,
    email,
   
    l_name)

    const { type, field } = payload;

  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenant/users/list/disabled`, {
      filters: {
  f_name,
   email,
   l_name,
   mobile
      },
      pages: {
        page,
        limit,
      },
      sorting: [{
        type: 'asc',
        field: 'name',
      }]
    });
    yield put(fetchAllFilteredDisabledUsersSuccess(data));
  } catch (error) {
    yield put(fetchAllFilteredDisabledUsersFail(error));
  }

}

export function* getReportToListAsync ({ payload} : any){
  let { } = yield select((state) => state.user);
  const { role } = payload;
  console.log("role_name---->", role);
  try{
    const { data } = yield tenantInstance.post(`/tenant/users/get-user-by-role`, {
      role_name: role
    })
    yield put(UserAction.getReportToListSuccess(data));
  }
  catch(error){
    yield put(UserAction.getReportToListFail(error));
  }
}

// //enable users list
// export function* getEnableApiData() {
//   try {
//     // Fetch user data from the API
//     const { data } = yield tenantInstance.post(`/tenant/users/list`,{
//       filters: {
//         "id": [
          
//         ],
//         "email": "",
//         "f_name": "",
//         "m_name": "",
//         "l_name": "",
//         "d_name": "",
//         "gender": "",
//         "mobile": ""
//       }
//     });
//     yield put(recieveEnableApiData(data));
//   } catch (error) {
//     yield put(recieveEnableApiDataFail(error));
//   }
// }

// //disable users list
// export function* getDisableApiData() {
//   try {
//     // Fetch user data from the API
//     const { data } = yield tenantInstance.post(`/tenant/users/list/disabled`,{
//       filters: {
//         "id": [
          
//         ],
//         "email": "",
//         "f_name": "",
//         "m_name": "",
//         "l_name": "",
//         "d_name": "",
//         "gender": "",
//         "mobile": ""
//       }
//     });
//     yield put(recieveEnableApiData(data));
//   } catch (error) {
//     yield put(recieveDisableApiDataFail(error));
//   }
// }

//disable user from enable list
function* sendDisableUserSaga(action) {
  try {
    const { data } = yield tenantInstance.put(`/tenant/users/disable`, {
      id: [action.payload],
    });
    yield put(openAlert("User is Disabled", "success"));

    yield put(sendDisableUserSuccess(data));
  } catch (error) {
    yield put(sendDisableUserFail(error));
  }
}


//enable user from disable list
function* sendEnableUserSaga(action) {
  try {
    const { data } = yield tenantInstance.put(`/tenant/users/enable`, {
      id: [action.payload],
    });
    yield put(openAlert("User is Enabled ", "success"));

    yield put(sendEnableUserSuccess(data));
  } catch (error) {
    yield put(sendEnableUserFail(error));
  }
}


//filtered users
export function* onChangeApplyUsersFilterAsync({ payload }: any) {
  try {
    // Fetch product data from the API
    const { data } = yield tenantInstance.post(`/tenant/users/list`, {
      ...payload,
    });
    yield put(fetchAllFilteredUsersSuccess(data));
  } catch (error) {
    yield put(fetchAllFilteredUsersFail(error));
  }
}
export function* onChangeApplyDisableUsersFilterAsync({ payload }: any) {
  try {
    // Fetch product data from the API
    const { data } = yield tenantInstance.post(`/tenant/users/list/disabled`, {
      ...payload,
    });
    yield put(fetchAllFilteredDisabledUsersSuccess(data));
  } catch (error) {
    yield put(fetchAllFilteredDisabledUsersFail(error));
  }
}
//Update User Profile
export function* updateUserAsync({ payload }: any) {
  try {
    const {id, f_name, l_name, email, dob, report_to} = payload;
    // Fetch product data from the API
    const { data } = yield tenantInstance.post(`/tenant/users/update`, {
      // ...payload,
      id,
      full_name: `${f_name} ${l_name}`, 
      email: email,
      user_profile: id,
      dob,
      report_to,
    });
    yield put(updateUserSuccess(data));
  } catch (error) {
    yield put(updateUsersFail(error));
  }
}

export function* fetchSettingDataAsync({ payload }: any) {
  console.log('Payload before API call:', payload);
  // /tenants/users-settings/list
  try {
  const { data } = yield tenantInstance.post(`/tenants/users-settings/list`,
  {
      user_id:payload.salesPersonId
  });
  yield put(fetchSettingDataSuccess(data));
  } catch (error) {
    yield put(fetchSettingDataFail(error));
  }

}
//update user setting 
export function* updateSettingAsync({ payload }: any) {
  console.log('Payload before API call:', payload);
  try {
    // Fetch product data from the API
    const { data } = yield tenantInstance.put(`/tenants/users-settings/create-update`, {
      ...payload,
    });
    yield put(updateSettingSuccess(data));
  } catch (error) {
    yield put(updateSettingFail(error));
  }
}
//watch filtered users list
export function* watchFetchAllFilteredUsersAsync() {
  yield takeLatest(
FETCH_FILTERED_USERS_START,
    fetchAllFilteredUsers
  );
}
export function* watchFetchAllFilteredDisableUsersAsync() {
  yield takeLatest(
FETCH_FILTERED_DISABLE_USERS_START,
    fetchAllFilteredDisableUsers
  );
}
// //watch enable users list
// export function* watchEnableFetchApi() {
//   yield takeLatest(REQUEST_ENABLE_API_DATA, getEnableApiData);
// }
// //watch disable users list
// export function* watchDisableFetchApi() {
//   yield takeLatest(REQUEST_DISABLE_API_DATA, getDisableApiData);
// }
//watch disable user from enable list

export function* watchSendDisableUser() {
  yield takeLatest(SEND_DISABLE_USER, sendDisableUserSaga);
}
//watch enable user from disable list

export function* watchSendEnableUser() {
  yield takeLatest(SEND_ENABLE_USER, sendEnableUserSaga);
}

export function* watchGetReportToListAsync(){
  yield takeLatest(UserTypes.GET_REPORT_TO_LIST_START, getReportToListAsync);
}

export function* watchUpdateUserAsync() {
  yield takeLatest(UPDATE_SOLO_USER_START, updateUserAsync);
}
export function* watchFetchSettingDataAsync(){
  yield takeLatest(
    FETCH_SETTING_DATA_START,
    fetchSettingDataAsync
  )
}
export function* watchUpdateSettingAsync() {
  yield takeLatest(UPDATE_SETTING_START, updateSettingAsync);
}
export function* UserSaga() {
  yield all([
    // call(watchEnableFetchApi),
    // call(watchDisableFetchApi),
    call(watchSendDisableUser),
    call(watchSendEnableUser),
    call(watchFetchAllFilteredUsersAsync),
    call(watchFetchAllFilteredDisableUsersAsync),
    call(watchUpdateUserAsync),
    call(watchFetchSettingDataAsync),
    call(watchUpdateSettingAsync),
    call(watchGetReportToListAsync),
  ]);
}


// https://shrihari.staging.api.pulseplay.in/api/tenant/users/view
// https://shrihari.staging.api.pulseplay.in/api/users/list
