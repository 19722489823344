import { connect } from "react-redux";

import {
  changeApplyBeatFilter,
  createTenantBeatStart,
  deleteTenantBeatStart,
  fetchAllBeatsListStart,
  fetchTenantBeatByIdStart,
  updateTenantBeatStart,
  fetchSoloBeatStart
} from "../../../store/beats/beats.actions";
import {
  fetchCityByStateIdStart,
  fetchCountriesStart,
  fetchStateByCountryIdStart,
  fetchBeatsDataByIdStart,
  
} from "../../../store/masters/master.actions";
import { viewTenantUserStart } from "../../../store/tenant/tenant.actions";
import { fetchUsersBasedOnRolesStart } from "../../../store/traders/traders.actions";

const mapStateToProps = (state) => ({
  beats: state.beats,
  master: state.master,
  tenant: state.tenant,
  traders: state.traders,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchAllBeatsListStart: (page, limit, type, field) => dispatch(fetchAllBeatsListStart(page, limit, type, field)),
  onCreateTenantBeatStart: (formData, cb) =>
    dispatch(createTenantBeatStart(formData, cb)),
  onUpdateTenantBeatStart: (formData, cb) =>
    dispatch(updateTenantBeatStart(formData, cb)),
  onDeleteTenantBeatStart: (formData, cb) =>
    dispatch(deleteTenantBeatStart(formData, cb)),
  onFetchTenantBeatByIdStart: (id) => dispatch(fetchTenantBeatByIdStart(id)),
  onFetchCountriesStart: () => dispatch(fetchCountriesStart()),
  onFetchStateByCountryIdStart: (id) =>
    dispatch(fetchStateByCountryIdStart(id)),
  onFetchCityByStateIdStart: (id) => dispatch(fetchCityByStateIdStart(id)),
  onFetchBeatsDataByIdStart: ( formData, cb ) => dispatch(fetchBeatsDataByIdStart(formData, cb)),
  // onViewTenantUserStart: () => dispatch(viewTenantUserStart()),
  onChangeApplyBeatFilter: (name, value) =>
    dispatch(changeApplyBeatFilter(name, value)),
  onFetchUsersBasedOnRolesStart: (roleId, field, type) =>
    dispatch(fetchUsersBasedOnRolesStart(roleId, field, type)),
  onfetchSoloBeatStart: (id) => dispatch(fetchSoloBeatStart(id)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
