import * as BeatsActions from "./beats.actions";
import * as BeatsType from "./beats.types";

import { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";

export function* fetchAllBeatsListAsync( {payload}: any ) {
  let { name, beat_id, city, state, country, } = yield select(
    (state) => state.beats
  );
  const { page, limit, type, field } = payload;
  try {
    // Fetch beats list from the API
    const { data } = yield tenantInstance.post(`/tenants/beats/list`, {
      filters: {
        name,
        beat_id,
        city_id: city.id ? [city.id] : [],
        state_id: state.id ? [state.id] : [],
        country_id: country.id ? [country.id] : [],
      },
      pages: {
        page,
        limit,
      },
      sorting: [
        {
          type,
          field,
        }
      ],
    });

    yield put(BeatsActions.fetchAllBeatsListSuccess(data));
  } catch (error) {
    yield put(BeatsActions.fetchAllBeatsListFail(error));
  }
}

export function* createTenantBeatAsync({ payload: { formData, cb } }: any) {
  try {
    const { data } = yield tenantInstance.post(`/tenants/beats/create-update`, {
      ...formData,
    });
    yield put(openAlert(`Beat Added Successfully`, "success"));

    yield put(BeatsActions.createTenantBeatSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(BeatsActions.createTenantBeatSuccess(error));
    console.log("error", error?.response?.data?.message);
    yield put(
      openAlert(
        `${
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Failed to create beat"
        }`,
        "error"
      )
    );
  }
}

export function* updateTenantBeatAsync({ payload: { formData, cb } }: any) {
  try {
    const { data } = yield tenantInstance.put(
      `/tenants/beats/update/${formData?.id}`,
      {
        ...formData,
      }
    );
    yield put(openAlert(`Beat Updated Successfully`, "success"));

    yield put(BeatsActions.updateTenantBeatSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(BeatsActions.updateTenantBeatFail(error));
    console.log("error", error?.response?.data?.detail);
    yield put(
      openAlert(
        `${
          error?.response?.data?.detail
            ? error?.response?.data?.detail
            : "Failed to update beat"
        }`,
        "error"
      )
    );
  }
}

export function* deleteTenantBeatAsync({ payload: { id, cb } }: any) {
  try {
    const { data } = yield tenantInstance.delete(`/tenants/beats/delete/${id}`);
    yield put(openAlert("Beat Deleted Successfully", "success"));

    yield put(BeatsActions.deleteTenantBeatSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    console.log(error);
    yield put(BeatsActions.deleteTenantBeatFail(error));
    yield put(openAlert(`Failed to delete beat`, "error"));
    if (cb) {
      yield cb();
    }
  }
}

export function* fetchTenantBeatDetailsAsync({ payload: { id } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/beats/get/${id}`);

    yield put(BeatsActions.fetchTenantBeatByIdSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(BeatsActions.fetchTenantBeatByIdFail(error));
  }
}

export function* fetchBeatDetailsAsync({ payload: { id } }: any) {
  try {
    const { data } = yield tenantInstance.post(`/tenants/beats/get`, {
      beat_id: id
    });
    yield put(BeatsActions.fetchSoloBeatSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(BeatsActions.fetchSoloBeatFail(error));
  }
}

export function* tenantBeatsMoveTraderAsync({
  payload: { from, to, id, cb },
}: any) {
  try {
    const { data } = yield tenantInstance.post(`/tenants/beats/move-traders`, {
      from_beat_id: Number(from),
      to_beat_id: Number(to),
      trader_id: Number(id),
    });

    yield put(BeatsActions.tenantBeatsMoveTraderSuccess(data));
    yield put(openAlert("Trader Moved Successfully", "success"));

    if (cb) {
      yield cb();
    }
  } catch (error) {
    console.log(error);
    yield put(openAlert("Failed to move trader", "error"));

    yield put(BeatsActions.tenantBeatsMoveTraderFailure(error));
  }
}

export function* pushRetailerToBeatAsync({ payload }: any){
  const { selectedBeatId } = yield select(
    (state) => state.beats
  );
  const { beat_id, traders } = payload;
  try{
    
    const {data} = yield tenantInstance.post(`tenants/beats/user-traders/manage`, {
      beat_id: beat_id,
      user_id: 0,
      traders: [traders],
      // setSelectedTraderId,
      
      // beat_id: 1686,
      // user_id: 0,
      // traders: [38],
    });
    yield put(BeatsActions.pushRetailersToBeatSuccess(data));
  }
  catch(error){
    yield put(BeatsActions.pushRetailerToBeatFail(error));
  }
}

export function* remainingTradorsAync({payload}: any){
  const {f_name, l_name} = yield select(
    (state) => state.beats
  );
  try{
    const {data} = yield tenantInstance.post(`tenant/users/remaining-trader-list`, {
    });
    yield put (BeatsActions.remaingTradorsSuccess(data));
  }
  catch(error){
    yield put(BeatsActions.remaingTradorsFail(error));
  }
}

export function* watchFetchAllBeatsListAsync() {
  yield takeLatest(BeatsType.FETCH_BEATS_LIST_START, fetchAllBeatsListAsync);
}

export function* watchCreateTenantBeatAsync() {
  yield takeLatest(BeatsType.CREATE_TENANT_BEAT_START, createTenantBeatAsync);
}
export function* watchUpdateTenantBeatAsync() {
  yield takeLatest(BeatsType.UPDATE_TENANT_BEAT_START, updateTenantBeatAsync);
}
export function* watchDeleteTenantBeatAsync() {
  yield takeLatest(BeatsType.DELETE_TENANT_BEAT_START, deleteTenantBeatAsync);
}
export function* watchFetchTenantBeatDetailsAsync() {
  yield takeLatest(
    BeatsType.FETCH_TENANT_BEAT_BY_ID_START,
    fetchTenantBeatDetailsAsync
  );
}
export function* watchFetchBeatDetailsAsync() {
  yield takeLatest(
    BeatsType.FETCH_SOLO_BEAT_START,
    fetchBeatDetailsAsync
  );
}

export function* watchFenantBeatsMoveTraderAsync() {
  yield takeLatest(
    BeatsType.TENANT_BEATS_MOVE_TRADERS_START,
    tenantBeatsMoveTraderAsync
  );
}

export function* watchPushRetailerToBeatAsync(){
  yield takeLatest(
    BeatsType.PUSH_RETAILER_TO_BEAT_START,
    pushRetailerToBeatAsync
  );
}

export function* watchRemainingTradorsAync(){
  yield takeLatest(
    BeatsType.REMAINING_TRADERS_START,
    remainingTradorsAync
  )
}

export function* beatSagas() {
  yield all([
    call(watchFetchAllBeatsListAsync),
    call(watchCreateTenantBeatAsync),
    call(watchUpdateTenantBeatAsync),
    call(watchDeleteTenantBeatAsync),
    call(watchFetchTenantBeatDetailsAsync),
    call(watchFenantBeatsMoveTraderAsync),
    call(watchFetchBeatDetailsAsync),
    call(watchPushRetailerToBeatAsync),
    call(watchRemainingTradorsAync),
  ]);
}