import { connect } from "react-redux";
import {
  acceptInvitationStart,
  addInvitationStart,
  listInvitationStart,
} from "../../../store/invitation/invitation.actions";
import { fetchTenantRolesStart } from "../../../store/tenant/tenant.actions";

const mapStateToProps = (state) => ({
  invitation: state.invitation,
  auth: state.auth,
  tenant: state.tenant,
});

const mapDispatchToProps = (dispatch) => ({
  onAddInvitationStart: (formData, cb) => dispatch(addInvitationStart(formData, cb)),
  onListInvitationStart: () => dispatch(listInvitationStart()),
  onAcceptInvitationStart: () => dispatch(acceptInvitationStart()),
  onFetchTenantRolesStart: () => dispatch(fetchTenantRolesStart()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
