import * as SalesReportDetailsType from "./salesReportDetails.types";

// For Table
export const getSalesReportDetailsListStart = (fromDate, toDate, page, limit, type, field) => {
    return{
        type: SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_START,
        payload: { fromDate, toDate, page, limit, type, field}
    }
};
export const getSalesReportDetailsListSuccess = (result) => ({
    type: SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_SUCCESS,
    payload: result,
});
export const getSalesReportDetailsListFail = (error) => ({
    type: SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_FAILURE,
    payload: error,
});

// For Search
export const searchSalesDeatailsName = (key, value) => ({
    type: SalesReportDetailsType.SEARCH_SALES_DETAILS_NAME,
    payload: { key, value },
});

// For Today
export const getSalesReportDetailsTodayStart = (fromDate, toDate, type, field) => {
    return{
        type: SalesReportDetailsType.GET_SALES_DETAILS_TODAY_START,
        payload: { fromDate, toDate, type, field }
    }
}
export const getSalesReportDetailsTodaySuccess = (result) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_TODAY_SUCCESS,
    payload: result,
});
export const getSalesReportDetailsTodayFail = (error) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_TODAY_FAILURE,
    payload: error,
});

// For Yesterday
export const getSalesReportDetailsYesterdayStart = (fromDate, toDate, type, field) => {
    return{
        type: SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_START,
        payload: { fromDate, toDate, type, field }
    }
}
export const getSalesReportDetailsYesterdaySuccess = (result) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_SUCCESS,
    payload: result,
});
export const getSalesReportDetailsYesterdayFail = (error) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_FAILURE,
    payload: error,
});

// For Monthly
export const getSalesReportDetailsMonthlyStart = (fromDate, toDate, type, field) => {
    return{
        type: SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_START,
        payload: { fromDate, toDate, type, field }
    }
}
export const getSalesReportDetailsMonthlySuccess = (result) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_SUCCESS,
    payload: result,
});
export const getSalesReportDetailsMonthlyFail = (error) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_FAILURE,
    payload: error,
});

// Yearly Data for Bar
export const getSalesReportDetailsYearlyBarStart = (fromDate, toDate,) => {
    return{
        type: SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_START,
        payload: { fromDate, toDate }
    }
}
export const getSalesReportDetailsYearlyBarSuccess = (result) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_SUCCESS,
    payload: result,
});
export const getSalesReportDetailsYearlyBarFail = (error) => ({
    type: SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_FAILURE,
    payload: error,
});

// For Order By total_order_sales (For Sales Report Details)
export const getHighestPlacedOrderStart = (fromDate, toDate, type, field) => ({
    type: SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_START,
    payload: { fromDate, toDate, type, field },
});
export const getHighestPlacedOrderSuccess = (result) => ({
    type: SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_SUCCESS,
    payload: result,
});
export const getHighestPlacedOrderFail = (error) => ({
    type: SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_FAILURE,
    payload: error,
});

// For Custom Bar
export const getOrderListForBarStart = (fromDate, toDate) => ({
    type: SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_START,
    payload: { fromDate, toDate },
});
export const getOrderListForBarSuccess = (result) => ({
    type: SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_SUCCESS,
    payload: result,
});
export const getOrderListForBarFail = (error) => ({
    type: SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_FAILURE,
    payload: error,
});