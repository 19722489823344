import React, { Fragment, useState } from "react";

// Material-ui
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// Icons Imports
import FilterIcon from "../../../../assets/images/filter.svg";

// Project Imports
import CustomButton from "../../../../components/CustomButton";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AttendanceFilter = ({onFetchAttendanceUsersStart, setSelectedButton, onDateChange}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [userNameFilter, setUserNameFilter] = React.useState(""); 

  const currentDate = moment().format("YYYY-MM-DD");
  const [fromDate, setFromDate] = React.useState(currentDate);
  const [toDate, setToDate] = React.useState(currentDate);
  const [selectedFilter, setSelectedFilter] = useState('');

  const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

  const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

  const handleCustomDatePopoverOpen = (event) => {
    setCustomDatePopoverOpen(true);
  };
  
  const handleCustomDatePopoverClose = () => {
    setCustomDatePopoverOpen(false);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: "#008E8E", 
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };
  
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  // const handleFilter = () => {
  //  console.log("userNameFilter---->", userNameFilter);
  //   onFetchAttendanceUsersStart(userNameFilter);
  //   handleClose()
    
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "name"){
      setUserNameFilter(value);
    }
  };

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedFilter(selectedValue);
  };

  const handleClearFilter = () => {
    setSelectedFilter(''); 
    setUserNameFilter(''); 
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
    onFetchAttendanceUsersStart(currentDate, currentDate);
    setSelectedButton('today');
  };

  const handleFilter = () => {
    handleClose(); 
    if (dateRange.length === 1) {
      const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
      const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
      onDateChange(selectedStartDate, selectedEndDate);
      onFetchAttendanceUsersStart(selectedStartDate, selectedEndDate);
      setSelectedButton('customRange');
    }
  };
  
  // React.useEffect(() => {
  //   onFetchAttendanceUsersStart(currentDate, currentDate);
  // }, []);
  return (
    <Fragment>
      <Box
        sx={{
          width: "89px",
          height: "29px",
          padding: "18px 10px",
          borderRadius: "4px",
          border: "1px solid #45464E",
          display: "flex",
          alignItems: "center",
          gap: "9px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
          <Grid container spacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" mb={"30px"}>
                  Filter
                </Typography>                
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                    User Name
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="name"
                    id="name"
                    value={userNameFilter}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ maxLength: 30 }}
                  />
                </FormControl>
              </Grid> */}
              
              <Grid item xs={12}>
              <>
                  <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                    Date Selection
                  </FormLabel>
                  <Typography
                    sx={{ 
                      ml: 2, mt: 1,// cursor: "pointer"
                      width: "185px",
                      height: "29px",
                      padding: "18px 10px",
                      borderRadius: "4px",
                      border: "1px solid #45464E",
                      display: "flex",
                      alignItems: "center",
                      gap: "9px",
                      cursor: "pointer",
                   }}
                    onClick={handleCustomDatePopoverOpen}
                  >
                    {customDateLabel}
                  </Typography>
                  <Popover
                  open={customDatePopoverOpen}
                  anchorEl={anchorEl}
                  onClose={handleCustomDatePopoverClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  >
                    <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handleCustomDatePopoverClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                        <DateRangePicker
                          ranges={dateRange}
                          onChange={handleSelect}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          direction="vertical"
                          showDateDisplay={false}
                          editableDateInputs={false}
                        />
                      </div>    
                    </Box>
                  </Popover>
                </>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
           
              <Grid item xs={12}>
                <CustomButton
                  variant = "outlined"
                  name="Clear Filter"
                  handleClick={handleClearFilter}
                  sx={{ width: '100%' }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <CustomButton
                  name="Filter"
                  handleClick={handleFilter}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default AttendanceFilter;
