import React from "react";

// Material-ui
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

// Third party libraries
import moment from "moment";

// Project imports
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../../components/CustomTable";
import OrderFilter from "./OrderFilter";
import { useDispatch } from "react-redux";
import TableLoader, { CustomPagination } from "../../../../components/CustomTable/CustomTable";
import distributor from "../../../../assets/images/distributor.svg";
import retailer from "../../../../assets/images/retailer.svg";
import salesman from "../../../../assets/images/salesman.svg";
import admin from "../../../../assets/images/admin.svg";
import system from "../../../../assets/images/system.svg";
import Loading from "../../../../assets/images/loading.svg";

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  isShow: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Order ID",
    isShow: true,
  },
  {
    id: "placed",
    numeric: false,
    disablePadding: false,
    label: "Placed By",
    isShow: true,
  },
  {
    id: "lable",
    numeric: false,
    disablePadding: false,
    label: "",
    isShow: true,
  },
  {
    id: "items",
    numeric: true,
    disablePadding: false,
    label: "Items",
    isShow: true,
  },
  {
    id: "qty",
    numeric: true,
    disablePadding: false,
    label: "Total Qty",
    isShow: true,
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
    isShow: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Order Date",
    isShow: true,
  },
];

const OrderOnbeHalfTable = ({
  onChangeApplyOrderFilter,
  onFetchOrdersOnbeHalfStart,
  onFetchTradersStart,
  orders: { ordersOnbeHalfFilter, ordersOnbeHalf, loading, traders },
}) => {
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<any>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [noOfPages, setNoOfPages] = React.useState([]);

  const [page, setPage] = React.useState(0);

  const [activePage, setActivePage] = React.useState<any>(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    ordersOnbeHalfFilter?.limit
  );

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (event.target.checked) {
      const newSelected = sortedData?.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(
        ordersOnbeHalf?.page_details?.total / ordersOnbeHalfFilter?.limit
      );
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [ordersOnbeHalf?.page_details?.total, ordersOnbeHalfFilter?.limit]);

  React.useEffect(() => {
    onFetchOrdersOnbeHalfStart();
  }, []);

  const handleRequestSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);

    onFetchOrdersOnbeHalfStart(isAsc ? "asc" : "desc", field);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const sortedData = ordersOnbeHalf?.orders ? ordersOnbeHalf?.orders.sort(getComparator(order, orderBy)) : [];

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ordersOnbeHalf.length) : 0;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // dispatch()
    onChangeApplyOrderFilter("limit", Number(event.target.value), "orderonbehalf");
    onFetchOrdersOnbeHalfStart('asc', 'id');
  };

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeApplyOrderFilter("page", event.target.value, "orderonbehalf");
    setActivePage(Number(event.target.value));
    onFetchOrdersOnbeHalfStart('asc', 'id');
  };

  const dispatch = useDispatch();

  const handleSearchChange = (e) => {
    onChangeApplyOrderFilter("item_name", e, "orderonbehalf");
    onFetchOrdersOnbeHalfStart('asc', 'id');
  };

  const showNextPage = () => {
    onChangeApplyOrderFilter("page", activePage + 1, "orderonbehalf");
    setActivePage(activePage + 1);
    onFetchOrdersOnbeHalfStart('asc', 'id');
  };

  const showPrevPage = () => {
    onChangeApplyOrderFilter("page", activePage - 1, "orderonbehalf");
    setActivePage(activePage - 1);
    onFetchOrdersOnbeHalfStart('asc', 'id');
  };
  // Add a state variable to track whether to show the full list of order_items
  const [showFullOrderItems, setShowFullOrderItems] = React.useState({});
  // Create a function to truncate order_items and show "..." when not showing the full list
  function truncateOrderItems(orderItems) {
    const maxItemsToShow = 2; // Change this number to show more or fewer items initially
    if (orderItems.length <= maxItemsToShow) {
      return orderItems.map((el) => el?.product?.name).join(",");
    } else {
      const truncatedItems = orderItems.slice(0, maxItemsToShow);
      return `${truncatedItems.map((el) => el?.product?.name).join(",")} ...`;
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected?.length}
          handleChange={handleSearchChange}
          tableHeaderComps={
            <OrderFilter
              filterName="orderonbehalf"
              onChangeApplyOrderFilter={onChangeApplyOrderFilter}
              onFetchOrdersOnbeHalfStart={onFetchOrdersOnbeHalfStart}
              onFetchTradersStart={onFetchTradersStart}
              traders={traders}
              ordersOnbeHalfFilter={ordersOnbeHalfFilter}
            />
          }
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={(event, property) => handleRequestSort(property)}
              rowCount={sortedData?.length}
              headCells={headCells}
            />
            {/* {loading ? (
              <React.Fragment>
                {[...Array(10)].map((_, index) => (
                  <TableLoader rows={headCells} key={index} />
                ))}
              </React.Fragment>
            ) : ( */}
              <TableBody>
                {sortedData && sortedData.length > 0 ? 
                  (sortedData?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isFullOrderItemsShown = showFullOrderItems[row.id];
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">{row?.id}</TableCell>
                      <TableCell align="left">
                        {row.placed_to
                          ? row?.placed_to.f_name
                            ? row?.placed_to.f_name
                            : "" + " " + row?.placed_to.l_name
                              ? row?.placed_to.l_name
                              : ""
                          : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row?.placed_by?.role_details.name === "Sales" && (
                          <React.Fragment>
                            <img
                              src={salesman} // Use the imported image source
                              alt="salesman"
                              style={{ marginRight: "4px" }} // Add space using margin
                            />
                          </React.Fragment>
                        )}
                        {row?.placed_by?.role_details.name === "Distributor" && (
                          <React.Fragment>
                            <img
                              src={distributor} // Use the imported image source
                              alt="distributor"
                              style={{ marginRight: "4px" }} // Add space using margin
                            />
                          </React.Fragment>
                        )}
                        {row?.placed_by?.role_details.name === "Retailer" && (
                          <React.Fragment>
                            <img
                              src={retailer} // Use the imported image source
                              alt="retailer"
                              style={{ marginRight: "4px" }} // Add space using margin
                            />
                          </React.Fragment>
                        )}
                        {row?.placed_by?.role_details.name === "System" && (
                          <React.Fragment>
                            <img
                              src={system} // Use the imported image source
                              alt="system"
                              style={{ marginRight: "4px" }} // Add space using margin
                            />
                          </React.Fragment>
                        )}
                        {row?.placed_by?.role_details.name === "Admin" && (
                          <React.Fragment>
                            <img
                              src={admin} // Use the imported image source
                              alt="admin"
                              style={{ marginRight: "4px" }} // Add space using margin
                            />
                          </React.Fragment>
                        )}
                      </TableCell>
                      {/* <TableCell align="left">
                        {row?.order_items?.length > 0 &&
                          row?.order_items
                            ?.map((el) => el?.product?.name)
                            .join(",")}
                      </TableCell> */}
                      <TableCell align="left" sx = {{width: "440px"}}>
                          {isFullOrderItemsShown
                            ? row?.order_items?.map((el) => el?.product?.name).join(",")
                            : truncateOrderItems(row?.order_items)}
                          {row?.order_items.length > 2 && (
                            <span
                              style={{ cursor: "pointer", color: "blue" }}
                              // onClick={() => setShowFullOrderItems(!showFullOrderItems)}
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowFullOrderItems(prevState => ({
                                  ...prevState,
                                  [row.id]: !prevState[row.id]
                                }));
                              }}
                            >
                              {isFullOrderItemsShown ? "Read Less" : "Read More"}
                            </span>
                          )}
                      </TableCell>
                      <TableCell align="left">
                        {row?.order_items[0]?.quantity}
                      </TableCell>
                      <TableCell align="left">₹ {row?.order_value}</TableCell>
                      <TableCell align="left">
                        {row?.order_date
                          ? moment(row?.order_date).format("YYYY-MM-DD")
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })) : 
                  (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center">
                        <img src={Loading}/>
                        </TableCell>
                    </TableRow>
                  )
                }
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            {/* )} */}
          </Table>
        </TableContainer>
        <CustomPagination
          count={ordersOnbeHalf?.page_details?.total}
          rowsPerPage={rowsPerPage}
          page={activePage - 1}
          handleChangePage={() => { }}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          pageNo={ordersOnbeHalfFilter?.page}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
        />
      </Paper>
    </Box>
  );
};

export default OrderOnbeHalfTable;
