import * as InventoryType from "./inventory.types";

// Get all filtered products
export const fetchAllFilteredProductsStart = (type, field) => ({
  type: InventoryType.GET_FILTERED_PRODUCTS_BY_TENANT_START,
  payload: { type, field }
});

export const fetchAllFilteredProductsSuccess = (result) => {
  return{ type: InventoryType.GET_FILTERED_PRODUCTS_BY_TENANT_SUCCESS,
  payload: result,}
};

export const fetchAllFilteredProductsFail = (error) => ({
  type: InventoryType.GET_FILTERED_PRODUCTS_BY_TENANT_FAILURE,
  payload: error,
});

// Get all products List
export const fetchAllTenantProductsStart = () => ({
  type: InventoryType.GET_TENANTS_PRODUCTS_LIST_START,
});

export const fetchAllTenantProductsSuccess = (result) => ({
  type: InventoryType.GET_TENANTS_PRODUCTS_LIST_SUCCESS,
  payload: result,
});

export const fetchAllTenantProductsFail = (error) => ({
  type: InventoryType.GET_TENANTS_PRODUCTS_LIST_FAILURE,
  payload: error,
});

// All Items
export const fetchAllItemsStart = () => ({
  type: InventoryType.FETCH_ALL_ITEMS_START,
});
export const fetchAllItemsSuccess = (result) => ({
  type: InventoryType.FETCH_ALL_ITEMS_SUCCESS,
  payload: result,
});
export const fetchAllItemsFail = (error) => ({
  type: InventoryType.FETCH_ALL_ITEMS_FAILURE,
  payload: error,
});
//

// Create or update product
export const createOrUpdateProductStart = (formData, cb) => ({
  type: InventoryType.CREATE_OR_UPDATE_PRODUCT_START,
  payload: { formData, cb },
});

export const createOrUpdateProductSuccess = (result) => ({
  type: InventoryType.CREATE_OR_UPDATE_PRODUCT_SUCCESS,
  payload: result,
});

export const createOrUpdateProductFail = (error) => ({
  type: InventoryType.CREATE_OR_UPDATE_PRODUCT_FAILURE,
  payload: error,
});

// Fetch all Filtered category
export const fetchAllFilteredProductsCategoryStart = (type, field) => ({
  type: InventoryType.FETCH_FILTERED_PRODUCTS_CATEGORY_START,
  payload: { type, field }
});

export const fetchAllFilteredProductsCategorySuccess = (result) => ({
  type: InventoryType.FETCH_FILTERED_PRODUCTS_CATEGORY_SUCCESS,
  payload: result,
});

export const fetchAllFilteredProductsCategoryFail = (error) => ({
  type: InventoryType.FETCH_FILTERED_PRODUCTS_CATEGORY_FAILURE,
  payload: error,
});

// Get all Categories
export const fetchAllTenantCategoriesStart = () => ({
  type: InventoryType.GET_TENANTS_CATEGORIES_LIST_START,
});

export const fetchAllTenantCategoriesSuccess = (result) => ({
  type: InventoryType.GET_TENANTS_CATEGORIES_LIST_SUCCESS,
  payload: result,
});

export const fetchAllTenantCategoriesFail = (error) => ({
  type: InventoryType.GET_TENANTS_CATEGORIES_LIST_FAILURE,
  payload: error,
});

// Delete product
export const productDeleteStart = (formData, cb) => ({
  type: InventoryType.DELETE_PRODUCT_START,
  payload: { formData, cb },
});

export const productDeleteSuccess = (result) => ({
  type: InventoryType.DELETE_PRODUCT_SUCCESS,
  payload: result,
});

export const productDeleteStartFail = (error) => ({
  type: InventoryType.DELETE_PRODUCT_FAILURE,
  payload: error,
});

// Fetch product Details
export const fetchProductDetailsStart = (id) => ({
  type: InventoryType.FETCH_PRODUCT_DETAILS_START,
  payload: { id },
});

export const fetchProductDetailsSuccess = (result) => ({
  type: InventoryType.FETCH_PRODUCT_DETAILS_SUCCESS,
  payload: result,
});

export const fetchProductDetailsFail = (error) => ({
  type: InventoryType.FETCH_PRODUCT_DETAILS_FAILURE,
  payload: error,
});

// Add or update Category
export const createOrUpdateCategoryStart = (formData, cb) => ({
  type: InventoryType.CREATE_OR_UPDATE_CATEGORY_START,
  payload: { formData, cb },
});

export const createOrUpdateCategorySuccess = (result) => ({
  type: InventoryType.CREATE_OR_UPDATE_CATEGORY_SUCCESS,
  payload: result,
});

export const createOrUpdateCategoryFail = (error) => ({
  type: InventoryType.CREATE_OR_UPDATE_CATEGORY_FAILURE,
  payload: error,
});

//Create Order
// export const onCreateOrderStart = (formData, cb) => ({
//   type: InventoryType.CREATE_ORDER_START,
//   payload: {formData, cb},
// });

export const onCreateOderSuccess = (result) => ({
  type: InventoryType.CREATE_ORDER_SUCCESS,
  payload: result,
});
export const onCreateOderFail = (error) => ({
  type: InventoryType.CREATE_ORDER_FAILURE,
  payload: error,
})

// Delete Category
export const categoryDeleteStart = (formData, cb) => ({
  type: InventoryType.DELETE_CATEGORY_START,
  payload: { formData, cb },
});

export const categoryDeleteSuccess = (result) => ({
  type: InventoryType.DELETE_CATEGORY_SUCCESS,
  payload: result,
});

export const categoryDeleteStartFail = (error) => ({
  type: InventoryType.DELETE_CATEGORY_FAILURE,
  payload: error,
});

// Fetch Solo Category
export const fetchSoloCategoryStart = (id) => ({
  type: InventoryType.FETCH_SOLO_CATEGORY_START,
  payload: { id },
});

export const fetchSoloCategorySuccess = (result) => ({
  type: InventoryType.FETCH_SOLO_CATEGORY_SUCCESS,
  payload: result,
});

export const fetchSoloCategoryFail = (error) => ({
  type: InventoryType.FETCH_SOLO_CATEGORY_FAILURE,
  payload: error,
});

// Apply Product Filter
export const changeApplyProductFilter = (key, value) => ({
  type: InventoryType.ON_CHANGE_APPLY_PRODUCT_FILTER,
  payload: { key, value },
});
// clear Product Filter
export const changeApplyProductClearFilter = () => ({
  type: InventoryType.ON_CHANGE_APPLY_PRODUCT_CLEAR_FILTER,
  payload: { 
  name:"",
  sku: "",
  product_id: [],
  category: [],
  status: true,
  amount_greater_than: 0,
  amount_less_than: 0, },
});

// Apply Category Filter
export const changeApplyCategoryFilter = (key, value) => ({
  type: InventoryType.ON_CHANGE_APPLY_CATEGORY_FILTER,
  payload: { key, value },
});

// clear category Filter
export const changeApplyProductCategoryClearFilter = () => ({
  type: InventoryType.ON_CHANGE_APPLY_PRODUCT_CATEGORY_CLEAR_FILTER,
  payload: { 
  name:"",
  order:0 },
});

// Place Order
