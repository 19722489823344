import * as ItemStatisticalReportType from "./itemStatisticalReport.types";

export const getItemStatisticalReportListStart = (fromDate, toDate, page, limit, type, field) => {
    return{
        type: ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_START,
        payload: { fromDate, toDate, page, limit, type, field }
    }
}
export const getItemStatisticalReportListSuccess = (result) => ({
    type: ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_SUCCESS,
    payload: result 
});
export const getItemStatisticalReportListFail =  (error) => ({
    type: ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_FAILURE,
    payload: { error }
});

// For Search
export const searchItemStatisticalProductName = (key, value) => ({
    type: ItemStatisticalReportType.SEARCH_ITEM_STATISTICAL_PRODUCT_NAME,
    payload: { key, value },
});

// For Yearly Data
export const yearlyItemListStart = (fromDate, toDate, type, field) => ({
    type: ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_START,
    payload: { fromDate, toDate, type, field }
});
export const yearlyItemListSuccess = (result) => ({
    type: ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_SUCCESS,
    payload: result
});
export const yearlyItemListFail = (error) => ({
    type: ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_FAILURE,
    payload: error
})