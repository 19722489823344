import { takeLatest, call, put, all, select } from "redux-saga/effects";

import * as authActions from "./auth.actions";
import * as AuthType from "./auth.types";

import axiosConfig, {
  setAuthToken,
  tenantInstance,
} from "../../configs/axiosConfig";
import { openAlert } from "../alert/alert.actions";
import _ from "lodash";

export function* loadUserAsync() {
  if (localStorage["access_token"]) {
    setAuthToken(localStorage["access_token"]);
  }

  try {
    // Fetch user data from the API
    const { data: userData } = yield axiosConfig.post(`/users/me`);
    if (!localStorage["tenant_details"] && userData?.data?.tenant_details[0]) {
      localStorage.setItem(
        "tenant_details",
        JSON.stringify(userData?.data?.tenant_details[0])
      );
    }

    yield put(authActions.loadUserSuccess(userData));

    yield put(authActions.loadUserBasedOnTenantStart());
  } catch (error) {
    yield put(authActions.loadUserFail(error));
  }
}

export function* loadUserBasedOnTenantAsync() {
  try {
    // Fetch user data from the API
    const { data: userData } = yield tenantInstance.post(`/tenant/users/me`);

    yield put(authActions.loadUserBasedOnTenantSuccess(userData));
  } catch (error) {
    yield put(authActions.loadUserBasedOnTenantFailure(error));
  }
}

export function* onSigninAsync({ payload: { formData, cb } }: any) {
  try {
    // Send a sign-in request to the API
    const {
      data: { data },
    } = yield axiosConfig.post("/auth/login-user-with-password", {
      ...formData,
    });

    // Store access token in local storage
    localStorage.setItem("access_token", data?.access_token);

    yield put(
      authActions.signinSuccess({
        data,
      })
    );

    if (data["access_token"]) {
      setAuthToken(data["access_token"]);
    }

    yield put(authActions.loadUserStart());

    if (cb) {
      yield cb();
    }
  } catch (err) {
    console.error(err);
    yield put(authActions.signinFail(err?.data));
    yield put(openAlert("Incorrect username or password", "error"));
  }
}

export function* onSignInWithTokenAsync({ payload: { token, cb } }: any) {
  try {
    // Send a sign-in request to the API
    const {
      data: { data },
    } = yield axiosConfig.post("/auth/login-user", {
      firebase_token: token,
    });

    // Store access token in local storage
    localStorage.setItem("access_token", data?.access_token);

    yield put(
      authActions.signinSuccess({
        data,
      })
    );
    if (cb) {
      yield cb();
    }

    if (data["access_token"]) {
      setAuthToken(data["access_token"]);
    }

    yield put(authActions.loadUserStart());
    yield put(authActions.loadUserBasedOnTenantStart());

    // let { isTenantAuthenticated } = yield select((state) => state.auth);

    // console.log("isAuthenticated", isTenantAuthenticated);

    // if (_.isNull(isTenantAuthenticated)) {
    //   yield put(openAlert("Error fetching tenant details", "error"));
    // } else {
    //   yield put(openAlert("User Signed In Successfully", "success"));
    // }

    yield put(openAlert("User Signed In Successfully", "success"));
  } catch (err) {
    console.error(err);
    yield put(authActions.signinFail(err?.data));
    yield put(openAlert("Incorrect username or password", "error"));
  }
}

export function* signOutAsync({ payload: { history } }: any) {
  try {
    yield put(authActions.signoutSuccess());

    localStorage.removeItem("access_token");
    localStorage.removeItem("tenant_details");
    yield put(openAlert("User Signed Out Successfully", "success"));
  } catch (err) {
    console.error(err);
    yield put(authActions.signoutFail(err));
    yield put(openAlert("Failed to sign out", "error"));
  }
}

export function* changeTenant({ payload: { value, cb } }: any) {
  try {
    yield put(authActions.changeTenantSuccess(value));
    if (cb) {
      yield cb();
    }
  } catch (err) {
    console.error(err);
    yield put(authActions.changeTenantFail(err));
  }
}

export function* acceptTenantInvitationAsync({ payload: { id } }: any) {
  console.log("payload", id);
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.put(`/invitations/accept`, {
      tenant_id: id,
    });

    yield put(authActions.acceptTenantInvitationSuccess(data));
  } catch (error) {
    yield put(authActions.acceptTenantInvitationFail(error));
  }
}

export function* watchLoadUser() {
  yield takeLatest(AuthType.LOAD_USER_START, loadUserAsync);
}

export function* watchLoadUserBasedOnTenantAsync() {
  yield takeLatest(
    AuthType.LOAD_USER_BASED_ON_TENANT_START,
    loadUserBasedOnTenantAsync
  );
}

export function* watchSignin() {
  yield takeLatest(AuthType.SIGN_IN_START, onSigninAsync);
}

export function* watchSignInWithToken() {
  yield takeLatest(AuthType.SIGN_IN_WITH_TOKEN_START, onSignInWithTokenAsync);
}

export function* watchSignout() {
  yield takeLatest(AuthType.SIGN_OUT_START, signOutAsync);
}

export function* watchChangeTenant() {
  yield takeLatest(AuthType.CHANGE_TENANT_START, changeTenant);
}

export function* watchAcceptTenantInvitationAsync() {
  yield takeLatest(
    AuthType.ACCEPT_TENANT_INVITATION_START,
    acceptTenantInvitationAsync
  );
}

export function* authSagas() {
  yield all([
    call(watchSignin),
    call(watchSignout),
    call(watchLoadUser),
    call(watchLoadUserBasedOnTenantAsync),
    call(watchChangeTenant),
    call(watchSignInWithToken),
    call(watchAcceptTenantInvitationAsync),
  ]);
}
