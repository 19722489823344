import React, { useEffect, useState } from "react";

// Material-ui
import { Stack, Typography } from "@mui/material";

// Formik imports
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";

// Project imports
import CustomizedDialog from "../../../../components/CustomDialog";
import CustomButton from "../../../../components/CustomButton";
import Loading from "../../../../assets/images/loading.svg";



// ====================== Add and Update Category Component ================= //
const CategoryModal = ({
  openAddModal,
  setOpenAddModal,
  onCreateOrUpdateCategoryStart,
  onFetchSoloCategoryStart,
  soloCategory,
  onFetchAllFilteredProductsCategoryStart,
}) => {
  // If id is passed inside openAddModal checking its type it handles update and add functionality respectively
  const [loading, setLoading] = useState(true);
  
  const isUpdating = typeof openAddModal === "number";

  // useEffect(() => {
  //   if (isUpdating) {
  //     onFetchSoloCategoryStart(openAddModal);
  //   }
  // }, [isUpdating, openAddModal]);

  useEffect(() => {
    if (isUpdating) {
      onFetchSoloCategoryStart(openAddModal);
      setLoading(false); 
    }
  }, [isUpdating, openAddModal]);

  function validation(value) {
    let error;
    if (value === '' || value===false) {
      error = 'Required!';
    }
    return error;
  }

  return (
    <>
    { Object.keys(soloCategory).length === 0 && isUpdating == true ? (
      <CustomizedDialog
      open={openAddModal}
      handleClose={() => setOpenAddModal(false)}
      title={
        <Typography sx={{ color: "#111" }} variant="h3">
          {openAddModal && isUpdating ? "Update" : "Add"} Category
        </Typography>
      }
      maxWidth={"sm"}
      fullWidth={false}
      >
        <img src={Loading}/>
      </CustomizedDialog>
    ) : (
    <CustomizedDialog
      open={openAddModal}
      handleClose={() => setOpenAddModal(false)}
      title={
        <Typography sx={{ color: "#111" }} variant="h3">
          {openAddModal && isUpdating ? "Update" : "Add"} Category
        </Typography>
      }
      maxWidth={"sm"}
      fullWidth={false}
    >
      {loading && isUpdating && soloCategory.length}
      <Formik
        initialValues={{
          name: isUpdating ? soloCategory?.name : "",
          order: isUpdating ? soloCategory?.order : "0",
          description: isUpdating ? soloCategory?.description : "",
        }}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onCreateOrUpdateCategoryStart(
            {
              id: isUpdating ? openAddModal : undefined,
              ...values,
            },
            () => {
              setOpenAddModal(false);
              onFetchAllFilteredProductsCategoryStart("asc", "name");
              setSubmitting(true);
            }
          );
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Stack spacing={2} sx={{ width: 375, marginTop: 2 }}>
              <Field
                variant="filled"
                component={TextField}
                validate={validation}
                type="string"
                autoFocus
                fullWidth
                name="name"
                id="name"
                label="Name"
                InputProps={{
                  disableUnderline: true,
                  onInput: (e) => {
                    e.preventDefault();
                    const inputValue = e.target.value;
                    const acceptedValue = inputValue.slice(0, 100);
                    e.target.value = acceptedValue;
                  },
                }}
              />
              <Field
                variant="filled"
                component={TextField}
                type="number"
                autoFocus
                fullWidth
                name="order"
                id="order"
                label="Order"
                InputProps={{
                  disableUnderline: true,
                  onInput: (e) => {
                    e.preventDefault();
                    const inputValue = e.target.value;
                    const acceptedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
                    e.target.value = acceptedValue;
                  },
                }}
              />
              <Field
                fullWidth
                name="description"
                validate={validation}
                variant="filled"
                component={TextField}
                label="Description"
                multiline
                minRows={6}
                maxRows={6}
                InputProps={{
                  disableUnderline: true,
                  onInput: (e) => {
                    e.preventDefault();
                    const inputValue = e.target.value;
                    const acceptedValue = inputValue.slice(0, 200);
                    e.target.value = acceptedValue;
                  },
                }}
              />
              <Stack
                direction={"row"}
                width={"100%"}
                spacing={4}
                sx={{
                  paddingTop: (theme) => theme.spacing(8),
                  paddingBottom: 1,
                }}
              >
                <CustomButton
                  variant="outlined"
                  name="Cancel"
                  sx={{ width: "50%" }}
                  handleClick={() => setOpenAddModal(false)}
                />
                <CustomButton
                  name="Save"
                  sx={{ width: "50%" }}
                  handleClick={submitForm}
                />
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </CustomizedDialog>
    )
    }
    </>
  );
};

export default CategoryModal;