import React from "react";
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";

import User from "../../../views/Admin/UserProfile";

const UserProfile = () => {
  return <WithLayout layout={Admin} component={User} />;
};

export default UserProfile;
