// Material-ui imports
import { AppBar, Box, Toolbar } from "@mui/material";

// Icon imports
import Logo from "../../../assets/images/logo_alt.svg";

// Main Client Topbar
const Topbar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: "#fff", boxShadow: "none" }}>
        <Toolbar>
          <img src={Logo} alt="pulseplay" />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Topbar;
