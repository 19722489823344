import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import CustomButton from "../../../components/CustomButton";

import * as Yup from "yup";

import _, { isEmpty } from "lodash";

import { read, utils, writeFile } from "xlsx";

import ExcelIcon from "../../../assets/images/excel.svg";

import container from "./DashboardItems.container";
import MappingView from "./components/MappingView";
import { getHeaders } from "../../../utils/helper";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

const DashboardItems = ({
  onGetExcelFieldsStart,
  excel: { excelFields },
  onImportMappedProductsStart,
  onImportMappedBeatsStart,
  onImportMappedUserStart
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const [headers, setHeaders] = useState([]);

  const [mappingView, setMappingView] = useState(false);

  const { slug } = useParams();

  const onDrop = useCallback((files) => {
    setAcceptedFiles(files);
  }, []);

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      <img src={ExcelIcon} alt="excel" height={"20px"} width={"20px"} />{" "}
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (mappingView && slug) {
      if (slug === "products") {
        onGetExcelFieldsStart("product");
      }else if (slug === "beats") {
        onGetExcelFieldsStart("beats");
      }else if (slug === "categories") {
        onGetExcelFieldsStart("categories");
      } else if (slug === "users") {
        onGetExcelFieldsStart("invitation");
      }
    }
  }, [mappingView, slug]);

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          const values = getHeaders(wb.Sheets[sheets[0]]);
          setHeaders(values);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [acceptedFiles]);

  const [excelHeader, setExcelHeader] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setExcelHeader(event.target.value as string);
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      {mappingView ? (
        <Formik
          initialValues={{
            fieldsArr: [
              {
                fields: "",
                mandatory: false,
                excel_column: "",
              },
            ],
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            fieldsArr: Yup.array().of(
              Yup.object().shape({
                excel_column: Yup.string().when("mandatory", {
                  is: true,
                  then: (schema) =>
                    schema.required("Mandatory fields are required"),
                  otherwise: (schema) => schema,
                }),
              })
            ),
          })}
          onSubmit={(values) => {
            const formData = new FormData();
            var fieldsArr = {
              fields: values?.fieldsArr,
            };
            formData.append("data", JSON.stringify(fieldsArr));
            formData.append("file", acceptedFiles[0]);
            if (slug === "products") {
              onImportMappedProductsStart(formData, () => {
                navigate("/manage/dashboard");
              });
            }else if (slug === "beats") {
              onImportMappedBeatsStart(formData, () => {
                navigate("/manage/dashboard");
              });
            }else if (slug === "users") {
              onImportMappedUserStart(formData, () => {
                navigate("/manage/dashboard");
              });
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            touched,
            submitForm,
            resetForm,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (!isEmpty(excelFields)) {
                let newFieldArr = excelFields.map((field) => {
                  return {
                    fields: field.fields ?? "",
                    mandatory: field.mandatory,
                    excel_column: "",
                  };
                });

                setFieldValue("fieldsArr", newFieldArr);
              }
            }, [excelFields]);

            return (
              <MappingView
                tableTitleOne={`${slug ? slug : ""}`}
                tableTitleTwo={`${slug ? slug + " Details" : ""}`}
                excelFields={excelFields}
                excelHeader={excelHeader}
                handleChange={handleChange}
                headers={headers}
                formValues={values}
                submitForm={submitForm}
              />
            );
          }}
        </Formik>
      ) : (
        <Container maxWidth="md">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <Dropzone onDrop={onDrop} maxFiles={1}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  sx={{
                    border: "1.8px dashed rgba(5, 173, 173, 0.5)",
                    borderRadius: "10px",
                    width: "100%",
                    background: "#FFFFFF",
                    textAlign: "center",
                    marginBottom: (theme) => theme.spacing(6),
                    cursor: "pointer",
                    padding: (theme) => theme.spacing(6),
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  <img src={ExcelIcon} alt="Excel" />

                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "28px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Select an Excel file to import
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    or drag and drop it here
                  </Typography>
                </Box>
              )}
            </Dropzone>
            <Box
              sx={{
                width: "100%",
                "& > ul > li": {
                  textDecoration: "none",
                  listStyleType: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                },
              }}
            >
              <ul>{acceptedFileItems}</ul>
            </Box>
            <Box>
              <CustomButton
                name={" Continue to Mapping"}
                disabled={acceptedFiles?.length === 0}
                handleClick={() => setMappingView(true)}
              />
            </Box>
          </Box>
        </Container>
      )}
    </Fragment>
  );
};

export default container(DashboardItems);
