// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyVETpXv5UNB-xtXFiod09fSkn31Q3hYA",
  authDomain: "pulse-play-637fd.firebaseapp.com",
  databaseURL: "https://pulse-play-637fd.firebaseio.com",
  projectId: "pulse-play-637fd",
  storageBucket: "pulse-play-637fd.appspot.com",
  messagingSenderId: "957479354061",
  appId: "1:957479354061:web:1e9dcd524e0add48e17384",
  measurementId: "G-6XBC1JZS7Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
