// Redux import
import { combineReducers } from "redux";

// Project imports
import authReducer from "./auth/auth.reducer";
import alertReducer from "./alert/alert.reducer";
import invitationReducer from "./invitation/invitation.reducer";
import masterReducer from "./masters/master.reducers";
import inventoryReducer from "./inventory/inventory.reducers";
import ordersReducer from "./orders/orders.reducers";
import beatsReducer from "./beats/beats.reducers";
import tenantReducer from "./tenant/tenant.reducers";
import excelReducers from "./excel/excel.reducers";
import traderReducers from "./traders/traders.reducers";
import userReducers from "./users/users.reducers";
import tourScreenReducers from "./tourScreen/tourScreen.reducers";
import visitScreenReducer from "./visitlist/visit.reducers";
import chartScreenReducer from "./chart/chart.reducers";
import attendanceScreenReducer from "./attendance/attendance.reducers";
import settingDataReducer from "./users/users.reducers";
import organisationReducer from "./organisation/organisation.reducers";
import salesReportReducer from "./salesReport/salesReport.reducers";
import reStatisticalReportReducer from "./restatisticalreport/reStatisticalReport.reducers";
import personSalesReportReducer from "./personsalesreport/personSalesReport.reducers";
import reportByBeatsReducer from "./reportbybeats/reportByBeats.reducers";
import reportByDistributorReducer from "./reportbydistributor/reportByDistributor.reducers";
import salesReportDetailsReducer from "./salesreportdetails/salesReportDetails.reducers";
import itemStatisticalReportReducer from "./itemstatisticalreport/itemStatisticalReport.reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  invitation: invitationReducer,
  master: masterReducer,
  inventory: inventoryReducer,
  tenant: tenantReducer,
  orders: ordersReducer,
  beats: beatsReducer,
  excel: excelReducers,
  user: userReducers,
  traders: traderReducers,
  tourScreen: tourScreenReducers,
  visitScreen: visitScreenReducer,
  visitReport: visitScreenReducer,
  chartScreen: chartScreenReducer,
  attendanceScreen: attendanceScreenReducer,
  settingData: settingDataReducer,
  organisationScreen: organisationReducer,
  salesReport: salesReportReducer,
  reStatisticalReport: reStatisticalReportReducer,
  personSalesReport: personSalesReportReducer,
  reportByBeats: reportByBeatsReducer,
  reportByDistributor: reportByDistributorReducer,
  salesReportDetails: salesReportDetailsReducer,
  itemStatisticalReport: itemStatisticalReportReducer,
});

export default rootReducer;
