import * as AuthType from "./auth.types";

// Load user
export const loadUserStart = () => ({
  type: AuthType.LOAD_USER_START,
});

export const loadUserSuccess = (currentUser) => ({
  type: AuthType.LOAD_USER_SUCCESS,
  payload: currentUser,
});

export const loadUserFail = (error) => ({
  type: AuthType.LOAD_USER_FAILURE,
  payload: error,
});

// Load user based on tenant
export const loadUserBasedOnTenantStart = () => ({
  type: AuthType.LOAD_USER_BASED_ON_TENANT_START,
});

export const loadUserBasedOnTenantSuccess = (currentUser) => ({
  type: AuthType.LOAD_USER_BASED_ON_TENANT_SUCCESS,
  payload: currentUser,
});

export const loadUserBasedOnTenantFailure = (error) => ({
  type: AuthType.LOAD_USER_BASED_ON_TENANT_FAILURE,
  payload: error,
});

// Login User
export const signinStart = (formData, cb) => ({
  type: AuthType.SIGN_IN_START,
  payload: { formData, cb },
});

export const signinSuccess = (currentUser) => ({
  type: AuthType.SIGN_IN_SUCCESS,
  payload: currentUser,
});

export const signinFail = (error) => ({
  type: AuthType.SIGN_IN_FAILURE,
  payload: error,
});

// Logout the user
export const signoutStart = (cb) => ({
  type: AuthType.SIGN_OUT_START,
  payload: { cb },
});

export const signoutSuccess = () => ({
  type: AuthType.SIGN_OUT_SUCCESS,
});

export const signoutFail = (error) => ({
  type: AuthType.SIGN_OUT_FAILURE,
  payload: error,
});

// Change Tenant
export const changeTenantStart = (value, cb) => ({
  type: AuthType.CHANGE_TENANT_START,
  payload: { value, cb },
});

export const changeTenantSuccess = (value) => ({
  type: AuthType.CHANGE_TENANT_SUCCESS,
  payload: value,
});

export const changeTenantFail = (error) => ({
  type: AuthType.CHANGE_TENANT_FAILURE,
  payload: error,
});

// Login User with token
export const signInWithTokenStart = (token, cb) => ({
  type: AuthType.SIGN_IN_WITH_TOKEN_START,
  payload: { token, cb },
});

export const signInWithTokenSuccess = (currentUser) => ({
  type: AuthType.SIGN_IN_WITH_TOKEN_SUCCESS,
  payload: currentUser,
});

export const signInWithTokenFail = (error) => ({
  type: AuthType.SIGN_IN_WITH_TOKEN_FAILURE,
  payload: error,
});

// Accept Tenant Invitation
/// It is used here instead of tenant store because to update the state value
/// which is inside auth; after the user accepts the tenant invitaition
export const acceptTenantInvitationStart = (id) => ({
  type: AuthType.ACCEPT_TENANT_INVITATION_START,
  payload: { id },
});

export const acceptTenantInvitationSuccess = (result) => ({
  type: AuthType.ACCEPT_TENANT_INVITATION_SUCCESS,
  payload: result,
});

export const acceptTenantInvitationFail = (error) => ({
  type: AuthType.ACCEPT_TENANT_INVITATION_FAILURE,
  payload: error,
});
