import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import Setting from "../../../views/Admin/Settings";

// =================== Visit Report Page ================= //
const Settings = () => {
  return <WithLayout layout={Admin} component={Setting} />;
};

export default Settings;