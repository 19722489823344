import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as SalesReportActions from "./salesReport.action";
import * as SalesReportType from "./salesReport.types";
import { tenantInstance } from "../../configs/axiosConfig";

export function* getSalesReportListAsync({ payload }: any) {
    let{ name, user_id,} = yield select((state) => state.salesReport);
    const { fromDate, toDate, field, type, page, limit, total} = payload;
    
    try {
      const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
        filters: {
            format_data: "date-wise",
            user_id,
            name,
            from_date:  fromDate, //"2023-09-01",
            to_date: toDate,//"2023-09-27",
        },
        pages: {
          page,
          limit,
          total,
        },
        sorting: [
          {
            type,
            field,
          }
        ]
        // filters: {
        //     user_id: [3],
        //     name: "",
        //     from_date: "2023-08-01",
        //     to_date: "2023-08-01",
        // },
        // sorting: [
        //   {
        //     type: "desc",
        //     field: "created at"
        //   }
        // ]
      });
      yield put(SalesReportActions.getSalesReportListSuccess(data));
    } catch (error) {
      yield put(SalesReportActions.getSalesReportListFail(error));
    }
}
export function* watchGetSalesReportListAsync() {
    yield takeLatest(
        SalesReportType.GET_SALES_REPORT_LIST_START,
        getSalesReportListAsync
    );
}

// Today
export function* getSalesReportTodayListAsync( {payload}: any ){
  const { fromDate, toDate } = payload;

  try {
    const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
      filters: {
        from_date: fromDate,
        to_date: toDate,
      }
    });
    yield put(SalesReportActions.getSalesReportTodaySuccess(data));
  }
  catch (error) {
    yield put(SalesReportActions.getSalesReportTodayFailure(error));
  }
}
export function* watchGetSalesReportTodayListAsync() {
  yield takeLatest(
      SalesReportType.GET_SALES_REPORT_TODAY_START,
      getSalesReportTodayListAsync
  );
}

// Yesterday
export function* getSalesReportYesterdayListAsync( {payload}: any ){
  const { fromDate, toDate } = payload;

  try {
    const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
      filters: {
        from_date: fromDate,
        to_date: toDate,
      }
    });
    yield put(SalesReportActions.getSalesReportYesterdaySuccess(data));
  }
  catch (error) {
    yield put(SalesReportActions.getSalesReportYesterdayFailure(error));
  }
}
export function* watchGetSalesReportYesterdayListAsync() {
  yield takeLatest(
      SalesReportType.GET_SALES_REPORT_TODAY_START,
      getSalesReportTodayListAsync
  );
}

// Monthly
export function* getSalesReportMonthlyListAsync( {payload}: any ){
  const { fromDate, toDate } = payload;

  try {
    const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
      filters: {
        from_date: fromDate,
        to_date: toDate,
      }
    });
    yield put(SalesReportActions.getSalesReportMonthlySuccess(data));
  }
  catch (error) {
    yield put(SalesReportActions.getSalesReportMonthlyFailure(error));
  }
}
export function* watchGetSalesReportMonthlyListAsync() {
  yield takeLatest(
      SalesReportType.GET_SALES_REPORT_MONTHLY_START,
      getSalesReportMonthlyListAsync
  );
}

// Bar
export function* getOrderListForBarStartAsync({ payload }: any){
  let{ } = yield select((state) => state.salesReport);
  const { fromDate, toDate, field, type,} = payload;
  
  try {
    const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
      filters: {
          from_date: fromDate, 
          to_date: toDate,
      }
    });
    yield put(SalesReportActions.getOrderListForBarSuccess(data));
  } catch (error) {
    yield put(SalesReportActions.getOrderListForBarFail(error));
  }
}

export function* watchGetOrderListForBarStartAsync() {
  yield takeLatest(
    SalesReportType.GET_ORDER_LIST_FOR_BAR_START,
      getOrderListForBarStartAsync
);
}

// Top Orders
export function* getHighestPlacedOrderAsync({ payload }: any){
  let{ } = yield select((state) => state.salesReport);
  const { fromDate, toDate, field, type,} = payload;
  
  try {
    const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
      filters: {
          from_date: fromDate, 
          to_date: toDate,
      },
      sorting: [
        {
          type,
          field,
        }
      ]
    });
    yield put(SalesReportActions.getHighestPlacedOrderSuccess(data));
  } catch (error) {
    yield put(SalesReportActions.getHighestPlacedOrderFail(error));
  }
}

export function* watchGetHighestPlacedOrderAsync() {
yield takeLatest(
  SalesReportType.GET_HIGHEST_PLACED_ORDER_START,
  getHighestPlacedOrderAsync
);
}
export function* salesReportSagas() {
    yield all([
      call(watchGetSalesReportListAsync),
      call(watchGetSalesReportTodayListAsync),
      call(watchGetSalesReportYesterdayListAsync),
      call(watchGetSalesReportMonthlyListAsync),
      call(watchGetOrderListForBarStartAsync),
      call(watchGetHighestPlacedOrderAsync),
    ]);
}