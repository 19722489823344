import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import ItemStatisticalReportComp from "../../../views/Admin/ItemStatisticalReport/ItemStatisticalReport.component";


// =================== Order Page ================= //
const ItemStatisticalReport = () => {
  return <WithLayout layout={Admin} component={ItemStatisticalReportComp} />;
};


export default ItemStatisticalReport;