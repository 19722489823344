import React, { Fragment, useEffect, useRef, useState } from "react";

// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TableCell,
  Typography,
  styled, 
} from "@mui/material";
import { useLocation } from "react-router-dom";
// Formik imports

// Third party library

// Project imports
import CategoryCustomBtn from "../../../components/CategoryCustomButton";
import AddCustomBtn from "../../../components/AddCustomButton";
import container from "./AddOrder.container";

// Config imports

// Assets imports
import DefaultProduct from "../../../assets/images/defaultProduct.svg";
import DiscountOffer from "../../../assets/images/discount.svg";
import CustomButton from "../../../components/CustomButton";

// import { openAlert } from "../../../store/alert/alert.actions";
import { openAlert } from "../../../store/alert/alert.actions";
import { useDispatch } from "react-redux";
import Loading from "../../../assets/images/loading.svg";
// Define the custom Item component
const Item = ({ children }) => {
  return <div>{children}</div>;
};
const CustomBox = styled("div")({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "0.5px solid #B1AFAF",
  borderRadius: "10px",
  height: "100%",
  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const CustomLabel = styled("label")({
  height: "100%",
  width: "100%",
  textTransform: "none",
  background: "#F4F5FA",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  padding: "1.2rem 1.475rem",
  textAlign: "center",
});

const FileInput = styled("input")({
  display: "none",
});

// ======================= Main Add Order View ================= //
const AddOrder = ({
  onFetchAllTenantProductsStart, onOnCreateOrderStart,
  inventory, auth, onCreateOrderStart,
}) => {
  const [quantity, setQuantity] = React.useState(0);
  const [isAdditionalCardOpen, setIsAdditionalCardOpen] = React.useState(false);
  
  const [selectedCardId, setSelectedCardId] = useState([]); 
  const [ cardQuantities, setCardQuantities] = useState({});

  const [displayedProducts, setDisplayedProducts] = useState(inventory?.filtered_products);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dispatch = useDispatch();

  const isItemInCart = (itemId) => selectedCardId.includes(itemId);

  const [cardWidth, setCardWidth] = useState(null);
  
  console.log("cardWidth---->", cardWidth);
  const toggleOrderCartList = () => {
    setIsAdditionalCardOpen(!isAdditionalCardOpen);
  };
  const handleQuantityChange = (newQuantity, id) => {
    if (newQuantity <= 0) {
      setSelectedCardId(prevIds => prevIds.filter(cardId => cardId !== id));
      setCardQuantities(prevQuantities => ({
        ...prevQuantities,
        [id]: 1,
      }));
    } else {
      setCardQuantities(prevQuantities => ({
        ...prevQuantities,
        [id]: newQuantity,
      }));
    }
    const updatedCartItems = cartItems.map(item => {
      if (item.id === id) {
        return {
          ...item,
          quantity: newQuantity,
        };
      }
      return item;
    });
  
    setCartItems(updatedCartItems);
  };
  // const handleQuantityChange = (newQuantity, id) => {
  //   if (newQuantity <= 0) {
  //     setSelectedCardId(prevIds => prevIds.filter(cardId => cardId !== id));
  //     setCardQuantities(prevQuantities => ({
  //       ...prevQuantities,
  //       [id]: 1,
  //     }));
      
  //     const updatedCartItems = cartItems.filter(item => item.id !== id);
  //     setCartItems(updatedCartItems);
  //   } else {
  //     const existingQuantity = cardQuantities[id] || 0;
  //     setCardQuantities(prevQuantities => ({
  //       ...prevQuantities,
  //       [id]: existingQuantity + newQuantity,
  //     }));
    
  //     const updatedCartItems = cartItems.map(item => {
  //       if (item.id === id) {
  //         return {
  //           ...item,
  //           quantity: newQuantity,
  //         };
  //       }
  //       return item;
  //     });
    
  //     setCartItems(updatedCartItems);
  //   }
  // };  

  // useEffect(() => {
  //   if (cardWidth !== null) {
  //     console.log('Card Width:', cardWidth);
  //   }
  // }, [cardWidth]);

  useEffect(() => {
    const cardMediaRef = document.getElementById('card-media');

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        setCardWidth(newWidth);
      }
    });

    if (cardMediaRef) {
      observer.observe(cardMediaRef);
    }

    return () => observer.disconnect(); // Clean up observer on component unmount

  }, []);
  
  useEffect(() => {
    const newTotalPrice = cartItems.reduce((total, item) => {
      return total + (item.mrp * item.quantity);
    }, 0);
  
    setTotalPrice(newTotalPrice);
  }, [cartItems]);

  const handleAddButtonClick = (productIndex, id) => {
    setQuantity(1); 
    setCardQuantities(prevQuantities => ({
      ...prevQuantities,
      [id]: 1, 
    }));
    toggleOrderCartList(); 
    setSelectedCardId(prevSelectedCardIds => [...prevSelectedCardIds, id]);
    
    setSelectedItems([...selectedItems, id]);
    setCartItems(prevCartItems => [
      ...prevCartItems,
      {
        id: id,
        quantity: 1, 
      }
    ]);
  };
  const handleAddCard = (card) => {
    // setSelectedCards([...selectedCards, card]);
  };
  const cartOrderList = () => {
   
  }
  
  const handleOrderPlaceClick = (selectedCardId, cardQuantities) => {
    if (Object.keys(cardQuantities).length === 0) {
     
      // dispatch(openAlert("No items in the cart", "failure"));
      return;
    }
    onCreateOrderStart(selectedCardId, cardQuantities);
    setSelectedCardId([]);
    setCardQuantities({});
    setCartItems([]);
  }
  
  const categoryBesan = (categoryId) => {
    // const categoryData = inventory?.filtered_products.filter(item => item.category.id === categoryId);
    // setDisplayedProducts(categoryData);
    // console.log("Besan Category Clicked", categoryData);
    // console.log("CategoryId---->", categoryId);
    // console.log("inventory?.filtered_products?.category---->", inventory?.filtered_products);
    const categoryData = inventory?.filtered_products.filter(item => item.category.id === categoryId);
    setDisplayedProducts(categoryData);
    setSelectedCategory(categoryId);
    console.log("Besan Category Clicked", categoryData);
    console.log("CategoryId---->", categoryId);
    console.log("inventory?.filtered_products?.category---->", inventory?.filtered_products);

  }

  const resetDisplayedProducts = () => {
    setDisplayedProducts(inventory?.filtered_products);
  }

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    for (const cartItem of cartItems) {
      const product = inventory?.filtered_products.find(p => p.id === cartItem.id);
      if (product) {
        totalPrice += product.mrp * cartItem.quantity;
      }
    }
    return totalPrice;
  };

  const handleClearCartClick = () => {
    setCartItems([]);
    setCardQuantities({});
    setSelectedCardId([]);
  };

  const clearCart = () => {
    setCartItems([]);
    setCardQuantities({});
  };

  const cardMediaRef = useRef(null);

  // useEffect(() => {
  //   if (cardMediaRef.current) {
  //     const cardMediaWidth = cardMediaRef.current.offsetWidth;
  //     console.log('Width of CardMedia:', cardMediaWidth);
  //   }
  // }, [cardMediaRef]);
  useEffect(() => {
  if (cardMediaRef.current) {
    const cardMediaWidth = cardMediaRef.current.offsetWidth;
    if (isAdditionalCardOpen) {
      setCardWidth(cardMediaWidth);
    } else {
      setCardWidth(cardMediaWidth / 3.65);
    }
  }
  }, [cardMediaRef, isAdditionalCardOpen]);

  React.useEffect(() => {
    onFetchAllTenantProductsStart();
  }, []);
  useEffect(() => {
    resetDisplayedProducts();
  }, [inventory]);
  // React.useEffect(() => {
  //   onOnCreateOrderStart();
  // }, []);
  return (
    <>
    <Box sx={{width: "100%"}}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center",
          margin: "20px",
          "& > *": {
            margin: 0, 
          },
          marginTop: "10px",
          }}>
          <Grid sx={{flex: "3"}}>
            {/* Category Buttons */}
            <Box>
            {/* <Grid container spacing={2} sx={{ marginTop: "10px", marginRight: "10px", overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex'}}> */}
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', 
            maxWidth: `${cardWidth === 220 ? '940' : '803'}px`,
            display: 'flex'}}>
            <CategoryCustomBtn
               name="All"
               variant = "outlined"
               sx={{ width: '80px', 
               marginRight: "10px",
               }}
              handleClick={resetDisplayedProducts}
              categoryId={0} 
            />
            {inventory?.filtered_products?.map(cat => cat?.category).filter((cat, index, arr) => arr.findIndex(c => c.name === cat.name && c.id === cat.id) === index).map((category) => {
            // inventory?.filtered_products?.map((cat) => {
              // console.log("cat---->", cat?.category?.id);
              console.log("category.name---->", category.name , "--", category.name.length);
              const buttonWidth = `${category.name.length * 10}px`;
              return(
            <CategoryCustomBtn
                key={category.id}
                name={category.name} 
                // name={(cat?.category?.name)} 
                variant = "outlined"
                sx={{ width: buttonWidth,
                  // height: "50px", 
                marginTop: "6px",
                marginRight: "10px",
                flex: '0 0 auto' ,
                backgroundColor: selectedCategory === category.id ? '#F4F5FA' : 'transparent',
                color: selectedCategory === category.id ? '#008E8E' : '#000000',
                }}
                // handleClick={categoryBesan}
                handleClick={() => categoryBesan(category.id)}
                // categoryId={cat?.category?.id} 
                categoryId = {category.id}
            /> );

            })}
            </div>
            {/* </Grid> */}
            </Box>
            {/* All Items' Card */}
            <Box sx = {{flex: "3"}}>
              <Card sx={{marginLeft: "10px", marginTop: "10px",}}>
                <CardContent sx={{ flexGrow: 1, height: '505px', overflow: 'auto', padding: "2px" }}>
                {/* Main Cards’ Content */}
                <Grid container spacing={3}>
                {displayedProducts && displayedProducts.length>0 ? 
                  (displayedProducts?.map((row, index) => {
                  const imageFileData = row?.image_files?.file_encoded;
                  let imageUrl;
                  try {
                    const byteCharacters = atob(imageFileData);
                    const byteArrays = [];
                  
                  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                  
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                      byteNumbers[i] = slice.charCodeAt(i);
                    }
                  
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                  }
                
                  const blob = new Blob(byteArrays, { type: "image/jpeg" });
                  imageUrl = URL.createObjectURL(blob);
                
                  } catch (error) {
                    // Handle the error
                    console.error("File decoding error:", error);
                  }
                return(
                <Grid item xs={12} sm={6} md={4} lg={3} xl = {3} key={index} sx={{ margin: 0 }}>
                <Card key={index} sx={{ maxWidth: 260, border: '1px solid #ccc', }} id = {row?.id}
                  ref={el => el && setCardWidth(el.offsetWidth)}>
                  <CardMedia
                    id="card-media"
                    ref={cardMediaRef}
                    sx={{ height: '75px',
                    width: '82px',
                    paddingTop: '5px',
                    marginTop: '10px',
                    // marginLeft: cardMediaWidth/2,
                    marginLeft: `${cardWidth ? cardWidth/3.65 : cardWidth/2}px`, 
                    position: 'relative',
                    }}
                    
                    // image={row?.image_files?.file_encoded ? imageUrl : DefaultProduct}
                    image={row?.image_files?.name ? row?.image_files?.name : DefaultProduct}
                    title={row?.image_files?.name ? row?.image_files?.name : "Default Image"}
                  />
                  <CardContent sx={{ flexGrow: 1, maxHeight: '150px', overflow: 'auto', padding: "2px" }}>
                    <Grid item xs={12}
                      sx={{ fontSize: '12px',
                      color: '#ADADAD'}}>
                      {row?.short_name && row.short_name.includes(' ') ? row.short_name.slice(row.short_name.indexOf(' ') + 1) : row.short_name}
                    </Grid>

                    <Grid item xs={12}
                      sx={{ fontSize: '14px',
                      color: '#253D4E', }}>
                      {row?.name}
                    </Grid>
                    <Grid
                      container 
                      direction="row" alignItems="center" justifyContent="space-between"
                      sx={{
                        fontWeight: 'bold', fontSize: '16px', marginTop: '10px',
                        '& .price': {
                          marginRight: 'auto',
                        },
                        '& .button': {
                          marginLeft: 'auto',
                        },
                      }}
                    >
                      <Grid item className="price" sx = { {fontSize: "14px"}}>
                        ₹{row?.mrp} 
                      </Grid>
                      <Grid item className="button">
                    { isItemInCart(row?.id) ?
                    // selectedCardId.includes(row?.id) ? 
                    // isAdditionalCardOpen && row?.id === selectedCardId ?  
                    (
                    <>
                      <Button
                        sx={{ color: "#000000", fontSize: "14px", minWidth: "30px", height: "30px",
                        border: "1px solid #ccc", borderRadius: 0, borderTopLeftRadius: '4px', 
                        borderBottomLeftRadius: '4px', }}
                        // onClick={() => handleQuantityChange(quantity - 1, index)}
                        onClick={() => handleQuantityChange(cardQuantities[row?.id] - 1, row?.id)}
                        >-
                      </Button>
                      <Button sx={{color: "#FFFFFFFF", backgroundColor: "#008E8E",
                        '&:hover': {
                          backgroundColor: "#008E8E !important",
                        },
                        borderRadius: 0, border: "1px solid #008E8E", minWidth: "45px", height: "30px",
                        }}>{cardQuantities[row?.id]}
                      </Button>
                      {/* {quantity} */}
                      <Button
                        sx={{ color: "#000000", fontSize: "14px", minWidth: "30px", height: "30px",
                        border: "1px solid #ccc", borderRadius: 0,  borderTopRightRadius: '4px', 
                        borderBottomRightRadius: '4px',}}
                        // onClick={() => handleQuantityChange(quantity + 1, index)}
                        onClick={() => handleQuantityChange(cardQuantities[row?.id] + 1, row?.id)}
                        >+
                      </Button>
                    </>
                  ) : (
                    <AddCustomBtn
                      id = {`add-button-${row?.id}`}
                      name="Add"
                      variant="contained"
                      handleClick={() => handleAddButtonClick(index, row?.id)}
                      sx={{ fontSize: "16px", minWidth: "105px", height: "30px"}}
                    />
                  )}
                      </Grid>
                    </Grid> 
                    <Grid container item xs={12} sx={{ marginTop: "10px", alignItems: "center", marginLeft: "5px", }}>
                      <Grid>
                        <img src={DiscountOffer} height="17px" width="17px" alt="Discount Offer" />
                      </Grid>
                      <Grid sx={{ fontSize: '11px', color: '#008E8E', marginRight: "10px" }}>
                        View Offer
                      </Grid>
                      <Grid>  
                        <Grid container alignItems="center" spacing={1}>
                          {[10, 20, 30, 50].map((value, index) => (
                            <Grid item key={index}>
                              <AddCustomBtn
                                name={value.toString()}
                                variant="contained"
                                sx={{ color: "#253D4E", fontSize: "10px", height: "20px", minWidth: "25px", padding: 0 }}
                                handleClick={() => handleQuantityChange(value, row?.id)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>                      
                    </Grid>
                  </CardContent>
                </Card>
                </Grid>
                  )}) ) : 
                  (
                    // <img src={Loading}/>
                      
                        <img style={{ display: 'flex', justifyContent: 'center', 
                        alignItems: 'center', marginLeft: "300px", marginTop: "20px" }}
                        src={Loading} alt="Loading" />
                  )
                
                }
                </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          {/* New Card: Customer Information */}
          <Grid sx={{ flex: "1", width: "100%", marginTop: "10px"}}>
            {/* {isAdditionalCardOpen && selectedCard &&( */}
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2, height: "680px", border: "1px solid #ccc",
                marginLeft: "12px", }}>
              <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center",
                margin: "20px",
                "& > *": {
                  margin: 0, 
                },
                marginTop: "10px",}}>
                <Grid>
                  <Typography sx={{fontSize: "16px", fontWeight: 'bold'}}>Customer Information</Typography>
                </Grid>
              </Box>
              <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center",
                margin: "20px",
                "& > *": {
                  margin: 0, 
                },
                marginTop: "4px",}}>
                <Grid sx={{marginTop: "8px"}}>
                  <Typography sx={{color: "#888888"}}>Customer Name:</Typography>
                  <Typography sx={{color: "#4c4c4c"}}>{auth?.currentUser?.name}</Typography>
                </Grid>
                <Grid sx={{marginTop: "8px"}}>
                  <Typography sx={{color: "#888888"}}>Distributor:</Typography>
                  <Typography sx={{color: "#4c4c4c"}}>{auth?.selectedTenant?.name}</Typography>
                </Grid>
              </Box>
              {/* Current Order */}
              <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center",
                margin: "20px",
                "& > *": {
                  margin: 0, 
                },
                marginTop: "4px",}}>
                <Grid sx={{marginTop: "8px",}}>
                  <Typography sx={{fontSize: "16px", fontWeight: 'bold'}}>Current Order</Typography>
                </Grid>
                <AddCustomBtn
                      id = "clearcart"
                      name="Clear Cart"
                      variant="contained"
                      // handleClick={() => handleAddButtonClick(index, row?.id)}
                      sx={{ fontSize: "12px", minWidth: "95px", height: "25px"}}
                      // handleClick={clearCart}
                      handleClick={handleClearCartClick}
                    />
              </Box>
              {/* List of Cards in Cart */}
              <Box sx={{flex:"2"}}>
                <Card sx={{border: '1px solid #ccc', marginLeft: "6px", marginRight: "6px"}}>
                  <CardContent sx={{ flexGrow: 1, height: '350px', overflow: 'auto', padding: "2px" }}>
                    {/* Hello This is the List of Items' containing Area */}
                    {cartItems?.map((cartItem, index) => {
                      const row = inventory?.filtered_products.find(product => product.id === cartItem.id);
                      if (!row) return null;

                      const individualPrice = row?.mrp * cardQuantities[row?.id];

                      const imageFileData = row?.image_files?.file_encoded;
                      let imageUrl;
                      try {
                        const byteCharacters = atob(imageFileData);
                        const byteArrays = [];
                        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                          const slice = byteCharacters.slice(offset, offset + 1024);
                          const byteNumbers = new Array(slice.length);
                          for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                          }
                          const byteArray = new Uint8Array(byteNumbers);
                          byteArrays.push(byteArray);
                        }
                        const blob = new Blob(byteArrays, { type: "image/jpeg" });
                        imageUrl = URL.createObjectURL(blob);
                      } catch (error) {
                        console.error("File decoding error:", error);
                      }
                    
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index} sx={{ flexBasis: '20%', boxSizing: 'border-box', p: '10px' }}>
                          {selectedCardId.includes(row?.id) && (
                            <Card key={index} sx={{ border: '1px solid #ccc', display: 'flex' }} id={row?.id}>
                              <CardMedia
                                sx={{ height: '45px', width: '80px', paddingTop: '2px', marginTop: '15px', display: 'flex', marginLeft: "5px", justifyContent: 'center !important', alignItems: 'flex-start' }}
                                // image={row?.image_files?.file_encoded ? imageUrl : DefaultProduct}
                                image={row?.image_files?.name ? imageUrl : DefaultProduct}
                                title={row?.image_files?.name ? row?.image_files?.name : "Default Image"}
                              />
                              <CardContent sx={{ flexGrow: 1, maxHeight: '100px', paddingTop: '2px', marginTop: "5px" }}>
                                <Grid container>
                                  <Grid item xs={12} sx={{ fontSize: '10px', color: '#253D4E' }}>
                                    {row?.name}
                                  </Grid>
                                  <Grid item xs={12} sx={{ fontSize: '8px', color: '#ADADAD' }}>
                                    {row?.short_name && row.short_name.includes(' ') ? row.short_name.slice(row.short_name.indexOf(' ') + 1) : row.short_name}
                                  </Grid>
                                  <Grid
                                    container direction="row" alignItems="center" justifyContent="space-between"
                                    sx={{
                                      fontWeight: 'bold', 
                                      fontSize: '12px',
                                    }}
                                  >
                                    <Grid item className="price">
                                      ₹{row?.mrp} X {cardQuantities[row?.id]} = {individualPrice}
                                    </Grid>
                                    <Grid item className="button">
                                      <Button
                                        sx={{ color: "#000000", fontSize: "12px", minWidth: "20px" }}
                                        onClick={() => handleQuantityChange(cardQuantities[row?.id] - 1, row?.id)}
                                      >
                                        -
                                      </Button>
                                      {cardQuantities[row?.id]}
                                      <Button
                                        sx={{ color: "#000000", fontSize: "12px", minWidth: "20px"  }}
                                        onClick={() => handleQuantityChange(cardQuantities[row?.id] + 1, row?.id)}
                                      >
                                        +
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                        </Grid>
                      );
                    })}
                  </CardContent>
                </Card>
              </Box>
              {/* Item total  */}
              <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center",
                margin: "18px",
                "& > *": {
                  margin: 0, 
                },
                marginTop: "10px",
              }}>
                <Grid sx={{marginTop: "8px",}}>
                  <Typography sx={{fontSize: "14px", fontWeight: 'bold', color: "#323232"}}>Item total (incl. taxes)</Typography>
                </Grid>
                <Grid sx={{marginTop: "8px",}}>
                  <Typography sx={{fontSize: "14px", fontWeight: 'bold'}}>₹{calculateTotalPrice()} </Typography>
                </Grid>
              </Box>
              {/* Grand Total */}
              <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center",
                margin: "18px",
                "& > *": {
                  margin: 0, 
                },
                marginTop: "8px",
              }}>
                <Grid sx={{marginTop: "8px",}}>
                  <Typography sx={{fontSize: "16px", fontWeight: 'bold', color: "#000000"}}>Grand Total</Typography>
                </Grid>
                <Grid sx={{marginTop: "8px",}}>
                  <Typography sx={{fontSize: "16px", fontWeight: 'bold',  color: "#000000"}}>₹ {calculateTotalPrice()}</Typography>
                </Grid>
              </Box>
              {/* Place Order */}
              <Box sx={{
                display: "flex", justifyContent: "center", 
                marginTop: "8px",
              }}>
                <CustomButton
                  name="Place Order"
                  variant="contained"
                  sx = {{fontSize: "14px",}}
                  handleClick={() => handleOrderPlaceClick(selectedCardId, cardQuantities)}
                />
              </Box>
              </Paper>
            </Box>
            {/* )} */}
          </Grid>  
        </Box>
      </Paper>
    </Box>
    </>
  );
};
export default container(AddOrder);