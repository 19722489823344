import { connect } from "react-redux";

import { fetchAllBeatsListStart } from "../../../store/beats/beats.actions";
import {
  createOrUpdateTenantTourProgramStart,
  listTenantTourProgramsListStart,
} from "../../../store/tenant/tenant.actions";
import { openAlert } from "../../../store/alert/alert.actions";

const mapStateToProps = (state) => ({
  beats: state.beats,
  tenant: state.tenant,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchAllBeatsListStart: (page, limit, type, field) => dispatch(fetchAllBeatsListStart(page, limit, type, field)),
  onListTenantTourProgramsListStart: (date) =>
    dispatch(listTenantTourProgramsListStart(date)),
  createOrUpdateTenantTourProgramStart: (formData, cb) =>
    dispatch(createOrUpdateTenantTourProgramStart(formData, cb)),
  onOpenAlert: (message, severity) => dispatch(openAlert(message, severity)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
