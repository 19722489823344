import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import CustomButton from "../../../components/CustomButton";

// @ts-ignore
import CreateIcon from "../../../assets/images/create.svg";
// @ts-ignore
import ExportIcon from "../../../assets/images/export.svg";
import BeatsTable from "./components/BeatsTable";
import container from "./Beats.container";
import BeatModal from "./components/BeatModal";

const Beats = ({
  beats: {
    allBeats,
    pages,
    soloBeat,
    country,
    state,
    city,
    limit: pageLimit,
    page: pageNo,
  },
  auth: { tenantProfile },
  master,
  tenant,
  traders,
  onFetchAllBeatsListStart,
  onCreateTenantBeatStart,
  onFetchCountriesStart,
  onFetchStateByCountryIdStart,
  onFetchCityByStateIdStart,
  onFetchBeatsDataByIdStart,
  onViewTenantUserStart,
  onChangeApplyBeatFilter,
  onFetchUsersBasedOnRolesStart,
  onfetchSoloBeatStart,
}) => {
  const [openBeatModal, setOpenBeatModal] = React.useState(null);

  React.useEffect(() => {
    onFetchUsersBasedOnRolesStart([5, 6]);
  }, []);
  // This React hook is used to fetch the solo beat data when the 'openBeatModal' changes.
  React.useEffect(() => {
    // Check if 'openBeatModal' is truthy (i.e., the modal is open).
    if (openBeatModal) {
      // If the modal is open, call the 'onfetchSoloBeatStart' function to initiate fetching of the solo beat.
      onfetchSoloBeatStart(openBeatModal);
    }
  }, [openBeatModal, onfetchSoloBeatStart]);
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "25px",
        }}
      >
        <Typography variant="h3">Beats</Typography>
        <Box sx={{ display: "flex", gap: (theme) => theme.spacing(2) }}>
          <CustomButton
            name="Export"
            sx={{ width: "122px" }}
            icon={<img src={ExportIcon} alt="" />}
          />
          {tenantProfile?.role_details?.permissions_details?.some(
            (permission) => permission.module === "beat.create"
          ) && (
            <CustomButton
              name="Create Beat"
              sx={{ width: "176px" }}
              icon={<img src={CreateIcon} alt="" />}
              handleClick={() => setOpenBeatModal(true)}
            />
          )}
        </Box>
      </Box>
      <Box>
        <BeatsTable
          allBeats={allBeats}
          pages={pages}
          onChangeApplyBeatFilter={onChangeApplyBeatFilter}
          onFetchAllBeatsListStart={onFetchAllBeatsListStart}
          onFetchStateByCountryIdStart={onFetchStateByCountryIdStart}
          onFetchCityByStateIdStart={onFetchCityByStateIdStart}
          onFetchBeatsDataByIdStart={onFetchBeatsDataByIdStart}
          setOpenBeatModal={setOpenBeatModal}
          master={master}
          country={country}
          state={state}
          city={city}
          pageLimit={pageLimit}
          pageNo={pageNo}
        />
      </Box>
      <BeatModal
        openBeatModal={openBeatModal}
        setOpenBeatModal={setOpenBeatModal}
        soloBeat={soloBeat}
        master={master}
        tenant={tenant}
        onCreateTenantBeatStart={onCreateTenantBeatStart}
        onFetchCountriesStart={onFetchCountriesStart}
        onFetchStateByCountryIdStart={onFetchStateByCountryIdStart}
        onFetchCityByStateIdStart={onFetchCityByStateIdStart}
        onFetchBeatsDataByIdStart={onFetchBeatsDataByIdStart}
        onfetchSoloBeatStart={onfetchSoloBeatStart}
        traders={traders} />
        {/* MenuItem={undefined} */}
    </Fragment>
  );
};

export default container(Beats);
