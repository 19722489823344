import * as MasterType from "./master.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  roles: null,
  countries: [],
  stateList: [],
  cities: [],
  error: null,
  loading: false,
};

// Reducer function responsible for managing the authentication state
const masterReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case MasterType.FETCH_ROLES_START:
    case MasterType.FETCH_COUNTRIES_START:
    case MasterType.FETCH_STATE_BY_COUNTRY_ID_START:
    case MasterType.FETCH_CITY_BY_STATE_ID_START:
      return {
        ...state,
        loading: true,
      };

    case MasterType.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: payload?.data?.role,
        loading: false,
      };

    case MasterType.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload?.data?.country,
        loading: false,
      };

    case MasterType.FETCH_STATE_BY_COUNTRY_ID_SUCCESS:
      return {
        ...state,
        stateList: payload?.data?.state,
        loading: false,
      };

    case MasterType.FETCH_CITY_BY_STATE_ID_SUCCESS:
      return {
        ...state,
        cities: payload?.data?.cities,
        loading: false,
      };

    case MasterType.FETCH_ROLES_FAILURE:
    case MasterType.FETCH_COUNTRIES_FAILURE:
    case MasterType.FETCH_STATE_BY_COUNTRY_ID_FAILURE:
    case MasterType.FETCH_CITY_BY_STATE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default masterReducer;
