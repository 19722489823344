import {
  Box,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography, 
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import EditIcon from "../../../../assets/images/edit.svg";
import DragIcon from "../../../../assets/images/dragIcon.svg";
import FilterListIcon from "@mui/icons-material/FilterList";
import container from "../BeatsAlt.container";
import BeatPopper from "./BeatPopper";
import Loading from "../../../../assets/images/loading.svg";

const tableHeader = [
  {
    id: 1,
    name: "Beat Name",
  },
  {
    id: 2,
    name: "User Name",
  },
  {
    id: 3,
    name: "Trader Name",
  },
  {
    id: 4,
    name: "Address",
  },
  {
    id: 5,
    name: "Mobile",
  },
  {
    id: 6,
    name: "Action",
  },
];

const BeatsTable = ({
  activeBeat,
  setActiveBeat,
  onTenantBeatsMoveTraderStart,
  onFetchAllBeatsListStart,
  allBeats, setOpenBeataltModal,
  onPushRetailerToBeatStart, traders,
  beat_id, remainingTraders,
}) => {
  // const [openBeatModal, setOpenBeatModal] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>( null );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [inputValue, setInputValue] = React.useState("");

  const [selectedBeatId, setSelectedBeatId] = useState(null);
  const [isIconVisible, setIsIconVisible] = useState(false);    
  const onDragEnd = (result) => {
    const sourceIds = result.destination.droppableId.split("+");
    if (sourceIds.includes("drop") && sourceIds?.length > 1) {
      onTenantBeatsMoveTraderStart(
        result?.source?.droppableId,
        sourceIds[1],
        result?.draggableId,
        () => {
          onFetchAllBeatsListStart();
        }
      );
    }
  };
  const [sortBy, setSortBy] = useState("id"); // Default sorting by "Beat Name"
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order is ascending 
  const sortedBeats = [...allBeats].sort((a, b) => {
    // Define a comparison function based on the sorting field and order
    let comparison = 0;
    if (sortBy === "id") {
      comparison = b.id - a.id;
    } 
    if (sortOrder === "desc") {
      comparison *= -1;
    }
    
    return comparison;
  });

  return (
  <Box>
    <Box
      sx={{
        overflowX: "auto",
        maxHeight: "82vh",
        height: "100%",
        overflowY: "hidden",
      }}
    >
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid
            container
            sx={{ marginTop: "15px", background: "#fff", minWidth: 1300 }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                borderTop: "1px solid var(--grey, #E1E2E9)",
                borderBottom: "1px solid var(--grey, #E1E2E9)",
                height: "60px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {tableHeader.map((header) => (
                <Grid
                  item
                  xs={
                    header.name === "Action"
                      ? 1
                      : header.name === "User Name"
                      ? 3
                      : 2
                  }
                  key={header.id}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        paddingLeft:
                          header.name === "User Name" ? "74px" : "25px",
                      }}
                    >
                      {header.name}
                    </Typography>
                    <IconButton>
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs={2}
                sx={{
                  background: "#fff",
                  borderRight: "1px solid #E1E2E9",
                  overflow: "auto",
                  height: "100vh",
                }}
              >
                {/* {!_.isEmpty(allBeats) &&
                  allBeats.map((beat, index) => ( */}
                  {sortedBeats && sortedBeats.length > 0 ?
                    (sortedBeats.map((beat, index) => (
                    <Droppable
                      droppableId={`drop+${beat?.id.toString()}`}
                      key={index}
                    >
                      {(provided) => (
                        <Box
                        sx={{
                          padding: "10px 0 16px 30px",
                          borderBottom: "1px solid #E1E2E9", 
                          background: beat?.id === activeBeat?.id ? "#05ADAD" : "#fff",
                          color: beat?.id === activeBeat?.id ? "#fff" : "#111",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#05ADAD",
                            color: "#fff",
                            '& .edit-icon': {
                              display: 'block',
                            },
                          },
                          wordWrap: "break-word",
                        }}
                        onMouseEnter={() => setIsIconVisible(true)}
                        onMouseLeave={() => setIsIconVisible(false)}
                        onClick={() => {
                          setActiveBeat(beat);
                          setSelectedBeatId(beat.id); }
                        }
                      >
                        <Typography variant="subtitle1">
                          {beat?.name} (
                          {beat?.beat_traders?.length > 0
                            ? beat?.beat_traders?.length
                            : 0}
                          )
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenBeataltModal(beat?.id);
                          }}
                        >
                        <img src={EditIcon} alt="Edit" className={`edit-icon`}
                        style={{
                          width: '20px', height: '20px',
                          display: isIconVisible ? 'block' : 'none',
                          fill: "white",
                        }} />
                        </IconButton>
                      </Box>
                      )}
                    </Droppable>
                    ))) : (
                      <img src={Loading}/>
                    )
                  }
              </Grid>

              <Grid
                item
                xs={10}
                sx={{
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Droppable
                  droppableId={activeBeat?.id.toString()}
                  isDragDisabled={true}
                >
                  {(provided) => (
                    <Grid
                      container
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {!_.isNull(activeBeat) &&
                        activeBeat?.beat_traders.map((beat, index) => (
                          <Draggable
                            key={beat.id} // Use a unique key for each draggable item
                            draggableId={beat.id.toString()} // Use a unique identifier for each draggable item
                            index={index}
                          >
                            {(provided) => (
                              <Grid
                                container
                                item
                                xs={12}
                                key={index}
                                sx={{
                                  padding: "14px 0",
                                  borderRight: "1px solid #E1E2E9",
                                  borderBottom: "1px solid #E1E2E9",
                                  background: "#FFF",
                                  cursor: "pointer",
                                  height: "55px",
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Grid
                                  item
                                  xs={3.6}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "17px",
                                  }}
                                >
                                  <Box sx={{ paddingLeft: "38px" }}>
                                    <img src={DragIcon} alt="drag" />
                                  </Box>
                                  <Typography>
                                    {beat?.f_name ?? ""} {beat?.l_name ?? ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                  {beat?.trader_name ?? ""}
                                </Grid>
                                <Grid item xs={2.4}>
                                  {beat?.trader_address ?? ""}
                                </Grid>
                                <Grid item xs={2.4}>
                                  {beat?.mobile ?? ""}
                                </Grid>
                                <Grid
                                  item
                                  xs={1.2}
                                  sx={{ textAlign: "center" }}
                                >
                                  <img src={EditIcon} alt="Edit" />
                                </Grid>
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </Grid>
            </Grid>
          </Grid>
        </DragDropContext>
      </Box>
    </Box>
    <BeatPopper
    open={open}
    anchorEl={anchorEl}
    handleClose={handleClose}
    // traders={remainingTraders}
    inputValue={inputValue}
    id={id}
    onPushRetailerToBeatStart = {onPushRetailerToBeatStart}
    remainingTraders = {remainingTraders}
    activeBeat={activeBeat}
    onFetchAllBeatsListStart = {onFetchAllBeatsListStart}
  />
  </Box>
  );
};

export default container(BeatsTable);