import {
  Box,
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../../components/CustomTable";
import { sendDisableUser } from "../../../../store/users/users.action";
import { useDispatch } from "react-redux";
import { CustomPagination } from "../../../../components/CustomTable/CustomTable";
import TraderFilter from "./TraderFilter";
import Loading from "../../../../assets/images/loading.svg";

type Order = "asc" | "desc";
interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  isShow: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "User ID",
    isShow: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    isShow: true,
  },
  {
    id: "f_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isShow: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
    isShow: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
    isShow: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    isShow: true,
  },
];

const DistributorTable = ({
  distributorUsers: { users, pages, page: maxPage, limit, roleId ,f_name,l_name,email,mobile, loading},
  onFetchUsersBasedOnRolesStart,
  onChangeApplyTraderFilter,
}) => {
  console.log("distributer",roleId);
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");
  const [noOfPages, setNoOfPages] = React.useState([]);

  const [activePage, setActivePage] = React.useState<any>(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(limit);

  const [page, setPage] = React.useState(0);
  const [role_id,setRole_id] = React.useState(roleId);
  
  console.log("distributer role id",roleId);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(
      onChangeApplyTraderFilter(
        "limit",
        Number(event.target.value),
        "distributor"
      )
    );
    onFetchUsersBasedOnRolesStart(6, 'asc', 'id');
  };

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      onChangeApplyTraderFilter("page", event.target.value, "distributor")
    );
    setActivePage(Number(event.target.value));
    onFetchUsersBasedOnRolesStart(6, 'asc', 'id');
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users?.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(pages?.total / limit);
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [pages?.total, limit]);

  React.useEffect(() => {
    onFetchUsersBasedOnRolesStart(6, 'asc', 'id');
  }, []);
  const handleSwitchChange = (id) => {
    dispatch(sendDisableUser(id));
    // onFetchUsersStart();
    onFetchUsersBasedOnRolesStart(6);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

    const handleRequestSort = (field) => {
      const isAsc = orderBy === field && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(field);
  
      onFetchUsersBasedOnRolesStart(6, isAsc ? "asc" : "desc", field);
    };

  const showNextPage = () => {
    dispatch(onChangeApplyTraderFilter("page", activePage + 1, "distributor"));
    setActivePage(activePage + 1);
    onFetchUsersBasedOnRolesStart(6, 'asc', 'id');
  };

  const showPrevPage = () => {
    dispatch(onChangeApplyTraderFilter("page", activePage - 1, "distributor"));
    setActivePage(activePage - 1);
    onFetchUsersBasedOnRolesStart(6, 'asc', 'id');
  };
  const handleSearchChange = (e, filterName) => {
    console.log("filterName---->", filterName)
    dispatch(onChangeApplyTraderFilter("f_name", e, filterName));
    onFetchUsersBasedOnRolesStart(6, 'asc', 'id');
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected?.length} handleChange={(e) => handleSearchChange(e, "distributor")} tableHeaderComps={
            <TraderFilter
              filterName="distributor"
              f_name={f_name}
              l_name={l_name}
              email={email}
              mobile={mobile}
              onFetchUsersBasedOnRolesStart={ onFetchUsersBasedOnRolesStart}
             
            />
          }/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={(event, property) => handleRequestSort(property)}
              rowCount={users?.length}
              headCells={headCells}
            />
            <TableBody>
              { loading && users?.length === 0 ? 
                (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      <img src={Loading}/>
                    </TableCell>
                  </TableRow>
                ) : users.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                ) :
                (users && users.length > 0 ? (users?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => {}}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.id ? row?.id : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.email ? row?.email : ""}
                      </TableCell>

                      <TableCell align="left">
                        {row?.f_name ? row?.f_name : ""}{" "}
                        {row?.m_name ? row?.m_name : ""}{" "}
                        {row?.l_name ? row?.l_name : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.mobile ? row?.mobile : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.gender ? row?.gender : ""}
                      </TableCell>
                      <TableCell align="left">
                        <Switch
                          {...label}
                          defaultChecked
                          onChange={() => {
                            handleSwitchChange(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }) ) : 
                (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      <img src={Loading}/>
                      </TableCell>
                  </TableRow>)
                )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          count={pages?.total}
          rowsPerPage={rowsPerPage}
          page={activePage-1}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          pageNo={maxPage}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
        />
      </Paper>
    </Box>
  );
};

export default DistributorTable;
