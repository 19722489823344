import React, { Fragment, useEffect, } from "react";
import { Box, TableContainer, Paper, Table, TableBody, TableCell,
TableRow, } from "@mui/material";
import { EnhancedTableHead } from '../../../../components/CustomTable';
import moment from "moment";
const Calendar = ({ onGetReStatisticalReportStart, fromDate, toDate,
    reStatisticalReport: {reStatisticalData, reStaPageDetails},}) => {
    const [dense, setDense] = React.useState(false);
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("user_name");
    const [isMonthView, setIsMonthView] = React.useState(false);

    useEffect(() => {
        const startMoment = moment(fromDate);
        const endMoment = moment(toDate);
    
        const diffInMonths = endMoment.diff(startMoment, 'months');
    
        setIsMonthView(diffInMonths >= 1); 
    }, [fromDate, toDate]);

    interface HeadCell {
        disablePadding: boolean;
        id: any;
        label: string;
        isShow: boolean;
        numeric: boolean;
        align?: string;
    }
    const headCells: readonly HeadCell[] = [
        {
            id: "mobile",
            numeric: false,
            disablePadding: true,
            label: "Mobile No.",
            isShow: true,
          },
        {
          id: "beat_name",
          numeric: false,
          disablePadding: false,
          label: "Beat",
          isShow: true,
        },
        {
            id: "total_order_value",
            numeric: false,
            disablePadding: false,
            label: "Total Sales",
            isShow: true,
        },
    ];
    
    const getColumnsForDateRange = (fromDate, toDate, isMonth = false) => {
        const columns = [];
        const currentDate = new Date(fromDate);
    
        while (currentDate <= new Date(toDate)) {
            const formattedColumn = isMonth
                ? moment(currentDate).format("YYYY-MM")
                : moment(currentDate).format("YYYY-MM-DD");
    
            columns.push({
                id: formattedColumn,
                numeric: false,
                disablePadding: false,
                label: isMonth ? moment(currentDate).format("MMMM") : formattedColumn,
                isShow: true,
            });
    
            if (isMonth) {
                currentDate.setMonth(currentDate.getMonth() + 1);
            } else {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    
        return columns;
    };    
    
    const dateRangeColumns = getColumnsForDateRange(fromDate, toDate);
    const monthRangeColumns = getColumnsForDateRange(fromDate, toDate, true);
    const allColumns = [...headCells, ...(isMonthView ? monthRangeColumns : dateRangeColumns)];
    
    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
        onGetReStatisticalReportStart(fromDate, toDate, reStaPageDetails?.page, reStaPageDetails?.limit, isAsc ? "asc" : "desc", field, );
    };
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <TableContainer component={Paper}>
                    <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                        <EnhancedTableHead
                            numSelected={0}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={() => {}}
                            onRequestSort={(event, property) => handleRequestSort(property)}
                            rowCount={reStatisticalData?.length}
                            headCells={allColumns}
                            isCheckBox={false}
                        />
                        <TableBody>
                            {reStatisticalData && reStatisticalData?.length > 0 ? (
                                reStatisticalData?.map((row, index) => {
                                    return(
                                    <TableRow key={index}>
                                        <TableCell align = "center" sx = {{borderTop: "1px solid #ebeded",
                                            borderRight: "1px solid #ebeded",
                                            height: "75px"
                                        }}>
                                            {row.mobile ? row?.mobile : "-"}
                                        </TableCell>
                                        <TableCell align = "center" sx = {{borderTop: "1px solid #ebeded",
                                            borderRight: "1px solid #ebeded",
                                            borderLeft: "1px solid #ebeded",
                                            whiteSpace: "nowrap",
                                        }}>
                                            {row?.beat_name ? row?.beat_name : "-"}
                                        </TableCell>
                                        <TableCell align = "center" sx = {{borderTop: "1px solid #ebeded",
                                            borderRight: "1px solid #ebeded",
                                            borderLeft: "1px solid #ebeded",
                                        }}>
                                            {/* {row?.total_order_value ? row?.total_order_value : "0"} */}
                                            {row?.total_order_value != undefined ? row?.total_order_value.toFixed(2) : "0.00"}
                                        </TableCell>
                                        {isMonthView ? (
                                          monthRangeColumns.map((monthColumn) => (
                                            <TableCell
                                                key={monthColumn.id}
                                                align="center"
                                                sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}
                                            >
                                                {row.month_wise_data &&
                                                row.month_wise_data[monthColumn.id] !== undefined &&
                                                row.month_wise_data[monthColumn.id] !== null
                                                    ? row.month_wise_data[monthColumn.id]
                                                    : "-"}
                                            </TableCell>
                                        ))
                                        ) : (
                                          dateRangeColumns.map((dateColumn) => (
                                            <TableCell
                                              key={dateColumn.id}
                                              align="center"
                                              sx={{
                                                borderTop: "1px solid #ebeded",
                                                borderRight: "1px solid #ebeded",
                                                borderLeft: "1px solid #ebeded",
                                              }}
                                            >
                                              {row.date_wise_data &&
                                              row.date_wise_data[dateColumn.id] !== undefined
                                                ? row.date_wise_data[dateColumn.id]
                                                : "-"}
                                            </TableCell>
                                          ))
                                        )}
                                    </TableRow>
                                    )
                                })
                                ) : (
                                <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">
                                      {/* <img src={Loading}/> */}
                                      No Data Available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            </Box>
        </Fragment>
    )
}

export default Calendar;