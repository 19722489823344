import * as ReportByBeatsType from "./reportByBeats.types";

const INITIAL_STATE = {
    reportByBeatsData: [],
    beatsPageDetails: null,
    grandTotalSales: null,
    monthTotalSales: null,

    yearlyBeatsList: [],
    yeralyGrandeTotalSales: null,
    yearlyIndividualMonthlySales: null,
    
};
const reportByBeatsReducer = ( state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch(type){
        case ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_START:
            return {
                ...state,
                loading: true,
            }
        case ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_SUCCESS:
            return {
                ...state,
                reportByBeatsData: payload?.data?.data,
                beatsPageDetails: payload?.data?.page_details,
                grandTotalSales: payload?.data?.grand_total_sales,
                monthTotalSales: payload?.data?.month_total_sales,
                loading: false,
            }
        case ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        
        // For Search
        case ReportByBeatsType.SEARCH_REPORT_BEAT_NAME:
            const { key, value } = action.payload;
            return {
                ...state,
                reportByBeatsData: payload?.data?.data,
                beatsPageDetails: payload?.data?.page_details,
                grandTotalSales: payload?.data?.grand_total_sales,
                monthTotalSales: payload?.data?.month_total_sales,
                [key]: value,
            }

        case ReportByBeatsType.YEARLY_BEATS_LIST_START:
            return {
                ...state,
                loading: true,
            }
        case ReportByBeatsType.YEARLY_BEATS_LIST_SUCCESS:
            return {
                ...state,
                yearlyBeatsList: payload?.data?.data,
                yeralyGrandeTotalSales: payload?.data?.grand_total_sales,
                yearlyIndividualMonthlySales: payload?.data?.month_total_sales,
            }
        case ReportByBeatsType.YEARLY_BEATS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default:
            return state;
    }
}
export default reportByBeatsReducer;