import * as tourScreenActions from "./tourScreen.actions";
import * as tourScreenType from "./tourScreen.types";

import { useSelector } from "react-redux";

import axiosConfig, { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import moment from "moment";


export function* fetchAllTourScreenUsersAsync({ payload }: any) {
  let {
    name, beat_name, user_id, from_date, to_date, page, limit, selectedDateRange
  } = yield select((state) => state.tourScreen);
  const { fromDate, toDate } = payload;
    try {
      // Fetch user data from the API
      const { data } = yield tenantInstance.post(`/tenants/tour_program/list`, {
        filters: {
          user_id: Number(user_id)?[Number(user_id)]:[],
          name,
          beat_name,
         
          from_date: fromDate,
          to_date: toDate,
        //   from_date:
        //   selectedDateRange === "this-week"
        //     ? moment().startOf("isoWeek").format("YYYY-MM-DD")
        //     : selectedDateRange === "last-week"
        //     ? moment()
        //         .subtract(1, "week")
        //         .startOf("isoWeek")
        //         .format("YYYY-MM-DD")
        //     : selectedDateRange === "this-month"
        //     ? moment().startOf("month").format("YYYY-MM-DD")
        //     : selectedDateRange === "last-month"
        //     ? moment()
        //         .subtract(1, "month")
        //         .startOf("month")
        //         .format("YYYY-MM-DD")
        //     : selectedDateRange === "this-year"
        //     ? moment().startOf("year").format("YYYY-MM-DD")
        //     : selectedDateRange === "last-year"
        //     ? moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
        //     : from_date,
        // to_date:
        //   selectedDateRange === "this-week"
        //     ? moment().endOf("isoWeek").format("YYYY-MM-DD")
        //     : selectedDateRange === "last-week"
        //     ? moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD")
        //     : selectedDateRange === "this-month"
        //     ? moment().endOf("month").format("YYYY-MM-DD")
        //     : selectedDateRange === "last-month"
        //     ? moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        //     : selectedDateRange === "this-year"
        //     ? moment().endOf("year").format("YYYY-MM-DD")
        //     : selectedDateRange === "last-year"
        //     ? moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
        //     : to_date,
        },
        pages: {
          page,
          limit,
        }
      });
      yield put(tourScreenActions.fetchAllTourScreenUsersSuccess(data));
    } catch (error) {
      yield put(tourScreenActions.fetchAllTourScreenUsersFail(error));
    }
  }
  export function* watchFetchAllTourScreenUsersAsync() {
    yield takeLatest(
      tourScreenType.GET_TOURSCREEN_USERS_LIST_START,
      fetchAllTourScreenUsersAsync
    );
  }
  export function* tourScreenSagas() {
    yield all([
      call(watchFetchAllTourScreenUsersAsync)])
    }