import React, { Fragment, useEffect, } from "react";
import { Box, TableContainer, Paper, Table, TableBody, TableRow, TableCell} from "@mui/material";
import { EnhancedTableHead } from '../../../../components/CustomTable';
import moment from "moment";
import { useNavigate } from 'react-router-dom';

const Calendar = ({onGetVisitListStart, fromDate, toDate, onFetchReportListStart,
    visitScreen: {visitData, page_details}}) => {
    const [dense, setDense] = React.useState(false);
    const [isMonthView, setIsMonthView] = React.useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        const startMoment = moment(fromDate);
        const endMoment = moment(toDate);
    
        const diffInMonths = endMoment.diff(startMoment, 'months');
    
        setIsMonthView(diffInMonths >= 1); 
    }, [fromDate, toDate]);
    const getColumnsForDateRange = (fromDate, toDate, isMonth = false) => {
        const columns = [];
        const currentDate = new Date(fromDate);
    
        while (currentDate <= new Date(toDate)) {
            const formattedColumn = isMonth
                ? moment(currentDate).format("YYYY-MM")
                : moment(currentDate).format("YYYY-MM-DD");
    
            columns.push({
                id: formattedColumn,
                numeric: false,
                disablePadding: false,
                label: isMonth ? moment(currentDate).format("MMMM") : formattedColumn,
                isShow: true,
            });
    
            if (isMonth) {
                currentDate.setMonth(currentDate.getMonth() + 1);
            } else {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    
        return columns;
    };    
    
    const dateRangeColumns = getColumnsForDateRange(fromDate, toDate);

    const monthRangeColumns = getColumnsForDateRange(fromDate, toDate, true);
    const allColumns = [...(isMonthView ? monthRangeColumns : dateRangeColumns)]; 
    
    const handleCellClick = (date, rowId, name) => {
        navigate("/manage/visit-list/visit-reports");
        // console.log("date, rowId---->", date, rowId);
        onFetchReportListStart(date, rowId, name);
    }
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                                numSelected={0}
                                order={null}
                                orderBy={null}
                                onSelectAllClick={() => {}}
                                onRequestSort={() => {}}
                                rowCount={visitData?.length}
                                headCells={allColumns}
                                isCheckBox={false}
                            />
                            <TableBody>
                                {visitData && visitData?.length > 0 ? (
                                    visitData?.map((row, index) => {
                                        return(
                                            <TableRow key={index}>
                                                {isMonthView ? (
                                                    allColumns.map((monthColumn) => (
                                                      <TableCell
                                                          key={monthColumn.id}
                                                          align="center"
                                                          sx={{
                                                              borderTop: "1px solid #ebeded",
                                                              borderRight: "1px solid #ebeded",
                                                              borderLeft: "1px solid #ebeded",
                                                              height: "75px",
                                                          }}
                                                      >
                                                          {/* {row.month_wise_data &&
                                                          row.month_wise_data[monthColumn.id] !== undefined &&
                                                          row.month_wise_data[monthColumn.id] !== null
                                                              ? row.month_wise_data[monthColumn.id]
                                                              : "-"} */}
                                                      </TableCell>
                                                    ))
                                                    ) : (
                                                        allColumns.map((dateColumn) => (
                                                        <TableCell
                                                          key={dateColumn.id}
                                                          align="center"
                                                          sx={{
                                                            borderTop: "1px solid #ebeded",
                                                            borderRight: "1px solid #ebeded",
                                                            height: "75px",
                                                            cursor: row.just_visit_dates[dateColumn.id] !== undefined &&
                                                            row.just_visit_dates[dateColumn.id] !== null ? 'pointer' : 'default',
                                                          }}
                                                        //   onClick={(event) => handleCellClick(dateColumn.id, row.id, row.name)}
                                                            onClick={(event) => {
                                                              if (row.just_visit_dates[dateColumn.id] !== undefined &&
                                                                row.just_visit_dates[dateColumn.id] !== null ) {
                                                                handleCellClick(dateColumn.id, row.id, row.name);
                                                              }
                                                            }}
                                                        >
                                                          {row.just_visit_dates &&
                                                              row.just_visit_dates[dateColumn.id] !== undefined &&
                                                              row.just_visit_dates[dateColumn.id] !== null ? (
                                                              row.just_visit_dates[dateColumn.id]
                                                            ) : (
                                                              0
                                                            )}
                                                        </TableCell>
                                                      ))
                                                    )}
                                            </TableRow>
                                        )
                                    })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={allColumns.length} align="center">
                                              No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box> 
        </Fragment>
    )
}

export default Calendar;