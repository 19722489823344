import * as ChartType from "./chart.types";

const INITIAL_STATE = {
  visitsList: [],
  start_date: null,
  end_date: null,
  selectedDateRange: "",
};
function chartScreenReducer(state = INITIAL_STATE, action){
  // console.log(action.payload)
  const { type, payload} = action;
  switch(type){
    case ChartType.GET_CHART_LIST_START:
    return {
      ...state,
      loading: true,
    };
    case ChartType.GET_CHART_LIST_SUCCESS:
    return {
      ...state,
      // data: payload?.data?.visit_list,
      visitsList:payload?.data,
      loading: false,
    };
    case ChartType.GET_CHART_LIST_FAILURE:
    return {
      ...state,
      loading: true,
    };
    default:
      return state;
  }
}
export default chartScreenReducer;