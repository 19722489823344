import * as ExcelActions from "./excel.actions";
import * as ExcelType from "./excel.types";

import { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";

export function* getExcelFieldsAsync({ payload: { slug } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.get(`/tenants/excel/fields/${slug}`);

    yield put(ExcelActions.getExcelFieldsSuccess(data));
  } catch (error) {
    yield put(ExcelActions.getExcelFieldsFail(error));
  }
}

export function* importMappedProductsStartAsync({
  payload: { formData, cb },
}: any) {
  console.log("formData11", formData);

  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/excel/import/products`,
      formData,
      config
    );

    yield put(ExcelActions.importMappedProductsSuccess(data));
    yield put(openAlert("Product Imported succesfully", "success"));

    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(ExcelActions.importMappedProductsFail(error));
    yield put(openAlert("Failed to import product", "error"));
  }
}

export function* importMappedBeatsStartAsync({
  payload: { formData, cb },
}: any) {
  console.log("formData11", formData);

  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/excel/import/beats`,
      formData,
      config
    );

    yield put(ExcelActions.importMappedBeatsSuccess(data));
    yield put(openAlert("Beats Imported succesfully", "success"));

    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(ExcelActions.importMappedBeatsFail(error));
    yield put(openAlert("Failed to import beats", "error"));
  }
}

export function* importMappedBeatsUsersAsync({
  payload: { formData, cb },
}: any) {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/excel/import/invitations`,
      formData,
      config
    );

    yield put(ExcelActions.importMappedBeatsSuccess(data));
    yield put(openAlert("Users Invitations Imported succesfully", "success"));

    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(ExcelActions.importMappedBeatsFail(error));
    yield put(openAlert("Failed to import Users invitations", "error"));
  }
}

export function* watchGetExcelFieldsAsync() {
  yield takeLatest(ExcelType.GET_EXCEL_FIELDS_START, getExcelFieldsAsync);
}

export function* watchImportMappedProductsStartAsync() {
  yield takeLatest(
    ExcelType.IMPORT_MAPPED_PRODUCTS_START,
    importMappedProductsStartAsync
  );
}

export function* watchImportMappedBeatsStartAsync() {
  yield takeLatest(
    ExcelType.IMPORT_MAPPED_BEATS_START,
    importMappedBeatsStartAsync
  );
}

export function* watchImportMappedUsersStartAsync() {
  yield takeLatest(
    ExcelType.IMPORT_MAPPED_USERS_START,
    importMappedBeatsUsersAsync
  );
}

export function* excelSagas() {
  yield all([
    call(watchGetExcelFieldsAsync),
    call(watchImportMappedProductsStartAsync),
    call(watchImportMappedBeatsStartAsync),
    call(watchImportMappedUsersStartAsync),
  ]);
}
