import {
  Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, 
  Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Grid,
  FormLabel, Popover,
} from "@mui/material";
import React, { Fragment, useState } from "react";

import AddProductIcon from "../../../assets/images/addProduct.svg";
import CustomButton from "../../../components/CustomButton";
import RightIcon from "../../../assets/images/right.svg";

import { useNavigate } from "react-router-dom";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../components/CustomTable";

// Icons Import
import EditIcon from "../../../assets/images/edit.svg";
import DelIcon from "../../../assets/images/delete.svg";
import CustomizedDialogs from "../../../components/CustomDialog";
import container from "./TourScreen.container";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TourFilter from "./components/TourFilter";
import { changeApplyTourScreenFilter } from "../../../store/tourScreen/tourScreen.actions";
import { useDispatch } from "react-redux";
import { CustomPagination } from "../../../components/CustomTable/CustomTable";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import FilterIcon from "../../../assets/images/filter.svg";
interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  isShow: boolean;
  numeric: boolean;
  align?: string;
}

const TourScreen = ({ limit, onFetchAllTourScreenUsersStart, tourScreen, page:pageNo }) => {
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigate = useNavigate();
 
  const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

  const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

  const handleCustomDatePopoverOpen = (event) => {
    setCustomDatePopoverOpen(true);
  };
  
  const handleCustomDatePopoverClose = () => {
    setCustomDatePopoverOpen(false);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: "#008E8E", 
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setCustomDateLabel(
      `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
        'MM/DD/YYYY'
      )}`
    );

  };

  const handleFilter = () => {
    handleClose();
    if (dateRange.length === 1) {
      const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
      const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
      onFetchAllTourScreenUsersStart(selectedStartDate, selectedEndDate);
      setFromDate(selectedStartDate);
      setToDate(selectedEndDate);
    }
    // setSelectedFilter('');
  };

  const handleClearFilter = () => {
    // setSelectedFilter(''); 
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
    setFromDate(currentDate);
    setToDate(currentDate);
    setCustomDateLabel('Custom Date Selection');
    onFetchAllTourScreenUsersStart(currentDate, currentDate,);
  };

  type Order = "asc" | "desc";
  const headCells: readonly HeadCell[] = [
    {
      id: "username",
      numeric: false,
      disablePadding: true,
      label: "User Name",
      isShow: true,
    },
    {
      id: "assignedBeat",
      numeric: false,
      disablePadding: false,
      label: "Assigned Beat",
      isShow: true,
    },
    {
      id: "dates",
      numeric: false,
      disablePadding: false,
      label: "Date",
      isShow: true,
    }
    // {
    //   id: "action",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Action",
    //   align: "center",
    //   isShow: true,
    // },
    // {
    //   id: "show",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "",
    //   isShow: true,
    // },
  ];
  const dispatch = useDispatch()
  const [delModal, setDelModal] = React.useState(null);
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("dates");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [noOfPages, setNoOfPages] = React.useState([]);


  const currentDate = moment().format("YYYY-MM-DD");

  const [fromDate, setFromDate] = React.useState(currentDate);
  const [toDate, setToDate] = React.useState(currentDate); 


  const [page, setPage] = React.useState(0);

  const [activePage, setActivePage] = React.useState<any>(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    tourScreen?.page_details?.limit
  );
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  // const plans = [
  //   {
  //     id: 678,
  //     username: "Arravalli Hasmukhgiri Gosai",
  //     assignedBeat: "DITVASH",
  //     date: "2023/06/03",
  //   },
  //   {
  //     id: 678,
  //     username: "Arravalli Hasmukhgiri Gosai",
  //     assignedBeat: "DITVASH",
  //     date: "2023/06/03",
  //   },
  //   {
  //     id: 679,
  //     username: "Arravalli Hasmukhgiri Gosai",
  //     assignedBeat: "DITVASH",
  //     date: "2023/06/03",
  //   },
  //   {
  //     id: 680,
  //     username: "Arravalli Hasmukhgiri Gosai",
  //     assignedBeat: "DITVASH",
  //     date: "2023/06/03",
  //   },
  //   {
  //     id: 681,
  //     username: "Arravalli Hasmukhgiri Gosai",
  //     assignedBeat: "DITVASH",
  //     date: "2023/06/03",
  //   },
  // ];

  // Avoid a layout jump when reaching the last page with empty rows.
 
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tourScreen.data?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(
        tourScreen?.page_details?.total / tourScreen?.page_details?.limit
      );
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [tourScreen?.page_details?.total, tourScreen?.page_details?.limit]);

  React.useEffect(() => {
    onFetchAllTourScreenUsersStart(fromDate, toDate);
  }, []);

  // const handleRequestSort = (field) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  //   onFetchAllTourScreenUsersStart(isAsc ? "desc" : "asc", field)
  // };
  const handleRequestSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);

    onFetchAllTourScreenUsersStart(fromDate, toDate, isAsc ? "asc" : "desc", field);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  // const conditionIsMet = true;

  // React.useEffect(() => {
  //   if (conditionIsMet) {
  //     setOrderBy("assignedBeat");
  //   }
  // }, [conditionIsMet]);


  const sortedData = tourScreen?.data ? tourScreen?.data?.sort(getComparator(order, orderBy)) : [];
  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected: readonly string[] = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected?.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected?.concat(selected?.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected?.concat(selected?.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected?.concat(
  //       selected?.slice(0, selectedIndex),
  //       selected?.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const emptyRows =
  page > 0
    ? Math.max(0, (1 + page) * rowsPerPage - tourScreen?.data.length)
    : 0;

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      dispatch(
        changeApplyTourScreenFilter("limit", Number(event.target.value))
      );
      onFetchAllTourScreenUsersStart(fromDate, toDate);
    };
  
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changeApplyTourScreenFilter("page", event.target.value));
      setActivePage(Number(event.target.value));
      onFetchAllTourScreenUsersStart(fromDate, toDate);
    };
  
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
  
    const showNextPage = () => {
      dispatch(changeApplyTourScreenFilter("page", activePage + 1));
      setActivePage(activePage + 1);
      onFetchAllTourScreenUsersStart(fromDate, toDate);
    };
  
    const showPrevPage = () => {
      dispatch(changeApplyTourScreenFilter("page", activePage - 1));
      setActivePage(activePage - 1);
      onFetchAllTourScreenUsersStart(fromDate, toDate);
    };
  
  const handleSearchChange = (e) => {
    dispatch(changeApplyTourScreenFilter("name", e));
    onFetchAllTourScreenUsersStart(fromDate, toDate);
  };


  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: (theme) => theme.spacing(4),
        }}
      >
        <Typography variant="h3">Tour Plan</Typography>
        <CustomButton
          name="Add Tour Plan"
          handleClick={() => navigate("/manage/tour-plan/add-tour-plan")}
          icon={<img src={AddProductIcon} alt="" />}
        />
      </Box>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={0}
          handleChange={handleSearchChange}
          tableHeaderStartComps={
            <ButtonGroup
              color="secondary"
              size="small"
              sx={{
                "& > button": {
                  textTransform: "none",
                },
                display:"none",
              }}
            >
              <Button variant="contained" sx={{ color: "#fff" }}>
                Today
              </Button>
              <Button>Tomorrow</Button>
            </ButtonGroup>
          }
          // tableHeaderComps={<TourFilter  
          //   onFetchAllTourScreenUsersStart={onFetchAllTourScreenUsersStart} 
          //   name={tourScreen?.name}
          //   beat_name={tourScreen?.beat_name}
          //   fromDate = {fromDate}
          //   toDate = {toDate}
          //   selectedDateRange={tourScreen?.selectedDateRange}
          //   />}
          tableHeaderComps = {
            <>
              <Box
                sx={{
                width: "89px",
                height: "29px",
                padding: "18px 10px",
                borderRadius: "4px",
                border: "1px solid #45464E",
                display: "flex",
                alignItems: "center",
                gap: "9px",
                cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <img src={FilterIcon} alt="filter" />
                <Typography>Filter</Typography>
              </Box>
              <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}>
                  <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
                    <Grid container spacing={4}>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3">
                            Filter
                          </Typography>
                        </Grid>
                        <Grid item xs = {12}>
                                  {/* <RadioGroup
                                      aria-label="filter-options"
                                      name="filter-options"
                                      value={selectedFilter}
                                      onChange={handleRadioChange}
                                      >
                                        <FormControlLabel value="thisWeek" control={<Radio />} label="This Week" />
                                        <FormControlLabel value="lastWeek" control={<Radio />} label="Last Week" />
                                        <FormControlLabel value="thisYear" control={<Radio />} label="This Year" />
                                  </RadioGroup> */}
                                  <Grid item xs={12}>
                                  <>
                                    <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                      Date Selection
                                    </FormLabel>
                                    <Typography
                                      sx={{ 
                                        ml: 2, mt: 1,
                                        width: "185px",
                                        height: "29px",
                                        padding: "18px 10px",
                                        borderRadius: "4px",
                                        border: "1px solid #45464E",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "9px",
                                        cursor: "pointer",
                                     }}
                                      onClick={handleCustomDatePopoverOpen}
                                    >
                                      {customDateLabel}
                                    </Typography>
                                    <Popover
                                    open={customDatePopoverOpen}
                                    anchorEl={anchorEl}
                                    onClose={handleCustomDatePopoverClose}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    >
                                      <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                      <IconButton
                                        aria-label="close"
                                        onClick={handleCustomDatePopoverClose}
                                        sx={{
                                          position: "absolute",
                                          right: 8,
                                          top: 8,
                                          color: (theme) => theme.palette.grey[500],
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                        <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                          <DateRangePicker
                                            ranges={dateRange}
                                            onChange={handleSelect}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            direction="vertical"
                                            showDateDisplay={false}
                                            editableDateInputs={false}
                                          />
                                        </div>    
                                      </Box>
                                    </Popover>
                                  </>
                                  </Grid>
                                  {/* <Grid item xs = {12} sx={{mb: "6px"}}>
                                  <Grid item xs = {12} sx={{mb: "6px"}} hidden>
                                      <Typography>Custom Date Range Selection</Typography>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <DatePicker
                                            views={["day"]}
                                            sx={{ width: 140 }}
                                          //value="singleMonthData"
                                            value={selectedMonth}
                                            slotProps={{ textField: { size: "small" } }}
                                          onChange={handleMonthCalendar}
                                          />
                                      </LocalizationProvider>
                                  </Grid>
                                  </Grid> */}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <CustomButton
                                      variant = "outlined"
                                      name="Clear Filter"
                                      handleClick={handleClearFilter}
                                      sx={{ width: '100%' }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                      <CustomButton
                                        name="Filter"
                                        handleClick={handleFilter}
                                        sx={{ width: "100%" }}
                                      />
                                   </Grid>                                        
                              </Grid>
                          </Grid>
                      </Box>
                  </Popover>
                  </>
                  
              }
          />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={(event, property) => handleRequestSort(property)}
              rowCount={tourScreen?.data?.length}
              headCells={headCells}
              isCheckBox={false}
            />

            <TableBody>
              { tourScreen?.data && tourScreen?.data.length > 0 ? (
              tourScreen?.data?.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                const beatRows = [];

                // Iterate through the dates array and check if beats is not null
                row.dates.forEach((date) => {
                  if (date.beats !== null) {
                    date.beats.forEach((beat) => {
                      // Create a new table row for each beat and date
                      beatRows.push(
                        <TableRow
                          hover
                          onClick={() => {}}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.user_id}-${date.date}-${beat.beat_id}`}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                            sx={{ paddingLeft: "1rem" }}
                          >
                            {row.name}
                          </TableCell>
                          {/* <TableCell align="center">{row.user_id}</TableCell> */}
                          <TableCell align="center">{beat.beat_name}</TableCell>
                          <TableCell align="center">{date.date}</TableCell>
                          {/* <TableCell
                            sx={{
                              width: 130,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <img src={EditIcon} alt="Edit" />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDelModal(row?.id);
                                }}
                                className="delete-icon-class"
                              >
                                <img src={DelIcon} alt="Del" />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <img src={RightIcon} alt=">" />
                          </TableCell> */}
                        </TableRow>
                      );
                    });
                  }
                });

                return beatRows;
              })) : (
                <TableRow>
                  <TableCell colSpan={3}>No Data Available</TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <CustomPagination
        count={tourScreen?.page_details?.total}
        rowsPerPage={rowsPerPage}
        page={activePage-1}
        handleChangePage={() => {}}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePageChange={handlePageChange}
        pageNo={tourScreen?.page_details?.page}
        noOfPages={noOfPages}
        activePage={activePage}
        showPrevPage={showPrevPage}
        showNextPage={showNextPage}
        /> */}
      </Paper>
      <CustomizedDialogs
        open={delModal}
        handleClose={() => setDelModal(false)}
        title={"Delete Tour Plan"}
        maxWidth={"sm"}
      >
        <Box>
          <Typography variant="h3">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: (theme) => theme.spacing(4),
              justifyContent: "flex-end",
              marginTop: (theme) => theme.spacing(4),
            }}
          >
            <CustomButton
              variant="outlined"
              name="Cancel"
              handleClick={() => setDelModal(null)}
              sx={{ width: 110 }}
            />
            <CustomButton
              name="Delete"
              handleClick={() => {}}
              sx={{ width: 110 }}
            />
          </Box>
        </Box>
      </CustomizedDialogs>
    </Fragment>
  );
};

export default container(TourScreen);
