export const GET_ATTENDACE_USERS_LIST_START =
  "GET_ATTENDACE_USERS_LIST_START";
export const GET_ATTENDACE_USERS_LIST_SUCCESS =
  "GET_ATTENDACE_USERS_LIST_SUCCESS";
export const GET_ATTENDACE_USERS_LIST_FAILURE =
  "GET_ATTENDACE_USERS_LIST_FAILURE";
export const ON_CHANGE_APPLY_ATTENDANCE_SCREEN_FILTER= "ON_CHANGE_APPLY_ATTENDANCE_SCREEN_FILTER"

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const ON_CHANGE_APPLY_ATTENDANCE_FILTER  = "ON_CHANGE_APPLY_ATTENDANCE_FILTER";