import * as PersonSalesReportType from "./personSalesReport.types";

export const getPersonSalesReportListStart = (fromDate, toDate, page, limit, type, field, flag) => {
    return{
    type: PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_START,
    payload: { fromDate, toDate, page, limit, type, field, flag }
    }   
};
  
export const getPersonSalesReportListSuccess = (result) => ({
    type: PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_SUCCESS,
    payload: result,
});
  
export const getPersonSalesReportListFail = (error) => ({
    type: PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_FAILURE,
    payload: error,
});

export const searchPersonSalesName = (key, value) => ({
    type: PersonSalesReportType.SEARCH_PERSON_SALES_NAME,
    payload: { key, value }
});