import * as ReStatisticalReportType from "./reStatisticalReport.types";

export const getReStatisticalReportStart = (fromDate, toDate, page, limit, type, field) => {
    return{
    type: ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_START,
    payload: { fromDate, toDate, page, limit, type, field }
    }   
};

export const getReStatisticalReportSuccess = (result) => ({
    type: ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_SUCCESS,
    payload: result,
});

export const getReStatisticalReportFail = (error) => ({
    type: ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_FAILURE,
    payload: error,
});

export const searchrReStatisticalName = (key, value) => ({
    type: ReStatisticalReportType.SEARCH_RESTATISTICAL_NAME,
    payload: { key, value },
});