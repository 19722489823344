// React-redux
import { connect } from "react-redux";
import { fetchReportListStart, getVisitListStart, 
  changeApplyVisitFilter,
  filterVisitDataStart, } from "../../../store/visitlist/visit.action";

const mapStateToProps = (state) => ({
  visitScreen: state.visitScreen,
});

const mapDispatchToProps = (dispatch) => ({
  onGetVisitListStart: (start_date, end_date) => dispatch(getVisitListStart(start_date, end_date)),
  onFetchReportListStart: (date, rowId) => dispatch(fetchReportListStart(date, rowId)),
  onChangeApplyVisitFilter: (name, value) => dispatch(changeApplyVisitFilter(name, value)),
  onFilterVisitDataStart: (startDate, endDate) => dispatch(filterVisitDataStart(startDate, endDate)), 
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
