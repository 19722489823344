import React, { Fragment, useState } from 'react';
import container from './ReStatisticalReport.container';
import { Box, Button, ButtonGroup, FormControlLabel, Grid, Paper, 
    Popover, Radio, RadioGroup, Typography, FormControl, Select, FormLabel} from '@mui/material';
import InputLabel from "@mui/material/InputLabel";
import ExportIcon from "../../../assets/images/export.svg";
import FilterIcon from "../../../assets/images/filter.svg";

import CustomButton from '../../../components/CustomButton';

import { EnhancedTableToolbar, EnhancedTableHead } from '../../../components/CustomTable';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from "../../../assets/images/loading.svg";
import { CustomPagination } from '../../../components/CustomTable/CustomTable';
import HeadColumn from './components/HeadColumn';
import Calendar from "./components/Calendar";
import { searchrReStatisticalName
 } from '../../../store/restatisticalreport/reStatisticalReport.action';
import { useDispatch } from "react-redux";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ReStatisticalReport = ({onGetReStatisticalReportStart, 
    reStatisticalReport: {reStatisticalData, reStaPageDetails}}) => {

    const dispatch = useDispatch();
    const currentDate = moment().format("YYYY-MM-DD");

    const [fromDate, setFromDate] = React.useState(currentDate);
    const [toDate, setToDate] = React.useState(currentDate); 
    const [selectedMonth, setSelectedMonth] = useState(null);

    const [monthStartDate, setMonthStartDate] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [dense, setDense] = React.useState(false);
    const [viewMode, setViewMode] = useState('totalSales');

    const [selectedStartMonth, setSelectedStartMonth] = useState(null);
    const [selectedEndMonth, setSelectedEndMonth] = useState(null);

    const [showMonths, setShowMonths] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);

    const [selectedButton, setSelectedButton] = useState('today');

    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };

    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    };

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("user_name");
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    const [activePage, setActivePage] = React.useState<any>(1);
    const [noOfPages, setNoOfPages] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(reStaPageDetails?.limit || 10);

    const [displayColumns, setDisplayColumns] = useState(1);


    const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#008E8E", 
        },
    ]);

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
        setCustomDateLabel(
          `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
            'MM/DD/YYYY'
          )}`
        );
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleThisWeek = () => {
        const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
        const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
        setFromDate(startOfWeek);
        setToDate(endOfWeek);
        onGetReStatisticalReportStart(startOfWeek, endOfWeek, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
        setShowMonths(false);
        setDisplayColumns(1);
    }
    const handleLastWeek = () => {
        const startOfLastWeek = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
        const endOfLastWeek = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
        setFromDate(startOfLastWeek);
        setToDate(endOfLastWeek);
        onGetReStatisticalReportStart(startOfLastWeek, endOfLastWeek, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
        setShowMonths(false);
        setDisplayColumns(1);
    };
    const handleYearClick = () => {
        const currentDate = moment();
        const startOfLastYear = moment(currentDate).startOf('year').format('YYYY-MM-DD');
        const endOfLastYear = moment(currentDate).endOf('year').format('YYYY-MM-DD');

        setFromDate(startOfLastYear);
        setToDate(endOfLastYear);

        onGetReStatisticalReportStart(startOfLastYear, endOfLastYear, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
        const monthsInRange = [];
        const currentMonth = moment(startOfLastYear);

        while (currentMonth <= moment(endOfLastYear)) {
          monthsInRange.push(currentMonth.format("MMMM"));
          currentMonth.add(1, "month");
        }
    
        setSelectedMonths(monthsInRange);
        setShowMonths(true);
        setViewMode('monthWiseData');
        setDisplayColumns(0);
    };
    const handleMonthClick = (monthIndex) => {
        const currentDate = moment();
        const startDate = moment(currentDate).month(monthIndex).startOf('month').format("YYYY-MM-DD");
        const endDate = moment(currentDate).month(monthIndex).endOf('month').format("YYYY-MM-DD");
        setFromDate(startDate);
        setToDate(endDate);
        onGetReStatisticalReportStart(startDate, endDate, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
        setDisplayColumns(1);
    }

    const handleCustomMonths = () =>{
        const startMonthDate = selectedStartMonth ? selectedStartMonth.format("YYYY-MM-DD") : null;
        const endMonthDate = selectedEndMonth ? selectedEndMonth.format("YYYY-MM-DD") : null;
            
        const monthsInRange = [];
            
        for (let month = selectedStartMonth.clone(); month <= selectedEndMonth; month.add(1, 'month')) {
          monthsInRange.push(month.format('MMMM'));
        }
    
        setSelectedMonths(monthsInRange);
        onGetReStatisticalReportStart(startMonthDate, endMonthDate, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
        setViewMode('monthWiseData');
        setShowMonths(true);
        setDisplayColumns(0);
    }
    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
      
        if (selectedValue === 'thisWeek') {
          handleThisWeek();
        } else if (selectedValue === 'lastWeek') {
          handleLastWeek();
        } else if (selectedValue === 'thisYear') {
            handleYearClick();
        }
      
        setSelectedFilter(selectedValue);
        clearFilter();
    };
    // const handleFilter = (selectedFilter, monthIndex = null) => {
    //     handleClose();
    //     if (selectedFilter === 'thisWeek') {
    //       handleThisWeek();
    //     } else if (selectedFilter === 'lastWeek') {
    //       handleLastWeek();
    //     } else if (selectedFilter === 'thisYear') {
    //         handleYearClick();
    //     } else if (selectedFilter === 'singleMonthData'){
    //         handleMonthClick(monthIndex);
    //     } else {
    //         handleCustomMonths();
    //     }
    //     setSelectedStartMonth(null);
    //     setSelectedEndMonth(null);
    //     clearFilter();
    // };
    const handleFilter = () => {
        handleClose();
        if (dateRange.length === 1) {
          const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
          const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
          onGetReStatisticalReportStart(selectedStartDate, selectedEndDate, 1, 10, 'asc', 'name');
          setFromDate(selectedStartDate);
          setToDate(selectedEndDate);
        }
        setActivePage(1);
        // setSelectedFilter('');
    };
    const handleClearFilter = () => {
        setSelectedFilter(''); 
        // setUserNameFilter(''); 
        setDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: "#008E8E", 
          },
        ]);
        
        setFromDate(currentDate);
        setToDate(currentDate);
        setActivePage(1);
        onGetReStatisticalReportStart(currentDate, currentDate, 1, 10, 'asc', 'name');
        setSelectedButton('today');
        setCustomDateLabel('Custom Date Selection');
        setSelectedStartMonth(null);
        setSelectedEndMonth(null);
    };

    const clearFilter = () => {
        setSelectedFilter(''); 
        handleClose(); 
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        onGetReStatisticalReportStart(fromDate, toDate, activePage, event.target.value, 'asc', 'name');
    }
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPage = Number(event.target.value);
        setActivePage(newPage);
        onGetReStatisticalReportStart(fromDate, toDate, newPage, reStaPageDetails?.limit, 'asc', 'name');
    }

    const showPrevPage = () => {
        const nextPage = activePage - 1;
        setActivePage(activePage - 1);
        onGetReStatisticalReportStart(fromDate, toDate, nextPage, reStaPageDetails?.limit, 'asc', 'name')
    }
    const showNextPage = () => {
        const nextPage = activePage + 1;
        setActivePage(activePage + 1);
        onGetReStatisticalReportStart(fromDate, toDate, nextPage, reStaPageDetails?.limit, 'asc', 'name')
    }
    
    const handleSearchChange = (e) => {
        dispatch(searchrReStatisticalName("name", e));
        onGetReStatisticalReportStart(fromDate, toDate, 1, 10, 'asc', 'name');
    };

    React.useEffect(() => {
      const calculatePages = () => {
        const totalPages = Math.ceil(reStaPageDetails?.total / reStaPageDetails?.limit);
        const newPages = [];
        for (let i = 1; i <= totalPages; i++) {
          newPages.push(i);
        }
        setNoOfPages(newPages);
      };
          calculatePages();
    }, [reStaPageDetails?.total, reStaPageDetails?.limit]);

    React.useEffect(() => {
        onGetReStatisticalReportStart(fromDate, toDate, 1, 10, 'asc', 'name');
    }, []);
    return(
        // <div>
        //     This is Retailers' Statistical Report
        // </div>
        <Fragment>
            <Box
                sx={{
                display: "none",
                //   display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: (theme) => theme.spacing(4),
                }}
                >
                <Typography variant="h3"></Typography>
                <CustomButton
                    name="Export"
                    sx={{ width: "122px", display: 'none'}}
                    icon={<img src={ExportIcon} alt="" />}
                  />
            </Box>

            <Paper sx={{ width: "100%", }}>
                <EnhancedTableToolbar 
                    numSelected={0}
                    handleChange={handleSearchChange}
                    tableHeaderStartComps={ 
                        <Box sx={{display: "flex"}}>
                            <ButtonGroup
                                color="secondary"
                                size="small"
                                sx={{
                                  "& > button": {
                                    textTransform: "none",
                                  },
                                }}
                                >
                                <Button 
                                    // variant="contained" sx={{ color: "#fff" }}
                                    // onClick={() => {onGetReStatisticalReportStart(currentDate, currentDate);}}
                                    variant={selectedButton === 'today' ? 'contained' : 'outlined'}
                                    sx={{ color: selectedButton === 'today' ? '#fff' : '#8B8D97' }}
                                    onClick={() => {
                                      const today = moment().format("YYYY-MM-DD");
                                      setFromDate(today);
                                      setToDate(today);
                                      onGetReStatisticalReportStart(today, today, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
                                      setSelectedButton('today')}}
                                    >
                                  Today
                                </Button>
                                <Button 
                                    variant={selectedButton === 'yesterday' ? 'contained' : 'outlined'}
                                    sx={{ color: selectedButton === 'yesterday' ? '#fff' : '#8B8D97' }} 
                                    onClick={() => {
                                    const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
                                    setFromDate(yesterday);
                                    setToDate(yesterday);
                                    onGetReStatisticalReportStart(yesterday, yesterday, reStaPageDetails?.page, reStaPageDetails?.limit, 'asc', 'name');
                                    setSelectedButton('yesterday')
                                }}>Yesterday</Button>
                            </ButtonGroup>
                            <Typography variant = "h5" sx={{ ml: 2, mt: 1 }}>Date: {fromDate} to {toDate}</Typography>
                        </Box>
                    }
                    tableHeaderComps = {
                        <>
                            <Box
                                sx={{
                                width: "89px",
                                height: "29px",
                                padding: "18px 10px",
                                borderRadius: "4px",
                                border: "1px solid #45464E",
                                display: "flex",
                                alignItems: "center",
                                gap: "9px",
                                cursor: "pointer",
                                }}
                              onClick={handleClick}
                                >
                                <img src={FilterIcon} alt="filter" />
                                <Typography>Filter</Typography>
                            </Box>
                            <Popover 
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}>
                                <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
                                    <Grid container spacing={4}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3">
                                                  Filter
                                                </Typography>
                                            </Grid>
                                            <Grid item xs = {12}>
                                            
                                        <Grid item xs={12}>
                                        <>
                                        <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                          Date Selection
                                        </FormLabel>
                                        <Typography
                                          sx={{ 
                                            ml: 2, mt: 1,
                                            width: "185px",
                                            height: "29px",
                                            padding: "18px 10px",
                                            borderRadius: "4px",
                                            border: "1px solid #45464E",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "9px",
                                            cursor: "pointer",
                                         }}
                                          onClick={handleCustomDatePopoverOpen}
                                        >
                                          {customDateLabel}
                                        </Typography>
                                        <Popover
                                        open={customDatePopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCustomDatePopoverClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        >
                                          <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                            <IconButton
                                              aria-label="close"
                                              onClick={handleCustomDatePopoverClose}
                                              sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                              }}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                            <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                              <DateRangePicker
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="vertical"
                                                showDateDisplay={false}
                                                editableDateInputs={false}
                                              />
                                            </div>    
                                          </Box>
                                        </Popover>
                                      </>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CustomButton
                                          variant = "outlined"
                                          name="Clear Filter"
                                          handleClick={handleClearFilter}
                                          sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomButton
                                          name="Filter"
                                          handleClick={handleFilter}
                                          sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Popover>
                        </>  
                    }
                />
            </Paper>
            <Grid container>
                <Grid item xs={3} md={3}>
                    <HeadColumn 
                        onGetReStatisticalReportStart = {onGetReStatisticalReportStart}
                        fromDate = {fromDate}
                        toDate = {toDate}
                        reStatisticalReport = {{reStatisticalData, reStaPageDetails}}
                    />
                </Grid>
                <Grid item xs={9} md={9}>
                    <Calendar 
                        onGetReStatisticalReportStart = {onGetReStatisticalReportStart}
                        fromDate = {fromDate}
                        toDate = {toDate}
                        reStatisticalReport = {{reStatisticalData, reStaPageDetails}}
                    />
                </Grid>
            </Grid>
            <Paper sx={{ width: "100%",}}>
                <CustomPagination
                count={reStaPageDetails?.total}
                rowsPerPage={rowsPerPage}
                page={activePage - 1}
                handleChangePage={() => { }}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handlePageChange={handlePageChange}
                // pageNo={reStaPageDetails?.page}
                pageNo = {activePage}
                noOfPages={noOfPages}
                activePage={activePage}
                showPrevPage={showPrevPage}
                showNextPage={showNextPage}
                />
            </Paper>
        </Fragment>
    )
}

export default container(ReStatisticalReport);