import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import AddOrganisation from "../../../views/Admin/AddOrganisation";

const Organisation = () => {
  return <WithLayout layout={Admin} component={AddOrganisation} />;
};

export default Organisation;
