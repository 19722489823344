import * as React from "react";

// Material-ui
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogWrapperTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function DialogWrapperTitle(props: DialogWrapperTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

// ================== Main Dialog Component ================= //
export default function CustomizedDialogs({
  open,
  handleClose,
  title,
  children,
  maxWidth,
  fullWidth = true,
}) {
  return (
    <div>
      <DialogWrapper
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogWrapperTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogWrapperTitle>
        <DialogContent>{children}</DialogContent>
      </DialogWrapper>
    </div>
  );
}
