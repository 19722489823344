import React from "react";

// Material-ui
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

// Project imports
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../../components/CustomTable";
import BeatFilter from "./BeatFilter";

import _ from "lodash";

import { useDispatch } from "react-redux";
import { CustomPagination } from "../../../../components/CustomTable/CustomTable";
import EditIcon from "../../../../assets/images/edit.svg";
import IconButton from "@mui/material/IconButton";
type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  isShow: true;
}

const headCells: readonly HeadCell[] = [
  {
    id: "allocatedTo",
    numeric: false,
    disablePadding: true,
    label: "Allocated To",
    isShow: true,
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Id",
    isShow: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isShow: true,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    isShow: true,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    isShow: true,
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    isShow: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    isShow: true,
  },
];

const BeatsTable = ({
  onFetchAllBeatsListStart,
  allBeats,
  master,
  pages,
  onChangeApplyBeatFilter,
  onFetchStateByCountryIdStart,
  onFetchCityByStateIdStart,
  country,
  state,
  city,
  pageLimit,
  pageNo,
  setOpenBeatModal,
  onFetchBeatsDataByIdStart
}) => {
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [activePage, setActivePage] = React.useState<any>(1);

  const [noOfPages, setNoOfPages] = React.useState([]);

  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected =
        _.isArray(allBeats) && allBeats?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  React.useEffect(() => {
    onFetchAllBeatsListStart();
  }, []);

  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(pages?.total / pageLimit);
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [pages, pageLimit]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allBeats.length) : 0;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(onChangeApplyBeatFilter("limit", Number(event.target.value)));
    onFetchAllBeatsListStart();
  };

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangeApplyBeatFilter("page", event.target.value));
    setActivePage(Number(event.target.value));
    onFetchAllBeatsListStart();
  };

  const showNextPage = () => {
    dispatch(onChangeApplyBeatFilter("page", activePage + 1));
    setActivePage(activePage + 1);
    onFetchAllBeatsListStart();
  };

  const showPrevPage = () => {
    dispatch(onChangeApplyBeatFilter("page", activePage - 1));
    setActivePage(activePage - 1);
    onFetchAllBeatsListStart();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected?.length}
          handleChange={() => {}}
          tableHeaderComps={
            <BeatFilter
              onChangeApplyBeatFilter={onChangeApplyBeatFilter}
              onFetchStateByCountryIdStart={onFetchStateByCountryIdStart}
              onFetchCityByStateIdStart={onFetchCityByStateIdStart}
              onFetchAllBeatsListStart={onFetchAllBeatsListStart}
              onFetchBeatsDataByIdStart={onFetchBeatsDataByIdStart}
              master={master}
              country={country}
              state={state}
              city={city}
            />
          }
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allBeats?.length}
              headCells={headCells}
            />
            <TableBody>
              {_.isArray(allBeats) &&
                allBeats?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        {row?.allocatedTo}
                      </TableCell>
                      <TableCell padding="checkbox">{row?.id}</TableCell>
                      <TableCell padding="checkbox">{row?.name}</TableCell>
                      <TableCell padding="checkbox">
                        {row?.city_name ?? ""}
                      </TableCell>
                      <TableCell padding="checkbox">
                        {row?.state_name ?? ""}
                      </TableCell>
                      <TableCell padding="checkbox">
                        {row?.country_name ?? ""}
                      </TableCell>
                      <TableCell sx={{ width: 100 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginBottom: "3px",
                            }}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenBeatModal(row?.id);
                              }}
                            >
                              <img src={EditIcon} alt="Edit" />
                            </IconButton>
                          </Box>
                        </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          count={pages?.total}
          rowsPerPage={rowsPerPage}
          page={activePage-1}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          pageNo={pageNo}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
        />
      </Paper>
    </Box>
  );
};

export default BeatsTable;
