import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Admin from "../../layouts/Admin";
import InventoryScreen from "../../views/Admin/Inventory";

// =================== Inventory Page =================//
const Inventory = () => {
  return <WithLayout layout={Admin} component={InventoryScreen} />;
};

export default Inventory;
