import * as attendanceScreenTypes from "./attendance.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  user_id: [],
  name: "",
  dates: [],
  date: "",
  loading: false,
  error: null,
  start_date: "",
  end_date: "",
  selectedDateRange: "",
  page: 1,
  limit: 10,
  page_details: [],
  data: [],
  userNameFilter: "",
};
function attendanceReducer(state = INITIAL_STATE, action) {
  // console.log("DATA---->", action.payload)
  const { type, payload } = action;
  switch (type) {

    case attendanceScreenTypes.GET_ATTENDACE_USERS_LIST_START:
    case attendanceScreenTypes.SEARCH_USER_START:
      return {
        ...state,
        loading: true,
        userNameFilter: payload.userNameFilter,
      };

    case attendanceScreenTypes.GET_ATTENDACE_USERS_LIST_SUCCESS:
    case attendanceScreenTypes.SEARCH_USER_SUCCESS:
      return {
        ...state,
        data: payload?.data,
        page_details: payload?.data?.page_details,
        loading: false,
      };


    case attendanceScreenTypes.GET_ATTENDACE_USERS_LIST_FAILURE:
    case attendanceScreenTypes.SEARCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
export default attendanceReducer;
