export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_OUT_START = "SIGN_OUT_START";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";

export const LOAD_USER_START = "LOAD_USER_START";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";

export const LOAD_USER_BASED_ON_TENANT_START =
  "LOAD_USER_BASED_ON_TENANT_START";
export const LOAD_USER_BASED_ON_TENANT_SUCCESS =
  "LOAD_USER_BASED_ON_TENANT_SUCCESS";
export const LOAD_USER_BASED_ON_TENANT_FAILURE =
  "LOAD_USER_BASED_ON_TENANT_FAILURE";

export const CHANGE_TENANT_START = "CHANGE_TENANT_START";
export const CHANGE_TENANT_SUCCESS = "CHANGE_TENANT_SUCCESS";
export const CHANGE_TENANT_FAILURE = "CHANGE_TENANT_FAILURE";

export const SIGN_IN_WITH_TOKEN_START = "SIGN_IN_WITH_TOKEN_START";
export const SIGN_IN_WITH_TOKEN_SUCCESS = "SIGN_IN_WITH_TOKEN_SUCCESS";
export const SIGN_IN_WITH_TOKEN_FAILURE = "SIGN_IN_WITH_TOKEN_FAILURE";

export const ACCEPT_TENANT_INVITATION_START = "ACCEPT_TENANT_INVITATION_START";
export const ACCEPT_TENANT_INVITATION_SUCCESS =
  "ACCEPT_TENANT_INVITATION_SUCCESS";
export const ACCEPT_TENANT_INVITATION_FAILURE =
  "ACCEPT_TENANT_INVITATION_FAILURE";
