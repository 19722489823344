//Vist List 
export const VIEW_VISIT_LIST_START = "VIEW_VISIT_LIST_START";
export const VIEW_VISIT_LIST_SUCCESS = "VIEW_VISIT_LIST_SUCCESS";
export const VIEW_VISIT_LIST_FAILURE = "VIEW_VISIT_LIST_FAILURE";

export const GET_VISIT_LIST_START = "GET_VISIT_LIST_START";
export const GET_VISIT_LIST_SUCCESS = "GET_VISIT_LIST_SUCCESS";
export const GET_VISIT_LIST_FAILURE = "GET_VISIT_LIST_FAILURE";
export const ON_CHANGE_APPLY_VISIT_SCREEN_FILTER = "ON_CHANGE_APPLY_VISIT_SCREEN_FILTER";

export const FETCH_VISIT_REPORT_START = "FETCH_VISIT_REPORT_START";
export const FETCH_VISIT_REPORT_SUCCESS = "FETCH_VISIT_REPORT_SUCCESS";
export const FETCH_VISIT_REPORT_FAILURE = "FETCH_VISIT_REPORT_FAILURE";
export const SET_SELECTED_CLICK_DATA = "SET_SELECTED_CLICK_DATA";

export const ON_CHANGE_APPLY_VISIT_FILTER = "ON_CHANGE_APPLY_VISIT_FILTER";
export const FILTER_VISIT_DATA_START = "FILTER_VISIT_DATA_START";
export const FILTER_VISIT_DATA_SUCCESS = "FILTER_VISIT_DATA_SUCCESS";
export const FILTER_VISIT_DATA_FAILURE = "FILTER_VISIT_DATA_FAILURE";

export const SEARCH_VISIT_DATA_NAME = "SEARCH_VISIT_DATA_NAME";