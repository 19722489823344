import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
// Material-ui
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Switch,
} from "@mui/material";

// Project imports
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../../components/CustomTable";
import {
  sendDisableUser,
  changeApplyUserFilter,
  changeApplyDisabledUserFilter,
  fetchAllFilteredUsersStart,
  fetchAllFilteredDisabledUsersStart,
  sendEnableUser,
} from "../../../../store/users/users.action";
import UserFilter from "./UserFilter";
import TableLoader, {
  CustomPagination,
} from "../../../../components/CustomTable/CustomTable";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "../../../../assets/images/edit.svg";
import { selectedUserClickData } from '../../../../store/users/users.action';
import { useNavigate } from 'react-router-dom';
import UserEditModal from "./UserEditModal";
import Loading from "../../../../assets/images/loading.svg";

type Order = "asc" | "desc";
interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  isShow: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "User ID",
    isShow: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    isShow: true,
  },
  {
    id: "f_name",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    isShow: true,
  },
  {
    id: "l_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    isShow: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
    isShow: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    isShow: true,
  },
];

var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const UserTable = ({
  Users: { Users, pageDetails, limit, page: noOfPage, },
  user,
  onFetchUsersStart,
  loading,
  value,
  filters,
  onFetchSettingDataStart,
  onupdateUserStart, onFetchEnableUsersStart, onFetchDisableUsersStart, 
  onGetReportToListStart,
  reportToData,
}) => {
  // console.log("data---->", reportToData);
  const dispatch = useDispatch();
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [activePage, setActivePage] = React.useState<any>(1);

  const [noOfPages, setNoOfPages] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);

  const [openUserEditModal, setOpenUserEditModal] = React.useState(null);

  const handleSwitchChange = (id) => {
    if (value === 0) {
      dispatch(sendDisableUser(id));
      // dispatch(fetchAllFilteredUsersStart())
     
    } else {
      dispatch(sendEnableUser(id));

      // dispatch(fetchAllFilteredDisabledUsersStart())
    }
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;
  // const label = { inputProps: { "aria-label": "Switch demo" } };
  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: any
  // ) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const handleRequestSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);

    if (value === 0) {
      onFetchEnableUsersStart(isAsc ? "asc" : "desc", field)
    } else {
      fetchAllFilteredDisabledUsersStart(isAsc ? "asc" : "desc", field);
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const sortedData = Users ? Users.sort(getComparator(order, orderBy)) : [];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = Users?.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
  

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleEditModalClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1) // 
      );
    }
    // setSelected(newSelected);
    setOpenUserEditModal(id); 
  };

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (value === 0) {
      dispatch(changeApplyUserFilter("page", event.target.value, 'asc', 'name'));
      setActivePage(Number(event.target.value));
      onFetchEnableUsersStart('asc', );
    } else {
      dispatch(changeApplyDisabledUserFilter("page", event.target.value));
      setActivePage(Number(event.target.value));
      // dispatch(fetchAllFilteredDisabledUsersStart());
      onFetchDisableUsersStart();
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (value === 0) {
      dispatch(changeApplyUserFilter("limit", Number(event.target.value), 'asc', 'name'));
      // dispatch(fetchAllFilteredUsersStart());
      onFetchEnableUsersStart('asc', 'id');
    } else {
      dispatch(
        changeApplyDisabledUserFilter("limit", Number(event.target.value))
      );
      // dispatch(fetchAllFilteredDisabledUsersStart());
      onFetchDisableUsersStart('asc', 'id');
    }
  };

  const showNextPage = () => {
    if (value === 0) {
      dispatch(changeApplyUserFilter("page", activePage + 1, 'asc', 'name'));
      setActivePage(activePage + 1);
      // dispatch(fetchAllFilteredUsersStart());
      onFetchEnableUsersStart('asc', 'id');
    } else {
      dispatch(changeApplyDisabledUserFilter("page", activePage + 1));
      setActivePage(activePage + 1);
      // dispatch(fetchAllFilteredDisabledUsersStart());
      onFetchDisableUsersStart('asc', 'id');
    }
  };

  const showPrevPage = () => {
    if (value === 0) {
      dispatch(changeApplyUserFilter("page", activePage - 1, 'asc', 'name'));
      setActivePage(activePage - 1);
      // dispatch(fetchAllFilteredUsersStart());
      onFetchEnableUsersStart('asc', 'id');
    } else {
      dispatch(changeApplyDisabledUserFilter("page", activePage - 1));
      setActivePage(activePage - 1);
      // dispatch(fetchAllFilteredDisabledUsersStart());
      onFetchDisableUsersStart('asc', 'id');
    }
  };
  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Users.length) : 0;

  React.useEffect(() => {
    if (value === 0) {
      // dispatch(fetchAllFilteredUsersStart());
      onFetchEnableUsersStart('asc', 'id');
    } else {
      // dispatch(fetchAllFilteredDisabledUsersStart());
      onFetchDisableUsersStart('asc', 'id');
    }
    // dispatch(fetchAllFilteredUsersStart())
    // onFetchUsersStart();
  }, []);
  const [action, setAction] = React.useState("");
  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(pageDetails?.total / limit);
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [pageDetails?.total, limit]);

  const handleSearchChange = (e) => {
    if (value === 0) {
      dispatch(changeApplyUserFilter("f_name", e, 'asc', 'name'));
      // dispatch(fetchAllFilteredUsersStart());
      onFetchEnableUsersStart();
    } else {
      dispatch(changeApplyDisabledUserFilter("f_name", e));
      // dispatch(fetchAllFilteredDisabledUsersStart());
      onFetchDisableUsersStart();
    }
  };
  const navigate = useNavigate();
  const handleCellClick = (event: React.MouseEvent<unknown>, salesPersonId) => {
    onFetchSettingDataStart(salesPersonId); 
    // navigate("/manage/settings");
    redirectToSettingsPage();
  };
  const redirectToSettingsPage = () => {
    navigate('/manage/users/settings');
  };

  const openEdit = () => {
    console.log("Button Clicked");
  }

  // React.useEffect(() => {
  //   onGetReportToListStart('Retailer');
  // }, [])
  return (
    <Fragment>
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected?.length}
          handleChange={handleSearchChange}
          tableHeaderComps={
            <UserFilter
              filterName={value === 0 ? "enabled" : "disabled"}
              filters={filters}
              onFetchDisableUsersStart = {onFetchDisableUsersStart}
              onFetchEnableUsersStart = {onFetchEnableUsersStart}
            />
          }
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={(event, property) => handleRequestSort(property)}
              rowCount={sortedData?.length}
              headCells={headCells}
              
            />
            {/* {loading ? (
              <React.Fragment>
                {[...Array(10)].map((_, index) => (
                  <TableLoader rows={headCells} key={index} />
                ))}
              </React.Fragment>
            ) : ( */}
              <TableBody>
                {/* { */}
                {loading && Users.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headCells.length} align="center">
                    {/* Loading... */}
                    <img src={Loading}/>
                  </TableCell>
                </TableRow>
                ) : Users.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                )

               
                :
                (sortedData && sortedData?.length > 0 ? (
                  sortedData?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const a = row?.role_details?.name;
                    // console.log("a---->", a);
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {row.id ? row?.id : ""}
                        </TableCell>
                        <TableCell align="left">
                          {row.email ? row?.email : ""}
                        </TableCell>

                        <TableCell align="left">
                          {row.f_name ? row?.f_name : ""}
                        </TableCell>

                        <TableCell align="left">
                          {row.l_name ? row?.l_name : ""}
                        </TableCell>

                        <TableCell align="left">
                          {row.mobile ? row?.mobile : ""}
                        </TableCell>

                        <TableCell align="left">
                          {value === 0 && (
                            <React.Fragment>
                              <Switch
                                defaultChecked
                                onChange={() => handleSwitchChange(row.id)}
                              />
                              <IconButton onClick={() => console.log("Clicked!")}>
                                <SettingsIcon  onClick={(event) => handleCellClick(event, row.id)}/>
                              </IconButton>
                            </React.Fragment>
                          )}
                          {value === 1 && (
                            <React.Fragment>
                              <Switch
                                onChange={() => handleSwitchChange(row.id)}
                              />
                              <IconButton>
                                <SettingsIcon  onClick={(event) => handleCellClick(event, row.id)}/>
                              </IconButton>
                            </React.Fragment>
                          )}
                          {/* {value === 2 && (
                            <React.Fragment>
                              <Switch /> */}
                              <IconButton 
                              onClick={(event) => {event.stopPropagation(); 
                                handleEditModalClick(event, row.id);
                                onGetReportToListStart(row?.role_details?.name);
                                // console.log("row?.role_details?.name---->", row?.role_details?.name);
                              }}><img src={EditIcon} alt="Edit" />
                              </IconButton>
                              {/* <IconButton><img onClick={ (event) => handleEditModalClick(event, row.id)
                                // () => setOpenUserEditModal(true)
                                } src={EditIcon} alt="Edit" /></IconButton> */}
                            {/* </React.Fragment>
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  }) ) : 
                  (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center">
                        <img src={Loading}/>
                        </TableCell>
                    </TableRow>
                  )
                ) }
                {/* } */}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            {/* )} */}
            {" "}
          </Table>
        </TableContainer>
        <CustomPagination
          count={pageDetails?.total}
          rowsPerPage={rowsPerPage}
          page={activePage - 1}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          pageNo={noOfPage}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
        />
      </Paper>
    </Box>
  <UserEditModal 
     openUserEditModal = {openUserEditModal}
     setOpenUserEditModal = {setOpenUserEditModal}
     user = {user}
     Users = {Users}
     onupdateUserStart = {onupdateUserStart}
     onFetchEnableUsersStart = {onFetchEnableUsersStart}
     onFetchDisableUsersStart = {onFetchDisableUsersStart}
     onGetReportToListStart = {onGetReportToListStart}
     reportToData={reportToData}
      />
    </Fragment>
  );
};

export default UserTable;
