export const GET_SALES_REPORT_DETAILS_LIST_START = "GET_SALES_REPORT_DETAILS_LIST_START";
export const GET_SALES_REPORT_DETAILS_LIST_SUCCESS = "GET_SALES_REPORT_DETAILS_LIST_SUCCESS";
export const GET_SALES_REPORT_DETAILS_LIST_FAILURE = "GET_SALES_REPORT_DETAILS_LIST_FAILURE";

export const SEARCH_SALES_DETAILS_NAME = "SEARCH_SALES_DETAILS_NAME";

// Today
export const GET_SALES_DETAILS_TODAY_START = "GET_SALES_DETAILS_TODAY_START";
export const GET_SALES_DETAILS_TODAY_SUCCESS = "GET_SALES_DETAILS_TODAY_SUCCESS";
export const GET_SALES_DETAILS_TODAY_FAILURE = "GET_SALES_DETAILS_TODAY_FAILURE";

// Yesterday
export const GET_SALES_DETAILS_YESTERDAY_START = "GET_SALES_DETAILS_YESTERDAY_START";
export const GET_SALES_DETAILS_YESTERDAY_SUCCESS = "GET_SALES_DETAILS_YESTERDAY_SUCCESS";
export const GET_SALES_DETAILS_YESTERDAY_FAILURE = "GET_SALES_DETAILS_YESTERDAY_FAILURE";

// Monthly
export const GET_SALES_DETAILS_MONTHLY_START = "GET_SALES_DETAILS_MONTHLY_START";
export const GET_SALES_DETAILS_MONTHLY_SUCCESS = "GET_SALES_DETAILS_MONTHLY_SUCCESS";
export const GET_SALES_DETAILS_MONTHLY_FAILURE = "GET_SALES_DETAILS_MONTHLY_FAILURE";

// Bar Chart
export const GET_SALES_DETAILS_YEAR_BAR_START = "GET_SALES_DETAILS_YEAR_BAR_START";
export const GET_SALES_DETAILS_YEAR_BAR_SUCCESS = "GET_SALES_DETAILS_YEAR_BAR_SUCCESS";
export const GET_SALES_DETAILS_YEAR_BAR_FAILURE = "GET_SALES_DETAILS_YEAR_BAR_FAILURE";

// Highest Placed Order (Order by Total Sales)
export const GET_HIGHEST_PLACED_ORDER_START = "GET_HIGHEST_PLACED_ORDER_START";
export const GET_HIGHEST_PLACED_ORDER_SUCCESS = "GET_HIGHEST_PLACED_ORDER_SUCCESS";
export const GET_HIGHEST_PLACED_ORDER_FAILURE = "GET_HIGHEST_PLACED_ORDER_FAILURE";

// Custom Range Order List for Bar Chart
export const GET_ORDER_LIST_FOR_BAR_START = "GET_ORDER_LIST_FOR_BAR_START";
export const GET_ORDER_LIST_FOR_BAR_SUCCESS = "GET_ORDER_LIST_FOR_BAR_SUCCESS";
export const GET_ORDER_LIST_FOR_BAR_FAILURE = "GET_ORDER_LIST_FOR_BAR_FAILURE";