import React from "react";
import { Button } from "@mui/material";

const CustomViewButton = ({ label, onClick, isActive}) => {
    return (
        <Button
          sx={{
            borderRadius: '90px',
            textTransform: 'none',
            color: isActive ? '#222222' : '#222222',
            backgroundColor: isActive ? '#CFD3D4' : '#f0f0f0',
            borderColor: '#CFD3D4',
            '&:hover': {
              backgroundColor: isActive ? '#CFD3D4' : '#f0f0f0',
            },
          }}
          onClick={onClick}
        >
          {label}
        </Button>
      );
}
export default CustomViewButton;
