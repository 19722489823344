import { values } from "lodash";
import * as ItemStatisticalReportType from "./itemStatisticalReport.types";
const INITIAL_STATE = {
    itemStatisticalReportData: [],
    itemPageDetails: null,
    grandTotalSales: null,
    monthTotalSales: null,

    yearlyItemList: [],
    itemPageDetailsYearly: null,
    grandTotalSalesYearly: null,
    monthTotalSalesYearly: null,
    // yeralyGrandeTotalSales: null,
}
const itemStatisticalReportReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch(type){
        case ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_START:
            return {
                ...state,
                loading: true,
            }

        case ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_SUCCESS:
            // console.log("itemStatisticalReportData---->", payload);
            return {
                ...state,
                itemStatisticalReportData: payload?.data?.data,
                itemPageDetails: payload?.data?.page_details,
                grandTotalSales: payload?.data?.grand_total_sales,
                monthTotalSales: payload?.data?.month_total_sales,
                loading: false,
            }

        case ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
          
        // For Search
        case ItemStatisticalReportType.SEARCH_ITEM_STATISTICAL_PRODUCT_NAME:
            const { key, value } = action.payload;
            return {
                ...state,
                itemStatisticalReportData: payload?.data?.item_statistical_report,
                itemPageDetails: payload?.data?.page_details,
                [key]: value,
            }

        // For Hollow Pie Chart (Yearly for Now)
        case ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_START:
            return {
                ...state,
                loading: true,
            }

        case ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_SUCCESS:
            return {
                ...state,
                // yearlyItemList: payload?.data?.item_statistical_report,
                yearlyItemList: payload?.data?.data,
                itemPageDetailsYearly: payload?.data?.page_details,
                grandTotalSalesYearly: payload?.data?.grand_total_sales,
                monthTotalSalesYearly: payload?.data?.month_total_sales,
                // itemPageDetails: payload?.data?.page_details,
                loading: false,
            }

        case ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default: 
            return state;
    }   
}
export default itemStatisticalReportReducer;