import React, { Fragment } from "react";

import CustomizedDialog from "../../../components/CustomDialog";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import container from "./AddTourScreen.container";
import {
  Box,
  Typography,
} from "@mui/material";

import Calendar from "./components/Calendar";

const AddTourScreen = ({
  onFetchAllBeatsListStart,
  beats: { allBeats, pages, },
  onListTenantTourProgramsListStart,
  tenant: { tourPrograms },
  createOrUpdateTenantTourProgramStart,
  onOpenAlert, 
}) => {// Add Tour Screen requires bellow components 

  React.useEffect(() => {
    onListTenantTourProgramsListStart();
  }, []);

  const [selectedDate, setSelectedDate] = React.useState(moment());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    
    onListTenantTourProgramsListStart(moment(date).format("YYYY-MM-DD"));
  };

  return (
    <Fragment>
      <Typography
        variant="h3"
        sx={{
          paddingY: (theme) => theme.spacing(3),
        }}
      >
        Add Tour Plan
      </Typography>
      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#fff",
          paddingX: (theme) => theme.spacing(3),
          paddingY: (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: (theme) => theme.spacing(3),
            marginBottom: "18px",
          }}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={["year"]}
                sx={{ width: 140, marginRight: "5px" }}
                value={selectedDate}
                slotProps={{ textField: { size: "small" } }}
                onChange={handleDateChange}
              />
              <DatePicker
                views={["month"]}
                sx={{ width: 140 }}
                value={selectedDate}
                slotProps={{ textField: { size: "small" } }}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              gap: (theme) => theme.spacing(3),
            }}
          >
            <CustomButton
              name="Cancel"
              variant="outlined"
              size="small"
              sx={{ minWidth: 130 }}
            />
            <CustomButton name="Lock Tour Program" size="small" />
          </Box> */}
        </Box>
        <Calendar
          onFetchAllBeatsListStart={onFetchAllBeatsListStart}
          tourPrograms={tourPrograms}
          allBeats={allBeats}
          createOrUpdateTenantTourProgramStart={
            createOrUpdateTenantTourProgramStart
          }
          pages = {pages}
          onOpenAlert={onOpenAlert}
          selectedDate={selectedDate}
          onListTenantTourProgramsListStart={onListTenantTourProgramsListStart}
        />
      </Box>
    </Fragment>
  );
};

export default container(AddTourScreen);
