import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { tenantInstance } from "../../configs/axiosConfig";
import * as VisitActions from "./visit.action";
import * as VisitType from './visit.types';

//Visit List Data
export function* getVisitListAsync( { payload }: any ) {
  const { start_date, end_date} = payload;
  // console.log("start_date---->", start_date);
  // console.log("end_date---->", end_date);
  let{ user_id, name} = yield select((state) => state.visitScreen);
  try{
    const { data } = yield tenantInstance.post(`/tenants/visit/get-just-visits-report`, { 
      start_date,
      end_date,
      filters: {
        f_name: name,
      }
      // filters: {
      //   user_id,
      //   email: "",
      //   f_name: "",
      //   m_name: "",
      //   l_name: "",
      //   d_name: "",
      //   gender: "",
      //   mobile: "",
      // }
    })
    yield put(VisitActions.getVisitListSuccess(data));
  }
  catch(error){
    yield put(VisitActions.getVisitListFail(error));
  }
}
export function* fetchReportListAsync({ payload }: any) {
  try {
    const { name } = yield select((state) => state.visitScreen);
    let { date, rowId,} = payload;
    const { data } = yield tenantInstance.post(`/tenants/visit/get-retailer-details`,
    {
      date,
      sales_person_id: rowId,
      // filters: {
      //   name,
      // }
    }
    );
    yield put(VisitActions.fetchReportListSuccess(data));
  } catch (error) {
    yield put(VisitActions.fetchReportListFail(error));
  }
}
export function* filterVisitDataAsync({ payload }: any) {
  const {startDate, endDate } = payload;
  try {
    const { data } = yield tenantInstance.post(`/tenants/visit/get-just-visits-report`,
    {
      // filter: {
      start_date: startDate,
      end_date: endDate,
      name: ""
      // }
    }

    );
    yield put(VisitActions.filterVisitDataSuccess(data));
  } catch (error) {
    yield put(VisitActions.filterVisitDataFail(error));
  }
}

export function* watchGetVisitListAsync() {
  yield takeLatest(
    VisitType.GET_VISIT_LIST_START,
    getVisitListAsync
  );
}
export function* watchFetchReportListAsync(){
  yield takeLatest(
    VisitType.FETCH_VISIT_REPORT_START,
    fetchReportListAsync
  )
}
export function* watchFilterDataAsync(){
  yield takeLatest(
    VisitType.FILTER_VISIT_DATA_START,
    filterVisitDataAsync
  )
}
export function* visitSagas() {
  yield all([
    call(watchGetVisitListAsync),
    call(watchFetchReportListAsync),
    call(watchFilterDataAsync),
  ]);
}