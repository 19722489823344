import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import AddProduct from "../../../views/Admin/AddProducts";

const Dashboard = () => {
  return <WithLayout layout={Admin} component={AddProduct} />;
};

export default Dashboard;
