import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import Attendances from "../../../views/Admin/Attendance";

// =================== Order Page ================= //
const Attendance = () => {
  return <WithLayout layout={Admin} component={Attendances} />;
};

export default Attendance;
