import { connect } from "react-redux";
import { getReportByBeatsListStart, yearlyBeatsListStart } from "../../../store/reportbybeats/reportByBeats.action";

const mapStateToProps = (state) => {
    return{
        reportByBeats: state.reportByBeats,
    }
};
const mapDispatchToProps = (dispatch) => ({
    onGetReportByBeatsListStart: (fromDate, toDate, page, limit, type, field, flag) => dispatch(getReportByBeatsListStart(fromDate, toDate, page, limit, type, field, flag)),
    onYearlyBeatsListStart: (fromDate, toDate) => dispatch(yearlyBeatsListStart(fromDate, toDate)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;