import { connect } from "react-redux";
import { changeTenantStart, signoutStart } from "../../store/auth/auth.actions";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeTenantStart: (name, cb) => dispatch(changeTenantStart(name, cb)),
  onSignoutStart: (cb) => dispatch(signoutStart(cb)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
