// React-redux
import { connect } from "react-redux";
import {
  changeApplyTraderFilter,
  fetchUsersBasedOnRolesStart,
} from "../../../store/traders/traders.actions";

const mapStateToProps = (state) => ({
  traders: state.traders,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchUsersBasedOnRolesStart: (roleId, type, field) => dispatch(fetchUsersBasedOnRolesStart(roleId, type, field)),
  onChangeApplyTraderFilter: (name, value, filterValue) => dispatch(changeApplyTraderFilter(name, value, filterValue)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
