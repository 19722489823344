import { connect } from "react-redux";
import { getReportByDistributorStart } from "../../../store/reportbydistributor/reportByDistributor.action";

const mapStateToProps = (state) => {
    return{
        reportByDistributor: state.reportByDistributor,
    }
};
const mapDispatchToProps = (dispatch) => ({
    onGetReportByDistributorStart: (fromDate, toDate) => dispatch(getReportByDistributorStart(fromDate, toDate)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;