// React-redux
import { connect } from "react-redux";

// Project imports
import {
  acceptTenantInvitationStart,
  changeTenantStart,
} from "../../../store/auth/auth.actions";
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeTenantStart: (value, cb) => dispatch(changeTenantStart(value, cb)),
  onAcceptTenantInvitationStart: (id) =>
    dispatch(acceptTenantInvitationStart(id)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
