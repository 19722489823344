//Visit List Import
import * as VisitType from "./visit.types";

export const getVisitListStart = (start_date, end_date) => ({
  type: VisitType.GET_VISIT_LIST_START,
  // payload:{name, value} 
  payload: { start_date, end_date }
});
export const getVisitListSuccess = (result) => {
  return{type: VisitType.GET_VISIT_LIST_SUCCESS,
  payload: result,}
};
export const getVisitListFail = (error) => ({
  type: VisitType.GET_VISIT_LIST_FAILURE,
  payload: error,
});

// Reportlist Data
export const fetchReportListStart = (date, rowId,) => {
  return{type: VisitType.FETCH_VISIT_REPORT_START,
    payload: { date, rowId }}
};
export const fetchReportListSuccess = (beat_id) => {
  return {type: VisitType.FETCH_VISIT_REPORT_SUCCESS,
  payload: beat_id }
};
export const fetchReportListFail = (error) => {
  return{
    type: VisitType.FETCH_VISIT_REPORT_FAILURE,
    payload: error,
  }
};
export const selectedClickData = (date, salesPersonId) => ({
  type: VisitType.SET_SELECTED_CLICK_DATA,
  payload: {date, salesPersonId}
});

//Filter Visit Data
export const filterVisitDataStart = (startDate, endDate) => {
  return{
    payload: { startDate, endDate },
    type: VisitType.FILTER_VISIT_DATA_START,
  }
};
export const filterVisitDataSuccess = (result) => ({
    type: VisitType.FILTER_VISIT_DATA_SUCCESS,
    payload: result,
});
export const filterVisitDataFail = (error) => ({
  type: VisitType.FILTER_VISIT_DATA_FAILURE,
  payload: error,
});
export const changeApplyVisitFilter = (key, value) => ({
  type: VisitType.ON_CHANGE_APPLY_VISIT_FILTER,
  payload: { key, value },
});

export const searchVisiDataName = (key, value) => ({
  type: VisitType.SEARCH_VISIT_DATA_NAME,
  payload: {key, value },
})