import moment from "moment";
import React, { Fragment, useEffect, } from "react";
import { Box, Table, TableContainer, TableRow, TableBody,
Paper, TableCell, } from "@mui/material";
import { EnhancedTableHead } from '../../../../components/CustomTable';

const Calendar = ({onGetPersonSalesReportListStart, fromDate, toDate, flag,
    personSalesReport: {personSalesReportData, personPageDetails, monthTotalSales, }}) => {
    const [dense, setDense] = React.useState(false);

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("name");
    const [isMonthView, setIsMonthView] = React.useState(false);

    useEffect(() => {
        const startMoment = moment(fromDate);
        const endMoment = moment(toDate);
    
        const diffInMonths = endMoment.diff(startMoment, 'months');
    
        setIsMonthView(diffInMonths >= 1); 
    }, [fromDate, toDate]);

    const getColumnsForDateRange = (fromDate, toDate, isMonth = false) => {
        const columns = [];
        const currentDate = new Date(fromDate);
    
        while (currentDate <= new Date(toDate)) {
            const formattedColumn = isMonth
                ? moment(currentDate).format("MMM")
                : moment(currentDate).format("YYYY-MM-DD");
    
            columns.push({
                id: formattedColumn,
                numeric: false,
                disablePadding: false,
                label: isMonth ? moment(currentDate).format("MMMM") : formattedColumn,
                isShow: true,
            });
    
            if (isMonth) {
                currentDate.setMonth(currentDate.getMonth() + 1);
            } else {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    
        return columns;
    };    
    
    const dateRangeColumns = getColumnsForDateRange(fromDate, toDate);
    const monthRangeColumns = getColumnsForDateRange(fromDate, toDate, true);
    const allColumns = [...(isMonthView ? monthRangeColumns : dateRangeColumns)];

    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
        onGetPersonSalesReportListStart(fromDate, toDate, personPageDetails?.page, personPageDetails?.limit, isAsc ? "asc" : "desc", field, flag);
    };
    // React.useEffect(() => {
    //     onGetPersonSalesReportListStart(fromDate, toDate, 1, 10, 'asc', 'name');
    // }, []);
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                                numSelected={0}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={() => {}}
                                onRequestSort={(event, property) => handleRequestSort(property)}
                                rowCount={personSalesReportData?.length}
                                headCells={allColumns}
                                isCheckBox={false}
                            />
                            <TableBody>
                                {personSalesReportData && personSalesReportData?.length > 0 ? (
                                    personSalesReportData?.map((row, index) => {
                                        return(
                                            <TableRow key={index}>
                                                {isMonthView ? (
                                                    monthRangeColumns.map((monthColumn) => (
                                                      <TableCell
                                                          key={monthColumn.id}
                                                          align="center"
                                                          sx={{
                                                                borderTop: "1px solid #ebeded",
                                                                borderRight: "1px solid #ebeded",
                                                                height: "75px", whiteSpace: "nowrap",
                                                          }}
                                                      > 
                                                          {row.sales &&
                                                            row.sales.find(sale => sale.date === monthColumn.id) ? (
                                                              `${row.sales.find(sale => sale.date === monthColumn.id).total_order_value} (${row.sales.find(sale => sale.date === monthColumn.id).total_order_quantity})`
                                                            ) : (
                                                              "-"
                                                        )}
                                                      </TableCell>
                                                    ))
                                                    ) : (
                                                      dateRangeColumns.map((dateColumn) => (
                                                        <TableCell
                                                          key={dateColumn.id}
                                                          align="center"
                                                          sx={{
                                                            borderTop: "1px solid #ebeded",
                                                            borderRight: "1px solid #ebeded",
                                                            height: "75px",
                                                          }}
                                                        >
                                                          {row.sales &&
                                                            row.sales.find(sale => sale.date === dateColumn.id) ? (
                                                              `${row.sales.find(sale => sale.date === dateColumn.id).total_order_value} (${row.sales.find(sale => sale.date === dateColumn.id).total_order_quantity})`
                                                            ) : (
                                                              "-"
                                                        )}
                                                        </TableCell>
                                                      ))
                                                    )}
                                            </TableRow>
                                            
                                        )
                                    })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={allColumns.length} align="center">
                                              {/* <img src={Loading}/> */}
                                              No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            {isMonthView ? (
                                monthRangeColumns.map((monthColumn) => (
                                  <TableCell
                                      key={monthColumn.id}
                                      align="center"
                                      sx={{
                                            borderTop: "1px solid #ebeded",
                                            borderRight: "1px solid #ebeded",
                                            // height: "75px", 
                                            whiteSpace: "nowrap",
                                            fontWeight: "bold", 
                                      }}
                                  > 
                                      {/* {monthTotalSales &&
                                        monthTotalSales.find(sale => sale.date === monthColumn.id) ? (
                                          `${monthTotalSales.find(sale => sale.date === monthColumn.id).total_sales}`
                                        ) : (
                                          "-"
                                    )} */}
                                    {monthTotalSales &&
                                        monthTotalSales.find(sale => sale.date === monthColumn.id) ? (
                                          `${(monthTotalSales.find(sale => sale.date === monthColumn.id).total_sales).toFixed(2)}`
                                        ) : (
                                          "0.00"
                                    )}
                                  </TableCell>
                                ))
                                ) : (
                                  dateRangeColumns.map((dateColumn) => (
                                    <TableCell
                                      key={dateColumn.id}
                                      align="center"
                                      sx={{
                                        borderTop: "1px solid #ebeded",
                                        borderRight: "1px solid #ebeded",
                                        // height: "75px",
                                        fontWeight: "bold", whiteSpace: "nowrap",
                                      }}
                                    >
                                      {monthTotalSales &&
                                        monthTotalSales.find(sale => sale.date === dateColumn.id) ? (
                                          `${(monthTotalSales.find(sale => sale.date === dateColumn.id).total_sales).toFixed(2) }`
                                        ) : (
                                          "0.00"
                                    )}
                                    </TableCell>
                                  ))
                                )}
                        </Table>
                    </TableContainer>
                </Box>
            </Box> 
        </Fragment>
    )
}

export default Calendar;