import * as SalesReportDetailsType from "./salesReportDetails.types";

const INITIAL_STATE = {
    salesReportDetailsData: [],
    salesDetails: null,
    totalSales: null,

    salesReportDetailsDataToday: [],
    salesDetailsToday: null,
    totalSalesToday: null,

    salesReportDetailsDataYesterday: [],
    salesDetailsYesterday: null,
    totalSalesYesterday: null,

    salesReportDetailsDataMonthly: [],
    salesDetailsMonthly: null,
    totalSalesMonthly: null,

    salesReportDetailsDataYearlyBar: [],
    salesDetailsYearlyBar: null,
    totalSalesYearlyBar: null,

    highestSalesData: [],

    barCustomDateData: [],

};
const salesReportDetailsReducer = ( state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch(type){
        // For Table
        case SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_START:
            return {
                ...state,
                loading: true,
            }
        case SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                salesReportDetailsData: payload?.data?.data,
                salesDetails: payload?.data?.page_details,
                totalSales: payload?.data?.total_sales,
                loading: false,
            }
        case SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        
        // For Search
        case SalesReportDetailsType.SEARCH_SALES_DETAILS_NAME:
            const {key, value} = action.payload;
            return {
                ...state,
                salesReportDetailsData: payload?.data?.data,
                salesDetails: payload?.data?.page_details,
                totalSales: payload?.data?.total_sales,
                [key]: value,
            }

        // For Today
        case SalesReportDetailsType.GET_SALES_DETAILS_TODAY_START:
            return {
                ...state,
                loading: true,
            }
        case SalesReportDetailsType.GET_SALES_DETAILS_TODAY_SUCCESS:
            return {
                ...state,
                salesReportDetailsDataToday: payload?.data?.data,
                salesDetailsToday: payload?.data?.page_details,
                totalSalesToday: payload?.data?.total_sales,
                loading: false,
            }
        
        case SalesReportDetailsType.GET_SALES_DETAILS_TODAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        // For Yesterday
        case SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_START:
            return {
                ...state,
                loading: true,
            }
        case SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_SUCCESS:
            return {
                ...state,
                salesReportDetailsDataYesterday: payload?.data?.data,
                salesDetailsYesterday: payload?.data?.page_details,
                totalSalesYesterday: payload?.data?.total_sales,
                loading: false,
            }
        
        case SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }

        // For Monthly
        case SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_START:
            return {
                ...state,
                loading: true,
            }
        case SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_SUCCESS:
            return {
                ...state,
                salesReportDetailsDataMonthly: payload?.data?.data,
                salesDetailsMonthly: payload?.data?.page_details,
                totalSalesMonthly: payload?.data?.total_sales,
                loading: false,
            }
        
        case SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }

        // Yearly Data for Bar
        case SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_START:
            return {
                ...state,
                loading: true,
            }
        case SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_SUCCESS:
            return {
                ...state,
                salesReportDetailsDataYearlyBar: payload?.data?.data,
                salesDetailsYearlyBar: payload?.data?.page_details,
                totalSalesYearlyBar: payload?.data?.total_sales,
                loading: false,
            }
        
        case SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }

        // For Highest Placed order
        case SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_START:
            return {
              ...state,
              loading: true,
            }
        
        case SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_SUCCESS: 
          return {
            ...state,
            highestSalesData: payload?.data?.sales_report,
            loading: false,
          }
    
        case SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_FAILURE:
          return {
            ...state,
            loading: false,
            error: payload,
          }

        // For Bar Custom Range
        case SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_START:
            return {
              ...state,
              loading: true,
            }
        
        case SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_SUCCESS: 
          return {
            ...state,
            barCustomDateData: payload?.data?.sales_report,
            loading: false,
          }
    
        case SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_FAILURE:
          return {
            ...state,
            loading: false,
            error: payload,
          }
        default:
            return state;
    }
}
export default salesReportDetailsReducer;