import { select, put, takeLatest, all, call, } from "redux-saga/effects";
import { tenantInstance } from "../../configs/axiosConfig";
import * as ReportByBeatsActions from "./reportByBeats.action";
import * as ReportByBeatsType from "./reportByBeats.types";

export function* getReportByBeatsListAsync ({payload}: any) {
    let {user_id, name, } = yield select((state) => state.reportByBeats)
    const { fromDate, toDate, limit, page, type, field, flag} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/report-by-beat`, {
            filters: {
                user_id,
                name,
                beat_name: "",
                format_data: flag,
                from_date: fromDate,
                to_date: toDate,
            },
            pages:{
                page,
                limit,
                // total,//: //267,
            },
            sorting: [
              {
                type,
                field,
              }
            ]
        });
        yield put(ReportByBeatsActions.getReportByBeatsListSuccess(data));
    }
    catch(error){
        yield put(ReportByBeatsActions.getReportByBeatsListFail(error));
    }
}

export function* watchgetReportByBeatsListAsync(){
    yield takeLatest(
        ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_START,
        getReportByBeatsListAsync,
    )
}

// API For Yealy Beats Data
export function* yearlyBeatsListAysnc({payload}: any){
    const { fromDate, toDate } = payload;

    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/report-by-beat`, {
            filters: {
                format_data: "month-wise",
                from_date: fromDate,
                to_date: toDate,
            },
        });
        yield put(ReportByBeatsActions.yearlyBeatsListSuccess(data));
    }
    catch(error){
        yield put(ReportByBeatsActions.yearlyBeatsListFail(error));
    }
}
export function* watchYearlyBeatsListAysnc(){
    yield takeLatest(
        ReportByBeatsType.YEARLY_BEATS_LIST_START,
        yearlyBeatsListAysnc,
    )
}

export function* reportByBeatsSagas(){
    yield all([
        call(watchgetReportByBeatsListAsync),
        call(watchYearlyBeatsListAysnc),
    ]);
}