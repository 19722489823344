import { connect } from "react-redux";
import {
  createOrUpdateProductStart,
  fetchAllFilteredProductsCategoryStart,
  fetchAllTenantCategoriesStart,
  fetchProductDetailsStart,
} from "../../../store/inventory/inventory.actions";

const mapStateToProps = (state) => ({
  inventory: state.inventory,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateOrUpdateProductStart: (formData, cb) =>
    dispatch(createOrUpdateProductStart(formData, cb)),
  onFetchAllTenantCategoriesStart: () =>
    dispatch(fetchAllTenantCategoriesStart()),
  onFetchProductDetailsStart: (id) => dispatch(fetchProductDetailsStart(id)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
