import React, { Fragment,} from "react";
import { Box, TableContainer, Paper, Table, TableBody, TableRow, TableCell} from "@mui/material";
import { EnhancedTableHead } from '../../../../components/CustomTable';

const Calendar = ({onGetSalesReportDetailsListStart, fromDate, toDate,
    salesReportDetails: {salesReportDetailsData, salesDetails, totalSales}, }) => {
    const [dense, setDense] = React.useState(false);
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("user_name");

    interface HeadCell {
        disablePadding: boolean;
        id: any;
        label: string;
        isShow: boolean;
        numeric: boolean;
        align?: string;
    }
    const headCells: readonly HeadCell[] = [
        {
          id: "distributor_name",
          numeric: false,
          disablePadding: false,
          label: "Distributor Name",
          isShow: true,
        },
        {
          id: "beat_name",
          numeric: false,
          disablePadding: false,
          label: "Beat Name ",
          isShow: true,
        },
        {
          id: "retailer_name",
          numeric: false,
          disablePadding: false,
          label: "Retailer Name",
          isShow: true,
        },
        {
          id: "total_sale",
          numeric: false,
          disablePadding: false,
          label: "Total Sales",
          isShow: true,
        },
        {
          id: "visit_time",
          numeric: false,
          disablePadding: false,
          label: "Visit Time",
          isShow: true,
        },
    ];
    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
        onGetSalesReportDetailsListStart(fromDate, toDate, salesDetails?.page, salesDetails?.limit, isAsc ? "asc" : "desc", field, );
    };
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                              numSelected={0}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={() => {}}
                              onRequestSort={(event, property) => handleRequestSort(property)}
                              rowCount={salesReportDetailsData?.length}
                              headCells={headCells}
                              isCheckBox={false}
                            />
                            <TableBody>
                                {salesReportDetailsData && salesReportDetailsData.length > 0 ? (
                                    salesReportDetailsData?.map((row, index) => {
                                        return(
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                }}>
                                                    -
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded", whiteSpace: "nowrap",
                                                }}>
                                                    {row.beat_name ? `${row?.beat_name} `  : "-"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}>
                                                    {row.retailer_name ? row?.retailer_name : "0"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                    height: "75px"
                                                }}>
                                                    {/* {row.total_sale ? row?.total_sale : "0"} */}
                                                    {row.total_sale !== undefined ? row.total_sale.toFixed(2) : "0.00"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                    whiteSpace: "nowrap",
                                                }}>
                                                    {row.visit_time ? row?.visit_time : "0"}
                                                </TableCell> 
                                            </TableRow>
                                        )
                                    
                                })) : 
                                    (
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center">
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )
                            }
                            </TableBody>
                            <TableCell
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                }}
                            >
                                
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                }}
                            >
                                
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded", 
                                }}
                            >
                                
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                    height: "54px"
                                }}
                            >
                                {/* {totalSales} */}
                                {totalSales != undefined ? totalSales.toFixed(2) : "0.00"}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                }}
                            >    
                            </TableCell>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Fragment>
    )
}

export default Calendar;