import React, { Fragment, useState } from "react";

// Material-ui
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// Icons Imports
import FilterIcon from "../../../../assets/images/filter.svg";

// Project Imports
import CustomButton from "../../../../components/CustomButton";
import FilterCalendar from "./FilterCalendar";
import { useDispatch } from "react-redux";
import moment from "moment";
import { changeApplyOrderClearFilter } from "../../../../store/orders/orders.action";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
interface OrderMenuProps {
  filterName: string;
  onChangeApplyOrderFilter: any;
  onFetchPlacedOrdersStart?: any;
  onFetchTradersStart?:any;
  onFetchReceivedOrdersStart?: any;
  receivedOrdersFilter?: any;
  placedOrdersFilter?: any;
  ordersOnbeHalfFilter?: any;
  traders?:any
  onFetchOrdersOnbeHalfStart?: any;
  
}
const OrderMenu = ({
  filterName,
  onChangeApplyOrderFilter,
  onFetchPlacedOrdersStart,
  onFetchReceivedOrdersStart,
  onFetchOrdersOnbeHalfStart,
  onFetchTradersStart,
  receivedOrdersFilter,
  placedOrdersFilter,
  ordersOnbeHalfFilter,
  traders:{traders},
  
}: OrderMenuProps) => {
  // console.log("orders---->", orders);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isReceivedFilter, setIsReveivedFilter] = React.useState(false);
  const [isOrderOnbeHalfFilter, setOrderOnbeHalfFilter] = React.useState(false);

  const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };
    const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
  
    const handleSelect = (ranges) => {
      setDateRange([ranges.selection]);
      setCustomDateLabel(
        `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
          'MM/DD/YYYY'
        )}`
      );

    };

  React.useEffect(() => {
    onFetchTradersStart()
  }, []);
  React.useEffect(() => {
    
    filterName === "received"
      ? setIsReveivedFilter(true)
      : setIsReveivedFilter(false);
  }, [filterName]);

  React.useEffect(() => {
    filterName === "orderonbehalf"
      ? setOrderOnbeHalfFilter(true)
      : setOrderOnbeHalfFilter(false);
  }, [filterName]);
  

  const handleFilter = () => {
    if (isReceivedFilter) {
      dispatch(onFetchReceivedOrdersStart());
      handleClose()
    }else if (isOrderOnbeHalfFilter) {
      dispatch(onFetchOrdersOnbeHalfStart());
      handleClose()
    } else {
      dispatch(onFetchPlacedOrdersStart());
      handleClose()
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  // const handleChange = (e) => {
  //      const { name, value } = e.target;
  //      if(name==='order_id'){
  //       console.log(value.length)
  //       console.log(name,[Number(value)],"checking")
  //       value.length? dispatch(changeApplyOrderFilter(name, [Number(value)],filterValue)):dispatch(changeApplyOrderFilter(name, [],filterValue))
  //     }
  //       else{
  //      dispatch(changeApplyOrderFilter(name, value,filterValue))
  //      console.log(typeof value)
  //      console.log(name,value,"checking")}
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   dispatch(onChangeApplyOrderFilter(name, value, filterName));
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

  if (name === "amount_greater_than" || name === "amount_less_than" || name === "order_id") {
    const isValid = /^[0-9]{1,8}$/.test(value);

    if (isValid) {
      dispatch(onChangeApplyOrderFilter(name, value, filterName));
    }
  } else {
    dispatch(onChangeApplyOrderFilter(name, value, filterName));
  }
    // const { name, value } = e.target;
    // dispatch(onChangeApplyProductFilter(name, value));
    // console.log(name, value, "checking");
  };
  const clearHandler = () => {
    if(filterName==="received"){
      dispatch(changeApplyOrderClearFilter(filterName));
      dispatch(onFetchReceivedOrdersStart());
      handleClose()}
    else if(filterName==="orderonbehalf"){
      dispatch(changeApplyOrderClearFilter(filterName));
      dispatch(onFetchOrdersOnbeHalfStart());
      handleClose()
    }else{
      dispatch(changeApplyOrderClearFilter(filterName));
      dispatch(onFetchPlacedOrdersStart());
      handleClose()
    }
  }

  const handleDateChange = (event) => {
    console.log(event.target.value, "e.target.value");
    dispatch(
      onChangeApplyOrderFilter(
        "selectedDateRange",
        event.target.value,
        filterName
      )
    );
    
  };

  return (
    <Fragment>
      <Box
        sx={{
          width: "89px",
          height: "29px",
          padding: "18px 10px",
          borderRadius: "4px",
          border: "1px solid #45464E",
          display: "flex",
          alignItems: "center",
          gap: "9px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "24px 21px", maxWidth: 400, width: "100%" }}>
          <Grid container spacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" mb={"30px"}>
                  Filter
                </Typography>

                <FormControl fullWidth>
                  <FormLabel id="trader_ids_list" sx={{ marginBottom: "8px" }}>
                    Trader
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="trader_ids_list"
                    options={traders?.filter(trader => trader?.f_name !== null)?.map(trader => trader?.f_name)}
                    value={traders?.f_name}
                    
                    onChange={(event, value) => {
                      if (value) {
                        const selectedTrader = traders.find(trader => trader.f_name === value);
                        console.log(selectedTrader.id);
                        dispatch(onChangeApplyOrderFilter("trader_ids_list", [selectedTrader.id], filterName));
                      }
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="All" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="order_id" sx={{ marginBottom: "8px" }}>
                    Order Id
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="order_id"
                    id="order_id"
                    // value={
                    //   isReceivedFilter
                    //     ? receivedOrdersFilter?.order_id
                    //     : placedOrdersFilter?.order_id
                    // }
                    value ={ isReceivedFilter
                        ? receivedOrdersFilter?.order_id
                        : isOrderOnbeHalfFilter
                          ? ordersOnbeHalfFilter?.order_id
                          : placedOrdersFilter?.order_id
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="item_name" sx={{ marginBottom: "8px" }}>
                    Item Name
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    name="item_name"
                    id="item_name"
                    // value={
                    //   isReceivedFilter
                    //     ? receivedOrdersFilter?.item_name
                    //     : placedOrdersFilter?.item_name
                    // }
                    value ={ isReceivedFilter
                      ? receivedOrdersFilter?.item_name
                      : isOrderOnbeHalfFilter
                        ? ordersOnbeHalfFilter?.item_name
                        : placedOrdersFilter?.item_name
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Amount</Typography>
                <Box sx={{ display: "flex", gap: (theme) => theme.spacing(2) }}>
                  <FormControl>
                    <FormLabel
                      id="from"
                      sx={{
                        fontWeight: 600,
                        fontSize: "10px",
                        lineHeight: "15px",
                        letterSpacing: "-0.025em",
                        color: "#1C1D22",
                        paddingBottom: "4px",
                      }}
                    >
                      From
                    </FormLabel>
                    <TextField
                      hiddenLabel
                      id="from"
                      variant="outlined"
                      size="small"
                      name="amount_greater_than"
                      fullWidth
                      onChange={handleChange}
                      // value={
                      //   isReceivedFilter
                      //     ? receivedOrdersFilter?.amount_greater_than
                      //     : placedOrdersFilter?.amount_greater_than
                      // }
                      value ={ isReceivedFilter
                        ? receivedOrdersFilter?.amount_greater_than
                        : isOrderOnbeHalfFilter
                          ? ordersOnbeHalfFilter?.amount_greater_than
                          : placedOrdersFilter?.amount_greater_than
                    }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      id="to"
                      sx={{
                        fontWeight: 600,
                        fontSize: "10px",
                        lineHeight: "15px",
                        letterSpacing: "-0.025em",
                        color: "#1C1D22",
                        paddingBottom: "4px",
                      }}
                    >
                      To
                    </FormLabel>
                    <TextField
                      hiddenLabel
                      id="to"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="amount_less_than"
                      onChange={handleChange}
                      // value={
                      //   isReceivedFilter
                      //     ? receivedOrdersFilter?.amount_less_than
                      //     : placedOrdersFilter?.amount_less_than
                      // }
                      value ={ isReceivedFilter
                        ? receivedOrdersFilter?.amount_less_than
                        : isOrderOnbeHalfFilter
                          ? ordersOnbeHalfFilter?.amount_less_than
                          : placedOrdersFilter?.amount_less_than
                    }
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs = {12}>
              <>
                <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                  Date Selection
                </FormLabel>
                <Typography
                  sx={{ 
                    ml: 2, mt: 1,// cursor: "pointer"
                    width: "185px",
                    height: "29px",
                    padding: "18px 10px",
                    borderRadius: "4px",
                    border: "1px solid #45464E",
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    cursor: "pointer",
                 }}
                  onClick={handleCustomDatePopoverOpen}
                >
                  {customDateLabel}
                </Typography>
                <Popover
                open={customDatePopoverOpen}
                anchorEl={anchorEl}
                onClose={handleCustomDatePopoverClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                >
                  <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={handleCustomDatePopoverClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                    <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction="vertical"
                        showDateDisplay={false}
                        editableDateInputs={false}
                      />
                    </div>    
                  </Box>
                </Popover>
              </>
              </Grid>
              <Grid item xs={12} hidden>
                <FormLabel id="demo-row-radio-buttons-group-label">
                    By Date
                  </FormLabel>
                <FormControl fullWidth>
                  {/* <FormLabel id="demo-row-radio-buttons-group-label">
                    By Date
                  </FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ justifyContent: "space-between" }}
                    // value={
                    //   isReceivedFilter
                    //     ? receivedOrdersFilter?.selectedDateRange
                    //     : placedOrdersFilter?.selectedDateRange
                    // }
                    value ={ isReceivedFilter
                      ? receivedOrdersFilter?.selectedDateRange
                      : isOrderOnbeHalfFilter
                        ? ordersOnbeHalfFilter?.selectedDateRange
                        : placedOrdersFilter?.selectedDateRange
                  }
                    onChange={handleDateChange}
                  >
                    <FormControlLabel
                      value="this-week"
                      control={<Radio />}
                      label="This Week"
                    />
                    <FormControlLabel
                      value="last-week"
                      control={<Radio />}
                      label="Last Week"
                    />
                    <FormControlLabel
                      value="this-month"
                      control={<Radio />}
                      label="This Month"
                    />
                    <FormControlLabel
                      value="last-month"
                      control={<Radio />}
                      label="Last Month"
                    />
                    <FormControlLabel
                      value="this-year"
                      control={<Radio />}
                      label="This Year"
                    />
                    <FormControlLabel
                      value="last-year"
                      control={<Radio />}
                      label="Last Year"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} hidden>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Date Range"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  name="Filter"
                  handleClick={handleFilter}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} >  
              <CustomButton
                sx={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Clear Filter"
                handleClick={clearHandler}
              />
            </Grid>
            </Grid>
            {/* <Grid item xs={6}>
              <FilterCalendar />
            </Grid> */}
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default OrderMenu;