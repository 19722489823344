import React from "react";
import { Avatar, Grid, Paper, Typography, } from "@mui/material";
import moment from "moment";

const TopSellingProducts = ({onYearlyItemListStart,
    itemStatisticalReport: {yearlyItemList}}) => {

    const today = moment().format("YYYY-MM-DD");
    const startDate = moment(today).startOf('month').format("YYYY-MM-DD");
    const endDate = moment(today).endOf('month').format("YYYY-MM-DD");

    const [dataLoaded, setDataLoaded] = React.useState(false);


    React.useEffect(() => {
        onYearlyItemListStart(startDate, endDate, 'asc', 'price');
    }, []);
    React.useEffect(() => {
        if (yearlyItemList) {
          setDataLoaded(true);
        }
      }, [yearlyItemList]);
    return(
        <Grid item container xs={12} md={12} lg={12}>
            <Paper sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px', height: "598px", overflowY: "auto", overflowX: "hidden", borderRadius: "14px" }}>
                <Grid item md={12}>
                    <Typography sx={{mt: "20px", ml: "18px", fontSize: "18px", fontWeight: "bold" }}>Top Selling Products</Typography>
                </Grid>
                {dataLoaded && yearlyItemList.length > 0 ? (
                    // <Grid item container md={12}>
                    //     {productName}
                    // </Grid>
                    <Grid item container md={12} ml={"15px"} mt={"8px"}>
                      {yearlyItemList.map((product, index) => (
                        <Grid container>
                            <Grid item xs={2} mt={"14px"}>
                            <Avatar sx={{ backgroundColor: "#D9D9D9", fontSize: "12px", color: "#000000", width: 32, height: 32 }}>
                              {index + 1}
                            </Avatar>
                            </Grid>
                            <Grid item xs={7} key={product.id} mt={"14px"}>
                              <Typography sx={{fontSize: "14px",}}>{product.name}</Typography>
                            </Grid>
                            <Grid item xs={3} key={product.id} mt={"14px"} sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "14px",}}>{product.total_sales}</Typography>
                            </Grid>
                        </Grid>
                      ))}
                    </Grid>
                ) : (
                  <Grid item container md={12} ml={"15px"} mt={"8px"}>
                      <Grid item xs={2} mt={"14px"}>
                          <Avatar sx={{ backgroundColor: "#D9D9D9", fontSize: "12px", color: "#000000", width: 32, height: 32 }}>
                              1
                          </Avatar>
                      </Grid>
                      <Grid item xs={6} mt={"14px"}>
                          <Typography sx={{fontSize: "14px",}}>0 Orders Made Yet</Typography>
                      </Grid>
                      <Grid item xs={4} mt={"14px"}>
                          <Typography sx={{fontSize: "14px",}}> </Typography>
                      </Grid>
                  </Grid>
                )
                }
            </Paper>
        </Grid>
    )
}

export default TopSellingProducts;