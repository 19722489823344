import React, { Fragment, useState } from 'react';
import container from './Chart.container';
import { Box, Grid, Popover, Typography } from '@mui/material';
import Charts from 'react-google-charts';
import moment from 'moment';
import FilterIcon from "../../../assets/images/filter.svg";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from "../../../assets/images/loading.svg";
import CustomButton from '../../../components/CustomButton';
interface ChartProps{
  order: any,
  onGetChartListStart: any,
  chartScreen, filterName
}
interface MonthlyCallDetail {
  [key: string]: number | string | null;
  username: string | null;
  January: number;
  February: number;
  March: number;
  April: number;
  May: number;
  June: number;
  July: number;
  August: number;
  September: number;
  October: number;
  November: number;
  December: number;
  // Add other months if present
}

const Chart: React.FC<ChartProps> = ({
  onGetChartListStart,
  chartScreen
}) => {
  const currentDate = moment().format("YYYY-MM-DD");

  const [selectedMonth, setSelectedMonth] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [fromDate, setFromDate] = React.useState(currentDate);
  const [toDate, setToDate] = React.useState(currentDate);
  const [monthStartDate, setMonthStartDate] = useState(null);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };
  const handleMonthClick = (monthIndex) => {
    const currentDate = moment();
    const startDate = moment(currentDate).month(monthIndex).startOf('month').format("YYYY-MM-DD");
    const endDate = moment(currentDate).month(monthIndex).endOf('month').add(2, 'months').endOf('month').format("YYYY-MM-DD");
    // const endDate = moment(currentDate).month(monthIndex).endOf('month').format("YYYY-MM-DD");
    setFromDate(startDate);
    setToDate(endDate);
    onGetChartListStart(startDate, endDate);
  };
  // const handleFilter = (monthIndex = null) => {
  //   handleClose();
  //       handleMonthClick(monthIndex);
  //   clearFilter();
  // }
  // const handleFilter = () => {
  //   handleClose();
  //   onGetChartListStart(selectedStartDate, selectedEndDate);
  //   clearFilter();
  // };
  //  Perfectly Working Filter Handle Filter Logic
  // const handleFilter = () => {
  //   handleClose();
  
  //   const formattedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
  //   const formattedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");
  
  //   onGetChartListStart(formattedStartDate, formattedEndDate);
  //   clearFilter();
  // };
  // If User enters months in reverse order then the logic
  const handleFilter = () => {
    handleClose();
  
    let formattedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formattedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");
  
    if (moment(formattedStartDate).isAfter(formattedEndDate)) {
      [formattedStartDate, formattedEndDate] = [formattedEndDate, formattedStartDate];
    }
  
    onGetChartListStart(formattedStartDate, formattedEndDate);
    // clearFilter();
  };

  const handleClearFilter = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };
  
  

  const clearFilter = () => {
    handleClose(); 
  };
  React.useEffect(() => {
    onGetChartListStart();
  }, []);
  
  const jsonData = chartScreen?.visitsList?.monthly_call_details || [];

  // Extract unique months from the data
  const months: (keyof MonthlyCallDetail)[] = Array.from(
    new Set(jsonData.flatMap(item => Object.keys(item).filter(key => key !== 'username')))
  );

  // Prepare the chart data
  const chartData = [
    ['User', ...months],
    ...jsonData.map((item: MonthlyCallDetail) => {
      const userValues = months.map(month => Number(item[month] || 0));
      return [item.username || 'Unknown User', ...userValues];
    }),
  ];
  const options = {
    chart: {
      title: "Average Total Productive Calls per Day",
    },
  };
  

  return(
    <Fragment>
      <Box position={"relative"}>
      {/* <Box sx={{marginLeft: "980px"}}>
        <ChartFilter 
        selectedMonth = {selectedMonth}
        handleMonthChange = {handleMonthChange}
        onGetChartListStart = {onGetChartListStart}/>
      </Box> */}
      <Box 
        sx={{
            width: "89px",
            height: "29px",
            padding: "18px 10px",
            borderRadius: "4px",
            border: "1px solid #45464E",
            display: "flex",
            alignItems: "center",
            gap: "9px",
            cursor: "pointer",
            marginLeft: "auto",
            }}
            onClick={handleClick}
        >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        >
          <Box sx={{ padding: "24px 21px", maxWidth: 390, width: "100%" }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h3">Filter</Typography>
                <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                <Typography sx={{fontSize: "14px"}}>Start Month</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                            views={["month"]}
                            sx={{ width: 140 }}
                            value={selectedStartDate}
                            slotProps={{ textField: { size: "small" } }}
                            // onChange={(date) => handleFilter(date.month())}
                            onChange={(date) => setSelectedStartDate(date)}
                        /> 
                    </LocalizationProvider>
                </Grid>
                <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                <Typography sx={{fontSize: "14px"}}>End Month</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                            views={["month"]}
                            sx={{ width: 140 }}
                            value={selectedEndDate}
                            slotProps={{ textField: { size: "small" } }}
                            // onChange={(date) => handleFilter(date.month())}
                            onChange={(date) => setSelectedEndDate(date)}
                        />
                        
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} mb={2} mt={2}>
                <CustomButton
                  variant = "outlined"
                  name="Clear Filter"
                  handleClick={handleClearFilter}
                  sx={{ width: '100%' }}
                />
              </Grid>
                <Grid item xs={12}>
                <CustomButton
                  name="Filter"
                  handleClick={handleFilter}
                  sx={{ width: "100%" }}
                />
              </Grid>
              </Grid>
            </Grid>
          </Box>
      </Popover>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "25px",
          }}
        >
          <Charts
            width={'100%'}
            height={'500px'}
            chartType="Bar"
            // loader={<div>Loading Chart</div>}
            loader = {<img src={ Loading } alt="img" />}
            data={chartData}
            options={options}
          />
      </Box>
      </Box>
    </Fragment>
  )
}
export default container(Chart)
