import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import SalesReportComponent from "../../../views/Admin/SalesReport/SalesReport.component";


// =================== Order Page ================= //
const SalesReport = () => {
  return <WithLayout layout={Admin} component={SalesReportComponent} />;
};


export default SalesReport;