import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Tenants from "../../views/Admin/Tenants";
import Main from "../../layouts/Main";

// ================= Select Tenants ================= //
const SelectTenants = () => {
  return <WithLayout layout={Main} component={Tenants} />;
};

export default SelectTenants;
