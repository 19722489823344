import * as ReactDOM from "react-dom/client";

import "./firebase";

// Porject imports
import store from "./store";
import App from "./App";

// third party libraries
import { Provider } from "react-redux";

// styles + assets
import "./assets/scss/styles.scss";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
