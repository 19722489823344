export const REQUEST_ENABLE_API_DATA='REQUEST_ENABLE_API_DATA'
export const RECIEVE_ENABLE_API_DATA='RECIEVE_ENABLE_API_DATA'
export const REQUEST_DISABLE_API_DATA='REQUEST_DISABLE_API_DATA'
export const RECIEVE_DISABLE_API_DATA='RECIEVE_DISABLE_API_DATA'
export const SEND_DISABLE_USER = 'SEND_DISABLE_USER';
export const RESPONSE_DISABLE_USER = 'RESPONSE_DISABLE_USER';
export const SEND_ENABLE_USER = 'SEND_ENABLE_USER';
export const RESPONSE_ENABLE_USER = 'RESPONSE_ENABLE_USER';
export const FETCH_FILTERED_USERS_START= "FETCH_FILTERED_USERS_START";
export const FETCH_FILTERED_USERS_SUCCESS= "FETCH_FILTERED_USERS_SUCCESS";
export const FETCH_FILTERED_USERS_FAILURE= " FETCH_FILTERED_USERS_FAILURE";
export const ON_CHANGE_APPLY_USERS_FILTER= "ON_CHANGE_APPLY_USERS_FILTER";
export const FETCH_FILTERED_DISABLE_USERS_START= "FETCH_FILTERED_DISABLE_USERS_START";
export const FETCH_FILTERED_DISABLE_USERS_SUCCESS= "FETCH_FILTERED_DISABLE_USERS_SUCCESS";
export const FETCH_FILTERED_DISABLE_USERS_FAILURE= " FETCH_FILTERED_DISABLE_USERS_FAILURE";
export const ON_CHANGE_APPLY_DISABLE_USERS_FILTER= "ON_CHANGE_APPLY_DISABLE_USERS_FILTER";
export const RECEIVE_ENABLE_DATA_FAILURE = "RECEIVE_ENABLE_DATA_FAILURE";
export const RECEIVE_DISABLE_DATA_FAILURE = "RECEIVE_DISABLE_DATA_FAILURE";
export const SEND_DISABLE_USER_FAILURE = " SEND_DISABLE_USER_FAILURE";
export const SEND_ENABLE_USER_FAILURE = "SEND_ENABLE_USER_FAILURE"
export const  ON_CHANGE_APPLY_USER_CLEAR_FILTER = " ON_CHANGE_APPLY_USER_CLEAR_FILTER"
export const  ON_CHANGE_APPLY_DISABLED_USER_CLEAR_FILTER = " ON_CHANGE_APPLY_DISABLED_USER_CLEAR_FILTER"
export const UPDATE_SOLO_USER_START = "UPDATE_SOLO_USER_START";
export const UPDATE_SOLO_USER_SUCCESS = "UPDATE_SOLO_USER_SUCCESS";
export const UPDATE_SOLO_USER_FAILURE = "UPDATE_SOLO_USER_FAILURE";
//user setting
export const FETCH_SETTING_DATA_START = "FETCH_SETTING_DATA_START";
export const FETCH_SETTING_DATA_SUCCESS = "FETCH_SETTING_DATA_SUCCESS";
export const FETCH_SETTING_DATA_FAILURE = "FETCH_SETTING_DATA_FAILURE";
export const SET_SELECTED_USER_CLICK_DATA = "SET_SELECTED_USER_CLICK_DATA";
export const UPDATE_SETTING_START = "UPDATE_SETTING_START";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_FAILURE = "UPDATE_SETTING_FAILURE";
//Report To List
export const GET_REPORT_TO_LIST_START = "GET_REPORT_TO_LIST_START";
export const GET_REPORT_TO_LIST_SUCCESS = "GET_REPORT_TO_LIST_SUCCESS";
export const GET_REPORT_TO_LIST_FAILURE = "GET_REPORT_TO_LIST_FAILURE";
