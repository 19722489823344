import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useState } from "react";

const BeatPopper = ({
  open,
  anchorEl,
  handleClose,
  inputValue,
  id, onPushRetailerToBeatStart,
  remainingTraders,
  activeBeat, onFetchAllBeatsListStart,
}) => {

  const retailersInfoId = activeBeat ? activeBeat?.id : "";
  // console.log("retailersInfoId---->", retailersInfoId);
  const [selectedTraderId, setSelectedTraderId] = useState(null);

  const filteredTraders = remainingTraders?.filter((user) => {
    const fullName = `${user?.f_name ?? ""} ${user?.m_name ?? ""} ${
      user?.l_name ?? ""
    }`;
    return fullName.toLowerCase().includes(inputValue.toLowerCase());
  });

  const pushRetailers = (retailersInfoId, remainingTraders) => {
    onPushRetailerToBeatStart(retailersInfoId, remainingTraders);
    onFetchAllBeatsListStart(1, 10, 'desc', 'id');
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableAutoFocus
    >
      <Box
        sx={{
          width: "100%",
          minWidth: "700px",
          borderRadius: "6px",
          border: "1px solid #E1E2E9",
          background: "#FFF",
          boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.20)",
        }}
      >
        {filteredTraders?.length > 0 ? (
          filteredTraders.map((user) => (
            <Box
              key={user?.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
              }}
            >
              <Typography>
                {user?.f_name ?? ""} {user?.m_name ?? ""} {user?.l_name ?? ""}
              </Typography>
              <Typography>{user?.mobile ?? ""}</Typography>
              <Button size="small" variant="outlined"
              // onClick={pushRetailers}
              onClick ={()=> {
                // pushRetailers(selectedBeatId, user?.id);
                setSelectedTraderId(user?.id);
                pushRetailers(retailersInfoId, user?.id);
              }}>
                Add
              </Button>
            </Box>
            //Capital ADD Button is here
          ))
        ) : (
          <Typography sx={{ p: 2 }}>No matching traders found.</Typography>
        )}
      </Box>
    </Popover>
  );
};

export default BeatPopper;