export const GET_SALES_REPORT_LIST_START = "GET_SALES_REPORT_LIST_START";
export const GET_SALES_REPORT_LIST_SUCCESS = "GET_SALES_REPORT_LIST_SUCCESS";
export const GET_SALES_REPORT_LIST_FAILURE = "GET_SALES_REPORT_LIST_FAILURE";

// For Search
export const SEARCH_SALES_REPORT_NAME = "SEARCH_SALES_REPORT_NAME";

// Today
export const GET_SALES_REPORT_TODAY_START = "GET_SALES_REPORT_TODAY_START";
export const GET_SALES_REPORT_TODAY_SUCCESS = "GET_SALES_REPORT_TODAY_SUCCESS";
export const GET_SALES_REPORT_TODAY_FAILURE = "GET_SALES_REPORT_TODAY_FAILURE";

// Yesterday
export const GET_SALES_REPORT_YESTERDAY_START = "GET_SALES_REPORT_YESTERDAY_START";
export const GET_SALES_REPORT_YESTERDAY_SUCCESS = "GET_SALES_REPORT_YESTERDAY_SUCCESS";
export const GET_SALES_REPORT_YESTERDAY_FAILURE = "GET_SALES_REPORT_YESTERDAY_FAILURE";

// Monthly
export const GET_SALES_REPORT_MONTHLY_START = "GET_SALES_REPORT_MONTHLY_START";
export const GET_SALES_REPORT_MONTHLY_SUCCESS = "GET_SALES_REPORT_MONTHLY_SUCCESS";
export const GET_SALES_REPORT_MONTHLY_FAILURE = "GET_SALES_REPORT_MONTHLY_FAILURE";

// Bar View
export const GET_ORDER_LIST_FOR_BAR_START = "GET_ORDER_LIST_FOR_BAR_START";
export const GET_ORDER_LIST_FOR_BAR_SUCCESS = "GET_ORDER_LIST_FOR_BAR_SUCCESS";
export const GET_ORDER_LIST_FOR_BAR_FAILURE = "GET_ORDER_LIST_FOR_BAR_FAILURE";

// Top Orders
export const GET_HIGHEST_PLACED_ORDER_START = "GET_HIGHEST_PLACED_ORDER_START";
export const GET_HIGHEST_PLACED_ORDER_SUCCESS = "GET_HIGHEST_PLACED_ORDER_SUCCESS";
export const GET_HIGHEST_PLACED_ORDER_FAILURE = "GET_HIGHEST_PLACED_ORDER_FAILURE";