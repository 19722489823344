import React from "react";

// third party libraries
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { useDispatch } from "react-redux";

// defaultTheme
import themes from "./themes";

// routing import
import Router from "./routes";

// alert import
import CustomAlert from "./components/CustomAlert";
import { loadUserStart } from "./store/auth/auth.actions";

function App() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadUserStart());
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes({})}>
        <CssBaseline />
        <CustomAlert />
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
