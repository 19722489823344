import * as TenantActions from "./tenant.actions";
import * as TenantTypes from "./tenant.types";

import { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";

export function* viewTenantUsersAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.get(`/tenant/users/list`);

    yield put(TenantActions.viewTenantUserSuccess(data));
  } catch (error) {
    yield put(TenantActions.viewTenantUserFail(error));
  }
}

export function* fetchTenantRolesListAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.get(`/tenants/masters/roles/list`);

    yield put(TenantActions.fetchTenantRolesSuccess(data));
  } catch (error) {
    yield put(TenantActions.fetchTenantRolesFail(error));
  }
}

export function* fetchSoloTenantTourProgramDetailAsync({
  payload: { id },
}: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/tour_program/get`, {
      id,
    });

    yield put(TenantActions.fetchSoloTenantTourProgramSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(TenantActions.fetchSoloTenantTourProgramFail(error));
  }
}

export function* listTenantTourProgramsAsync({ payload: { date } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/tour_program/list`, {
      date,
    });

    yield put(TenantActions.listTenantTourProgramsListSuccess(data));
  } catch (error) {
    yield put(TenantActions.listTenantTourProgramsListFail(error));
  }
}

export function* createOrUpdateTourProgramAsync({
  payload: { formData, cb },
}: any) {
  try {
    const { data } = yield tenantInstance.post(
      `/tenants/tour_program/create-update`,
      [formData]
    );

    yield put(TenantActions.createOrUpdateTenantTourProgramSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(TenantActions.createOrUpdateTenantTourProgramFail(error));
    console.log("erroring", error);
    yield put(
      openAlert(
        `${
          error?.response?.data?.detail
            ? error?.response?.data?.detail
            : "Failed to create tour program"
        }`,
        "error"
      )
    );
  }
}

export function* watchViewTenantUsersAsync() {
  yield takeLatest(TenantTypes.VIEW_TENANT_USERS_START, viewTenantUsersAsync);
}

export function* watchFetchTenantRolesListAsync() {
  yield takeLatest(
    TenantTypes.FETCH_TENANT_ROLES_START,
    fetchTenantRolesListAsync
  );
}

export function* watchFetchSoloTenantTourProgramDetailAsync() {
  yield takeLatest(
    TenantTypes.FETCH_SOLO_TENANT_TOUR_PROGRAM_START,
    fetchSoloTenantTourProgramDetailAsync
  );
}

export function* watchListTenantTourProgramsAsync() {
  yield takeLatest(
    TenantTypes.LIST_TENANT_TOUR_PROGRAMS_START,
    listTenantTourProgramsAsync
  );
}

export function* watchCreateOrUpdateTourProgramAsync() {
  yield takeLatest(
    TenantTypes.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_START,
    createOrUpdateTourProgramAsync
  );
}

export function* tenantSagas() {
  yield all([
    call(watchViewTenantUsersAsync),
    call(watchFetchTenantRolesListAsync),
    call(watchFetchSoloTenantTourProgramDetailAsync),
    call(watchListTenantTourProgramsAsync),
    call(watchCreateOrUpdateTourProgramAsync),
  ]);
}
