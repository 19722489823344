import Visit from "../../views/Admin/Visit";
import * as VisitType from "./visit.types";

const INITIAL_STATE = {
  name: "",
  id: null,
  salesmanName: "",
  start_date: null,
  end_date: null,
  selectedDateRange: "",
  visitsListFilter: [
    // f_name: "",
  ],
  pages: {},
  page: 1,
  limit: 10,
  clickedDate: null,
  filterName: "", 
  // filteredNames: [],
  // salesPersonName: '',
  visitLists: {
    visits: [],
    // full_name: "",
    totalVisits: null,
  },
  sales_person_id: null,
  visitData: [],
  page_details: null,
  visitReportData: [],
};
function visitScreenReducer(state = INITIAL_STATE, action){
  const { key, value, type, payload, } = action;
  switch(type){
    case VisitType.GET_VISIT_LIST_START:
    return {
      ...state,
      loading: true,
    };
    case VisitType.GET_VISIT_LIST_SUCCESS:
    return {
      ...state,
      visitData: payload?.data?.just_visit_details_list,
      pageDetails: payload?.data?.page_details,
      loading: false,
    };
    case VisitType.GET_VISIT_LIST_FAILURE:
    return {
      ...state,
      loading: true,
    };
    case VisitType.FETCH_VISIT_REPORT_START:
      return{
        ...state,
        loading: true,
    }
    case VisitType.FETCH_VISIT_REPORT_SUCCESS:
      return {
        ...state,
        visitReportData: payload?.data?.retailers_details,
        // selectedDate: action.payload.date,
        // selectedSalesPersonId: action.payload.salesPersonId,
        // data:payload?.data,
        // filteredNames: action.payload,
    };
    case VisitType.FETCH_VISIT_REPORT_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case VisitType.SET_SELECTED_CLICK_DATA:
      // console.log("action.payload.date---->", action.payload.date);
      // console.log("action.payload.salesPersonId---->", action.payload.salesPersonId);
      return {
        ...state,
        selectedDate: action.payload.date,
        selectedSalesPersonId: action.payload.salesPersonId,
    };
    case VisitType.ON_CHANGE_APPLY_VISIT_FILTER:
        return{
          ...state,
          [payload.key]: payload.value,
    }
    case VisitType.FILTER_VISIT_DATA_START:
    case VisitType.FILTER_VISIT_DATA_FAILURE:
    case VisitType.FILTER_VISIT_DATA_SUCCESS:
      return{
        ...state,
        loading: false,
        filteredVisits: payload?.data?.just_visit_details_list[0],
      }

    case VisitType.SEARCH_VISIT_DATA_NAME:
      const {key, value} = action.payload;
      return {
        ...state,
        visitData: payload?.data?.just_visit_details_list,
        pageDetails: payload?.data?.page_details,
        [key]: value,
      }
    default:
      return state;
  }
}
export default visitScreenReducer;