import Attendance from "../../views/Admin/Attendance";
import * as AttendanceScreenType from "./attendance.types";
// Get all AttendanceScreenUsers List

export const fetchAttendanceUserStart = (fromDate, toDate) => ({
    type: AttendanceScreenType.GET_ATTENDACE_USERS_LIST_START,
    payload: { fromDate, toDate }
  });
  
export const fetchAttendanceUserSuccess = (result) => ({
  type: AttendanceScreenType.GET_ATTENDACE_USERS_LIST_SUCCESS,
  payload: result,
});
  
export const fetchAttendanceUserFail = (error) => ({
  type: AttendanceScreenType.GET_ATTENDACE_USERS_LIST_FAILURE,
  payload: error,
});

export const searchAttendanceUsersStart = (data1) => ({
  type: AttendanceScreenType.SEARCH_USER_START,
  payload: data1,
});

export const searchAttendanceUsersSuccess = (result) => ({
  type: AttendanceScreenType.SEARCH_USER_SUCCESS,
  payload: result,
});

export const searchAttendanceUsersFail = (error) => ({
  type: AttendanceScreenType.SEARCH_USER_FAILURE,
  payload: error,
});

export const changeApplyAttendaceFiler = (key, value) => ({
  type: AttendanceScreenType.ON_CHANGE_APPLY_ATTENDANCE_FILTER,
  payload: { key, value },
});
  
