import { Box, FormControl, MenuItem, Paper, Typography } from "@mui/material";
import React, { Fragment } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Field, FieldArray } from "formik";
import { Select } from "formik-mui";
import CustomButton from "../../../../components/CustomButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: "#4A5569",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#4A5569",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#E8EBF5",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MappingView = ({
  excelFields,
  excelHeader,
  handleChange,
  headers,
  formValues,
  submitForm,
  tableTitleOne,
  tableTitleTwo,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            marginTop="10px"
            fontSize="24px"
            lineHeight="28.8px"
          >
            Mandatory
          </Typography>
          <Typography
            variant="h5"
            marginTop="7px"
            fontSize="14px"
            lineHeight="16.94px"
          >
            The left column contain the old Database fields
          </Typography>
        </Box>
        <CustomButton
          name="Save"
          sx={{ maxWidth: "130px", width: "100%" }}
          handleClick={submitForm}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          border: "1px solid #C6D1DD",
          boxShadow: "0px 4px 10px rgba(180, 180, 180, 0.25)",
          borderRadius: "1px",
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  width: "50%",
                  backgroundColor: "#E8EBF5 !important",
                  textTransform: "capitalize",
                }}
              >
                {tableTitleOne}
              </StyledTableCell>
              <StyledTableCell
                sx={{ width: "50%", textTransform: "capitalize" }}
              >
                {tableTitleTwo}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name="fieldsArr">
              {(arrayHelpers) => (
                <Fragment>
                  {arrayHelpers.form.values?.fieldsArr &&
                    arrayHelpers.form.values.fieldsArr.length > 0 &&
                    arrayHelpers.form.values?.fieldsArr?.map(
                      (fields, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            <span style={{ paddingRight: 40 }}>
                              {index + 1}
                            </span>
                            {fields?.mandatory && "*"}
                            {fields.fields}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{ padding: 0 }}
                          >
                            <FormControl
                              fullWidth
                              sx={{
                                "& > div > svg": {
                                  transform: "scale(1.8)",
                                },
                              }}
                            >
                              <Field
                                component={Select}
                                name={`fieldsArr[${index}].excel_column`}
                                variant="outlined"
                                disableUnderline
                                sx={{
                                  boxShadow: "none",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                  },
                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: 0,
                                    },
                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: 0,
                                    },
                                }}
                                onChange={handleChange}
                              >
                                {headers &&
                                  headers?.length > 0 &&
                                  headers?.map((header) => (
                                    <MenuItem value={header}>{header}</MenuItem>
                                  ))}
                              </Field>
                            </FormControl>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                </Fragment>
              )}
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MappingView;
