import React from "react";

// Material UI
import {
  Box,
  FormControl,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { useLocation } from "react-router-dom";
// Formik imports
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";

// Third party library
import { useNavigate } from "react-router-dom";

// Project imports
import CustomButton from "../../../components/CustomButton";
import container from "./AddOrganisation.container";

// Config imports
import { tenantInstance } from "../../../configs/axiosConfig";

// Assets imports
import Thumbnail from "../../../assets/images/thumbnail.svg";
import UploadIcon from "../../../assets/images/uploadCloud.svg";
import Loading from "../../../assets/images/loading.svg";

const CustomBox = styled("div")({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "0.5px solid #B1AFAF",
  borderRadius: "10px",
  height: "100%",
  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const CustomLabel = styled("label")({
  height: "100%",
  width: "100%",
  textTransform: "none",
  background: "#F4F5FA",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  padding: "1.2rem 1.475rem",
  textAlign: "center",
});

const FileInput = styled("input")({
  display: "none",
});

// ======================= Main Add Organisation View ================= //
const AddOrganisation = ({
  onCreateOrganisationStart
}) => {
  const location = useLocation();
  const [file, setFile] = React.useState(null);
  const [addValue, setAddValue] = React.useState(location.state?.value);
  const [imgUploading, setImgUploading] = React.useState(false);
  const [imageId, setImageId] = React.useState(null);
  console.log(addValue)


  function validation(value) {
    let error;
    if (value === '' || value === false) {
      error = 'Required!';
    }
    return error;
  }

  // Image Add Functionality
  const handleFileUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setImgUploading(true);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const { data } = await tenantInstance.post(`/tenants/files/`, formData, {
        params: {
          key: "tenants_image",
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setImageId(data.data.id);
    } catch (error) {
      console.error(error);
    } finally {
      setImgUploading(false);
    }
  };

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        name: "",
        db_name: "",
        db_user: "",
        db_host: "",
        db_password: "",
        d_name: "",
        user_details: {
          f_name: "",
          l_name: "",
          email: "",
          mobile: "",
          password: ""
        }
      }}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onCreateOrganisationStart(
          {
            ...values,
            image_id: imageId ? Number(imageId) : null
          },
          () => {
            setSubmitting(true);

            navigate("/manage/organisation");
          }
        );
      }}
    >
      {({  handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "space-between" },
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                margin: "20px 0",
                gap: { xs: 1 },
              }}
            >
              <Typography variant="h3"></Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: 1, sm: 2 },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <CustomButton
                  name="Cancel"
                  variant="outlined"
                  handleClick={() => {
                    setAddValue(false)
                    navigate("/manage/organisation", { state: { tabValue: 1 } })
                  }}
                />
                <CustomButton name="Save & Publish" type="submit" />
              </Box>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={9}>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: "auto",
                    width: "100%",
                    padding: (theme) => theme.spacing(2),
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="name"
                          id="name"
                          label="Organisation Name*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 100);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Field
                            variant="filled"
                            component={TextField}
                            type="text"
                            autoFocus
                            fullWidth
                            name="db_host"
                            id="db_host"
                            label="Database Host*"
                            validate={validation}
                            InputProps={{
                              disableUnderline: true,
                              onInput: (e) => {
                                e.preventDefault();
                                const inputValue = e.target.value;
                                const acceptedValue = inputValue.slice(0, 100);
                                e.target.value = acceptedValue;
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          validate={validation}
                          name="db_name"
                          id="db_name"
                          label="Database Name*"
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 100);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="password"
                          autoFocus
                          fullWidth
                          validate={validation}
                          name="db_password"
                          id="db_password"
                          label="Database Password*"
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="db_user"
                          id="db_user"
                          label="Database User*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 100);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="d_name"
                          id="d_name"
                          label="Display Name*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 100);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: "auto",
                    width: "100%",
                    padding: (theme) => theme.spacing(2),
                  }}
                >
                  <Typography variant="h3">Admin User Details</Typography>
                </Box>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: "auto",
                    width: "100%",
                    padding: (theme) => theme.spacing(2),
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="user_details.f_name"
                          id="f_name"
                          label="First Name*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 50);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="user_details.mobile"
                          id="mobile"
                          label="Mobile*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={4} spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          validate={validation}
                          name="user_details.l_name"
                          id="l_name"
                          label="Last Name*"
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 50);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="password"
                          autoFocus
                          fullWidth
                          name="user_details.password"
                          id="password"
                          label="Password*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="email"
                          autoFocus
                          fullWidth
                          name="user_details.email"
                          id="email"
                          label="Email*"
                          validate={validation}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: "auto",
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Grid item xs={12}>
                    <CustomBox
                      sx={{
                        height: "302px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {file ? (
                        <img src={imgUploading ? Loading : file} alt="img" />
                      ) : (
                        <CustomLabel htmlFor="fileInput">
                          <FileInput
                            type="file"
                            id="fileInput"
                            onChange={handleFileUpload}
                            accept=".jpg, .jpeg, .png,"
                          />
                          <img src={Thumbnail} alt="Upload" />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                              margin: "35px 0 16px 0",
                            }}
                          >
                            <img src={UploadIcon} alt="Upload" />
                            <Typography variant="h5">Upload Image</Typography>
                          </Box>
                          <Typography variant="body1">
                            Upload a cover image for your Organisation.
                          </Typography>
                          <Typography variant="body1">
                            File Format <strong>jpeg, png</strong> Recommened
                            Size <strong>600x600 (1:1)</strong>
                          </Typography>
                        </CustomLabel>
                      )}
                    </CustomBox>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default container(AddOrganisation);
