// Redux Imports
import {connect} from "react-redux";
import {
    categoryDeleteStart,
    changeApplyProductFilter,
    createOrUpdateCategoryStart,
    fetchAllFilteredProductsStart,
    fetchSoloCategoryStart,
    productDeleteStart,
    fetchAllTenantProductsStart,
    fetchAllFilteredProductsCategoryStart,
    changeApplyCategoryFilter,
    fetchAllTenantCategoriesStart
} from "../../../store/inventory/inventory.actions";

const mapStateToProps = (state) => ({auth: state.auth, inventory: state.inventory});

const mapDispatchToProps = (dispatch) => ({
    onFetchAllFilteredProductsStart: (field, type) => dispatch(fetchAllFilteredProductsStart(field, type)),
    onFetchAllTenantProductsStart: () => dispatch(fetchAllTenantProductsStart()),
    onProductDeleteStart: (formData, cb) => dispatch(productDeleteStart(formData, cb)),
    onFetchAllFilteredProductsCategoryStart: (field, type) => dispatch(fetchAllFilteredProductsCategoryStart(field, type)),
    onFetchAllTenantCategoriesStart: () => dispatch(fetchAllTenantCategoriesStart()),
    onCreateOrUpdateCategoryStart: (formData, cb) => dispatch(createOrUpdateCategoryStart(formData, cb)),
    onCategoryDeleteStart: (formData, cb) => dispatch(categoryDeleteStart(formData, cb)),
    onFetchSoloCategoryStart: (id) => dispatch(fetchSoloCategoryStart(id)),
    onChangeApplyProductFilter: (name, value) => dispatch(changeApplyProductFilter(name, value)),
    onChangeApplyCategoryFilter: (name, value) => dispatch(changeApplyCategoryFilter(name, value))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
