//CHART List Import
import * as ChartType from "./chart.types";

export const getChartListStart = (startDate, endDate) => {
  return{
    type: ChartType.GET_CHART_LIST_START,
    payload: { startDate, endDate}
  }
};
export const getChartListSuccess = (result) => {
  return{type: ChartType.GET_CHART_LIST_SUCCESS,
  payload: result,}
};
export const getChartListFail = (error) => ({
  type: ChartType.GET_CHART_LIST_FAILURE,
  payload: error,
});