import * as React from 'react';
import { Box, Paper, Grid, Typography} from "@mui/material";
import moment from 'moment';
import Chart from 'react-google-charts';

const YesterdayAnalyticalView = ({onGetSalesReportDetailsYesterdayStart, 
  salesReportDetailsYesterday: {salesReportDetailsDataYesterday, salesDetailsYesterday, totalSalesYesterday}}) => {

  const [dataLoaded, setDataLoaded] = React.useState(false);
  const today = moment().format("YYYY-MM-DD");

  const yesterday = moment(today).subtract(1, 'days').format("YYYY-MM-DD");

  const monthlyDataArray = salesReportDetailsDataYesterday?.map((row) => {
    return row?.total_sale;
  });

  const userList = salesReportDetailsDataYesterday?.map((row) => {
    return row?.user_name;
  });

  const xAxisData = Array.from({ length: salesDetailsYesterday?.total }, (_, index) => index + 1);

  const chartData = [
    ['x', 'Sales'],
    ['0', 0],
    ...xAxisData.map((item, index) => [`${userList[index]}`, monthlyDataArray[index] || 0,  ])
  ];

  const chartData1 = [
    ['x', 'Sales'],
    ["1", 0],
    ["2", 0],
    ["3", 0],
    ["4", 0],
    ["5", 0]
  ]

  const marginValue = dataLoaded && monthlyDataArray.length > 0 ? "-53px" : "1px";

  const options = {
    title: "",
    curveType: "none",
    legend: { position: "none", },
    hAxis: { textPosition: "none", gridlines: { color: 'transparent' }  },
    vAxis: { textPosition: "none", gridlines: { color: 'transparent' }  },
    backgroundColor: 'transparent', 
    baselineColor: "transparent",
    series: {
      0: { color: "#65C466", lineWidth: 3, type: 'area', areaOpacity: 0.3  }
    },
    chartArea: {
      width: "100%"
    }
  };

  function formatedNumber(number) {
    return number.toLocaleString('en-IN');
  }

  React.useEffect(() => {
    onGetSalesReportDetailsYesterdayStart(yesterday, yesterday, 'asc', 'visit_time',);
  }, []);

  React.useEffect(() => {
    if (salesReportDetailsDataYesterday && salesDetailsYesterday) {
      setDataLoaded(true);
    }
  }, [salesReportDetailsDataYesterday, salesDetailsYesterday]);

  return(
    <Grid container>
      <Paper sx={{width: '100%', border: '1px solid #EAEFF5', borderRadius: "14px"}}>
        <Grid item md={12}>
          <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>Yesterday's Sales</Typography>
        </Grid>

        <Grid item md={12}>
          <Typography sx={{mt: "12px", ml: "10px", fontWeight: "bold", fontSize: "22px"}}>₹{totalSalesYesterday != null ? formatedNumber(totalSalesYesterday.toFixed(2)) : "0.00"}</Typography>
        </Grid>

        <Grid container sx={{height: "110px"}}>
          <Grid item md={2}>
              <Typography sx={{mt: "28px", ml: "10px", color: "#009E73", fontSize: "14px"}}>+17.7%</Typography>
          </Grid>

          <Grid item md={10} sx={{mt: marginValue}}>
            {dataLoaded && monthlyDataArray.length > 0 ? (
              <>
                <Chart
                  chartType="LineChart"
                  data={chartData}
                  options={options}
                />
              </>
            ) : (
              <Chart
                chartType="LineChart"
                data={chartData1}
                options={options}
              />
            )}
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

export default YesterdayAnalyticalView;