import axios from "axios";
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: "https://staging.api.pulseplay.in/api",
});

const clearLocalStorageAtMidnight = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();

  // Check if the current time is 00:00:00
  if (hours === 23 && minutes === 59 && seconds === 59) { 
    // Clear localStorage
    localStorage.clear();
    console.log("LocalStorage cleared at 05:37:00");
  }
};

const intervalId = setInterval(clearLocalStorageAtMidnight, 1000);
const activeTenant = localStorage.getItem("tenant_details");

// Tenant Instance
// It fetches selected tenant from localStorage which is saved after user is loaded
// successfully. If there is active tenant then it calls api parsing activeTenant
// if not it calls same as instance
// Must be called inorder to call the tenant based APIs
const tenantInstance = axios.create({
  baseURL: `https://${
    activeTenant ? JSON.parse(activeTenant)?.name + "." : "angur."
  }staging.api.pulseplay.in/api`,
});
tenantInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("tenant_details");
  // const timeStamp = localStorage.getItem("timestamp");
  // console.log("token", token, token);
  if (token) {
    config.baseURL = `https://${
      token ? JSON.parse(token)?.name + "." : "angur."
    }staging.api.pulseplay.in/api`;
  } 
  
  else {
    localStorage.removeItem("tenant_details");
    
  }
  return config;
});


// Token Set Part
export const setAuthToken = (token?: string) => {
  if (token) {
    instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    tenantInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers["Authorization"];
    delete tenantInstance.defaults.headers["Authorization"];
  }
};

// Default Exports
export default instance;
export { tenantInstance };
