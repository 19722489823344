import * as ReportByDistributorType from "./reportByDistributor.types";

export const getReportByDistributorStart = (fromDate, toDate) => {
    return{
        type: ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_START,
        payload: { fromDate, toDate }
    }
};

export const getReportByDistributorSuccess = (result) => ({
    type: ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_SUCCESS,
    payload: result,
});

export const getReportByDistributorFail = (error) => ({
    type: ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_FAILURE,
    payload: error,
})