import * as attendanceScreenActions from "./attendance.actions";
import * as attendanceScreenType from "./attendance.types";
import axiosConfig, { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import moment from "moment";
import { useState } from "react";


export function* fetchAttendanceUsersAsync({ payload }: any) {
  let{ user_id, userNameFilter } = yield select((state) => state.attendanceScreen);
  let { fromDate, toDate, userName } = payload;
    try {
      // Fetch user data from the API
      const { data } = yield tenantInstance.post(`/tenants/attendance`, {
          filters: {
          name: "",
          start_date: fromDate,
          // currentDate,
          end_date: toDate,
          // currentDate,
        }
      });
      yield put(attendanceScreenActions.fetchAttendanceUserSuccess(data));
    } catch (error) {
      yield put(attendanceScreenActions.fetchAttendanceUserFail(error));
    }
}

export function* searchAttendanceUsersAsync({ payload }: any) {
  let{
    start_date,
    end_date,
    user_id,
    data1,  
  } = yield select((state) => state.attendanceScreen);
  const currentDate = moment().format("YYYY-MM-DD");
    try {
      // Fetch user data from the API
      const { data } = yield tenantInstance.post(`/tenants/attendance`, {
          start_date: currentDate,
          end_date: currentDate,
          data1,
      });
      yield put(attendanceScreenActions.searchAttendanceUsersSuccess(data));
    } catch (error) {
      yield put(attendanceScreenActions.searchAttendanceUsersFail(error));
    }
}

export function* watchFetchAttendaceUserAsync() {
    yield takeLatest(
      attendanceScreenType.GET_ATTENDACE_USERS_LIST_START,
      fetchAttendanceUsersAsync
    );
}

export function* watchSearchAttendanceUsersAsync(){
  yield takeLatest(
    attendanceScreenType.SEARCH_USER_START,
    searchAttendanceUsersAsync,
  );
}
export function* attendanceSagas() {
    yield all([
      call(watchFetchAttendaceUserAsync),
      call(watchSearchAttendanceUsersAsync),
    ])
}
//watchFetchAllAttendanceScreenUsersAsync