import React, { useEffect, useState } from "react";

// Material UI
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { useLocation } from "react-router-dom";
// Formik imports
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Select, TextField } from "formik-mui";

// Third party library
import { useNavigate, useParams } from "react-router-dom";

// Project imports
import CustomButton from "../../../components/CustomButton";
import container from "./AddProducts.container";

// Config imports
import { tenantInstance } from "../../../configs/axiosConfig";

// Assets imports
import Thumbnail from "../../../assets/images/thumbnail.svg";
import UploadIcon from "../../../assets/images/uploadCloud.svg";
import Loading from "../../../assets/images/loading.svg";
import LoaderIcon from "../../../assets/images/loader.svg";

const CustomBox = styled("div")({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "0.5px solid #B1AFAF",
  borderRadius: "10px",
  height: "100%",
  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const CustomLabel = styled("label")({
  height: "100%",
  width: "100%",
  textTransform: "none",
  background: "#F4F5FA",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  padding: "1.2rem 1.475rem",
  textAlign: "center",
});

const FileInput = styled("input")({
  display: "none",
});

// ======================= Main Add and Edit Product View ================= //
const AddProducts = ({
  onCreateOrUpdateProductStart,
  onFetchAllTenantCategoriesStart,
  inventory: { categories, soloProduct },
  onFetchProductDetailsStart, loading,
}) => {
  const location = useLocation();
  const sentValue = location.state?.value;
  const [file, setFile] = React.useState(null);
  const [addValue, setAddValue] = React.useState(location.state?.value);
  const [imgUploading, setImgUploading] = React.useState(false);
  const [imageId, setImageId] = React.useState(null);
  // const [loading, setLoading] = React.useState(true);
  // const [loading, setLoading] = useState(true);
  const [focusedField, setFocusedField] = useState(null);
  const [loadingProductDetails, setLoadingProductDetails] = useState(false);

  function validation(value, id) {
    let error;
    if (value === '' || value === false) {
      // error = 'Required!';
      if (id === 'sku') {
        error = 'Please Provide SKU Code';
      } else if (id === 'name') {
        error = 'Please Provide Product Name';
      } else if (id === 'name') {
        error = 'Please Select Category';
      }
    }
    return error;
  }

  // Image Add and Update Functionality
  const handleFileUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setImgUploading(true);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const { data } = await tenantInstance.post(`/tenants/files/`, formData, {
        params: {
          key: "product_image",
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setImageId(data.data.id);
    } catch (error) {
      console.error(error);
    } finally {
      setImgUploading(false);
    }
  };

  useEffect(() => {
    onFetchAllTenantCategoriesStart();
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await onFetchAllTenantCategoriesStart();
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await onFetchAllTenantCategoriesStart();
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  // React.useEffect(() => {
  //   if (id) {
  //     onFetchProductDetailsStart(id);
  //   }
  // }, [id]);
  React.useEffect(() => {
    setLoadingProductDetails(true);
    if (id) {
      onFetchProductDetailsStart(id)
      setLoadingProductDetails(false);
    }
  }, [id]);

  return (
    
    <Formik
      initialValues={{
        name: id && soloProduct ? soloProduct?.name : "",
        short_name: id && soloProduct ? soloProduct?.short_name : "",
        description: id && soloProduct ? soloProduct?.description : "",
        category_id:
          id && soloProduct && soloProduct?.category_id
            ? `${soloProduct?.category_id}`
            : "",
        status_id: id && soloProduct ? soloProduct?.status_id : null,
        packsize: id && soloProduct ? soloProduct?.packsize : "",
        case_pack: id && soloProduct ? soloProduct?.case_pack : "",
        variant: id && soloProduct ? soloProduct?.variant : "",
        net_weignt_in_grams:
          id && soloProduct ? soloProduct?.net_weignt_in_grams : "",
        net_weight_per_cv_in_kg:
          id && soloProduct ? soloProduct?.net_weight_per_cv_in_kg : "",
        sku: id && soloProduct ? soloProduct?.sku : "",
        code: id && soloProduct ? soloProduct?.code : "",
        hsn: id && soloProduct ? soloProduct?.hsn : "",
        mrp: id && soloProduct ? soloProduct?.mrp : "0",
        price: id && soloProduct ? soloProduct?.price : "0",
        is_active: id && soloProduct ? soloProduct?.is_active : false,
        scheme_information:
          id && soloProduct ? soloProduct?.scheme_information : "",
        image_files: id && soloProduct ? soloProduct?.image_files?.name: "",
      }}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onCreateOrUpdateProductStart(
          {
            ...values,
            category_id: Number(values?.category_id),
            image_id: imageId ? Number(imageId) : null,
            id: id ? id : undefined,
          },
          () => {
            setSubmitting(true);

            navigate("/manage/inventory");
          }
        );
      }}
    >
      {({ isSubmitting, values, handleSubmit, errors, setFieldValue }) => {
        return loading ? (  <img src={Loading} alt="Loading..." /> ) : (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "space-between" },
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                margin: "20px 0",
                gap: { xs: 1 },
              }}
            >
              {addValue && <Typography variant="h3">Add Products</Typography>}
              {!addValue && <Typography variant="h3">Edit Products</Typography>}
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: 1, sm: 2 },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <CustomButton
                  name="Cancel"
                  variant="outlined"
                  handleClick={() => {
                    setAddValue(false)
                    navigate("/manage/inventory", { state: { tabValue: 1 } })
                  }}
                />
                <CustomButton name="Save & Publish" type="submit" />
              </Box>
            </Box>
            { Object.keys(soloProduct).length === 0 && !addValue ? (
                <img src={Loading}/>
            ) :
            (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: "auto",
                    width: "100%",
                    padding: (theme) => theme.spacing(2),
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid container item xs={6}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="name"
                          id="name"
                          label="Product Name*"
                          validate={(value) => validation(value, 'name')}
                          onFocus={() => setFocusedField('name')}
                          onBlur={() => setFocusedField(null)}
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 30);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Field
                            component={Select}
                            name="category_id"
                            label="Select Product Category*"
                            variant="filled"
                            validate={(value) => validation(value, 'category_id')}
                            onFocus={() => setFocusedField('category_id')}
                            onBlur={() => setFocusedField(null)}
                            disableUnderline
                          >
                            {categories?.length > 0 &&
                              categories?.map((category) => (
                                <MenuItem
                                  value={category?.id}
                                  key={category?.id}
                                >
                                  {category?.name}
                                </MenuItem>
                              ))}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            variant="filled"
                            component={TextField}
                            type="number"
                            autoFocus
                            fullWidth
                            name="mrp"
                            id="mrp"
                          label="Selling Price (MRP)*"
                            validate={validation}
                            InputProps={{
                              disableUnderline: true,
                              onInput: (e) => {
                                e.preventDefault();
                                const inputValue = e.target.value;
                                const acceptedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
                                e.target.value = acceptedValue;
                              },
                            }}
                            
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            variant="filled"
                            component={TextField}
                            type="number"
                            autoFocus
                            fullWidth
                            name="price"
                            id="price"
                            label="Cost Price"
                            InputProps={{
                              disableUnderline: true,
                              onInput: (e) => {
                                e.preventDefault();
                                const inputValue = e.target.value;
                                const acceptedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
                                e.target.value = acceptedValue;
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="short_name"
                          id="short_name"
                          label="Short Name"
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 50);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: {
                              xs: "center",
                              sm: "center",
                              md: "space-between",
                            },
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                            },
                            width: "100%",
                          }}
                        >
                          <Typography variant="h5">Product Status</Typography>
                          <Field
                            type="checkbox"
                            name="is_active"
                            // validate={validation}
                            variant="caption"
                            color="error"
                          >
                            {({ field, form }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...field}
                                    color="secondary"
                                    checked={field.value}
                                  />
                                }
                                onChange={() => form.setFieldValue('is_active', !field.value)}
                                label={field.value ? 'Active' : 'In-Active'}
                                labelPlacement="start"
                              />
                            )}
                          </Field>

                          {/* Error message */}

                        </Box>
                        {/* <ErrorMessage name="is_active">
                      {(msg) => (
                        <Typography variant="caption" color="error" >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage> */}
                      </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          validate={(value) => validation(value, 'sku')}
                          name="sku"
                          id="sku"
                          label="SKU Code*"
                          onFocus={() => setFocusedField('sku')}
                          onBlur={() => setFocusedField(null)}
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 30);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          variant="filled"
                          component={TextField}
                          type="text"
                          autoFocus
                          fullWidth
                          name="packsize"
                          id="packsize"
                          label="Pack Size"
                          InputProps={{
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 100);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name="description"
                          variant="filled"
                          component={TextField}
                          label="Product Description"
                          multiline
                          minRows={10}
                          maxRows={10}
                          InputProps={{
                            notched: false,
                            disableUnderline: true,
                            onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.target.value;
                              const acceptedValue = inputValue.slice(0, 150);
                              e.target.value = acceptedValue;
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: "auto",
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  <Grid item xs={12}>
                    <CustomBox
                      sx={{
                        height: "302px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {file ? (
                        <img src={imgUploading ? LoaderIcon : file} alt="img" />
                      ) : (
                        <CustomLabel htmlFor="fileInput">
                          <FileInput
                            type="file"
                            id="fileInput"
                            onChange={handleFileUpload}
                            accept=".jpg, .jpeg, .png"
                          />
                          <img src={Thumbnail} alt="Upload" />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                              margin: "35px 0 16px 0",
                            }}
                          >
                            <img src={UploadIcon} alt="Upload" />
                            <Typography variant="h5">Upload Image</Typography>
                          </Box>
                          <Typography variant="body1">
                            Upload a cover image for your product.
                          </Typography>
                          <Typography variant="body1">
                            File Format <strong>jpeg, png</strong> Recommened
                            Size <strong>600x600 (1:1)</strong>
                          </Typography>
                        </CustomLabel>
                      )}
                    </CustomBox>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            )
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export default container(AddProducts);