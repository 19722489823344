import * as React from "react";

// Formik imports
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";

// Firebase imports
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

// Mui imports
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, LinearProgress } from "@mui/material";

// Third party libraries
import { MuiOtpInput } from "mui-one-time-password-input";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

// Icon imports
import Logo from "../../../assets/images/logo.svg";

// Project imports
import CountrySelect from "../../../components/CountrySelect";
import { auth } from "../../../firebase";
import container from "./OTPLogin.container";
import axios from "axios";
import { debounce } from "lodash";

const LoginCard = styled(Box)(({ theme }) => ({
  maxWidth: "443px",
  width: "100%",
  padding: theme.spacing(6),
  borderRadius: "12px",
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  gap: "28px",
  alignItems: "center",
  marginTop: "100px",
}));

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F5F9FE",
    border: "1px solid #EAEFF5",
    borderRadius: "12px",
  },
}));

const OTPLogin = ({
  onOpenAlert,
  onSignInWithTokenStart,
  auth: { isAutenticated },
}) => {
  const navigate = useNavigate();

  if (isAutenticated) {
    navigate("/manage/dashboard");
  }

  // Generate Recaptcha
  // const generateRecaptcha = () => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         // allow 
  //       },
  //     },
  //     auth
  //   );
  // };
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // console.log("reCAPTCHA success", response);
        },
        "expired-callback": () => {
          console.error("reCAPTCHA expired or failed");
        },
      },
      auth
    );
  };
  

  React.useEffect(() => {
    generateRecaptcha();
  }, []);

  const otpVerification = async (values, setFieldValue, setSubmitting) => {
    const phoneNumber = `+${values?.countryCode?.phone}${values?.phonenumber}`;
    if (!window.recaptchaVerifier) {
      generateRecaptcha();
    }
    try {
      let appVerifier = window.recaptchaVerifier;

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      // console.log("confirmationResult", confirmationResult);
      window.confirmationResult = confirmationResult;

      setSubmitting(false);
      setFieldValue("otpScreen", true);
    } catch (error) {
      console.log(error?.code);
      console.log("confirmationResult", error);

      setSubmitting(false);
      switch (error.code) {
        case "auth/too-many-requests":
          onOpenAlert("Too Many requests sent. Please wait", "error");
          break;

        default:
          onOpenAlert("Something went wrong", "error");
      }
    }
  };

  const resendOTP = (values, setFieldValue, setSubmitting) => {
    setSubmitting(true);
    otpVerification(values, setFieldValue, setSubmitting);
  };
  const defaultCountryCode = { code: "IN", label: "India", phone: "91" }; 
  return (
    <React.Fragment>
      <div id="recaptcha-container"></div>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        initialValues={{
          countryCode:  { code: "IN", label: "India", phone: "91" }, // Default country code for India
          defaultCountry: "India (+91)",
          phonenumber: "",
          otpScreen: false,
          otp: "",
        }}
        // validationSchema={Yup.object({
        //   countryCode: Yup.object().required("Country Code is Required"),
        //   phonenumber: Yup.string().required("Required").length(10),
        //   otpScreen: Yup.boolean(),
        //   otp: Yup.string().when("otpScreen", {
        //     is: true,
        //     then: (schema) => schema.required("Invalid OTP").length(6),
        //     otherwise: (schema) => schema,
        //   }),
        // })}
        validationSchema = {Yup.object({
          countryCode: Yup.object().required('Country Code is Required'),
          phonenumber: Yup.string()
            .required('Mobile Number is Required')
            .matches(/^\d{10}$/, 'Mobile Number should be exactly 10 digits'),
          })
        }
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          setSubmitting(false);

          if (values?.otpScreen) {
            const handleComplete = () => {
              let confirmationResult = window.confirmationResult;
              confirmationResult
                .confirm(Number(values?.otp))
                .then((result) => {
                  const user = result?.user;
                  onSignInWithTokenStart(user?.accessToken, () => {
                    navigate("/manage/selectTenants");
                    setFieldValue("otpScreen", false);
                    onOpenAlert("User Signed In Successfully", "success");
                  });
                })
                .catch((error) => {
                  console.log(error);
                  switch (error.code) {
                    case "auth/code-expired":
                      onOpenAlert("Invalid OTP Code", "error");
                      break;

                    default:
                      onOpenAlert("Something went wrong", "error");
                  }
                });
            };

            handleComplete();
          } else {
            otpVerification(values, setFieldValue, setSubmitting);
          }
        }}
      >
        {({
          submitForm,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          setSubmitting,
        }) => {
          const handleOTPChange = (otp) => {
            setFieldValue("otp", otp);
          };

          const getCountryFromCoordinates = async (latitude, longitude) => {
            try {
              const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAVqvzkKnQC0K0qLpnFxDJd9MI8sXLQ04U`
              );

              if (response.status === 200) {
                const results = response.data.results;
                if (results.length > 0) {
                  const addressComponents = results[0].address_components;
                  const country = addressComponents.find((component) =>
                    component.types.includes("country")
                  );
                  if (country) {
                    const countryName = country.long_name;
                    console.log("Country:", countryName);
                    setFieldValue("defaultCountry", countryName);
                  }
                }
              }
            } catch (error) {
              console.log("Error retrieving country:", error.message);
              setFieldValue("defaultCountry", "India");
            }
          };
          const debouncedGetCountryFromCoordinates = debounce(getCountryFromCoordinates, 1000);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useEffect(() => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  getCountryFromCoordinates(
                    position.coords.latitude,
                    position.coords.longitude
                  );
                },
                (error) => {
                  console.log("Error retrieving location:", error.message);
                  setFieldValue("countryCode", defaultCountryCode);
                }
              );
            } else {
              console.log("Geolocation is not supported by this browser.");
              setFieldValue("countryCode", defaultCountryCode);
            }
          }, []);

          return (
            <Form>
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoginCard>
                  <Box sx={{ textAlign: "center" }}>
                    <img src={Logo} alt="pulseplay" />
                    {values?.otpScreen ? (
                      <React.Fragment>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "32px",
                            lineHeight: "40px",
                            color: "#1A3048",
                          }}
                        >
                          Enter OTP
                        </Typography>
                        <Typography
                          variant="h5"
                          mt={"14px"}
                          sx={{ textAlign: "center" }}
                        >
                          Enter the OTP code we just sent you on your registered
                          Phone number
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography variant="h3">Welcome back!</Typography>
                        <Typography variant="h5" mt={"8px"}>
                          Enter Your Mobile Number
                        </Typography>
                      </React.Fragment>
                    )}
                  </Box>
                  {values?.otpScreen ? (
                    <React.Fragment>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: (theme) => theme.spacing(6),
                        }}
                      >
                        <Field name="otp">
                          {({ field, meta }) => (
                            <React.Fragment>
                              <MuiOtpInputStyled
                                value={values?.otp}
                                onChange={handleOTPChange}
                                length={6}
                              />
                              {meta.touched && meta.error && (
                                <FormHelperText style={{ color: "red" }}>
                                  {meta.error}
                                </FormHelperText>
                              )}
                            </React.Fragment>
                          )}
                        </Field>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginBottom: (theme) => theme.spacing(8),
                        }}
                      >
                        {isSubmitting && <LinearProgress />}

                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          onClick={submitForm}
                          sx={{
                            padding: "17px 16px",
                            background: "#05ADAD",
                            borderRadius: "12px",
                            width: "100%",
                            textTransform: "none",
                            marginTop: (theme) => theme.spacing(3),
                          }}
                        >
                          Login
                        </Button>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#3B4054",
                            mt: "16px",
                            textAlign: "center",
                            "& > span": {
                              color: (theme) => theme.palette.primary.dark,
                              cursor: "pointer",
                            },
                          }}
                        >
                          Didn’t get OTP?{" "}
                          <span
                            onClick={() =>
                              resendOTP(values, setFieldValue, setSubmitting)
                            }
                          >
                            Resend OTP
                          </span>
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: (theme) => theme.spacing(8),
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Box flex={"50%"}>
                            <Field name="countryCode">
                              {({
                                field,
                                form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                              }) => (
                                <div>
                                  <CountrySelect
                                    setFieldValue={setFieldValue}
                                    field={field}
                                    value={field.value}
                                    defaultCountry={values?.defaultCountry}
                                  />

                                  {meta.touched && meta.error && (
                                    <FormHelperText style={{ color: "red" }}>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                            </Field>
                          </Box>
                          <Box flex={"50%"}>
                            <Field
                              variant="filled"
                              component={TextField}
                              type="number"
                              autoFocus
                              fullWidth
                              name="phonenumber"
                              id="phonenumber"
                              label="Mobile Number"
                              InputProps={{
                                disableUnderline: true,
                                onInput: (e) => {
                                  e.preventDefault();
                                  const inputValue = e.target.value;
                                  const acceptedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
                                  e.target.value = acceptedValue;
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginBottom: (theme) => theme.spacing(8),
                        }}
                      >
                        {isSubmitting && <LinearProgress />}

                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          onClick={submitForm}
                          sx={{
                            padding: "17px 16px",
                            background: "#05ADAD",
                            borderRadius: "12px",
                            width: "100%",
                            textTransform: "none",
                          }}
                        >
                          Request an OTP
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                </LoginCard>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default container(OTPLogin); 