import * as TenantType from "./tenant.types";

// View Tenant
export const viewTenantUserStart = () => ({
  type: TenantType.VIEW_TENANT_USERS_START,
});

export const viewTenantUserSuccess = (result) => ({
  type: TenantType.VIEW_TENANT_USERS_SUCCESS,
  payload: result,
});

export const viewTenantUserFail = (error) => ({
  type: TenantType.VIEW_TENANT_USERS_FAILURE,
  payload: error,
});

// Fetch Tenant Roles
export const fetchTenantRolesStart = () => ({
  type: TenantType.FETCH_TENANT_ROLES_START,
});

export const fetchTenantRolesSuccess = (result) => ({
  type: TenantType.FETCH_TENANT_ROLES_SUCCESS,
  payload: result,
});

export const fetchTenantRolesFail = (error) => ({
  type: TenantType.FETCH_TENANT_ROLES_FAILURE,
  payload: error,
});

// Fetch Solo Tenant Tour Program
export const fetchSoloTenantTourProgramStart = (id) => ({
  type: TenantType.FETCH_SOLO_TENANT_TOUR_PROGRAM_START,
  payload: { id },
});

export const fetchSoloTenantTourProgramSuccess = (result) => ({
  type: TenantType.FETCH_SOLO_TENANT_TOUR_PROGRAM_SUCCESS,
  payload: result,
});

export const fetchSoloTenantTourProgramFail = (error) => ({
  type: TenantType.FETCH_SOLO_TENANT_TOUR_PROGRAM_FAILURE,
  payload: error,
});

// List Tenant Tour Programs
export const listTenantTourProgramsListStart = (date) => ({
  type: TenantType.LIST_TENANT_TOUR_PROGRAMS_START,
  payload: { date },
});

export const listTenantTourProgramsListSuccess = (result) => ({
  type: TenantType.LIST_TENANT_TOUR_PROGRAMS_SUCCESS,
  payload: result,
});

export const listTenantTourProgramsListFail = (error) => ({
  type: TenantType.LIST_TENANT_TOUR_PROGRAMS_FAILURE,
  payload: error,
});

// Create or update Tenant Tour Program
export const createOrUpdateTenantTourProgramStart = (formData, cb) => ({
  type: TenantType.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_START,
  payload: { formData, cb },
});

export const createOrUpdateTenantTourProgramSuccess = (result) => ({
  type: TenantType.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_SUCCESS,
  payload: result,
});

export const createOrUpdateTenantTourProgramFail = (error) => ({
  type: TenantType.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_FAILURE,
  payload: error,
});
