import React from "react";
import { Grid, Paper, Typography, } from "@mui/material";
import { PieChart, } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const HollowPieChartView = ({
    // onYearlyItemListStart,
    // itemStatisticalReport: {yearlyItemList}
}) => {

    const data = [
      { value: 5, label: 'A', color: '#E46B66' },
      { value: 10, label: 'B', color: '#65C466' },
      { value: 15, label: 'C', color: '#3B00ED' },
      { value: 20, label: 'D', color: '#F3B03D' },
    ];
    const size = {
      width: 370,
      height: 180,
    };

    const StyledText = styled('text')(({ theme }) => ({
      fill: theme.palette.text.primary,
      textAnchor: 'middle',
      dominantBaseline: 'central',
      fontSize: 20,
    }));

    function PieCenterLabel({ children }: { children: React.ReactNode }) {
      const { width, height, left, top } = useDrawingArea();
      return (
        <StyledText x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledText>
      );
    }
    // React.useEffect(() => {
    //     onYearlyItemListStart();
    // }, [])
    return(
        // <div>Hollow Pie Chart View</div>
        <Grid item container xs={12} md={12}>
            <Paper sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px', borderRadius: "14px" }}>
                <Grid container item md={12}>
                    <Grid md={12}>
                        <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>
                            Category Wise Sales
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid md={3}>
                        <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>
                            Category Wise Sales
                        </Typography>
                    </Grid>
                    <Grid md={9}>
                        <PieChart series={[{ data, innerRadius: 75 }]} {...size} legend={{ hidden: true }}>
                            {/* legend={{ direction: "column", }} */}
                            <PieCenterLabel>Center label</PieCenterLabel>
                       </PieChart>
                    </Grid>     
                </Grid>
                <Grid>
                    <Grid md={12}>
                        <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>
                            Category Wise Sales
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default HollowPieChartView;