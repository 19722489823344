import React, { Fragment } from "react";

// Material-ui
import { Box, Typography } from "@mui/material";

// Project imports
import { StyledTab, StyledTabs, TabPanel } from "../../../components/CustomTab";
import ReceivedTable from "./components/ReceivedTable";
import PlacedTable from "./components/PlacedTable";
import OrderOnbeHalfTable from "./components/OrderOnbeHalfTable"
import container from "./Orders.container";
import CustomButton from "../../../components/CustomButton";
import AddProductIcon from "../../../assets/images/addProduct.svg";
import { useNavigate } from "react-router-dom";
import AddCategoryIcon from "../../../assets/images/addCategory.svg";

const tabList = [
  {
    name: "Received Order",
    index: 0,
  },
  {
    name: "Placed Order",
    index: 1,
  },
  {
    name: "Order On Behalf",
    index: 2,
  },
];
interface OrdersProps {
  orders: any;
  onFetchPlacedOrdersStart: any;
  onFetchReceivedOrdersStart: any;
  onFetchOrdersOnbeHalfStart: any;
  onFetchTradersStart:any;
  onChangeApplyOrderFilter: () => void;
}
const Orders: React.FC<OrdersProps> = ({
  onFetchReceivedOrdersStart,
  onFetchPlacedOrdersStart,
  onFetchOrdersOnbeHalfStart,
  onChangeApplyOrderFilter,
  onFetchTradersStart,
  orders,
}) => {
  
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log(value)
  const [addPage,setAddPage] = React.useState(false);
  console.log(addPage)
  return (
    <Fragment>
      <Box position={"relative"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "25px",
          }}
        >
          <Typography variant="h3">Orders History</Typography>
          {/* <CustomButton
          name="Create Order"
          icon={<img src={AddProductIcon} alt="" />}
          // handleClick={() => {
          //   navigate("/manage/orders/create-order",{ state: { value: true } })  
          // }}
          handleClick={() => {
            setAddPage(true)
            console.log("Button Clicked");
            navigate("/manage/inventory/products-add",{ state: { value: true } })}}
          
          ></CustomButton> */}
          <CustomButton
                name="Create Order"
                handleClick={() => {
                  setAddPage(true)
                  
                  navigate("/manage/orders/create-order",{ state: { value: true } })}}
                sx={{
                  width: "auto",
                  zIndex: 2,
                  backgroundImage: AddCategoryIcon,
                }}
                icon={<img src={AddProductIcon} alt="" />}
              />
        </Box>

        <Box sx={{ top: 10, width: "100%", zIndex: 1 }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            {tabList?.map((tab) => (
              <StyledTab label={tab?.name} key={tab?.index} />
            ))}
          </StyledTabs>

          <TabPanel value={value} index={0}>
            <ReceivedTable
              onFetchReceivedOrdersStart={onFetchReceivedOrdersStart}
              onChangeApplyOrderFilter={onChangeApplyOrderFilter}
              onFetchTradersStart={onFetchTradersStart}
              orders={orders}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PlacedTable
              onFetchPlacedOrdersStart={onFetchPlacedOrdersStart}
              onChangeApplyOrderFilter={onChangeApplyOrderFilter}
              onFetchTradersStart={onFetchTradersStart}
              orders={orders}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <OrderOnbeHalfTable
              onFetchOrdersOnbeHalfStart={onFetchOrdersOnbeHalfStart}
              onChangeApplyOrderFilter={onChangeApplyOrderFilter}
              onFetchTradersStart={onFetchTradersStart}
              orders={orders}
            />
          </TabPanel>
        </Box>
      </Box>
    </Fragment>
  );
};

export default container(Orders);
