import { Box, TableContainer, Paper, Table, TableBody, TableRow, TableCell, 
Checkbox, } from "@mui/material";
import React, { Fragment, useState, } from "react";
import { EnhancedTableHead } from '../../../../components/CustomTable';

const HeadColumn = ({ onGetReStatisticalReportStart, fromDate, toDate,
    reStatisticalReport: {reStatisticalData, reStaPageDetails},}) => {
    const [dense, setDense] = React.useState(false);
    const [showMonths, setShowMonths] = useState(false);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("name");

    interface HeadCell {
        disablePadding: boolean;
        id: any;
        label: string;
        isShow: boolean;
        numeric: boolean;
        align?: string;
    }
    const headCells: readonly HeadCell[] = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Retailer Name",
            isShow: true,
        },
    ]
    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
    
        onGetReStatisticalReportStart(fromDate, toDate, reStaPageDetails?.page, reStaPageDetails?.limit, isAsc ? "asc" : "desc", field);
    };
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                                numSelected={0}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={() => {}}
                                onRequestSort={(event, property) => handleRequestSort(property)}
                                rowCount={reStatisticalData?.length}
                                headCells={headCells}
                                isCheckBox = {false}
                            />
                        <TableBody>
                            {reStatisticalData && 
                            reStatisticalData?.length > 0 ? 
                                (reStatisticalData?.map((row, index) => {
                                    return(
                                        <TableRow key={index}>
                                            <TableCell align="center" sx={{
                                                borderTop: "1px solid #ebeded",
                                                borderRight: "1px solid #ebeded",
                                                borderLeft: "1px solid #ebeded", 
                                                whiteSpace: "nowrap", width: "100%",
                                                height: "75px",
                                            }}>
                                              {row?.name ? row?.name : "-"}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })

                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={headCells.length} align="center">
                                            No Data Available
                                        </TableCell>
                                    </TableRow>
                                )

                            }
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Fragment>
    )
}

export default HeadColumn;