import * as React from "react";

// Material-ui
import { FormControl, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

// Third party libraries
import { useNavigate } from "react-router-dom";

// Icons import
import EditIcon from "../../../../assets/images/edit.svg";
import DelIcon from "../../../../assets/images/delete.svg";

// Project imports
import CustomButton from "../../../../components/CustomButton";
import CustomizedDialog from "../../../../components/CustomDialog";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../../components/CustomTable";
import TableLoader, {
  CustomPagination,
} from "../../../../components/CustomTable/CustomTable";
import FilterMenu from "./FilterMenu";
import { useDispatch } from "react-redux";
import CategoryFilterMenu from "./CategoryFilterMenu";
import Loading from "../../../../assets/images/loading.svg";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
// function stableSort<T>(
//   array: readonly T[],
//   comparator: (a: T, b: T) => number
// ) {
//   const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis?.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis?.map((el) => el[0]);
// }

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  isShow: boolean;
}

export default function CategoryTable({
  inventory: {
    filtered_categories,
    loading,
    categoryPageDetails,
    categoryPage: pageNo,
    categoryLimit: limit,
    name,
    order:orderId
  },
  onProductDeleteStart,
  onFetchAllFilteredProductsCategoryStart,
  setOpenAddModal,
  onCategoryDeleteStart,
  onChangeApplyCategoryFilter,
  role_details,
}) {
  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      isShow: true,
    },
    {
      id: "order",
      numeric: true,
      disablePadding: false,
      label: "Order",
      isShow: true,
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Description",
      isShow: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      isShow: role_details?.permissions_details?.some(
        (permission) => permission.module === "category.update"
      ),
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);

  const [delModal, setDelModal] = React.useState(null);
  const [addModal, setAddModal] = React.useState(null);
  const [noOfPages, setNoOfPages] = React.useState([]);
  const [activePage, setActivePage] = React.useState<any>(1);

  const dispatch = useDispatch();
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const handleRequestSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    onFetchAllFilteredProductsCategoryStart(isAsc ? "asc" : "desc", field);
};

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const sortedData = filtered_categories ? filtered_categories.sort(getComparator(order, orderBy)) : [];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = sortedData?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangeApplyCategoryFilter("categoryPage", event.target.value));
    setActivePage(Number(event.target.value));
    onFetchAllFilteredProductsCategoryStart('asc', 'name');
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(
      onChangeApplyCategoryFilter("categoryLimit", Number(event.target.value))
    );
    onFetchAllFilteredProductsCategoryStart('asc', 'name');
  };

  const showNextPage = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    dispatch(onChangeApplyCategoryFilter("categoryPage", activePage + 1));
    setActivePage(activePage + 1);
    onFetchAllFilteredProductsCategoryStart(isAsc ? "desc" : "asc", 'name');
  };

  const showPrevPage = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    dispatch(onChangeApplyCategoryFilter("categoryPage", activePage - 1));
    setActivePage(activePage - 1);
    onFetchAllFilteredProductsCategoryStart(isAsc ? "desc" : "asc", 'name');
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filtered_categories.length)
      : 0;

  const handleDelete = () => {
    onCategoryDeleteStart(
      {
        id: [delModal],
      },
      () => {
        setDelModal(null);
      }
    );
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    onFetchAllFilteredProductsCategoryStart('asc', 'name');
  }, []);

  const [action, setAction] = React.useState("");

  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(categoryPageDetails?.total / limit);
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [categoryPageDetails?.total, limit]);

  const handleChange = () => {
    setAction(action);
  };

  React.useEffect(() => {
    if (limit) {
      setRowsPerPage(limit);
    }
  }, [limit]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          // handleChange={(searchValue) => {
          //   dispatch(onChangeApplyCategoryFilter("name", searchValue));

          //   onFetchAllFilteredProductsCategoryStart();
          //   // Log the search value from EnhancedTableToolbar
          // }}
          handleChange={(searchValue, field) => {
            const isAsc = orderBy === field && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(field);
            dispatch(
              onChangeApplyCategoryFilter(
                "name",
                searchValue,
                isAsc ? "asc" : "desc",
                'name' // Pass 'field' for sorting
              )
            );
            onFetchAllFilteredProductsCategoryStart(isAsc ? "asc" : "desc", 'name');
          }}
          tableHeaderComps={
            <Box sx={{ display: "flex", alignItems: "center" }}>
                 <CategoryFilterMenu
                onChangeApplyCategoryFilter={onChangeApplyCategoryFilter}
                onFetchAllFilteredProductsCategoryStart={
                  onFetchAllFilteredProductsCategoryStart
                }
                name={name}
                order={orderId}
               
              />
              <FormControl sx={{ m: 1, minWidth: 120, display: "none" }} size="small">
                <InputLabel id="demo-select-small-label">
                  {" "}
                  <Typography sx={{ color: "#1C1D22" }}>Bulk Action</Typography>
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={action}
                  label="Bulk Action"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              onRequestSort={(event, property) => handleRequestSort(property)}
              rowCount={sortedData?.length}
              headCells={headCells}
            />
            {/* {loading ? (
              <React.Fragment>
                {[...Array(10)].map((_, index) => (
                  <TableLoader rows={headCells} key={index} />
                ))}
              </React.Fragment>
            ) : ( */}
              <TableBody>
                {/* { */}
                {loading && filtered_categories.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headCells.length} align="center">
                    {/* Loading... */}
                    <img src={Loading}/>
                  </TableCell>
                </TableRow>
                ) : filtered_categories.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                )
                : (sortedData && sortedData?.length > 0 ? 
                  (sortedData?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.product}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.name)}
                        />
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center" 
                        // style={{ width: '100px', minWidth: '100px' }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="center">
                          {row.order}
                      </TableCell>
                      <TableCell align="center">
                          {row.description}
                      </TableCell>
                      {role_details?.permissions_details?.some(
                        (permission) => permission.module === "category.update"
                      ) && (
                        <TableCell sx={{ width: 100 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenAddModal(row?.id);
                              }}
                            >
                              <img src={EditIcon} alt="Edit" />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setDelModal(row?.id);
                              }}
                              className="delete-icon-class"
                            >
                              <img src={DelIcon} alt="Del" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                }) ) : (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      {/* No Data Available */}
                      <img src={Loading}/>
                    </TableCell>
                  </TableRow>
                ))
                }
                {/* } */}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            {/* )} */}
          </Table>
        </TableContainer>
        <CustomPagination
          count={categoryPageDetails?.total}
          rowsPerPage={rowsPerPage}
          page={activePage-1}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          pageNo={pageNo}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
        />
      </Paper>
      <CustomizedDialog
        open={delModal}
        handleClose={() => setDelModal(false)}
        title={"Delete Product"}
        maxWidth={"sm"}
      >
        <Box>
          <Typography variant="h3">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: (theme) => theme.spacing(4),
              justifyContent: "flex-end",
              marginTop: (theme) => theme.spacing(4),
            }}
          >
            <CustomButton
              variant="outlined"
              name="Cancel"
              handleClick={() => setDelModal(null)}
              sx={{ width: 110 }}
            />
            <CustomButton
              name="Delete"
              handleClick={handleDelete}
              sx={{ width: 110 }}
            />
          </Box>
        </Box>
      </CustomizedDialog>
    </Box>
  );
}
