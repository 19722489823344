import * as tourScreenTypes from "./tourScreen.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
    user_id: [],
    beat_name:"",
    name: "",
    dates: [],
    date: "",
    loading: false,
    error:null,
    from_date: null,
    to_date: null,
    selectedDateRange: "",
    page:1,
    limit:10,
    page_details:[]
};
function tourScreenReducer(state = INITIAL_STATE, action) {
    // console.log(action.payload)
    const { type, payload } = action;
    switch (type) {
        
        case tourScreenTypes.GET_TOURSCREEN_USERS_LIST_START:
          return {
            ...state,
            loading: true,
          };
    
        case tourScreenTypes.GET_TOURSCREEN_USERS_LIST_SUCCESS:
          return {
            ...state,
            data: payload?.data?.tour_program,
            page_details:payload?.data?.page_details,
            loading: false,
          };
          case tourScreenTypes.ON_CHANGE_APPLY_TOUR_SCREEN_FILTER:
            const { key, value } = action.payload;
      
          
              return {
                ...state,
                data: payload?.data?.tour_program,
                page_details:payload?.data?.page_details,
                [key]: value,
                }
              
           
        case tourScreenTypes.GET_TOURSCREEN_USERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
export default tourScreenReducer;
