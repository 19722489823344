import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Admin from "../../layouts/Admin";
import BeatsAltScreen from "../../views/Admin/BeatsAlt";
import BeatsScreen from "../../views/Admin/Beats";

// ================ Main Beats ============================= //
const Beats = () => {
  return <WithLayout layout={Admin} component={BeatsAltScreen} />;
};

export default Beats;
