import React, { Fragment } from "react";

// Material-ui
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// Icons import
import FilterIcon from "../../../../assets/images/filter.svg";
import CustomButton from "../../../../components/CustomButton";
import { useDispatch } from "react-redux";
import { changeApplyProductClearFilter } from "../../../../store/inventory/inventory.actions";

const FilterMenu = ({
  onFetchAllFilteredProductsStart,
  onChangeApplyProductFilter,
  name,
  products,
  sku,
  product_id,
  categories,
  status,
  amount_greater_than,
  amount_less_than,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  // products?.map((row, index) => (
  //   // console.log("row?.category---->", row?.category)
  // ))
  
  console.log(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clearHandler = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    dispatch(changeApplyProductClearFilter());
    onFetchAllFilteredProductsStart(isAsc ? "asc" : "desc", 'name');
    handleClose();
    setAmountGreaterThan("0");
    setAmountLessThan("0");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilter = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    onFetchAllFilteredProductsStart(isAsc ? "asc" : "desc", 'name');
    handleClose();
  };

  const dispatch = useDispatch();
  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");

  const [amountGreaterThan, setAmountGreaterThan] = React.useState("0");
  const [amountLessThan, setAmountLessThan] = React.useState("0");

  // const handleChange = (e) => {
  //   const isAsc = orderBy === e && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(e);
  //   const { name, value } = e.target;

  // if (name === "amount_greater_than" || name === "amount_less_than") {
  //   const isValid = /^[0-9]{1,8}$/.test(value);

  //   if (isValid) {
  //     dispatch(onChangeApplyProductFilter(name, value, 'asc', 'name'));
  //   }
  // } else {
  //   dispatch(onChangeApplyProductFilter(name, value, 'asc', 'name'));
  // }
  // // if (name === "amount_greater_than" || name === "amount_less_than") {
  // //   if (value === "0") {
  // //     dispatch(onChangeApplyProductFilter(name, "", "asc", "name"));
  // //   } else {
  // //     const isValid = /^[0-9]{1,8}$/.test(value);

  // //     if (isValid) {
  // //       dispatch(onChangeApplyProductFilter(name, value, "asc", "name"));
  // //     }
  // //   }
  // // } else {
  // //   dispatch(onChangeApplyProductFilter(name, value, "asc", "name"));
  // // }
  //   // const { name, value } = e.target;
  //   // dispatch(onChangeApplyProductFilter(name, value));
  //   // console.log(name, value, "checking");
  // };
  const handleChange = (e) => {
    const isAsc = orderBy === e && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(e);
    const { name, value } = e.target;
    const isValid = /^[0-9]{1,8}$/.test(value);
    if (name === "amount_greater_than" || name === "amount_less_than") {
      // const sanitizedValue = value === "0" ? value : value.replace(/^0+/, '');
      const sanitizedValue = isValid ? value.replace(/^0+/, '') : "";
      if (name === "amount_greater_than") {
        setAmountGreaterThan(sanitizedValue);
      } else if (name === "amount_less_than") {
        setAmountLessThan(sanitizedValue);
      }
      dispatch(onChangeApplyProductFilter(name, sanitizedValue, 'asc', 'name'));
    } else {
      dispatch(onChangeApplyProductFilter(name, value, 'asc', 'name'));
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: "89px",
          height: "29px",
          padding: "18px 10px",
          borderRadius: "4px",
          border: "1px solid #45464E",
          display: "flex",
          alignItems: "center",
          gap: "9px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filter" />
        <Typography>Filter</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "24px 21px", maxWidth: 317, width: "100%" }}>
          <Typography
            variant="h4"
            sx={{ color: "#1C1D22", lineHeight: "24px", pb: "18px" }}
          >
            Filter
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="status"
                  sx={{ justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    value={status}
                    control={
                      <Radio
                        checked={status === true}
                        onClick={(event) => {
                          console.log(status);

                          if (event.target["value"] === "true") {
                            dispatch(
                              onChangeApplyProductFilter("status", null)
                            );
                          } else {
                            dispatch(
                              onChangeApplyProductFilter("status", true)
                            );
                          }
                        }}
                      />
                    }
                    label="Active"
                    onChange={(event) => {
                      console.log(status);
                      if (event.target["value"] === "true") {
                        dispatch(onChangeApplyProductFilter("status", null));
                      } else {
                        dispatch(onChangeApplyProductFilter("status", true));
                      }
                    }}
                  />
                  <FormControlLabel
                    value={status}
                    control={
                      <Radio
                        checked={status === false}
                        onClick={(event) => {
                          console.log(status);
                          if (event.target["value"] === "false") {
                            dispatch(
                              onChangeApplyProductFilter("status", null)
                            );
                          } else {
                            dispatch(
                              onChangeApplyProductFilter("status", false)
                            );
                          }
                        }}
                      />
                    }
                    label="In Active"
                    onChange={(event) => {
                      console.log(status);
                      if (event.target["value"] === "false") {
                        dispatch(onChangeApplyProductFilter("status", null));
                      } else {
                        dispatch(onChangeApplyProductFilter("status", false));
                      }
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="sku" sx={{ marginBottom: "8px" }}>
                  SKU
                </FormLabel>
                <TextField
                  hiddenLabel
                  name="sku"
                  id="sku"
                  value={sku}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="product-list" sx={{ marginBottom: "8px" }}>
                  Product
                </FormLabel>
                <Autocomplete
                  size="small"
                  id={"product-list"}
                  options={products?.length > 0 ? products : []}
                  autoHighlight
                  clearIcon
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, newValue) => {
                    dispatch(
                      onChangeApplyProductFilter("name", newValue?.name)
                    );
                  }}
                  renderOption={(props, option) => (
                    <React.Fragment>
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      placeholder="Pick Some"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      onChange={(e) => {
                        console.log("onChange", e.target.value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="category-list" sx={{ marginBottom: "8px" }}>
                  Category
                </FormLabel>
                <Autocomplete
                  size="small"
                  id={"category-list"}
                  options={products?.category?.length > 0 ? products?.category : []}
                  autoHighlight
                  clearIcon
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, newValue) => {
                    dispatch(
                      onChangeApplyProductFilter("category", [newValue?.name]));
                  }}
                  renderOption={(props, option) => (
                    <React.Fragment>
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      placeholder="All"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      onChange={(e) => {
                        console.log("onChange", e.target.value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Amount</Typography>
              <Box sx={{ display: "flex", gap: (theme) => theme.spacing(2) }}>
                <FormControl>
                  <FormLabel
                    id="from"
                    sx={{
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "15px",
                      letterSpacing: "-0.025em",
                      color: "#1C1D22",
                      paddingBottom: "4px",
                    }}
                  >
                    From
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    id="from"
                    name="amount_greater_than"
                    value={amountGreaterThan}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    id="to"
                    sx={{
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "15px",
                      letterSpacing: "-0.025em",
                      color: "#1C1D22",
                      paddingBottom: "4px",
                    }}
                  >
                    To
                  </FormLabel>
                  <TextField
                    hiddenLabel
                    id="to"
                    name="amount_less_than"
                    value={amountLessThan}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                name="Filter"
                sx={{ width: "100%" }}
                handleClick={handleFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                sx={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Clear Filter"
                handleClick={clearHandler}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { label: "Forrest Gump", year: 1994 },
  { label: "Inception", year: 2010 },
  {
    label: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: "Goodfellas", year: 1990 },
  { label: "The Matrix", year: 1999 },
  { label: "Seven Samurai", year: 1954 },
  {
    label: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { label: "City of God", year: 2002 },
  { label: "Se7en", year: 1995 },
  { label: "The Silence of the Lambs", year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: "Life Is Beautiful", year: 1997 },
  { label: "The Usual Suspects", year: 1995 },
  { label: "Léon: The Professional", year: 1994 },
  { label: "Spirited Away", year: 2001 },
  { label: "Saving Private Ryan", year: 1998 },
  { label: "Once Upon a Time in the West", year: 1968 },
  { label: "American History X", year: 1998 },
  { label: "Interstellar", year: 2014 },
  { label: "Casablanca", year: 1942 },
  { label: "City Lights", year: 1931 },
  { label: "Psycho", year: 1960 },
  { label: "The Green Mile", year: 1999 },
  { label: "The Intouchables", year: 2011 },
  { label: "Modern Times", year: 1936 },
  { label: "Raiders of the Lost Ark", year: 1981 },
  { label: "Rear Window", year: 1954 },
  { label: "The Pianist", year: 2002 },
  { label: "The Departed", year: 2006 },
  { label: "Terminator 2: Judgment Day", year: 1991 },
  { label: "Back to the Future", year: 1985 },
  { label: "Whiplash", year: 2014 },
  { label: "Gladiator", year: 2000 },
  { label: "Memento", year: 2000 },
  { label: "The Prestige", year: 2006 },
  { label: "The Lion King", year: 1994 },
  { label: "Apocalypse Now", year: 1979 },
  { label: "Alien", year: 1979 },
  { label: "Sunset Boulevard", year: 1950 },
  {
    label:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { label: "The Great Dictator", year: 1940 },
  { label: "Cinema Paradiso", year: 1988 },
  { label: "The Lives of Others", year: 2006 },
  { label: "Grave of the Fireflies", year: 1988 },
  { label: "Paths of Glory", year: 1957 },
  { label: "Django Unchained", year: 2012 },
  { label: "The Shining", year: 1980 },
  { label: "WALL·E", year: 2008 },
  { label: "American Beauty", year: 1999 },
  { label: "The Dark Knight Rises", year: 2012 },
  { label: "Princess Mononoke", year: 1997 },
  { label: "Aliens", year: 1986 },
  { label: "Oldboy", year: 2003 },
  { label: "Once Upon a Time in America", year: 1984 },
  { label: "Witness for the Prosecution", year: 1957 },
  { label: "Das Boot", year: 1981 },
  { label: "Citizen Kane", year: 1941 },
  { label: "North by Northwest", year: 1959 },
  { label: "Vertigo", year: 1958 },
  {
    label: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { label: "Reservoir Dogs", year: 1992 },
  { label: "Braveheart", year: 1995 },
  { label: "M", year: 1931 },
  { label: "Requiem for a Dream", year: 2000 },
  { label: "Amélie", year: 2001 },
  { label: "A Clockwork Orange", year: 1971 },
  { label: "Like Stars on Earth", year: 2007 },
  { label: "Taxi Driver", year: 1976 },
  { label: "Lawrence of Arabia", year: 1962 },
  { label: "Double Indemnity", year: 1944 },
  {
    label: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { label: "Amadeus", year: 1984 },
  { label: "To Kill a Mockingbird", year: 1962 },
  { label: "Toy Story 3", year: 2010 },
  { label: "Logan", year: 2017 },
  { label: "Full Metal Jacket", year: 1987 },
  { label: "Dangal", year: 2016 },
  { label: "The Sting", year: 1973 },
  { label: "2001: A Space Odyssey", year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

export default FilterMenu;
