import { IAuthState } from "../interfaces";
import * as AuthType from "./auth.types";

// Initial state object for the authentication reducer
const INITIAL_STATE: IAuthState = {
  isAuthenticated: null,
  isTenantAuthenticated: null,
  currentUser: {},
  tenantProfile: {},
  userRole: null,
  error: null,
  loading: false,
  tenant_details: [],
  selectedTenant: null,
  authLoading: true,
  tenantLoading: true,
};

// Reducer function responsible for managing the authentication state
const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AuthType.SIGN_IN_START:
    case AuthType.LOAD_USER_START:
    case AuthType.CHANGE_TENANT_START:
    case AuthType.SIGN_IN_WITH_TOKEN_START:
    case AuthType.ACCEPT_TENANT_INVITATION_START:
    case AuthType.LOAD_USER_BASED_ON_TENANT_START:
      return {
        ...state,
        loading: true,
      };

    case AuthType.SIGN_IN_SUCCESS:
    case AuthType.LOAD_USER_SUCCESS:
    case AuthType.SIGN_IN_WITH_TOKEN_SUCCESS:
      var activeTenant = localStorage.getItem("tenant_details");
      return {
        ...state,
        isAuthenticated: true,
        currentUser: payload?.data,
        userRole: payload?.data?.role_details,
        loading: false,
        authLoading: false,
        tenant_details: payload?.data?.tenant_details,
        selectedTenant: activeTenant
          ? JSON.parse(activeTenant)
          : payload?.data?.tenant_details?.length > 0
          ? payload?.data?.tenant_details[0]
          : null,
      };

    case AuthType.LOAD_USER_BASED_ON_TENANT_SUCCESS:
      return {
        ...state,
        isTenantAuthenticated: true,
        tenantLoading: false,
        loading: false,
        tenantProfile: payload?.data,
      };

    case AuthType.SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: null,
        authLoading: false,
        currentUser: {},
        tenantProfile: {},
        userRole: {},
        tenant_details: [],
        selectedTenant: null,
        error: null,
      };

    // case AuthType.CHANGE_TENANT_SUCCESS:
    //   localStorage.setItem("tenant_details", JSON.stringify(payload));
    //   // localStorage.setItem("timeStamp", a);
    //   const currentTimestamp = Date.now();
    //   localStorage.setItem('timestamp', currentTimestamp.toString());
    //   const storedTimestampString = localStorage.getItem('timestamp');
    //   console.log("storedTimestampString---->", storedTimestampString);
    //   return {
    //     ...state,
    //     loading: false,
    //     selectedTenant: payload,
    //   };
    // case AuthType.CHANGE_TENANT_SUCCESS:
    //   localStorage.setItem("tenant_details", JSON.stringify(payload));
    //   const currentTimestamp = Date.now();
    //   const formattedTimestamp = new Date(currentTimestamp).toLocaleString('en-US', { timeZone: 'UTC' });
    //   localStorage.setItem('timestamp', formattedTimestamp);
    //   const storedTimestampString = localStorage.getItem('timestamp');
    //   console.log("storedTimestampString---->", storedTimestampString);
    //   return {
    //     ...state,
    //     loading: false,
    //     selectedTenant: payload,
    // };
    // case AuthType.CHANGE_TENANT_SUCCESS:
    //   localStorage.setItem("tenant_details", JSON.stringify(payload));
    //   const currentTimestamp = Date.now();


    //   const options = { timeZone: 'Asia/Kolkata' };
    //   const formattedTimestamp = new Date(currentTimestamp).toLocaleString('en-IN', options);

    //   localStorage.setItem('timestamp', formattedTimestamp);
    //   const storedTimestampString = localStorage.getItem('timestamp');
    //   console.log("storedTimestampString---->", storedTimestampString);
    //   return {
    //     ...state,
    //     loading: false,
    //     selectedTenant: payload,
    // };
    case AuthType.CHANGE_TENANT_SUCCESS:
  localStorage.setItem("tenant_details", JSON.stringify(payload));
  const currentTimestamp = Date.now();

  const options = { timeZone: 'Asia/Kolkata' };
  const formattedTime = new Date(currentTimestamp).toLocaleTimeString('en-IN', options);

  localStorage.setItem('timestamp', formattedTime);
  const storedTime = localStorage.getItem('timestamp');
  return {
    ...state,
    loading: false,
    selectedTenant: payload,
  };

  
    case AuthType.ACCEPT_TENANT_INVITATION_SUCCESS:
      const updatedTenants = state.tenant_details.map((tenant) => {
        if (tenant?.id === payload?.data?.tenant_id) {
          return {
            ...tenant,
            is_invitation_pending: false,
          };
        }
        return tenant;
      });
      return {
        ...state,
        loading: false,
        tenant_details: updatedTenants,
      };

    case AuthType.LOAD_USER_FAILURE:
      localStorage.removeItem("access_token");

      return {
        ...state,
        isAuthenticated: null,
        currentUser: {},
        tenantProfile: {},
        userRole: null,
        loading: false,
        error: payload,
        authLoading: false,
      };

    case AuthType.LOAD_USER_BASED_ON_TENANT_FAILURE:
      localStorage.removeItem("access_token");

      return {
        ...state,
        isAuthenticated: null,
        isTenantAuthenticated: null,
        currentUser: {},
        tenantProfile: {},
        userRole: null,
        tenant_details: [],
        selectedTenant: null,
        loading: false,
        error: payload,
        tenantLoading: false,
      };

    case AuthType.SIGN_IN_FAILURE:
    case AuthType.SIGN_OUT_FAILURE:
    case AuthType.CHANGE_TENANT_FAILURE:
    case AuthType.SIGN_IN_WITH_TOKEN_FAILURE:
    case AuthType.ACCEPT_TENANT_INVITATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        authLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
// -- -.--  -. .- -- .  .. ... .-. .- .--- ...- ..  