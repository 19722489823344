import * as BeatsType from "./excel.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  excelFields: null,
  error: null,
  loading: false,
};

// Reducer function responsible for managing the authentication state
const excelReducers = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case BeatsType.GET_EXCEL_FIELDS_START:
    case BeatsType.IMPORT_MAPPED_PRODUCTS_START:
      return {
        ...state,
        loading: true,
      };
    case BeatsType.IMPORT_MAPPED_BEATS_START:
      return {
        ...state,
        loading: true,
      };
    case BeatsType.GET_EXCEL_FIELDS_SUCCESS:
      return {
        ...state,
        excelFields: payload,
        loading: false,
      };

    case BeatsType.IMPORT_MAPPED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BeatsType.IMPORT_MAPPED_BEATS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BeatsType.GET_EXCEL_FIELDS_FAILURE:
    case BeatsType.IMPORT_MAPPED_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case BeatsType.IMPORT_MAPPED_BEATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default excelReducers;
