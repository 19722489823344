import * as inventoryActions from "./inventory.actions";
import * as inventoryType from "./inventory.types";

import { useSelector } from "react-redux";

import axiosConfig, { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";
import moment from "moment";

export function* fetchAllFilteredProducts({ payload }: any) {
  let {
    name,
    category,
    sku,
    product_id,
    status,
    amount_greater_than,
    amount_less_than,
    page,
    limit,
  } = yield select((state) => state.inventory);
  const { type, field } = payload;
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/products/list`, {
      filters: {
        name,
        sku,
        product_id,
        categories: category,
        status,
        amount_greater_than: Number(amount_greater_than),
        amount_less_than: Number(amount_less_than),
      },
      pages: {
        page,
        limit,
      },
      sorting: [{
        type,
        field
      }]
    });
    yield put(inventoryActions.fetchAllFilteredProductsSuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchAllFilteredProductsFail(error));
  }
}

export function* fetchAllTenantProductsAsync({ payload }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/products/list`, {
      filters: {
        name:"",
        sku:"",
        product_id:[],
        categories:[],
        status:true,
        amount_greater_than: null,
        amount_less_than: null,
      },
      // pages: {
      //   page:1,
      //   limit: 169,
      // },
      // sorting: {
      //   type,
      //   field
      // }
    });
    yield put(inventoryActions.fetchAllTenantProductsSuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchAllTenantProductsFail(error));
  }
}

// export function* fetchAllItemsAsync({ payload }: any) {
//   try {
//     console.log("Payload of Orders---->", payload);
//     const { data } = yield tenantInstance.post(`/tenants/products/list`, {
//       filters: {
//         name:"",
//         sku:"",
//         product_id:[],
//         categories:[],
//         status:true,
//         amount_greater_than: null,
//         amount_less_than: null,
//       },
//       pages: {
//         page:2,
//         limit:20
//       },
//     });
//     yield put(inventoryActions.fetchAllItemsSuccess(data));
//   } catch (error) {
//     yield put(inventoryActions.fetchAllItemsFail(error));
//   }
// }
export function* createOrUpdateProductAsync({
  payload: { formData, cb },
}: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/products/create-update`,
      {
        ...formData,
      }
    );
    yield put(
      openAlert(
        `Product ${formData?.id ? "Updated" : "Added"} Successfully`,
        "success"
      )
    );

    yield put(inventoryActions.createOrUpdateProductSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(inventoryActions.createOrUpdateProductFail(error));
    // console.log("error", error?.response?.data?.detail);
    yield put(
      openAlert(
        `${
          error?.response?.data?.detail
            ? error?.response?.data?.detail
            : "Failed to create product"
        }`,
        "error"
      )
    );
  }
}

export function* fetchAllFilteredProductsCategoryAsync({ payload }: any) {
  let { categoryPage, categoryLimit ,name,order, } = yield select(
    (state) => state.inventory
  );
  const { field, type } = payload;
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/products/category/list`,
      {
        filters: {
          name,
          description: "",
          order: order?Number(order):0,
        },
        pages: {
          page: categoryPage,
          limit: categoryLimit,
        },
        sorting:[ 
          {
            type,
            field
          }
        ]
      }
    );
    yield put(inventoryActions.fetchAllFilteredProductsCategorySuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchAllFilteredProductsCategoryFail(error));
  }
}

export function* fetchAllTenantCategoriesAsync({ payload }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/products/category/list`,
      {
        filters: {
          name: "",
          description: "",
          order: 0,
        },
      }
    );
    yield put(inventoryActions.fetchAllTenantCategoriesSuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchAllTenantCategoriesFail(error));
  }
}

export function* deleteProductAsync({ payload: { formData, cb } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.delete(`/tenants/products/delete`, {
      data: {
        ...formData,
      },
    });
    yield put(openAlert("Product Deleted Successfully", "success"));

    yield put(inventoryActions.productDeleteSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(inventoryActions.productDeleteStartFail(error));
    yield put(openAlert(`Failed to delete product`, "error"));
    if (cb) {
      yield cb();
    }
  }
}

export function* fetchProductDetailsAsync({ payload: { id } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/products/get`, {
      id,
    });

    yield put(inventoryActions.fetchProductDetailsSuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchProductDetailsFail(error));
  }
}

export function* createOrUpdateCategoryAsync({
  payload: { formData, cb },
}: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/products/category/create-update`,
      {
        ...formData,
      }
    );
    yield put(
      openAlert(
        `Category ${formData?.id ? "Updated" : "Added"} Successfully`,
        "success"
      )
    );

    yield put(inventoryActions.createOrUpdateCategorySuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(inventoryActions.createOrUpdateCategoryFail(error));
    yield put(
      openAlert(
        `${
          error?.response?.data?.detail
            ? error?.response?.data?.detail
            : "Failed to create category"
        }`,
        "error"
      )
    );
  }
}

export function* deleteCategoryAsync({ payload: { formData, cb } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.delete(
      `/tenants/products/category/delete`,
      {
        data: {
          ...formData,
        },
      }
    );
    yield put(openAlert("Category Deleted Successfully", "success"));

    yield put(inventoryActions.categoryDeleteSuccess(data));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(inventoryActions.categoryDeleteStartFail(error));
    yield put(openAlert(`Failed to delete category`, "error"));
    if (cb) {
      yield cb();
    }
  }
}

export function* fetchCategoryDetailsAsync({ payload: { id } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(
      `/tenants/products/category/get`,
      {
        id,
      }
    );

    yield put(inventoryActions.fetchSoloCategorySuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchSoloCategoryFail(error));
  }
}

export function* onChangeApplyProductFilterAsync({ payload }: any) {
  try {
    // Fetch product data from the API
    const { data } = yield tenantInstance.post(`/tenants/products/list`, {
      ...payload,
    });
    yield put(inventoryActions.fetchAllFilteredProductsSuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchAllFilteredProductsFail(error));
  }
}
export function* onChangeApplyProductClearFilterAsync({ payload }: any) {
  try {
    // Fetch product data from the API
    const { data } = yield tenantInstance.post(`/tenants/products/list`, {
      ...payload,
    });
    yield put(inventoryActions.fetchAllFilteredProductsSuccess(data));
  } catch (error) {
    yield put(inventoryActions.fetchAllFilteredProductsFail(error));
  }
}

// export function* onCreateOrderAsync({ payload: { formData, cb} }: any){
//   try{
//     const {data} = yield tenantInstance.post(`/tenants/order/create`, {
//       ...formData,
//     });
//     yield put(inventoryActions.onCreateOderSuccess(data));
//     if (cb) {
//       yield cb();
//     }
//   }
//   catch(error){
//     yield put(inventoryActions.onCreateOderFail(error));
//   }
// }
// export function* onCreateOrderAsync({ payload}: any){
//   let { product_id, quantity} = yield select((state) => state.inventory);

//   const systemDate = moment().toISOString(); 
//   try{
//     const {data} = yield tenantInstance.post(`/tenants/order/create`, {
//       system_date: systemDate,
//       product_id:[],
//       quantity,
//       // products: [
//       //   product_id,
//       //   quantity,
//       // ]
//     });
//     yield put(inventoryActions.onCreateOderSuccess(data));
    
//   }
//   catch(error){
//     yield put(inventoryActions.onCreateOderFail(error));
//   }
// }

export function* watchFetchAllFilteredProducts() {
  yield takeLatest(
    inventoryType.GET_FILTERED_PRODUCTS_BY_TENANT_START,
    fetchAllFilteredProducts
  );
}

export function* watchFetchAllTenantProductsAsync() {
  yield takeLatest(
    inventoryType.GET_TENANTS_PRODUCTS_LIST_START,
    fetchAllTenantProductsAsync
  );
}

//
// export function* watchFetchAllItemsAsync(){
//   yield takeLatest(
//     inventoryType.FETCH_ALL_ITEMS_SUCCESS,
//     fetchAllItemsAsync
//   )
// }
//

export function* watchCreateOrUpdateProductAsync() {
  yield takeLatest(
    inventoryType.CREATE_OR_UPDATE_PRODUCT_START,
    createOrUpdateProductAsync
  );
}

export function* watchFetchAllFilteredProductsCategoryAsync() {
  yield takeLatest(
    inventoryType.FETCH_FILTERED_PRODUCTS_CATEGORY_START,
    fetchAllFilteredProductsCategoryAsync
  );
}

export function* watchFetchAllTenantCategoriesAsync() {
  yield takeLatest(
    inventoryType.GET_TENANTS_CATEGORIES_LIST_START,
    fetchAllTenantCategoriesAsync
  );
}

export function* watchDeleteProductAsync() {
  yield takeLatest(inventoryType.DELETE_PRODUCT_START, deleteProductAsync);
}

export function* watchFetchProductDetailsAsync() {
  yield takeLatest(
    inventoryType.FETCH_PRODUCT_DETAILS_START,
    fetchProductDetailsAsync
  );
}

export function* watchCreateOrUpdateCategoryAsync() {
  yield takeLatest(
    inventoryType.CREATE_OR_UPDATE_CATEGORY_START,
    createOrUpdateCategoryAsync
  );
}

export function* watchDeleteCategoryAsync() {
  yield takeLatest(inventoryType.DELETE_CATEGORY_START, deleteCategoryAsync);
}

export function* watchFetchCategoryDetailsAsync() {
  yield takeLatest(
    inventoryType.FETCH_SOLO_CATEGORY_START,
    fetchCategoryDetailsAsync
  );
}
// export function* watchOnCreateOrderAsync(){
//   yield takeLatest(
//     inventoryType.CREATE_ORDER_START,
//     onCreateOrderAsync
//   );
// }
export function* inventorySagas() {
  yield all([
    call(watchFetchAllFilteredProducts),
    call(watchFetchAllTenantProductsAsync),
    call(watchCreateOrUpdateProductAsync),
    call(watchFetchAllFilteredProductsCategoryAsync),
    call(watchFetchAllTenantCategoriesAsync),
    call(watchDeleteProductAsync),
    call(watchFetchProductDetailsAsync),
    call(watchCreateOrUpdateCategoryAsync),
    call(watchDeleteCategoryAsync),
    call(watchFetchCategoryDetailsAsync),
    // call(watchFetchAllItemsAsync),
    // call(watchOnCreateOrderAsync),
  ]);
}
