import * as InventoryTypes from "./inventory.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  filtered_products: [],
  filtered_categories: [],
  pageDetails: null,
  categoryPageDetails: null,
  products: [],
  categories: [],
  soloProduct: {},
  soloCategory: {},
  error: null,
  loading: false,
  name: "",
  sku: "",
  product_id: [],
  category: [],
  status: true,
  amount_greater_than: 0,
  amount_less_than: 0,
  order:0,
  page: 1,
  limit: 10,
  categoryPage: 1,
  categoryLimit: 10,
  // sorting: {
  //   type: "asc", 
  //   field: ""    
  // },
};

// Reducer function responsible for managing the authentication state
const inventoryReducer = (state = INITIAL_STATE, action) => {
 
  const { type, payload } = action;
  // console.log("Payload Inventory---->", payload);
  switch (type) {
    case InventoryTypes.GET_FILTERED_PRODUCTS_BY_TENANT_START:
    case InventoryTypes.GET_TENANTS_PRODUCTS_LIST_START:
    case InventoryTypes.CREATE_OR_UPDATE_PRODUCT_START:
    case InventoryTypes.FETCH_FILTERED_PRODUCTS_CATEGORY_START:
    case InventoryTypes.GET_TENANTS_CATEGORIES_LIST_START:
    case InventoryTypes.DELETE_PRODUCT_START:
    case InventoryTypes.FETCH_PRODUCT_DETAILS_START:
    case InventoryTypes.CREATE_OR_UPDATE_CATEGORY_START:
    case InventoryTypes.DELETE_CATEGORY_START:
    case InventoryTypes.FETCH_SOLO_CATEGORY_START:
    case InventoryTypes.FETCH_ALL_ITEMS_START:
    // case InventoryTypes.CREATE_ORDER_START:
      return {
        ...state,
        loading: true,
      };

    case InventoryTypes.GET_FILTERED_PRODUCTS_BY_TENANT_SUCCESS:
      return {
        ...state,
        filtered_products: payload?.data?.products,
        pageDetails: payload?.data?.page_details,
        loading: false,
      };

    case InventoryTypes.GET_TENANTS_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        filtered_products: payload?.data?.products,
        pageDetails: payload?.data?.page_details,
        loading: false,
      };
    case InventoryTypes.FETCH_ALL_ITEMS_SUCCESS:
      return{
        ...state,
        filtered_products: payload?.data?.products,
        loading: false,
      }
    case InventoryTypes.CREATE_OR_UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case InventoryTypes.FETCH_FILTERED_PRODUCTS_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        filtered_categories: payload?.data?.categories,
        categoryPageDetails: payload?.data?.page_details,
      };

    case InventoryTypes.GET_TENANTS_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categories: payload?.data?.categories,
        loading: false,
      };

    case InventoryTypes.DELETE_PRODUCT_SUCCESS:
      const updatedProducts = state.filtered_products.filter(
        (p) => p.id !== payload?.data?.success[0]
      );
      return {
        ...state,
        loading: false,
        filtered_products: updatedProducts,
      };

    case InventoryTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        soloProduct: payload?.data,
      };

    case InventoryTypes.CREATE_OR_UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    //
    // case InventoryTypes.CREATE_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     products: payload?.data?.products,
    //     loading: false,
    //   }
    //
    case InventoryTypes.DELETE_CATEGORY_SUCCESS:
      const updatedCategories = state.filtered_categories.filter(
        (c) => c.id !== payload?.data?.success[0]
      );
      return {
        ...state,
        loading: false,
        filtered_categories: updatedCategories,
      };

    case InventoryTypes.FETCH_SOLO_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        soloCategory: payload?.data,
      };

    case InventoryTypes.ON_CHANGE_APPLY_PRODUCT_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
      case InventoryTypes.ON_CHANGE_APPLY_PRODUCT_CLEAR_FILTER:
      
      return {
        ...state,
        ...action.payload
      };

    case InventoryTypes.ON_CHANGE_APPLY_CATEGORY_FILTER:
      const { key: cKey, value: cValue } = action.payload;
      return {
        ...state,
        [cKey]: cValue,
      };
      case InventoryTypes.ON_CHANGE_APPLY_PRODUCT_CATEGORY_CLEAR_FILTER:
      
      return {
        ...state,
        ...action.payload
      };

    case InventoryTypes.GET_FILTERED_PRODUCTS_BY_TENANT_FAILURE:
    case InventoryTypes.GET_TENANTS_PRODUCTS_LIST_FAILURE:
    case InventoryTypes.CREATE_OR_UPDATE_PRODUCT_FAILURE:
    case InventoryTypes.DELETE_PRODUCT_FAILURE:
    case InventoryTypes.FETCH_PRODUCT_DETAILS_FAILURE:
    case InventoryTypes.GET_TENANTS_CATEGORIES_LIST_FAILURE:
    case InventoryTypes.FETCH_FILTERED_PRODUCTS_CATEGORY_FAILURE:
    case InventoryTypes.CREATE_OR_UPDATE_CATEGORY_FAILURE:
    case InventoryTypes.DELETE_CATEGORY_FAILURE:
    case InventoryTypes.FETCH_SOLO_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default inventoryReducer;
