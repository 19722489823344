import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Admin from "../../layouts/Admin";
import TraderComponent from "../../views/Admin/Traders";

// =================== Order Page ================= //
const Traders = () => {
  return <WithLayout layout={Admin} component={TraderComponent} />;
};

export default Traders;
