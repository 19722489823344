import React, { Fragment, useState } from 'react';
import container from './ReportByBeats.container';
import { Button, ButtonGroup, Paper, Typography, Box, 
    Popover, Grid, RadioGroup, FormControlLabel, Radio, FormControl, Select } from '@mui/material';
import { EnhancedTableToolbar } from '../../../components/CustomTable';
import moment from 'moment';
import InputLabel from "@mui/material/InputLabel";
import FilterIcon from "../../../assets/images/filter.svg"
import { CustomPagination } from '../../../components/CustomTable/CustomTable';
import { useDispatch } from 'react-redux';
import HeadColumn from './components/HeadColumn';
import Calendar from './components/Calendar';
import { searchReportBeatName } from "../../../store/reportbybeats/reportByBeats.action";
import CustomButton from '../../../components/CustomButton';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ReportByBeatsComp = ({reportByBeats: { reportByBeatsData, beatsPageDetails, monthTotalSales, grandTotalSales}
    , onGetReportByBeatsListStart,}) => {

    const currentDate = moment().format("YYYY-MM-DD");
    const monthStartDate = moment(currentDate).startOf('month').format("YYYY-MM-DD");
    const monthEndDate = moment(currentDate).endOf('month').format("YYYY-MM-DD");
    
    const [selectedButton, setSelectedButton] = useState('');
    const [fromDate, setFromDate] = React.useState(monthStartDate);
    const [toDate, setToDate] = React.useState(monthEndDate); 

    const [selectedStartDateMonth, setSelectedStartDate] = useState(null);
    const [selectedEndDateMonth, setSelectedEndDate] = useState(null);


    const [rowsPerPage, setRowsPerPage] = React.useState(beatsPageDetails?.limit || 10);
    const [activePage, setActivePage] = React.useState<any>(1);
    const [noOfPages, setNoOfPages] = React.useState([]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedFilter, setSelectedFilter] = useState('date-wise');

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("id");

    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);
    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };

    const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#008E8E", 
        },
    ]);

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
        setCustomDateLabel(
          `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
            'MM/DD/YYYY'
          )}`
        );
    };

    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    };
    
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const generateHeadCellsForDateRange = () => {
        const startDate = moment(fromDate);
        const endDate = moment(toDate);
        const daysInDateRange = endDate.diff(startDate, 'days') + 1;

        return Array.from({ length: daysInDateRange }, (_, index) => {
            const day = startDate.clone().add(index, 'days').format('YYYY-MM-DD');
            return {
                id: day,
                numeric: false,
                disablePadding: true,
                label: day,
                isShow: true,
            };
        });
    };

    const headCellsForDateRange = generateHeadCellsForDateRange(); 
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // onGetReportByBeatsListStart('limit', Number(event.target.value));
        onGetReportByBeatsListStart(fromDate, toDate, activePage, event.target.value, 'asc', 'name', selectedFilter);
    }
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPage = Number(event.target.value);
        setActivePage(newPage);
        dispatch(onGetReportByBeatsListStart(fromDate, toDate, newPage, beatsPageDetails?.limit, 'asc', 'name', selectedFilter));
        // dispatch(onGetReportByBeatsListStart("page", event.target.value));
        // setActivePage(Number(event.target.value));
    }
    const showPrevPage = () => {
        const nextPage = activePage - 1;
        // dispatch(onGetReportByBeatsListStart(fromDate, toDate, nextPage, beatsPageDetails?.limit, 'asc', 'name'));
        onGetReportByBeatsListStart(fromDate, toDate, nextPage, beatsPageDetails?.limit, 'asc', 'name', selectedFilter)
        setActivePage(activePage - 1);
    }
    const showNextPage = () => {
        const nextPage = activePage + 1;
        // dispatch(onGetReportByBeatsListStart(fromDate, toDate, nextPage, beatsPageDetails?.limit, 'asc', 'name'));
        onGetReportByBeatsListStart(fromDate, toDate, nextPage, beatsPageDetails?.limit, 'asc', 'name', selectedFilter)
        setActivePage(activePage + 1);
    }
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleRadioChange = (event) => { 
        const selectedValue = event.target.value;
        setSelectedFilter(selectedValue);
    };

    const handleSearchChange = (e) => {
        dispatch(searchReportBeatName("name", e));
        onGetReportByBeatsListStart(fromDate, toDate, 1, 10, 'asc', 'name', selectedFilter);
    };

    const handleFilter = () => {
        handleClose();
        if(selectedFilter === 'date-wise'){
            if (dateRange.length === 1) {
                const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
                const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
                onGetReportByBeatsListStart(selectedStartDate, selectedEndDate, 1, 10, 'asc', 'name', selectedFilter);
                setFromDate(selectedStartDate);
                setToDate(selectedEndDate);
                setSelectedStartDate(null);
                setSelectedEndDate(null);
            }
        }
        else{
            let formattedStartDate = moment(selectedStartDateMonth).startOf('month').format("YYYY-MM-DD");
            let formattedEndDate = moment(selectedEndDateMonth).endOf('month').format("YYYY-MM-DD");

            if (moment(formattedStartDate).isAfter(formattedEndDate)) {
              [formattedStartDate, formattedEndDate] = [formattedEndDate, formattedStartDate];
            }
            setFromDate(formattedStartDate);
            setToDate(formattedEndDate);
            setCustomDateLabel('Custom Date Selection');
            onGetReportByBeatsListStart(formattedStartDate, formattedEndDate, 1, 10, 'asc', 'name', selectedFilter);
          }
        // setSelectedFilter('');
        setActivePage(1);
    };
    const handleClearFilter = () => {
        setSelectedFilter(''); 
        setDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: "#008E8E", 
          },
        ]);
        setFromDate(monthStartDate);
        setToDate(monthEndDate);
        setActivePage(1);
        setCustomDateLabel('Custom Date Selection');
        onGetReportByBeatsListStart(monthStartDate, monthEndDate, 1, 10, 'asc', 'name', selectedFilter);
        setSelectedButton('today');
    };
    
    React.useEffect(() => {
      const calculatePages = () => {
        const totalPages = Math.ceil(beatsPageDetails?.total / beatsPageDetails?.limit);
        const newPages = [];
        for (let i = 1; i <= totalPages; i++) {
          newPages.push(i);
        }
        setNoOfPages(newPages);
      };
          calculatePages();
    }, [beatsPageDetails?.total, beatsPageDetails?.limit]);
   
    return(
        // <div>This is Report By Beats </div> Reports By Beats 
        <Fragment>
            <Paper sx={{ width: "100%", }}>
            <EnhancedTableToolbar 
                numSelected={0}
                handleChange={handleSearchChange}
                tableHeaderStartComps={ 
                    <Box sx={{display: "flex"}}>
                        <ButtonGroup
                            color="secondary"
                            size="small"
                            sx={{
                              "& > button": {
                                textTransform: "none",
                              },
                            }}
                            >
                            <Button 
                            variant={selectedButton === 'today' ? 'contained' : 'outlined'}
                            sx={{ color: selectedButton === 'today' ? '#fff' : '#8B8D97' }}
                                onClick={() => {
                                  const today = moment().format("YYYY-MM-DD");
                                  setFromDate(today);
                                  setToDate(today);
                                  onGetReportByBeatsListStart(today, today, beatsPageDetails?.page, beatsPageDetails?.limit, 'name', 'asc', selectedFilter);
                                  setSelectedButton('today')}}
                                >
                              Today
                        </Button>
                        <Button 
                            variant={selectedButton === 'yesterday' ? 'contained' : 'outlined'}
                            sx={{ color: selectedButton === 'yesterday' ? '#fff' : '#8B8D97' }} 
                            onClick={() => {
                            const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
                            setFromDate(yesterday);
                            setToDate(yesterday);
                            onGetReportByBeatsListStart(yesterday, yesterday, beatsPageDetails?.page , beatsPageDetails?.limit ,'name', 'asc', selectedFilter);
                            setSelectedButton('yesterday')
                        }}>Yesterday</Button>
                        </ButtonGroup>
                        <Typography variant = "h5" sx={{ ml: 2, mt: 1 }}>Date: {fromDate} to {toDate}</Typography>
                    </Box>
                }
                tableHeaderComps = {
                    <>
                        <Box 
                            sx={{
                                width: "89px",
                                height: "29px",
                                padding: "18px 10px",
                                borderRadius: "4px",
                                border: "1px solid #45464E",
                                display: "flex",
                                alignItems: "center",
                                gap: "9px",
                                cursor: "pointer",
                                }}
                                onClick={handleClick}
                            >
                            <img src={FilterIcon} alt="filter" />
                            <Typography>Filter</Typography>
                        </Box>
                        <Popover 
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            >
                            <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                          Filter
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs = {12}>
                                    <RadioGroup 
                                        aria-label="filter-options"
                                        name="filter-options"
                                        value={selectedFilter}
                                        onChange={handleRadioChange}
                                    >    
                                        <Grid item xs={12}>
                                            <FormControlLabel value="date-wise" control={<Radio />} label="CustomRange Selection" /> 
                                            {selectedFilter === 'date-wise' && (
                                                <>
                                                <Typography
                                                  sx={{ 
                                                    ml: 2, mt: 1,
                                                    width: "185px",
                                                    height: "29px",
                                                    padding: "18px 10px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #45464E",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "9px",
                                                    cursor: "pointer",
                                                 }}
                                                  onClick={handleCustomDatePopoverOpen}
                                                >
                                                  {customDateLabel}
                                                </Typography>

                                                <Popover
                                                open={customDatePopoverOpen}
                                                anchorEl={anchorEl}
                                                onClose={handleCustomDatePopoverClose}
                                                anchorOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                              >
                                                <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                                <IconButton
                                                  aria-label="close"
                                                  onClick={handleCustomDatePopoverClose}
                                                  sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) => theme.palette.grey[500],
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                                  <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                                    <DateRangePicker
                                                      ranges={dateRange}
                                                      onChange={handleSelect}
                                                      showSelectionPreview={true}
                                                      moveRangeOnFirstSelection={false}
                                                      months={2}
                                                      direction="vertical"
                                                      showDateDisplay={false}
                                                      editableDateInputs={false}
                                                    />
                                                  </div>    
                                                </Box>
                                              </Popover>
                                              </>
                                            )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControlLabel value="month-wise" control={<Radio />} label="Month Comparison" /> 
                                                {selectedFilter === 'month-wise' && (
                                                    <>
                                                    <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                                      <Typography sx={{fontSize: "14px"}}>Start Month</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                              views={["month", "year"]}
                                                              sx={{ width: 140 }}
                                                              value={selectedStartDateMonth}
                                                              slotProps={{ textField: { size: "small" } }}
                                                              onChange={(date) => setSelectedStartDate(date)}
                                                            /> 
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                                    <Typography sx={{fontSize: "14px"}}>End Month</Typography>
                                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                                          <DatePicker
                                                            views={["month", "year"]}
                                                            sx={{ width: 140 }}
                                                            value={selectedEndDateMonth}
                                                            slotProps={{ textField: { size: "small" } }}
                                                            onChange={(date) => setSelectedEndDate(date)}
                                                          />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                            </RadioGroup>
                                            <Grid item xs = {12}>
                                                <CustomButton
                                                    variant = "outlined"
                                                    name="Clear Filter"
                                                    handleClick={handleClearFilter}
                                                    sx={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} mt={"5px"}>
                                                <CustomButton
                                                  name="Filter"
                                                  handleClick={handleFilter}
                                                  sx={{ width: "100%" }}
                                                />
                                            </Grid>
                                        </Grid>
                            </Box>
                        </Popover>
                    </>
                }
                />
            </Paper>
            <Grid container>
                <Grid item xs={5} md={5}>
                    <HeadColumn 
                        fromDate = {fromDate}
                        toDate = {toDate}
                        onGetReportByBeatsListStart = {onGetReportByBeatsListStart}
                        reportByBeats = {{ reportByBeatsData, beatsPageDetails, grandTotalSales}}
                        flag = {selectedFilter}
                    />
                </Grid>
                <Grid item xs={7} md={7}>
                    <Calendar 
                        fromDate = {fromDate}
                        toDate = {toDate}
                        onGetReportByBeatsListStart = {onGetReportByBeatsListStart}
                        reportByBeats = {{ reportByBeatsData, beatsPageDetails, monthTotalSales}}
                        flag = {selectedFilter}
                    />
                </Grid>
            </Grid>

            <Paper sx={{ width: "100%",}}>
                <CustomPagination
                    count={beatsPageDetails?.total}
                    rowsPerPage={rowsPerPage}
                    page={activePage - 1}
                    handleChangePage={() => {}}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handlePageChange={handlePageChange}
                    pageNo={activePage}
                    noOfPages={noOfPages}
                    activePage={activePage}
                    showPrevPage={showPrevPage}
                    showNextPage={showNextPage}
                />
            </Paper>
        </Fragment>
    )
}

export default container(ReportByBeatsComp);