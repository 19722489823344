import React, { useEffect } from "react";

// Mui imports
import { Box } from "@mui/material";

// Thir party library
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// Icon imports
import LoadingIcon from "../assets/images/loading.svg";
import LoaderIcon from "../assets/images/loader.svg";
import _ from "lodash";

// ================ Protected Route Components ============================= //
const ProtectedRoute = ({ isAuthenticated, authLoading, tenantProfile }) => {
  // Check if user is authenticated or not. If not then redirects to login page

  const location = useLocation();
  const activeSlug = location.pathname;

  const fallbackPath = "login";

  // useEffect(() => {
  //   localStorage.setItem("lastVisitedPath", activeSlug);
  // }, [activeSlug]);


  if (authLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <img src={LoaderIcon} alt="Loading...." />
      </Box>
    );
  }

  const checkHasAcess = (slug: any): any => {
    switch (slug) {
      case "/manage/users":
        return "user.read";
      case "/manage/traders":
        return "traders.read";
      case "/manage/beats":
        return "beat.read";
      case "/manage/orders":
        return "order.read";
      case "/manage/tour-plan":
        return "tour_program.read";
      default:
        return "";
    }
  };

  const requiredPermission = checkHasAcess(activeSlug);

  const hasPermission = _.isEmpty(requiredPermission)
    ? true
    : tenantProfile?.role_details?.permissions_details?.some(
        (permission: any) => permission.module === requiredPermission
      );

  // console.log("Permission", isAuthenticated, hasPermission);

  if (isAuthenticated && hasPermission) {
    return <Outlet />;
  }

  return isAuthenticated && hasPermission ? (
    <Outlet />
  ) : (
    // <Navigate to={fallbackPath} replace />
    // <Navigate to={localStorage.getItem("lastVisitedPath") || fallbackPath} replace />
    // tenantProfile
    <Navigate to={tenantProfile && localStorage.getItem("tenant_details") != null ? localStorage.getItem("lastVisitedPath") : fallbackPath} replace />
  );
};

export default ProtectedRoute;
