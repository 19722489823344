import * as ExcelType from "./excel.types";

// get excel field by slug
export const getExcelFieldsStart = (slug) => ({
  type: ExcelType.GET_EXCEL_FIELDS_START,
  payload: { slug },
});

export const getExcelFieldsSuccess = (result) => ({
  type: ExcelType.GET_EXCEL_FIELDS_SUCCESS,
  payload: result,
});

export const getExcelFieldsFail = (error) => ({
  type: ExcelType.GET_EXCEL_FIELDS_FAILURE,
  payload: error,
});

// Import mapped product
export const importMappedProductsStart = (formData, cb) => ({
  type: ExcelType.IMPORT_MAPPED_PRODUCTS_START,
  payload: { formData, cb },
});

export const importMappedProductsSuccess = (result) => ({
  type: ExcelType.IMPORT_MAPPED_PRODUCTS_SUCCESS,
  payload: result,
});

export const importMappedProductsFail = (error) => ({
  type: ExcelType.IMPORT_MAPPED_PRODUCTS_FAILURE,
  payload: error,
});

// Import mapped Beats
export const importMappedBeatsStart = (formData, cb) => ({
  type: ExcelType.IMPORT_MAPPED_BEATS_START,
  payload: { formData, cb },
});

export const importMappedBeatsSuccess = (result) => ({
  type: ExcelType.IMPORT_MAPPED_BEATS_SUCCESS,
  payload: result,
});

export const importMappedBeatsFail = (error) => ({
  type: ExcelType.IMPORT_MAPPED_BEATS_FAILURE,
  payload: error,
});

// Import mapped Beats
export const importMappedUserStart = (formData, cb) => ({
  type: ExcelType.IMPORT_MAPPED_USERS_START,
  payload: { formData, cb },
});

export const importMappedUserSuccess = (result) => ({
  type: ExcelType.IMPORT_MAPPED_USERS_SUCCESS,
  payload: result,
});

export const importMappedUserFail = (error) => ({
  type: ExcelType.IMPORT_MAPPED_USERS_FAILURE,
  payload: error,
});