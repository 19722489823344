import * as invitationActions from "./invitation.actions";
import * as invitationType from "./invitation.types";

import axiosConfig, { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { openAlert } from "../alert/alert.actions";

export function* addInvitationAsync({ payload: { formData, cb } }: any) {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.put(`/invitations/create`, [
      formData,
    ]);

    yield put(invitationActions.addInvitationSuccess(data));
    yield put(openAlert("Invitation Added Successfully", "success"));
    if (cb) {
      yield cb();
    }
  } catch (error) {
    yield put(invitationActions.addInvitationFail(error));
    yield put(openAlert("Failed to add invitation", "error"));
    if (cb) {
      yield cb();
    }
  }
}

export function* listInvitationAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.get(`/invitations/list`);

    yield put(invitationActions.listInvitationSuccess(data));
  } catch (error) {
    yield put(invitationActions.listInvitationFail(error));
    yield put(openAlert("Error fetching invitation", "error"));
  }
}

export function* acceptInvitationAsync() {
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.put(`/invitations/accept`);

    yield put(invitationActions.acceptInvitationSuccess(data));
    yield put(openAlert("Invitation accepted succesfully", "success"));
  } catch (error) {
    yield put(invitationActions.acceptInvitationFail(error));
    yield put(openAlert("Failed to accept invitation", "error"));
  }
}

export function* watchAddInvitation() {
  yield takeLatest(invitationType.ADD_INVITATION_START, addInvitationAsync);
}

export function* watchListInvitation() {
  yield takeLatest(invitationType.LIST_INVITATION_START, listInvitationAsync);
}

export function* watchAcceptInvitation() {
  yield takeLatest(
    invitationType.ACCEPT_INVITATION_START,
    acceptInvitationAsync
  );
}

export function* invitationSagas() {
  yield all([
    call(watchAddInvitation),
    call(watchListInvitation),
    call(watchAcceptInvitation),
  ]);
}
