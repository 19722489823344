// React-redux
import { connect } from "react-redux";
import {
  fetchAllFilteredUsersStart,
  fetchAllFilteredDisabledUsersStart,
  fetchSettingDataStart,
  updateUserStart,
  getReportToListStart
} from "../../../store/users/users.action";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchEnableUsersStart: (field, type) => dispatch(fetchAllFilteredUsersStart(field, type)),
  onFetchDisableUsersStart: (field, type) => dispatch(fetchAllFilteredDisabledUsersStart(field, type)),
  onFetchSettingDataStart: (salesPersonId) => dispatch(fetchSettingDataStart(salesPersonId)),
  onupdateUserStart: (value) => dispatch(updateUserStart(value)),
  onGetReportToListStart: (role) => dispatch(getReportToListStart(role)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
