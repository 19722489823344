import React, { useState } from "react";
import { Grid, Paper, Typography, Popover, Box,
FormControl, Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { Chart } from "react-google-charts";
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import CustomButton from "../../../../components/CustomButton";
import FilterIcon from "../../../../assets/images/filter.svg";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const PerformanceBarView = ({ onGetOrderListForBarStart,
  barCustomDateData: {barCustomDateData} }) => {

    const today = moment().format("YYYY-MM-DD");
    const startOfYear = moment(today).startOf('year').format("YYYY-MM-DD");

    const [selectedStartDateMonth, setSelectedStartDate] = useState(null);
    const [selectedEndDateMonth, setSelectedEndDate] = useState(null);

    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [fromDate, setFromDate] = React.useState(today);
    const [toDate, setToDate] = React.useState(today);  

    const [selectedFilter, setSelectedFilter] = useState('');

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    };

    const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  
    const handleSelect = (ranges) => {
      setDateRange([ranges.selection]);
      setCustomDateLabel(
        `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
          'MM/DD/YYYY'
        )}`
      );

    };

    const handleFilter = () => {
      handleClose();
      if (dateRange.length === 1) {
        const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
        const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
        onGetOrderListForBarStart(selectedStartDate, selectedEndDate);
        setFromDate(selectedStartDate);
        setToDate(selectedEndDate);
      }
      else{
        let formattedStartDate = moment(selectedStartDateMonth).format("YYYY-MM-DD");
        let formattedEndDate = moment(selectedEndDateMonth).format("YYYY-MM-DD");

        if (moment(formattedStartDate).isAfter(formattedEndDate)) {
          [formattedStartDate, formattedEndDate] = [formattedEndDate, formattedStartDate];
        }

        onGetOrderListForBarStart(formattedStartDate, formattedEndDate);
      }
      // setSelectedFilter('');
  };

  const handleClearFilter = () => {
    setSelectedFilter(''); 
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setFromDate(today);
    setToDate(today);

    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setCustomDateLabel('Custom Date Selection');
    onGetOrderListForBarStart(today, today);
  };
    
    const [selectedBeat, setSelectedBeat] = React.useState(null);

    const totalSales = barCustomDateData?.map((data) => {
      return data?.total_orders_value;
    });
    
    const userNamesList = barCustomDateData?.map((userName) => {
      return userName?.name;
    });

    const handleBeatChange = (event) => {
        const selectedBeatName = event.target.value;
        setSelectedBeat(selectedBeatName);
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = {
      chart: {
        title: "",
        subtitle: "",
      },
    };
  

    // const xAxisData = Array.from({ length: salesDetailsYearlyBar?.total_orders_value }, (_, index) => index + 1);

    const chartData = [
      ['x', 'Sales'],
      ...userNamesList.map((userName, index) => [userName, totalSales[index]]),
    ];

    const chartData1 = [
      ['x', 'Sales'],
      ['No Sales', 1],
    ]
    React.useEffect(() => {
      onGetOrderListForBarStart(startOfYear, today);
    }, [])
    
    return(
        <Grid item container xs={12} md={12}>
            <Paper sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px',  borderRadius: "14px" }}>
                <Grid container item md={12}>
                    <Grid md={10}>
                    <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>Best Performance</Typography>
                    </Grid>

                    <Grid md={2}>
                    <Box
                        sx={{
                        width: "89px",
                        height: "29px",
                        padding: "18px 10px",
                        borderRadius: "4px",
                        border: "1px solid #45464E",
                        display: "flex",
                        alignItems: "center",
                        gap: "9px",
                        cursor: "pointer",
                        }}
                      onClick={handleClick}
                        >
                        <img src={FilterIcon} alt="filter" />
                        <Typography>Filter</Typography>
                    </Box>
                    <Popover 
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}>
                        
                        <Box sx={{ padding: "24px 21px", width: "100%", maxWidth: 300,  }}>
                          <Grid container spacing={4}>
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                  <Typography variant="h3">
                                    Filter
                                  </Typography>
                              </Grid>
                              <Grid item xs = {12}>
                                <Grid item xs = {12}>
                                  <FormControl sx={{ m: 1, minWidth: 190 }} size="small">
                                    <InputLabel id="demo-select-small-label">
                                      {" "}
                                      <Typography sx={{ color: "#1C1D22" }}>{customDateLabel}</Typography>
                                    </InputLabel>
                                    <Select
                                      labelId="demo-select-small-label"
                                      id="demo-select-small"
                                      value={action}
                                      label="Bulk Action"
                                      onChange={handleChange}
                                    >
                                      <div style={{ fontFamily: 'Roboto, sans-serif', marginTop: 16,}}>
                                        <DateRangePicker
                                          ranges={dateRange}
                                          onChange={handleSelect}
                                          showSelectionPreview={true}
                                          moveRangeOnFirstSelection={false}
                                          months={2}
                                          direction="vertical"
                                          showDateDisplay={false}
                                          editableDateInputs={false}
                                        />
                                      </div>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography sx={{ color: "#1C1D22" }}>Month Comparison</Typography>
                                  <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                    <Typography sx={{fontSize: "14px"}}>Start Month</Typography>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <DatePicker
                                            views={["month"]}
                                            sx={{ width: 140 }}
                                            value={selectedStartDateMonth}
                                            slotProps={{ textField: { size: "small" } }}
                                            onChange={(date) => setSelectedStartDate(date)}
                                          /> 
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs = {12} sx={{mb: "6px", mt: "10px"}}>
                                    <Typography sx={{fontSize: "14px"}}>End Month</Typography>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <DatePicker
                                            views={["month"]}
                                            sx={{ width: 140 }}
                                            value={selectedEndDateMonth}
                                            slotProps={{ textField: { size: "small" } }}
                                            onChange={(date) => setSelectedEndDate(date)}
                                          />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <CustomButton
                                  variant = "outlined"
                                  name="Clear Filter"
                                  handleClick={handleClearFilter}
                                  sx={{ width: '100%' }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomButton
                                  name="Filter"
                                  handleClick={handleFilter}
                                  sx={{ width: "100%" }}
                                />
                               </Grid>                                        
                            </Grid>
                          </Grid>
                        </Box>
                      </Popover>
                    </Grid>
                </Grid>

                <Grid item md={12} sx={{mt: "10px"}}>
                  {barCustomDateData.length > 0 ? (
                    <Chart
                    chartType="Bar"
                    data={chartData}
                    options={options}
                    />
                  ): (
                      <Chart
                        chartType="Bar"
                        data={chartData1}
                        options={options}
                      />
                  )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default PerformanceBarView;