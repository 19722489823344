import React, { useEffect } from "react";

// Material UI
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Switch,
  Typography,
  styled,
  TextField,
  createTheme,
  ThemeProvider,
  InputAdornment,
  Select
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// Formik imports
import { Field, Form, Formik } from "formik";
// import { Select} from "formik-mui";

// Third party library
import { useNavigate, useParams } from "react-router-dom";

// Project imports
import CustomButton from "../../../components/CustomButton";


// Config imports
import { tenantInstance } from "../../../configs/axiosConfig";
import { TextFields } from "@mui/icons-material";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useLocation } from 'react-router-dom';
import container from "./UsersProfile.container";
import { on } from "events";
import dayjs from "dayjs";

const UserProfile = ({ onloadUserBasedOnTenantStart, auth: { currentUser, tenantProfile, selectedTenant }, onupdateUserStart }) => {
  const imageFileData = tenantProfile["profile_image"];
  console.log(tenantProfile)
  let imageUrl;

  try {
    const byteCharacters = atob(imageFileData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" });
    imageUrl = URL.createObjectURL(blob);
    console.log(imageUrl);

    // Continue with further processing or usage of the blob
  } catch (error) {
    // Handle the error
    console.error("File decoding error:", error);
  }

  React.useEffect(() => {
    onloadUserBasedOnTenantStart()

  }, []);
  const [isEdit, setIsEdit] = React.useState(false)


  const handleEdit = () => {
    setIsEdit(true);
  };
  const navigate = useNavigate();
  const handleSave = () => {
    setIsEdit(false);
    const formattedDob = dayjs(formValues.dob).format('YYYY-MM-DD');

    onupdateUserStart({
      id: formValues.id,
      full_name: formValues.name,
      dob: formattedDob,
      email: formValues.email,
      mobile: formValues.mobile,
      org: selectedTenant?.name,
      // notification: formValues.notification,
      // attendance: formValues.attendance
    }, () => {
      // Callback function after successful save and page reload
      window.location.reload(); // Refresh the page after saving
      navigate("/manage/user-profile");
    });
  };

  const [selectedOption, setSelectedOption] = React.useState('');
  // State to hold the form values

  const [formValues, setFormValues] = React.useState({
    id: tenantProfile?.id,
    name: tenantProfile?.name,
    dob: tenantProfile?.dob,
    email: tenantProfile?.email,
    mobile: tenantProfile?.mobile,
    // notification: tenantProfile?.notification,
    // attendance: tenantProfile?.attendance
  });
  // Update form values when the tenantProfile prop changes (on component mount)
  React.useEffect(() => {

    setFormValues({
      id: tenantProfile?.id,
      name: tenantProfile?.name,
      dob: tenantProfile?.dob,
      email: tenantProfile?.email,
      mobile: tenantProfile?.mobile,
      // notification: tenantProfile?.notification,
      // attendance: tenantProfile?.attendance
    });
  }, [tenantProfile]);
  // Handle changes in the form fields
  const [action, setAction] = React.useState("");
  const handleChange = (event) => {
    setSelectedOption(event.target.value as string);
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          margin: "20px 0",
          gap: { xs: 1 },
        }}
      >
        <Typography variant="h3">My Profile</Typography>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, sm: 2 },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
        </Box>
      </Box>
      <Grid container >

        <Box
          sx={{
            background: (theme) => theme.palette.background.paper,
            height: "auto",
            width: "928px",
            padding: (theme) => theme.spacing(2),
            paddingBottom: "65px",
            borderRadius: "9px"
          }}
        >
          {!isEdit && <CustomButton name="Edit" type="submit" handleClick={handleEdit} variant="outlined" sx={{ color: '#05ADAD', marginLeft: 'auto', display: "flex", width: "107px", height: "42px" }} />}
          {isEdit && <CustomButton name="Save" type="submit" handleClick={handleSave} variant="outlined" sx={{ color: '#05ADAD', marginLeft: 'auto', display: "flex", width: "107px", height: "42px" }} />}


          <Grid spacing={2} sx={{ display: "flex" }}>
            <Grid item xs={3} >
              <Avatar alt="Remy Sharp" src={imageUrl} sx={{ width: "140px", height: "140px", display: "flex", marginLeft: 'auto', marginRight: 'auto', }} />
            </Grid>
            <Grid item sx={{ display: "flex", marginTop: 'auto', marginBottom: 'auto', }}>
              <Typography variant="h2" sx={{ color: "black" }}>{tenantProfile?.name}</Typography>

            </Grid>

          </Grid>
          <Box sx={{ display: "flex", gap: (theme) => theme.spacing(25), marginTop: '40px', marginLeft: '95px' }}>
            <FormControl sx={{ width: "230px" }}>
              <FormLabel id="f_name" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Name
              </FormLabel>
              {!isEdit && <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>{tenantProfile?.name}</Typography>}
              {isEdit && <TextField
                hiddenLabel
                name="name"
                id="name"
                variant="outlined"
                size="small"
                fullWidth
                value={formValues.name}
                onChange={handleChange}
              />}
            </FormControl>
            <FormControl sx={{ width: "230px" }}>
              <FormLabel id="dob" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Date Of Birth
              </FormLabel>
              {!isEdit && <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>{tenantProfile?.dob}</Typography>}
              {isEdit &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(formValues.dob)}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(date) => setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      dob: date,
                    }))}
                  />
                </LocalizationProvider>
              }
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: (theme) => theme.spacing(25), marginTop: '40px', marginLeft: '95px' }}>
            <FormControl sx={{ width: "230px" }}>
              <FormLabel id="email" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Email
              </FormLabel>
              {!isEdit && <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>{tenantProfile?.email}</Typography>}
              {isEdit && <TextField
                hiddenLabel
                name="email"
                id="email"
                variant="outlined"
                size="small"
                fullWidth
                value={formValues.email}
                onChange={handleChange}
              />}
            </FormControl>
            <FormControl sx={{ width: "230px" }}>
              <FormLabel id="l_name" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Contact
              </FormLabel>
              {!isEdit && <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>{tenantProfile?.mobile}</Typography>}
              {isEdit && <TextField
                hiddenLabel
                name="mobile"
                id="mobile"
                variant="outlined"
                size="small"
                fullWidth
                value={formValues.mobile}
                onChange={handleChange}
              />}
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: (theme) => theme.spacing(25), marginTop: '40px', marginLeft: '95px' }}>
            <FormControl sx={{ width: "230px" }}>
              <FormLabel id="org" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Organisation
              </FormLabel>
              {isEdit && <TextField
                hiddenLabel
                name="org"
                id="org"
                variant="filled"
                size="small"
                fullWidth
                disabled
                value={selectedTenant?.name}
                onChange={handleChange}
              />}
              {!isEdit && <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>{selectedTenant?.name}</Typography>}

            </FormControl>
            <FormControl sx={{ width: "230px" }}>
              <FormLabel id="role" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Role
              </FormLabel>
              {!isEdit && <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>{tenantProfile?.role_details?.name}</Typography>}
              {isEdit && <TextField
                hiddenLabel
                name="role"
                id="role"
                variant="filled"
                size="small"
                fullWidth
                disabled
                value={tenantProfile?.role_details?.name}
                onChange={handleChange}
              />}
            </FormControl>
          </Box>
          {/* <Box sx={{ display: "flex", gap: (theme) => theme.spacing(25), marginTop: '40px', marginLeft: '95px' }}>
            <FormControl sx={{ width: '230px' }}>
              <FormLabel id="org" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Attendance
              </FormLabel>
              {!isEdit && (
                <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>
                  {formValues.attendance === 0 ? "No Attendance" : formValues.attendance === 1 ? "Attendance" : "Attendance & Capcture Image"}
                </Typography>
              )}
              {isEdit && (
                <Select value={formValues.attendance} onChange={handleChange} name="attendance">
                  <MenuItem value={0}>No Attendance</MenuItem>
                  <MenuItem value={1}>Attendance</MenuItem>
                  <MenuItem value={2}>Attendance & Capcture Image</MenuItem>
                </Select>
              )}
            </FormControl>
            <FormControl sx={{ width: '230px' }}>
              <FormLabel id="org" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Notification
              </FormLabel>
              {!isEdit && (
                <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>
                  {formValues.notification === 0 ? "Alert" : "Silent"}
                </Typography>
              )}
              {isEdit && (
                <Select value={formValues.notification} onChange={handleChange} name="notification">
                  <MenuItem value={0}>Alert</MenuItem>
                  <MenuItem value={1}>Silent</MenuItem>
                </Select>
              )}
            </FormControl>
          </Box> */}

        </Box>

      </Grid>


    </>
  );

};

export default container(UserProfile);
