import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import SalesReportDetailsComp from "../../../views/Admin/SalesReportDetails/SalesReportDetails.component";

// =================== Order Page ================= //
const SalesReportDetails = () => {
  return <WithLayout layout={Admin} component={SalesReportDetailsComp} />;
};


export default SalesReportDetails;