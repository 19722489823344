import * as TraderTypes from "./traders.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  retailerUsers: {
    users: [],
    pages: {},
    page: 1,
    limit: 10,
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
  },
  distributorUsers: {
    users: [],
    pages: {},
    page: 1,
    limit: 10,
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
  },
  allUsers: [],
  error: null,
  loading: false,
};

// Reducer function responsible for managing the authentication state
const traderReducers = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TraderTypes.FETCH_USERS_BASED_ON_ROLE_START:
      return {
        ...state,
        loading: true,
      };

    case TraderTypes.FETCH_USERS_BASED_ON_ROLE_SUCCESS:
      if (payload?.roleId === 5) {
        return {
          ...state,
          retailerUsers: {
            ...state.retailerUsers,
            users: payload?.result?.data?.users,
            pages: payload?.result?.data?.page_details,
            roleId: payload?.roleId,
          },
          loading: false,
        };
      } else if (payload?.roleId === 6) {
        return {
          ...state,
          distributorUsers: {
            ...state.distributorUsers,
            users: payload?.result?.data?.users,
            pages: payload?.result?.data?.page_details,
            roleId: payload?.roleId,
          },
          loading: false,
        };
      } else {
        return {
          ...state,
          allUsers: payload?.result?.data?.users,
          loading: false,
        };
      }

    case TraderTypes.FETCH_USERS_BASED_ON_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case TraderTypes.ON_CHANGE_APPLY_TRADER_FILTER:
      const { key, value, filterValue } = action.payload;

      if (filterValue === "retailer") {
        return {
          ...state,
          filterValue,
          retailerUsers: {
            ...state.retailerUsers,
            [key]: value,
          },
        };
      } else {
        return {
          ...state,
          filterValue,
          distributorUsers: {
            ...state.distributorUsers,
            [key]: value,
          },
        };
      }
      case TraderTypes.ON_CHANGE_APPLY_TRADER_CLEAR_FILTER:
        //const { filterValue } = action.payload;
        const { f_value } = action.payload;
        if (f_value === "retailer") {
          return {
            ...state,
            f_value,
            retailerUsers: {
              ...state.retailerUsers,
              ...action.payload.data
            },
          };
        } else {
          return {
            ...state,
            f_value,
            distributorUsers: {
              ...state.distributorUsers,
              ...action.payload.data
            },
          };
        }

    default:
      return state;
  }
};

export default traderReducers;
