import * as OrderActions from "./orders.action";
import * as OrderTypes from "./orders.types";

import { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import moment from "moment";
import inventory from "../../pages/manage/inventory";
import { openAlert } from "../alert/alert.actions";

export function* fetchReceivedOrdersAsync({payload}: any) {
  let {
    order_id,
    item_name,
    trader_ids_list,
    from_date,
    to_date,
    amount_greater_than,
    amount_less_than,
    selectedDateRange,
    page,
    limit,
  } = yield select((state) => state.orders.receivedOrdersFilter);
  const { field, type } = payload;
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/order/list/received`, {
      filters: {
        order_id :Number(order_id)?[Number(order_id)]:[],
        item_name,
        trader_ids_list,
        from_date:
          selectedDateRange === "this-week"
            ? moment().startOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "last-week"
            ? moment()
                .subtract(1, "week")
                .startOf("isoWeek")
                .format("YYYY-MM-DD")
            : selectedDateRange === "this-month"
            ? moment().startOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "last-month"
            ? moment()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD")
            : selectedDateRange === "this-year"
            ? moment().startOf("year").format("YYYY-MM-DD")
            : selectedDateRange === "last-year"
            ? moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
            : from_date,
        to_date:
          selectedDateRange === "this-week"
            ? moment().endOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "last-week"
            ? moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "this-month"
            ? moment().endOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "last-month"
            ? moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "this-year"
            ? moment().endOf("year").format("YYYY-MM-DD")
            : selectedDateRange === "last-year"
            ? moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
            : to_date,
        amount_greater_than: Number(amount_greater_than),
        amount_less_than: Number(amount_less_than),
      },
      pages: {
        page,
        limit,
      },
      sorting: [{
        type,
        field
      }]
    });

    yield put(OrderActions.fetchReceivedOrdersSuccess(data));
  } catch (error) {
    yield put(OrderActions.fetchReceivedOrdersFail(error));
  }
}

export function* fetchPlacedOrdersAsync({ payload }: any) {
  let {
    order_id,
    item_name,
    trader_ids_list,
    from_date,
    to_date,
    amount_greater_than,
    amount_less_than,
    selectedDateRange,
    page,
    limit,
  } = yield select((state) => state.orders.placedOrdersFilter);
  const { field, type } = payload;
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/order/list/placed`, {
      filters: {
        order_id:Number(order_id)?[Number(order_id)]:[],
        item_name,
        trader_ids_list,
        from_date:
          selectedDateRange === "this-week"
            ? moment().startOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "last-week"
            ? moment()
                .subtract(1, "week")
                .startOf("isoWeek")
                .format("YYYY-MM-DD")
            : selectedDateRange === "this-month"
            ? moment().startOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "last-month"
            ? moment()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD")
            : selectedDateRange === "this-year"
            ? moment().startOf("year").format("YYYY-MM-DD")
            : selectedDateRange === "last-year"
            ? moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
            : from_date,
        to_date:
          selectedDateRange === "this-week"
            ? moment().endOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "last-week"
            ? moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "this-month"
            ? moment().endOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "last-month"
            ? moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "this-year"
            ? moment().endOf("year").format("YYYY-MM-DD")
            : selectedDateRange === "last-year"
            ? moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
            : to_date,
        amount_greater_than: Number(amount_greater_than),
        amount_less_than: Number(amount_less_than),
      },
      pages: {
        page,
        limit,
      },
      sorting: [{
        type,
        field
      }]
    });
    yield put(OrderActions.fetchPlacedOrdersSuccess(data));
  } catch (error) {
    yield put(OrderActions.fetchPlacedOrdersFail(error));
  }
}

export function* fetchOrdersOnbeHalfAsync({ payload }: any) {
  let {
    order_id,
    item_name,
    trader_ids_list,
    from_date,
    to_date,
    amount_greater_than,
    amount_less_than,
    selectedDateRange,
    page,
    limit,
  } = yield select((state) => state.orders.ordersOnbeHalfFilter);
  const { type, field } = payload;
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/order/list/behalf_of`, {
      filters: {
        order_id:Number(order_id)?[Number(order_id)]:[],
        item_name,
        trader_ids_list,
        from_date:
          selectedDateRange === "this-week"
            ? moment().startOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "last-week"
            ? moment()
                .subtract(1, "week")
                .startOf("isoWeek")
                .format("YYYY-MM-DD")
            : selectedDateRange === "this-month"
            ? moment().startOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "last-month"
            ? moment()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD")
            : selectedDateRange === "this-year"
            ? moment().startOf("year").format("YYYY-MM-DD")
            : selectedDateRange === "last-year"
            ? moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
            : from_date,
        to_date:
          selectedDateRange === "this-week"
            ? moment().endOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "last-week"
            ? moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD")
            : selectedDateRange === "this-month"
            ? moment().endOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "last-month"
            ? moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
            : selectedDateRange === "this-year"
            ? moment().endOf("year").format("YYYY-MM-DD")
            : selectedDateRange === "last-year"
            ? moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
            : to_date,
        amount_greater_than: Number(amount_greater_than),
        amount_less_than: Number(amount_less_than),
      },
      pages: {
        page,
        limit,
      },
      sorting: [{
        type, 
        field
      }]
    });
    yield put(OrderActions.fetchOrdersOnbeHalfSuccess(data));
  } catch (error) {
    yield put(OrderActions.fetchOrdersOnbeHalfFail(error));
  }
}

export function* fetchTradersAsync({ payload }: any) {
 
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenants/all-traders/list`, {
      filters:{
        status: true,
        user_id: [
          
        ],
        trader_id: [
          
        ],
        from_date: null,
        to_date: null
      }
    });
    yield put(OrderActions.fetchTradersSuccess(data));
  } catch (error) {
    yield put(OrderActions.fetchTradersFail(error));
  }
}

export function* createOrderAsync({ payload}: any){
  const { selectedCardId, cardQuantities} = yield select((state) => state.orders);
  console.log("Payload---->", payload);
  const systemDate = moment().toISOString(); 
  // const product_id = inventory?.filtered_products?.id;
  // const quantity = 3;
  try{
    const products = payload.selectedCardId.map(cardId => {
      return {
        product_id: cardId,
        quantity: payload.cardQuantities[cardId]
      };
    });

    const {data} = yield tenantInstance.post(`/tenants/order/create`, {
      // trader_id: 1,
      system_date: systemDate,
      products, 
      // products: [
      //   {
      //     product_id: payload?.selectedCardId,
      //     quantity: payload?.cardQuantities?.value,
      //     // product_id: Object.keys(cardQuantities),
      //     // quantity: Object.values(cardQuantities), 
      //   }
      // ]

      // products: [
      //   product_id: 157,
      //   quantity: 3,
      // ]
    });
    yield put(openAlert("Order Placed Successfully", "success"));
    yield put(OrderActions.createOrderSuccess(data));
    
  }
  catch(error){
    yield put(openAlert("Please Add items before placing Order", "failure"));
    yield put(OrderActions.createOrderFail(error));
  }
}

export function* watchFetchReceivedOrdersAsync() {
  yield takeLatest(
    OrderTypes.FETCH_RECEIVED_ORDERS_START,
    fetchReceivedOrdersAsync
  );
}

export function* watchFetchPlacedOrdersAsync() {
  yield takeLatest(
    OrderTypes.FETCH_PLACED_ORDERS_START,
    fetchPlacedOrdersAsync
  );
}

export function* watchFetchOrdersOnbeHalfAsync() {
  yield takeLatest(
    OrderTypes.FETCH_ORDERS_ON_BEHALF_START,
    fetchOrdersOnbeHalfAsync
  );
}

export function* watchFetchTradersAsync() {
  yield takeLatest(
    OrderTypes.FETCH_TRADERS_START,
    fetchTradersAsync
  );
}

export function* watchCreateOrderAsync(){
  yield takeLatest(
    OrderTypes.CREATE_ORDER_START,
    createOrderAsync,
  );
}

export function* orderSagas() {
  yield all([
    call(watchFetchReceivedOrdersAsync),
    call(watchFetchPlacedOrdersAsync),
    call(watchFetchOrdersOnbeHalfAsync),
    call(watchFetchTradersAsync),
    call(watchCreateOrderAsync),
  ]);
}
