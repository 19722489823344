import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import TourScreen from "../../../views/Admin/TourScreen";

// =================== Tour Plan Page ================= //
const Tour = () => {
  return <WithLayout layout={Admin} component={TourScreen} />;
};

export default Tour;
