// React-redux
import { connect } from "react-redux";

// Project imports
import {
  changeApplyOrderFilter,
  fetchPlacedOrdersStart,
  fetchReceivedOrdersStart,
  fetchTradersStart,
  fetchOrdersOnbeHalfStart
} from "../../../store/orders/orders.action";

const mapStateToProps = (state) => ({
  orders: state.orders,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchReceivedOrdersStart: (field, type) => dispatch(fetchReceivedOrdersStart(field, type)),
  onFetchPlacedOrdersStart: (field, type) => dispatch(fetchPlacedOrdersStart(field, type)),
  onFetchOrdersOnbeHalfStart:(field, type) => dispatch(fetchOrdersOnbeHalfStart(field, type)),
  onFetchTradersStart: () => dispatch(fetchTradersStart()),
  onChangeApplyOrderFilter: (name, value, filterValue) =>
    dispatch(changeApplyOrderFilter(name, value, filterValue)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
