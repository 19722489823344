// React-redux
import { connect } from "react-redux";
import { getSalesReportListStart, getSalesReportTodayStart, getSalesReportYesterdayStart, 
  getSalesReportMonthlyStart, getOrderListForBarStart, getHighestPlacedOrderStart } from "../../../store/salesReport/salesReport.action";

const mapStateToProps = (state) => {
  return{
    salesReport: state.salesReport,
  }
};

const mapDispatchToProps = (dispatch) => ({
    onGetSalesReportListStart: (fromDate, toDate, page, limit, field, type) => dispatch(getSalesReportListStart(fromDate, toDate, page, limit, field, type,)),
    // Today
    onGetSalesReportTodayStart: (fromDate, toDate) => dispatch(getSalesReportTodayStart(fromDate, toDate)),
    // Yesterday
    onGetSalesReportYesterdayStart: (fromDate, toDate) => dispatch(getSalesReportYesterdayStart(fromDate, toDate)),
    // Monthly
    onGetSalesReportMonthlyStart: (fromDate, toDate) => dispatch(getSalesReportMonthlyStart(fromDate, toDate)),
    // Bar
    onGetOrderListForBarStart: (fromDate, toDate) => dispatch(getOrderListForBarStart(fromDate, toDate)),
    // Top Orders
    onGetHighestPlacedOrderStart: (fromDate, toDate, type, field) => dispatch(getHighestPlacedOrderStart(fromDate, toDate, type, field)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;