export const FETCH_BEATS_LIST_START = "FETCH_BEATS_LIST_START";
export const FETCH_BEATS_LIST_SUCCESS = "FETCH_BEATS_LIST_SUCCESS";
export const FETCH_BEATS_LIST_FAILURE = "FETCH_BEATS_LIST_FAILURE";

export const CREATE_TENANT_BEAT_START = "CREATE_TENANT_BEAT_START";
export const CREATE_TENANT_BEAT_SUCCESS = "CREATE_TENANT_BEAT_SUCCESS";
export const CREATE_TENANT_BEAT_FAILURE = "CREATE_TENANT_BEAT_FAILURE";

export const UPDATE_TENANT_BEAT_START = "UPDATE_TENANT_BEAT_START";
export const UPDATE_TENANT_BEAT_SUCCESS = "UPDATE_TENANT_BEAT_SUCCESS";
export const UPDATE_TENANT_BEAT_FAILURE = "UPDATE_TENANT_BEAT_FAILURE";

export const DELETE_TENANT_BEAT_START = "DELETE_TENANT_BEAT_START";
export const DELETE_TENANT_BEAT_SUCCESS = "DELETE_TENANT_BEAT_SUCCESS";
export const DELETE_TENANT_BEAT_FAILURE = "DELETE_TENANT_BEAT_FAILURE";

export const FETCH_TENANT_BEAT_BY_ID_START = "FETCH_TENANT_BEAT_BY_ID_START";
export const FETCH_TENANT_BEAT_BY_ID_SUCCESS =
  "FETCH_TENANT_BEAT_BY_ID_SUCCESS";
export const FETCH_TENANT_BEAT_BY_ID_FAILURE =
  "FETCH_TENANT_BEAT_BY_ID_FAILURE";

export const TENANT_BEATS_MOVE_TRADERS_START =
  "TENANT_BEATS_MOVE_TRADERS_START";
export const TENANT_BEATS_MOVE_TRADERS_SUCCESS =
  "TENANT_BEATS_MOVE_TRADERS_SUCCESS";
export const TENANT_BEATS_MOVE_TRADERS_FAILURE =
  "TENANT_BEATS_MOVE_TRADERS_FAILURE";

export const ON_CHANGE_APPLY_BEATS_FILTER = "ON_CHANGE_APPLY_BEATS_FILTER";
// Action Types for fetching a solo beat--AS
export const FETCH_SOLO_BEAT_START = "FETCH_SOLO_BEAT_START";
export const FETCH_SOLO_BEAT_SUCCESS = "FETCH_SOLO_BEAT_SUCCESS";
export const FETCH_SOLO_BEAT_FAILURE = "FETCH_SOLO_BEAT_FAILURE";

export const PUSH_RETAILER_TO_BEAT_START = "PUSH_RETAILER_TO_BEAT_START";
export const PUSH_RETAILER_TO_BEAT_SUCCESS = "PUSH_RETAILER_TO_BEAT_SUCCESS";
export const PUSH_RETAILER_TO_BEAT_FAILURE = "PUSH_RETAILER_TO_BEAT_FAILURE";

export const REMAINING_TRADERS_START = "REMAINING_TRADERS_START";
export const REMAINING_TRADERS_SUCCESS = "REMAINING_TRADERS_SUCCESS";
export const REMAINING_TRADERS_FAILURE = "REMAINING_TRADERS_FAILURE";