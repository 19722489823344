import { connect } from "react-redux";
import { openAlert } from "../../../store/alert/alert.actions";
import { signInWithTokenStart } from "../../../store/auth/auth.actions";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onOpenAlert: (message, severity) => dispatch(openAlert(message, severity)),
  onSignInWithTokenStart: (token, cb) =>
    dispatch(signInWithTokenStart(token, cb)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
