import moment from 'moment';
import React, { Fragment, useState } from 'react';
import container from './SalesReportDetails.container';
import { Paper, Button, Typography, ButtonGroup, Popover, Box, 
  Grid, RadioGroup, FormControlLabel, Radio, Divider, FormControl, Select, FormLabel} from '@mui/material';
import { CustomPagination, EnhancedTableToolbar, } from '../../../components/CustomTable/CustomTable';
import { useDispatch } from 'react-redux';
import InputLabel from "@mui/material/InputLabel";
import FilterIcon from "../../../assets/images/filter.svg";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CustomButton from '../../../components/CustomButton';
import HeadColumn from './components/HeadColumn';
import Calendar from './components/Calendar';
import { searchSalesDeatailsName } from '../../../store/salesreportdetails/salesReportDetails.action';
import CustomViewButton from '../../../components/CustomViewButton';
import TodayAnalyticalView from './components/TodayAnalyticalView';
import YesterdayAnalyticalView from './components/YesterdayAnalyticalView';
import MonthlyAnalyticalView from './components/MonthlyAnalyticalView';
import PerformanceBarView from './components/PerformanceBarView';
import PieChartView from './components/PieChartView';
import TopSalesAmount from './components/TopSalesAmount';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SalesReportDetailsComp = ({onGetSalesReportDetailsListStart, 
    onGetSalesReportDetailsTodayStart, onGetSalesReportDetailsYesterdayStart,
    onGetSalesReportDetailsMonthlyStart, onGetSalesReportDetailsYearlyBarStart,
    onGetHighestPlacedOrderStart, onGetOrderListForBarStart,
    salesReportDetails: {salesReportDetailsData, salesDetails, totalSales,
        salesReportDetailsDataToday, salesDetailsToday, totalSalesToday,
        salesReportDetailsDataYesterday, salesDetailsYesterday, totalSalesYesterday,
        salesReportDetailsDataMonthly, salesDetailsMonthly, totalSalesMonthly,
        salesReportDetailsDataYearlyBar, salesDetailsYearlyBar, totalSalesYearlyBar,
        highestSalesData, barCustomDateData}, 

    }) => {
    const [currentView, setCurrentView] = useState('table');
    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const [selectedButton, setSelectedButton] = useState('');
    const dispatch = useDispatch();
    const [dense, setDense] = React.useState(false);
    const currentDate = moment().format("YYYY-MM-DD");
    const monthStartDate = moment(currentDate).startOf('month').format("YYYY-MM-DD");
    const monthEndDate = moment(currentDate).endOf('month').format("YYYY-MM-DD");
    
    const [fromDate, setFromDate] = React.useState(monthStartDate);
    const [toDate, setToDate] = React.useState(monthEndDate); 

    const [rowsPerPage, setRowsPerPage] = React.useState(salesDetails?.limit || 10);
    
    const [activePage, setActivePage] = React.useState<any>(1);
    const [noOfPages, setNoOfPages] = React.useState([]);
    
    const [sorting, setSorting] = useState({ field: 'user_name', order: 'asc' });

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedFilter, setSelectedFilter] = useState('');

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };

    const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#008E8E", 
        },
    ]);
    
    const handleSelect = (ranges) => {
      setDateRange([ranges.selection]);
      setCustomDateLabel(
        `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
          'MM/DD/YYYY'
        )}`
      );
    };
    
    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    };
    
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        onGetSalesReportDetailsListStart(fromDate, toDate, activePage, event.target.value, 'asc', 'name');
    }
      
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPage = Number(event.target.value);
        setActivePage(newPage);
        onGetSalesReportDetailsListStart(fromDate, toDate, newPage, salesDetails?.limit, 'asc', 'name');
    }
    const showPrevPage = () => {
        const nextPage = activePage - 1;
        setActivePage(activePage - 1);
        onGetSalesReportDetailsListStart(fromDate, toDate, nextPage, salesDetails?.limit, 'asc', 'name');
    }
    const showNextPage = () => {
        const nextPage = activePage + 1;
        setActivePage(activePage + 1);
        onGetSalesReportDetailsListStart(fromDate, toDate, nextPage, salesDetails?.limit, 'asc', 'name');
    }
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleYearClick = () => {
        const currentDate = moment();
        const startOfLastYear = moment(currentDate).startOf('year').format('YYYY-MM-DD');
        const endOfLastYear = moment(currentDate).endOf('year').format('YYYY-MM-DD');
        setFromDate(startOfLastYear);
        setToDate(endOfLastYear);
        onGetSalesReportDetailsListStart(startOfLastYear, endOfLastYear, 1, 10, "asc", "user_name");
    };
    const handleRadioChange = (event) => { 
        const selectedValue = event.target.value;
        setSelectedFilter(selectedValue);
    };

    const handleClearFilter = () => {
        setSelectedFilter(''); 
        setDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: "#008E8E", 
          },
        ]);
        setActivePage(1);
        setFromDate(monthStartDate);
        setToDate(monthEndDate)
        setCustomDateLabel('Custom Date Selection');
        onGetSalesReportDetailsListStart(monthStartDate, monthEndDate, 1, 10, "asc", "user_name");
        // setSelectedButton('today');
    };

    const handleFilter = () => {
        handleClose();
        if (selectedFilter === 'thisyear') {
            handleYearClick();
        } else if (dateRange.length === 1) {
            const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
            const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
            setFromDate(selectedStartDate); 
            setToDate(selectedEndDate);
            setActivePage(1); 
            onGetSalesReportDetailsListStart(selectedStartDate, selectedEndDate, 1, 10, "asc", "user_name");
            setSelectedButton('customRange');
        }
    };
  
    const handleSearchChange = (e) => {
      dispatch(searchSalesDeatailsName("user_name", e));
      onGetSalesReportDetailsListStart(fromDate, toDate, 1, 10, 'asc', 'user_name');
    };
    
    React.useEffect(() => {
      const calculatePages = () => {
        const totalPages = Math.ceil(salesDetails?.total / salesDetails?.limit);
        const newPages = [];
        for (let i = 1; i <= totalPages; i++) {
          newPages.push(i);
        }
        setNoOfPages(newPages);
      };
          calculatePages();
    }, [salesDetails?.total, salesDetails?.limit]);
    // React.useEffect(() => {
    //     const calculatePages = () => {
    //       const totalPages = Math.ceil(salesDetails?.total / salesDetails?.limit);
    
    //       if (!isNaN(totalPages) && totalPages > 0) {
    //         const maxArrayLength = 10_000; 
      
    //         const newPages = [];
    //         const actualPages = Math.min(totalPages, maxArrayLength);
      
    //         for (let i = 1; i <= actualPages; i++) {
    //           newPages.push(i);
    //         }
      
    //         setNoOfPages(newPages);
    //       } else {
    //         console.error('Invalid totalPages:', totalPages);
    //       }
    //     };
      
    //     calculatePages();
    //   }, [salesDetails?.total, salesDetails?.limit]);
      
      

    React.useEffect(() => {
        onGetSalesReportDetailsListStart(currentDate, currentDate, 1, 10, 'asc', 'user_name');
    }, [])
    return(
        <>
        <Fragment>
            <Box sx={{display: "none", mb: 2,
                // display: "flex",
              }}>
                <CustomViewButton
                  label="Table View"
                  onClick={() => handleViewChange('table')}
                  isActive={currentView === 'table'}
                />
                <CustomViewButton
                  label="Analytics View"
                  onClick={() => {}}
                  // onClick={() => handleViewChange('analytics')}
                  isActive={currentView === 'analytics'}
                />
            </Box>
        </Fragment>
        {currentView === 'table' && (
        <Fragment>
            <Paper sx={{ width: "100%", }}>
            <EnhancedTableToolbar 
                numSelected={0}
                handleChange={handleSearchChange}
                tableHeaderStartComps={ 
                    <Box sx={{display: "flex"}}>
                            <ButtonGroup
                                color="secondary"
                                size="small"
                                sx={{
                                  "& > button": {
                                    textTransform: "none",
                                  },
                                }}>
                                <Button 
                                    variant={selectedButton === 'today' ? 'contained' : 'outlined'}
                                    sx={{ color: selectedButton === 'today' ? '#fff' : '#8B8D97' }}
                                        onClick={() => {
                                          const today = moment().format("YYYY-MM-DD");
                                          setFromDate(today);
                                          setToDate(today);
                                          onGetSalesReportDetailsListStart(today, today, salesDetails?.page, salesDetails?.limit, 'asc', 'name');
                                          setSelectedButton('today')}}
                                        >
                                      Today
                                </Button>
                                <Button 
                                    variant={selectedButton === 'yesterday' ? 'contained' : 'outlined'}
                                    sx={{ color: selectedButton === 'yesterday' ? '#fff' : '#8B8D97' }} 
                                    onClick={() => {
                                    const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
                                    setFromDate(yesterday);
                                    setToDate(yesterday);
                                    onGetSalesReportDetailsListStart(yesterday, yesterday, salesDetails?.page, salesDetails?.limit, 'asc', 'name');
                                    setSelectedButton('yesterday')
                                }}>Yesterday</Button>
                                
                            </ButtonGroup>
                            <Typography variant = "h5" sx={{ ml: 2, mt: 1 }}>Date: {fromDate} to {toDate}</Typography>
                        </Box>
                }
                tableHeaderComps = {
                    <>
                        <Box 
                            sx={{
                                width: "89px",
                                height: "29px",
                                padding: "18px 10px",
                                borderRadius: "4px",
                                border: "1px solid #45464E",
                                display: "flex",
                                alignItems: "center",
                                gap: "9px",
                                cursor: "pointer",
                                }}
                                onClick={handleClick}
                            >
                            <img src={FilterIcon} alt="filter" />
                            <Typography>Filter</Typography>
                        </Box>
                        <Popover 
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            >
                            <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                          Filter
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs = {12}>
                                  <Grid item xs={12}>
                                  <>
                                        <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                          Date Selection
                                        </FormLabel>
                                        
                                        <Typography
                                          sx={{ 
                                            ml: 2, mt: 1,
                                            width: "185px",
                                            height: "29px",
                                            padding: "18px 10px",
                                            borderRadius: "4px",
                                            border: "1px solid #45464E",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "9px",
                                            cursor: "pointer",
                                         }}
                                          onClick={handleCustomDatePopoverOpen}
                                        >
                                          {customDateLabel}
                                        </Typography>
                                        <Popover
                                        open={customDatePopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCustomDatePopoverClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        >
                                          <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                            <IconButton
                                              aria-label="close"
                                              onClick={handleCustomDatePopoverClose}
                                              sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                              }}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                            <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                              <DateRangePicker
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="vertical"
                                                showDateDisplay={false}
                                                editableDateInputs={false}
                                              />
                                            </div>    
                                          </Box>
                                        </Popover>
                                      </>
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12}>
                                    <Divider />
                                </Grid> */}

                                <Grid item xs={12}>
                                  <CustomButton
                                    variant = "outlined"
                                    name="Clear Filter"
                                    handleClick={handleClearFilter}
                                    sx={{ width: '100%', mt: "5px" }}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <CustomButton
                                    name="Filter"
                                    handleClick={handleFilter}
                                    sx={{ width: "100%", mt: "5px" }}
                                  />
                                </Grid>
                            </Box>
                        </Popover>
                    </>
                }
                />
            </Paper>
            <Grid container>
              <Grid item xs={3} md={3}>
                <HeadColumn 
                  fromDate = {fromDate}
                  toDate = {toDate}
                  onGetSalesReportDetailsListStart = {onGetSalesReportDetailsListStart}
                  salesReportDetails = {{salesReportDetailsData, salesDetails}}
                />
              </Grid>
              <Grid item xs={9} md={9}>
                <Calendar 
                  fromDate = {fromDate}
                  toDate = {toDate}
                  onGetSalesReportDetailsListStart = {onGetSalesReportDetailsListStart}
                  salesReportDetails = {{salesReportDetailsData, salesDetails, totalSales}}
                />
              </Grid>
            </Grid>
            {/* <Box sx = {{ display: "flex"}}>
                <HeadColumn 
                    fromDate = {fromDate}
                    toDate = {toDate}
                    onGetSalesReportDetailsListStart = {onGetSalesReportDetailsListStart}
                    salesReportDetails = {{salesReportDetailsData, salesDetails}}
                />
                <Calendar 
                    fromDate = {fromDate}
                    toDate = {toDate}
                    onGetSalesReportDetailsListStart = {onGetSalesReportDetailsListStart}
                    salesReportDetails = {{salesReportDetailsData, salesDetails, totalSales}}
                />
            </Box> */}
            <Paper sx={{ width: "100%", mb: 2 }}>
                <CustomPagination
                    count={salesDetails?.total}
                    rowsPerPage={rowsPerPage}
                    page={activePage - 1}
                    handleChangePage={() => {}}
                    // handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handlePageChange={handlePageChange}
                    pageNo={activePage}
                    noOfPages={noOfPages}
                    activePage={activePage}
                    showPrevPage={showPrevPage}
                    showNextPage={showNextPage}
                />
            </Paper>
        </Fragment>
        )}
        {currentView === 'analytics' && (
          <Grid container>
              <Grid container lg={8} mr={"15px"}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} xl = {4} lg = {4}>
                    <TodayAnalyticalView
                        onGetSalesReportDetailsTodayStart={onGetSalesReportDetailsTodayStart}
                        salesReportDetailsToday={{salesReportDetailsDataToday, salesDetailsToday, totalSalesToday,}}
                      />
                  </Grid>
                  <Grid item xs={12} md={4} xl = {4} lg = {4}>
                    <YesterdayAnalyticalView
                      onGetSalesReportDetailsYesterdayStart={onGetSalesReportDetailsYesterdayStart}
                      salesReportDetailsYesterday={{salesReportDetailsDataYesterday, salesDetailsYesterday, totalSalesYesterday}}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl = {4} lg = {4}>
                    <MonthlyAnalyticalView
                      onGetSalesReportDetailsMonthlyStart={onGetSalesReportDetailsMonthlyStart}
                      salesReportDetailsDataMonthly = {{salesReportDetailsDataMonthly, salesDetailsMonthly, totalSalesMonthly}}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs = {12} md = {12} xl = {12} lg = {12}>
                    <PerformanceBarView 
                      onGetOrderListForBarStart = {onGetOrderListForBarStart}
                      barCustomDateData = {{barCustomDateData}}
                    />
                  </Grid>
                  {/* <Grid item xs = {12} md = {5}>
                    <PieChartView />
                  </Grid> */}
                </Grid>
              </Grid>

              <Grid container lg={4} spacing={2}>
                <TopSalesAmount
                  onGetHighestPlacedOrderStart = {onGetHighestPlacedOrderStart}
                  highestSalesData = {{highestSalesData}}
                />
              </Grid>
          </Grid>

          // <Grid container>
          //   <Grid container lg={9} mr={"15px"}>
          //     <Grid container spacing={1}>
          //         <Grid item xs={12} md={4}>
          //             <TodayAnalyticalView
          //               onGetSalesReportDetailsTodayStart={onGetSalesReportDetailsTodayStart}
          //               salesReportDetailsToday={{salesReportDetailsDataToday, salesDetailsToday, totalSalesToday,}}
          //             />
          //         </Grid>
          //         <Grid item xs={12} md={4}>
          //           <YesterdayAnalyticalView
          //             onGetSalesReportDetailsYesterdayStart={onGetSalesReportDetailsYesterdayStart}
          //             salesReportDetailsYesterday={{salesReportDetailsDataYesterday, salesDetailsYesterday, totalSalesYesterday}}
          //           />
          //         </Grid>
          //         <Grid item xs={12} md={4}>
          //           <MonthlyAnalyticalView
          //             onGetSalesReportDetailsMonthlyStart={onGetSalesReportDetailsMonthlyStart}
          //             salesReportDetailsDataMonthly = {{salesReportDetailsDataMonthly, salesDetailsMonthly, totalSalesMonthly}}
          //           />
          //         </Grid>
          //     </Grid>
          //     <Grid container mt={"6px"} spacing={1}>
          //       <Grid item xs = {12} md = {7}>
          //         <PerformanceBarView 
          //             // onGetSalesReportDetailsYearlyBarStart={onGetSalesReportDetailsYearlyBarStart}
          //             // salesReportDetailsYearlyBar={{salesReportDetailsDataYearlyBar, salesDetailsYearlyBar, totalSalesYearlyBar}}
          //             onGetOrderListForBarStart = {onGetOrderListForBarStart}
          //             barCustomDateData = {{barCustomDateData}}
          //         />
          //       </Grid>
          //       <Grid item xs = {12} md = {5}>
          //         <PieChartView />
          //       </Grid>
          //   </Grid>
          //   </Grid>

          //   <Grid container lg={3}>
          //       <TopSalesAmount
          //         onGetHighestPlacedOrderStart = {onGetHighestPlacedOrderStart}
          //         highestSalesData = {{highestSalesData}}
          //       />
          //   </Grid>
          // </Grid>
        )}
        </>
    )
}
export default container(SalesReportDetailsComp);