import * as ReportByDistributorType from "./reportByDistributor.types";

const INITIAL_STATE = {

};

const reportByDistributorReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } =action;
    switch(type){
        case ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_START:
            return {
                ...state,
                loading: true,
            }

        case ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_SUCCESS:
            return {
                ...state,
                reportByDistributorData: payload?.data,
                loading: false,
            }

        case ReportByDistributorType.GET_REPORT_BY_DISTRIBUTOR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        
        default: 
            return state;
    }
}
export default reportByDistributorReducer;