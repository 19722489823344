import React from 'react';
import CustomizedDialog from '../../../../components/CustomDialog';
import { Typography, Grid, FormControl, Stack, MenuItem } from '@mui/material';
import CustomButton from "../../../../components/CustomButton";
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from "formik-mui";
import * as Yup from "yup";
import { select } from 'redux-saga/effects';

const UserEditModal = ({ openUserEditModal, setOpenUserEditModal, 
    Users: { Users, pageDetails, limit, page: noOfPage,  },
    user,
    onupdateUserStart, onFetchEnableUsersStart, onFetchDisableUsersStart, onGetReportToListStart,
    reportToData, 
     }) => {

    const allUsers = [...user.enabledUsers.Users, ...user.disabledUsers.Users];
    const selectedUser = allUsers.find(user => user.id === openUserEditModal);

    function validation(value) {
        let error;
        if (value === '' || value===false) {
          error = 'Required!';
        }
        return error;
      }
    return(
        // <div>
        //     This is User Edit Modal
        // </div>
        <CustomizedDialog
            open={openUserEditModal}
            handleClose={() => setOpenUserEditModal(false)}
            title={
                <Typography sx={{ color: "#111" }} variant="h3">
                   User Details
                </Typography>
              }
            maxWidth={"xs"}
            fullWidth={false}
            >
            <Formik
                initialValues={{
                    id: openUserEditModal,
                    // f_name: user?.enabledUsers?.find(user => enabledUsers.id === openUserEditModal)?.f_name || "", //Users?.f_name,
                    // f_name: user?.enabledUsers?.Users?.find(a => a.id === openUserEditModal) ? user?.enabledUsers?.Users?.f_name : "",
                    f_name: selectedUser?.f_name || "",
                    l_name: selectedUser?.l_name || "",
                    mobile: selectedUser?.mobile || "",
                    email: selectedUser?.email || "",
                    dob: selectedUser?.dob || "",
                    // report_to: reportToData?.name || "",
                }}
                enableReinitialize={true}
                validationSchema = {
                    Yup.object({
                        f_name: Yup.string().required("First Name is Required"),
                        l_name: Yup.string().required("Last Name is Required"),
                        mobile: Yup.string().required("Mobile Number is Required"),
                        email: Yup.string().required("Email is Required"),
                        // dob: Yup.string().required("DOB is Required"),
                        // report_to: Yup.string().required("Report to is Required"),
                    })
                }
                onSubmit = {(values, {setSubmitting}) => {
                    setSubmitting(false);
                    // const selectedReportTo = reportToData.find(item => item.name === values.report_to);
                    onupdateUserStart({
                        id: openUserEditModal,
                        f_name: values?.f_name,
                        l_name: values?.l_name,
                        mobile: values?.mobile,
                        email: values?.email,
                        dob: values?.dob,
                        // report_to: selectedReportTo?.id,
                    },
                    ()=> {
                        onFetchEnableUsersStart();
                        onFetchDisableUsersStart();
                        setSubmitting(true);
                        setOpenUserEditModal(false);
                    });
                }}
                >
                {({isSubmitting, submitForm, setFieldValue, values, handleSubmit}) => {
                    return(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={1} sx={{ alignItems: "flex-start" }}>
                              <Grid container item md={12} xs={12} spacing={2}>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={TextField}
                                      type="string"
                                      autoFocus
                                      name="id"
                                      id="id"
                                      label="Id"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{ marginTop: 2 }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={TextField}
                                      type="string"
                                      name="f_name"
                                      id="f_name"
                                      label="First Name"
                                      sx={{ marginTop: 2 }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={TextField}
                                      type="string"
                                      name="l_name"
                                      id="l_name"
                                      label="Last Name"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={TextField}
                                      type="string"
                                      name="mobile"
                                      id="mobile"
                                      label="Mobile"
                                      InputProps={{
                                          readOnly: true,
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={TextField}
                                      type="string"
                                      name="email"
                                      id="email"
                                      label="Email ID"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={TextField}
                                      type="string"
                                      name="dob"
                                      id="dob"
                                      label="Date of Birth"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      validate={validation}
                                      component={Select}
                                      type="string"
                                      name="report_to"
                                      id="report_to"
                                      label="Report To"
                                      disableUnderline
                                    >
                                      {reportToData && reportToData.map((item) => (
                                        <MenuItem key={item.id} value={item.name}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Stack
                                direction={"row"}
                                width={"100%"}
                                spacing={4}
                                sx={{
                                  paddingTop: (theme) => theme.spacing(12),
                                  paddingBottom: 1,
                                }}
                                >
                                <CustomButton
                                  variant="outlined"
                                  name="Cancel"
                                  sx={{ width: "50%" }}
                                  handleClick={() => setOpenUserEditModal(false)}
                                />
                                <CustomButton
                                  name="Save"
                                  sx={{ width: "50%" }}
                                  handleClick={submitForm}
                                />
                            </Stack>
                        </Form>
                    )
                }}
            </Formik>
        </CustomizedDialog>
    )

}

export default UserEditModal;