import { connect } from "react-redux";
import {
  createOrUpdateProductStart,
  fetchAllFilteredProductsCategoryStart,
  fetchAllItemsStart,
  fetchAllTenantCategoriesStart,
  fetchAllTenantProductsStart,
  fetchProductDetailsStart,
  // onCreateOrderStart,
} from "../../../store/inventory/inventory.actions";
import { loadUserBasedOnTenantStart } from "../../../store/auth/auth.actions";
import { createOrderStart } from "../../../store/orders/orders.action";
import { openAlert } from "../../../store/alert/alert.actions";

const mapStateToProps = (state) => ({
  inventory: state.inventory,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateOrUpdateProductStart: (formData, cb) =>
    dispatch(createOrUpdateProductStart(formData, cb)),
  onFetchAllTenantCategoriesStart: () =>
    dispatch(fetchAllTenantCategoriesStart()),
  onFetchProductDetailsStart: (id) => dispatch(fetchProductDetailsStart(id)),
  onFetchAllTenantProductsStart: () => dispatch(fetchAllTenantProductsStart()),
  onloadUserBasedOnTenantStart: () => { dispatch(loadUserBasedOnTenantStart()) },
  // onOnCreateOrderStart: (formData, cb) => {dispatch(onCreateOrderStart(formData, cb))},
  onCreateOrderStart: (selectedCardId, cardQuantities) => {dispatch (createOrderStart(selectedCardId, cardQuantities))},
  // onFetchAllItemsStart: () => { dispatch(fetchAllItemsStart())},
  onOpenAlert: (message, severity) => dispatch(openAlert(message, severity)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
