import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Admin from "../../layouts/Admin";
import InviteComp from "../../views/Admin/Invite";

// ===================== Invite Page ================= //
const Invite = () => {
  return <WithLayout layout={Admin} component={InviteComp} />;
};

export default Invite;
