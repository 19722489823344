// React-redux
import { connect } from "react-redux";
import { getItemStatisticalReportListStart, yearlyItemListStart, } from "../../../store/itemstatisticalreport/itemStatisticalReport.action";

const mapStateToProps = (state) => {  
  return{
    itemStatisticalReport: state.itemStatisticalReport,
  }
};

const mapDispatchToProps = (dispatch) => ({
    onGetItemStatisticalReportListStart: (fromDate, toDate, page, limit, type, field) => dispatch(getItemStatisticalReportListStart(fromDate, toDate, page, limit, type, field)),
    onYearlyItemListStart: (fromDate, toDate, type, field) => dispatch(yearlyItemListStart(fromDate, toDate, type, field)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;