import React, { Fragment, useState } from 'react';
import container from './ItemStatisticalReport.container';
import { Paper, RadioGroup, FormControlLabel, Radio, Box, Grid, 
    Typography, Popover, ButtonGroup, Button, FormControl, Select, FormLabel} from '@mui/material';
import moment from 'moment';
import InputLabel from "@mui/material/InputLabel";
import { useDispatch } from 'react-redux';
import { CustomPagination } from '../../../components/CustomTable/CustomTable';
import FilterIcon from "../../../assets/images/filter.svg";
import { EnhancedTableToolbar } from '../../../components/CustomTable';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CustomButton from '../../../components/CustomButton';
import Calendar from "./components/Calendar";
import HeadColumn from "./components/HeadColumn";
import { searchItemStatisticalProductName } from "../../../store/itemstatisticalreport/itemStatisticalReport.action";
import CustomViewButton from '../../../components/CustomViewButton';
import AnalyticalView from "./components/AnalyticalView";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ItemStatisticalReportComp = ({ onGetItemStatisticalReportListStart, 
    itemStatisticalReport: {itemStatisticalReportData, itemPageDetails, grandTotalSales, monthTotalSales} }) => {

    console.log("itemStatisticalReportData---->", itemStatisticalReportData);
    console.log("grandTotalSales---->", grandTotalSales);
    console.log("monthTotalSales---->", monthTotalSales);

    const [currentView, setCurrentView] = useState('table');
    const handleViewChange = (view) => {
        setCurrentView(view);
    };
      
    const [sortOrder, setSortOrder] = useState('asc');
    const dispatch = useDispatch();

    const currentDate = moment().format("YYYY-MM-DD");
    const currentMonthStartDate = moment().startOf('month').format("YYYY-MM-DD");
    const currentMonthEndDate = moment().endOf('month').format("YYYY-MM-DD");

    const [fromDate, setFromDate] = React.useState(currentMonthStartDate);
    const [toDate, setToDate] = React.useState(currentMonthEndDate); 
    const [rowsPerPage, setRowsPerPage] = React.useState(itemPageDetails?.limit || 10);
    
    const [activePage, setActivePage] = React.useState<any>(1);
    const [noOfPages, setNoOfPages] = React.useState([]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [selectedFilter, setSelectedFilter] = useState('');
    const [showMonths, setShowMonths] = useState(false);

    const [selectedStartMonth, setSelectedStartMonth] = useState(null);
    const [selectedEndMonth, setSelectedEndMonth] = useState(null);

    const [viewMode, setViewMode] = useState('monthWiseData');
    const [selectedButton, setSelectedButton] = useState('');
    
    const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

    const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);
    const handleCustomDatePopoverOpen = (event) => {
      setCustomDatePopoverOpen(true);
    };
    
    const handleCustomDatePopoverClose = () => {
      setCustomDatePopoverOpen(false);
    };

    const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#008E8E", 
        },
    ]);

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
        setCustomDateLabel(
          `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
            'MM/DD/YYYY'
          )}`
        );
    };

    const [action, setAction] = React.useState("");

    const handleChange = () => {
      setAction(action);
    //   console.log("handleChange clicked---->", handleChange);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // onGetItemStatisticalReportListStart('limit', Number(event.target.value));
        onGetItemStatisticalReportListStart(fromDate, toDate, activePage, event.target.value, 'asc', 'name');
    }
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPage = Number(event.target.value);
        setActivePage(newPage);
        onGetItemStatisticalReportListStart(fromDate, toDate, newPage, itemPageDetails?.limit, 'asc', 'name');
    }
    const showPrevPage = () => {
        const nextPage = activePage - 1;
        setActivePage(activePage - 1);
        onGetItemStatisticalReportListStart(fromDate, toDate, nextPage, itemPageDetails?.limit, 'asc', 'name');
    }
    const showNextPage = () => {
        const nextPage = activePage + 1;
        setActivePage(activePage + 1);
        onGetItemStatisticalReportListStart(fromDate, toDate, nextPage, itemPageDetails?.limit, 'asc', 'name');
    }
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleThisWeek = () => {
        const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
        const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
        setFromDate(startOfWeek);
        setToDate(endOfWeek);
        onGetItemStatisticalReportListStart(startOfWeek, endOfWeek, 1, 10, 'asc', 'name');
        setShowMonths(false);
    };
    const handleLastWeek = () => {
        const startOfLastWeek = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
        const endOfLastWeek = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
        setFromDate(startOfLastWeek);
        setToDate(endOfLastWeek);
        onGetItemStatisticalReportListStart(startOfLastWeek, endOfLastWeek, 1, 10, 'asc', 'name');
        // setShowMonths(true);
        setShowMonths(false);
    };
    const handleMonthClick = (monthIndex) => {
        const currentDate = moment();
        const startDate = moment(currentDate).month(monthIndex).startOf('month').format("YYYY-MM-DD");
        const endDate = moment(currentDate).month(monthIndex).endOf('month').format("YYYY-MM-DD");
        setFromDate(startDate);
        setToDate(endDate);
        onGetItemStatisticalReportListStart(startDate, endDate, 1, 10, 'asc', 'name');
    };
    const handleYearClick = () => {
        const currentDate = moment();
        const startOfLastYear = moment(currentDate).startOf('year').format('YYYY-MM-DD');
        const endOfLastYear = moment(currentDate).endOf('year').format('YYYY-MM-DD');
        setFromDate(startOfLastYear);
        setToDate(endOfLastYear);
        onGetItemStatisticalReportListStart(startOfLastYear, endOfLastYear, 1, 10, 'asc', 'name');
        setShowMonths(false);
    };
    const handleThisMonth = () =>{
        const currentDate = moment();
        const startOfMonth = moment(currentDate).startOf('month').format("YYYY-MM-DD");
        const endOfMonth = moment(currentDate).endOf('month').format("YYYY-MM-DD");
        setFromDate(startOfMonth);
        setToDate(endOfMonth);
        onGetItemStatisticalReportListStart(startOfMonth, endOfMonth, 1, 10, 'asc', 'name');
        setShowMonths(true);  
    }
    const handleCustomMonths = () =>{
        const startMonthDate = selectedStartMonth ? selectedStartMonth.format("YYYY-MM-DD") : null;
        const endMonthDate = selectedEndMonth ? selectedEndMonth.format("YYYY-MM-DD") : null;
            
        const monthsInRange = [];
            
        for (let month = selectedStartMonth.clone(); month <= selectedEndMonth; month.add(1, 'month')) {
          monthsInRange.push(month.format('MMMM'));
        }
    
        // setSelectedMonths(monthsInRange);
        onGetItemStatisticalReportListStart(startMonthDate, endMonthDate, 1, 10, 'asc', 'name');
        setViewMode('monthWiseData');
        setShowMonths(true);
    }
    const handleLastMonth = () =>{
        const currentDate = moment();
        const startOfLastMonth = moment(currentDate).subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
        const endOfLastMonth = moment(currentDate).subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
        setFromDate(startOfLastMonth);
        setToDate(endOfLastMonth);
        onGetItemStatisticalReportListStart(startOfLastMonth, endOfLastMonth, 1, 10, 'asc', 'name');
        setShowMonths(false);
    }
    const handleRadioChange = (event) => { 
        const selectedValue = event.target.value;
        if (selectedValue === 'thisWeek') {
            handleThisWeek();
        } 
        else if (selectedValue === 'lastWeek') {
            handleLastWeek();
        }
        else if (selectedValue === 'thisMonth'){
            handleThisMonth();
        }
        else if (selectedValue === 'lastMonth'){
            handleLastMonth();
        }
         else if (selectedValue === 'thisyear') {
            handleYearClick();
        }
        else {
            handleCustomMonths();
        }
        onGetItemStatisticalReportListStart(fromDate, toDate, itemPageDetails?.page, itemPageDetails?.limit, 'asc', 'name');
        setSelectedStartMonth(null);
        setSelectedEndMonth(null);
        clearFilter();

        setSelectedFilter(selectedValue);
    };

    const handleFilter = () => {
        handleClose();
        if (dateRange.length === 1) {
          const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
          const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
          setActivePage(1);
          onGetItemStatisticalReportListStart(selectedStartDate, selectedEndDate, 1, 10, 'asc', 'name');
          setFromDate(selectedStartDate);
          setToDate(selectedEndDate);
        //   console.log("selectedStartDate - selectedEndDate---->", selectedStartDate, selectedEndDate);
        }
        // setSelectedFilter('');
    };
    const handleClearFilter = () => {
        setSelectedFilter(''); 
        setDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: "#008E8E", 
          },
        ]);
        setFromDate(currentDate);
        setToDate(currentDate);
        setActivePage(1);
        setCustomDateLabel('Custom Date Selection');
        onGetItemStatisticalReportListStart(currentDate, currentDate, 1, 10, 'asc', 'name');
        setSelectedButton('today');
    };

    const clearFilter = () => {
        setSelectedFilter(''); 
        handleClose(); 
    };
    const handleSearchChange = (e) => {
        dispatch(searchItemStatisticalProductName("name", e));
        onGetItemStatisticalReportListStart(currentDate, currentDate, 1, 10, 'asc', 'name');
    };
    React.useEffect(() => {
      const calculatePages = () => {
        const totalPages = Math.ceil(itemPageDetails?.total / itemPageDetails?.limit);
        const newPages = [];
        for (let i = 1; i <= totalPages; i++) {
          newPages.push(i);
        }
        setNoOfPages(newPages);
      };
          calculatePages();
    }, [itemPageDetails?.total, itemPageDetails?.limit]);
  
    // React.useEffect(() => {
    //     onGetItemStatisticalReportListStart(currentDate, currentDate, 1, 10, 'asc', 'name');
    // }, []);
    return(
        <>
        <Fragment>
          <Box sx={{display: "none", mb: 2,
            // display: "flex",
          }}>
            <CustomViewButton
              label="Table View"
              onClick={() => handleViewChange('table')}
              isActive={currentView === 'table'}
            />
            <CustomViewButton
              label="Analytics View"
              onClick = {() => {}}
            //   onClick={() => handleViewChange('analytics')}
              isActive={currentView === 'analytics'}
            />
            </Box>
        </Fragment>
        {currentView === 'table' && (
        <Fragment>
            <Paper sx={{ width: "100%", }}>
            <EnhancedTableToolbar 
                numSelected={0}
                handleChange={handleSearchChange}
                tableHeaderStartComps={ 
                    <Box sx={{display: "flex"}}> 
                    <ButtonGroup
                                color="secondary"
                                size="small"
                                sx={{
                                  "& > button": {
                                    textTransform: "none",
                                  },
                                }}>
                                <Button 
                                    variant={selectedButton === 'today' ? 'contained' : 'outlined'}
                                    sx={{ color: selectedButton === 'today' ? '#fff' : '#8B8D97' }}
                                        onClick={() => {
                                          const today = moment().format("YYYY-MM-DD");
                                          setFromDate(today);
                                          setToDate(today);
                                          onGetItemStatisticalReportListStart(today, today, itemPageDetails?.page, itemPageDetails?.limit, 'asc', 'name');
                                          setSelectedButton('today')}}
                                        >
                                      Today
                                </Button>
                                <Button 
                                    variant={selectedButton === 'yesterday' ? 'contained' : 'outlined'}
                                    sx={{ color: selectedButton === 'yesterday' ? '#fff' : '#8B8D97' }} 
                                    onClick={() => {
                                    const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
                                    setFromDate(yesterday);
                                    setToDate(yesterday);
                                    onGetItemStatisticalReportListStart(yesterday, yesterday, itemPageDetails?.page, itemPageDetails?.limit, 'asc', 'name');
                                    setSelectedButton('yesterday')
                                }}>Yesterday</Button>
                            </ButtonGroup> 
                            <Typography variant = "h5" sx={{ ml: 2, mt: 1 }}>Date: {fromDate} to {toDate}</Typography>
                    </Box>
                }
                tableHeaderComps = {
                    <>
                        <Box 
                            sx={{
                                width: "89px",
                                height: "29px",
                                padding: "18px 10px",
                                borderRadius: "4px",
                                border: "1px solid #45464E",
                                display: "flex",
                                alignItems: "center",
                                gap: "9px",
                                cursor: "pointer",
                                }}
                                onClick={handleClick}
                            >
                            <img src={FilterIcon} alt="filter" />
                            <Typography>Filter</Typography>
                        </Box>
                        <Popover 
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            >
                            <Box sx={{ padding: "24px 21px", maxWidth: 300, width: "100%" }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                          Filter
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs = {12}>
                                {/* <RadioGroup 
                                    aria-label="filter-options"
                                    name="filter-options"
                                    value={selectedFilter}  
                                    onChange={handleRadioChange}
                                    >
                                    <FormControlLabel value="thisMonth" control={<Radio />} label="This Month" />
                                    <FormControlLabel value="lastMonth" control={<Radio />} label="Last Month" />
                                    <FormControlLabel value="thisyear" control={<Radio />} label="This Year" /> 
                                </RadioGroup> */}
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12}>
                                    <>
                                        <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                          Date Selection
                                        </FormLabel>
                                        <Typography
                                          sx={{ 
                                            ml: 2, mt: 1,// cursor: "pointer"
                                            width: "185px",
                                            height: "29px",
                                            padding: "18px 10px",
                                            borderRadius: "4px",
                                            border: "1px solid #45464E",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "9px",
                                            cursor: "pointer",
                                         }}
                                          onClick={handleCustomDatePopoverOpen}
                                        >
                                          {customDateLabel}
                                        </Typography>
                                        <Popover
                                        open={customDatePopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCustomDatePopoverClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        >
                                          <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                            <IconButton
                                              aria-label="close"
                                              onClick={handleCustomDatePopoverClose}
                                              sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                              }}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                            <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                              <DateRangePicker
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="vertical"
                                                showDateDisplay={false}
                                                editableDateInputs={false}
                                              />
                                            </div>    
                                          </Box>
                                        </Popover>
                                      </>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomButton
                                          variant = "outlined"
                                          name="Clear Filter"
                                          handleClick={handleClearFilter}
                                          sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomButton
                                          name="Filter"
                                          handleClick={handleFilter}
                                          sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Box>
                        </Popover>
                    </>
                }
                />
            </Paper>
            <Grid container>
                <Grid item xs={4} md={4}>
                    <HeadColumn 
                        fromDate={fromDate}
                        toDate={toDate}
                        onGetItemStatisticalReportListStart={onGetItemStatisticalReportListStart}
                        itemStatisticalReport={{ itemStatisticalReportData, itemPageDetails }} 
                    />
                </Grid>
                <Grid item xs={8} md={8}>
                    <Calendar
                        fromDate={fromDate}
                        toDate={toDate}
                        onGetItemStatisticalReportListStart={onGetItemStatisticalReportListStart}
                        itemStatisticalReport={{ itemStatisticalReportData, itemPageDetails, grandTotalSales, monthTotalSales }}
                    />
                </Grid>
            </Grid>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <CustomPagination
                    count={itemPageDetails?.total}
                    rowsPerPage={rowsPerPage}
                    page={activePage - 1}
                    handleChangePage={() => {}}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handlePageChange={handlePageChange}
                    pageNo={activePage}
                    noOfPages={noOfPages}
                    activePage={activePage}
                    showPrevPage={showPrevPage}
                    showNextPage={showNextPage}
                />
            </Paper>
        </Fragment>
        )}
        {currentView === 'analytics' && (
        <Fragment>
            <Paper sx={{ width: "100%", }}>
                Demo Analytical View
                <AnalyticalView />
            </Paper>
        </Fragment>
        )}
        </>
    )
}

export default container(ItemStatisticalReportComp);