export const FETCH_ROLES_START = "FETCH_ROLES_START";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";

export const FETCH_COUNTRIES_START = "FETCH_COUNTRIES_START";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";

export const FETCH_STATE_BY_COUNTRY_ID_START =
  "FETCH_STATE_BY_COUNTRY_ID_START";
export const FETCH_STATE_BY_COUNTRY_ID_SUCCESS =
  "FETCH_STATE_BY_COUNTRY_ID_SUCCESS";
export const FETCH_STATE_BY_COUNTRY_ID_FAILURE =
  "FETCH_STATE_BY_COUNTRY_ID_FAILURE";

export const FETCH_CITY_BY_STATE_ID_START = "FETCH_CITY_BY_STATE_ID_START";
export const FETCH_CITY_BY_STATE_ID_SUCCESS = "FETCH_CITY_BY_STATE_ID_SUCCESS";
export const FETCH_CITY_BY_STATE_ID_FAILURE = "FETCH_CITY_BY_STATE_ID_FAILURE";

export const FETCH_BEATS_DATA_BY_ID_START = "FETCH_BEATS_DATA_BY_ID_START";
export const FETCH_BEATS_DATA_BY_ID_SUCCESS = "FETCH_BEATS_DATA_BY_ID_SUCCESS";
export const FETCH_BEATS_DATA_BY_ID_FAILURE = "FETCH_BEATS_DATA_BY_ID_FAILURE";
