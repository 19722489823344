// React-redux
import { connect } from "react-redux";
import {
  getExcelFieldsStart,
  importMappedProductsStart,
  importMappedBeatsStart,
  importMappedUserStart
} from "../../../store/excel/excel.actions";

// Project imports

const mapStateToProps = (state) => ({
  excel: state.excel,
});

const mapDispatchToProps = (dispatch) => ({
  onGetExcelFieldsStart: (slug) => dispatch(getExcelFieldsStart(slug)),
  onImportMappedProductsStart: (formData, cb) =>
    dispatch(importMappedProductsStart(formData, cb)),
  onImportMappedBeatsStart: (formData, cb) =>
    dispatch(importMappedBeatsStart(formData, cb)),
  onImportMappedUserStart:(formData, cb) =>
  dispatch(importMappedUserStart(formData, cb)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
