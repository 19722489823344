import React from "react";

// Project Imports
import WithLayout from "../../components/WithLayout";
import Admin from "../../layouts/Admin";
import UsersComponent from "../../views/Admin/Users";

// =================== Order Page ================= //
const Users = () => {
  return <WithLayout layout={Admin} component={UsersComponent} />;
};

export default Users;