import { select, put, takeLatest, all, call, } from "redux-saga/effects";
import { tenantInstance } from "../../configs/axiosConfig";
import * as SalesReportDetailsActions from "./salesReportDetails.action";
import * as SalesReportDetailsType from "./salesReportDetails.types";

// API for Table
export function* getSalesReportDetailsListAsync ({payload}: any) {
    let { name } = yield select((state) => state.salesReportDetails);
    const { fromDate, toDate, page, total, type, field, limit} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/sales-report-details`, {
            filters: {
                name,
                beat_name: "",
                format_data: 'date-wise',
                from_date: fromDate,
                to_date: toDate,
            },
            pages:{
                page,
                limit,
            },
            sorting: [
              {
                type,
                field,
              }
            ]
        });
        yield put(SalesReportDetailsActions.getSalesReportDetailsListSuccess(data));
    }
    catch(error){
        yield put(SalesReportDetailsActions.getSalesReportDetailsListFail(error));
    }
}
export function* watchgetSalesReportDetailsListAsync(){
    yield takeLatest(
        SalesReportDetailsType.GET_SALES_REPORT_DETAILS_LIST_START,
        getSalesReportDetailsListAsync,
    )
}

// API for Today
export function* getSalesReportDetailsTodayListAsync ({payload}: any) {
    let { name, } = yield select((state) => state.salesReportDetails);
    const { fromDate, toDate, type, field} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/sales-report-details`, {
            filters: {
                name,
                beat_name: "",
                format_data: 'date-wise',
                // from_date: "2024-01-01",
                // to_date: "2024-01-01",
                from_date: fromDate,
                to_date: toDate,
            },
            sorting: [
              {
                type,
                field,
              }
            ]
        });
        yield put(SalesReportDetailsActions.getSalesReportDetailsTodaySuccess(data));
    }
    catch(error){
        yield put(SalesReportDetailsActions.getSalesReportDetailsTodayFail(error));
    }
}
export function* WatchGetSalesReportDetailsTodayListAsync(){
    yield takeLatest(
        SalesReportDetailsType.GET_SALES_DETAILS_TODAY_START,
        getSalesReportDetailsTodayListAsync,
    )
}

// API for Yesterday
export function* getSalesReportDetailsYesterdayListAsync ({payload}: any) {
    let { name, } = yield select((state) => state.salesReportDetails);
    const { fromDate, toDate, type, field,} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/sales-report-details`, {
            filters: {
                name,
                beat_name: "",
                format_data: 'date-wise',
                from_date: fromDate,
                to_date: toDate,
            },
            sorting: [
              {
                type,
                field,
              }
            ]
        });
        yield put(SalesReportDetailsActions.getSalesReportDetailsYesterdaySuccess(data));
    }
    catch(error){
        yield put(SalesReportDetailsActions.getSalesReportDetailsYesterdayFail(error));
    }
}
export function* WatchGetSalesReportDetailsYesterdayListAsync(){
    yield takeLatest(
        SalesReportDetailsType.GET_SALES_DETAILS_YESTERDAY_START,
        getSalesReportDetailsYesterdayListAsync,
    )
}

// API for Monthly
export function* getSalesReportDetailsMonthlyListAsync ({payload}: any) {
    let { name, } = yield select((state) => state.salesReportDetails);
    const { fromDate, toDate, type, field} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/sales-report-details`, {
            filters: {
                name,
                beat_name: "",
                format_data: 'date-wise',
                from_date: fromDate,
                to_date: toDate,
            },
            // pages:{
            //     page: "1",
            //     limit: "5",
            // },
            sorting: [
              {
                type,
                field,
              }
            ]
        });
        yield put(SalesReportDetailsActions.getSalesReportDetailsMonthlySuccess(data));
    }
    catch(error){
        yield put(SalesReportDetailsActions.getSalesReportDetailsMonthlyFail(error));
    }
}
export function* WatchGetSalesReportDetailsMonthlyListAsync(){
    yield takeLatest(
        SalesReportDetailsType.GET_SALES_DETAILS_MONTHLY_START,
        getSalesReportDetailsMonthlyListAsync,
    )
}

// API for Yearly (Bar)
export function* getSalesReportDetailsYearlyBarListAsync ({payload}: any) {
    let { name, } = yield select((state) => state.salesReportDetails);
    const { fromDate, toDate} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/sales-report-details`, {
            filters: {
                name,
                beat_name: "",
                from_date: fromDate,
                to_date: toDate,
            },
        });
        yield put(SalesReportDetailsActions.getSalesReportDetailsYearlyBarSuccess(data));
    }
    catch(error){
        yield put(SalesReportDetailsActions.getSalesReportDetailsYearlyBarFail(error));
    }
}
export function* WatchGetSalesReportDetailsYearlyBarListAsync(){
    yield takeLatest(
        SalesReportDetailsType.GET_SALES_DETAILS_YEAR_BAR_START,
        getSalesReportDetailsYearlyBarListAsync,
    )
}

// For Highest Placed Order
export function* getHighestPlacedOrderAsync({ payload }: any){
    let{ } = yield select((state) => state.salesReport);
    const { fromDate, toDate, field, type,} = payload;
    
    try {
      const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
        filters: {
            from_date: fromDate, 
            to_date: toDate,
        },
        sorting: [
          {
            type,
            field,
          }
        ]
      });
      yield put(SalesReportDetailsActions.getHighestPlacedOrderSuccess(data));
    } catch (error) {
      yield put(SalesReportDetailsActions.getHighestPlacedOrderFail(error));
    }
}
  
export function* watchGetHighestPlacedOrderAsync() {
  yield takeLatest(
      SalesReportDetailsType.GET_HIGHEST_PLACED_ORDER_START,
    getHighestPlacedOrderAsync
);
}

// For Bar Custom Range Order Total
export function* getOrderListForBarStartAsync({ payload }: any){
    let{ } = yield select((state) => state.salesReport);
    const { fromDate, toDate, field, type,} = payload;
    
    try {
      const { data } = yield tenantInstance.post(`/tenants/reports/sales-report`, {
        filters: {
            format_date: "date-wise",
            from_date: fromDate, 
            to_date: toDate,
        }
      });
      yield put(SalesReportDetailsActions.getOrderListForBarSuccess(data));
    } catch (error) {
      yield put(SalesReportDetailsActions.getOrderListForBarFail(error));
    }
}

export function* watchGetOrderListForBarStartAsync() {
    yield takeLatest(
        SalesReportDetailsType.GET_ORDER_LIST_FOR_BAR_START,
        getOrderListForBarStartAsync
  );
}

export function* salesReportDetailsSagas(){
    yield all([
        call(watchgetSalesReportDetailsListAsync),
        call(WatchGetSalesReportDetailsTodayListAsync),
        call(WatchGetSalesReportDetailsYesterdayListAsync),
        call(WatchGetSalesReportDetailsMonthlyListAsync),
        call(WatchGetSalesReportDetailsYearlyBarListAsync),
        call(watchGetHighestPlacedOrderAsync),
        call(watchGetOrderListForBarStartAsync),
    ]);
}