import React, { ReactNode } from "react";

// Material-ui
import { Box } from "@mui/material";

// Project imports
import Topbar from "./components/Topbar";

interface MainProps {
  children: ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <Box sx={{ background: "#F5F5F5", height: "100vh" }}>
      <Topbar />
      <main>{children}</main>
    </Box>
  );
};

export default Main;
