import React, { Fragment,} from "react";
import { Box, TableContainer, Paper, Table, TableBody, TableRow, TableCell} from "@mui/material";
import { EnhancedTableHead } from '../../../../components/CustomTable';

const Calendar = ({onGetSalesReportListStart, fromDate, toDate, 
    salesReport: { salesReportData, pageDetails, salesReportSummary}, }) => {
    const [dense, setDense] = React.useState(false);
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<any>("user_name");

    interface HeadCell {
        disablePadding: boolean;
        id: any;
        label: string;
        isShow: boolean;
        numeric: boolean;
        align?: string;
    }
    const headCells: readonly HeadCell[] = [
        {
          id: "report_to",
          numeric: false,
          disablePadding: false,
          label: "Reporting To",
          isShow: true,
        },
        {
          id: "beat_names",
          numeric: false,
          disablePadding: false,
          label: "Beat Name ",
          isShow: true,
        },
        {
          id: "total_retailers_count",
          numeric: false,
          disablePadding: false,
          label: "Total Retailers ",
          isShow: true,
        },
        {
          id: "peding_retailers",
          numeric: false,
          disablePadding: false,
          label: "Pending Retailers ",
          isShow: true,
        },
        {
          id: "retailers_total_Calls",
          numeric: false,
          disablePadding: false,
          label: "Retailers Total Calls ",
          isShow: true,
        },
        {
          id: "just_visit_calls",
          numeric: false,
          disablePadding: false,
          label: "Just Visit ",
          isShow: true,
        },
        {
          id: "productive_calls",
          numeric: false,
          disablePadding: false,
          label: "Productive Calls ",
          isShow: true,
        },
        {
            id: "total_orders_value",
            numeric: false,
            disablePadding: false,
            label: "Total Orders Value ",
            isShow: true,
        }
    ];
    const handleRequestSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
        onGetSalesReportListStart(fromDate, toDate, pageDetails?.page, pageDetails?.limit, isAsc ? "asc" : "desc", field, );
    };
    return(
        <Fragment>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                              numSelected={0}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={() => {}}
                              onRequestSort={(event, property) => handleRequestSort(property)}
                              rowCount={salesReportData?.length}
                              headCells={headCells}
                              isCheckBox={false}
                            />
                            <TableBody>
                                {salesReportData && salesReportData.length > 0 ? (
                                    salesReportData?.map((row, index) => {
                                        return(
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                }}>
                                                    -
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded", whiteSpace: "nowrap",
                                                }}>
                                                    {row.beat_names ? `${row?.beat_names} `  : "-"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}>
                                                    {row.total_retailers_count ? row?.total_retailers_count : "0"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                    height: "75px"
                                                }}>
                                                    {row.remaining_retailers ? row?.remaining_retailers : "0"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}>
                                                    {row.total_calls ? row?.total_calls : "0"}
                                                </TableCell> 
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}>
                                                    {row.just_visit_calls ? row.just_visit_calls : "0"}
                                                </TableCell>
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}>
                                                    {row.productive_calls ? row?.productive_calls : "0"}
                                                </TableCell>
                                                <TableCell align="center" sx={{
                                                    borderTop: "1px solid #ebeded",
                                                    borderRight: "1px solid #ebeded",
                                                    borderLeft: "1px solid #ebeded",
                                                }}>
                                                    {/* {row.total_orders_value ? row?.total_orders_value : "0"} */}
                                                    {row.total_orders_value != undefined ? row.total_orders_value.toFixed(2) : "0.00" }
                                                </TableCell>
                                                {/* total_orders_value */}
                                            </TableRow>
                                        )
                                    
                                })) : 
                                    (
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center">
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )
                            }
                            </TableBody>
                            <TableCell
                                key="totalSale"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    // borderLeft: "1px solid #ebeded",
                                    // height: "75px",
                                    fontWeight: "bold", 
                                }}
                            >
                                -
                            </TableCell>
                            <TableCell
                                key="totalSale"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                -
                            </TableCell>
                            <TableCell
                                key="totalRetailers"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                {salesReportSummary?.retailers_grand_total ? salesReportSummary?.retailers_grand_total : '0'}
                            </TableCell>
                            <TableCell
                                key="pendingRetailers"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                {salesReportSummary?.remaining_retailers_grand_total ? salesReportSummary?.remaining_retailers_grand_total : '0'}
                            </TableCell>
                            <TableCell
                                key="retailerTotalCall"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                {salesReportSummary?.calls_grand_total ? salesReportSummary?.calls_grand_total : '0'}
                            </TableCell>
                            <TableCell
                                key="justVisitTotal"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                {salesReportSummary?.just_visit_calls_grand_total ? salesReportSummary?.just_visit_calls_grand_total : '0'}
                            </TableCell>
                            <TableCell
                                key="productiveCallsTotal"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                {salesReportSummary?.productive_calls_grand_total ? salesReportSummary?.productive_calls_grand_total : '0'}
                            </TableCell>
                            <TableCell
                                key="totalOrders"
                                align="center"
                                sx={{
                                    borderTop: "1px solid #ebeded",
                                    borderRight: "1px solid #ebeded",
                                    borderLeft: "1px solid #ebeded",
                                    fontWeight: "bold", 
                                }}
                            >
                                {salesReportSummary?.order_value_grand_total ? salesReportSummary?.order_value_grand_total.toFixed(2) : '0.00'}
                            </TableCell>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Fragment>
    )
}

export default Calendar;