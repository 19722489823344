import React from "react";

//Material-ui imports
import { Button, SxProps, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

interface BtnWrapperProps {
  name: string;
  variant?: "text" | "outlined" | "contained" | undefined;
  size?: "small" | "medium" | "large";
  handleClick?: any;
  sx?: SxProps<Theme>;
  icon?: any;
  type?: any;
  disabled?: boolean;
  id?: string;
}

interface AddCustomBtnProps extends BtnWrapperProps {
    name: string;
    editMode?: boolean;
    value?: number;
    id?: string;
}


const BtnWrapper = styled(Button)<{ size?: string }>(
  ({ theme, size, variant }) => ({
    borderRadius: "5px",
    fontSize: size === "small" ? "14px" : "16px",
    fontWeight: 500,
    lineHeight: size === "small" ? "17px" : "22px",
    color:
      variant === "contained"
        ? "#008E8E"
        : "#000000",
    backgroundColor:
      variant === "contained" ? "#FFFFFF": "transparent",
    textTransform: "capitalize",
    borderColor: 
        variant === "contained" ? theme.palette.primary.dark : "#ADADAD",
    padding: "11px 14px",

    // Hover Properties
    '&:hover': {
        color: variant === "contained" ? "#FFFFFF" : "#008E8E", // Change text color on hover
        backgroundColor: variant === "contained" ? "#008E8E" : "#F4F5FA", // Change background color on hover
        borderColor: variant === "contained" ? theme.palette.primary.main : "#008E8E", // Change border color on hover
      },
  })
);


// ======================== Main Button Component ================= //
const AddCustomBtn = ({
  id, 
  name,
  variant = "contained",
  size = "medium",
  handleClick,
  sx,
  icon,
  type,
  disabled = false,
  editMode = false,
  value = 0,
}: AddCustomBtnProps) => {
  return (
    <BtnWrapper
      variant={variant}
      size={size}
      sx={sx}
      onClick={handleClick}
      startIcon={icon ? icon : null}
      type={type}
      disabled={disabled}
    >
      {name}
      {/* {editMode ? (
        <>
          <Button sx={{color: "#000000"}} onClick={() => handleClick(value - 1)}>-</Button>
          {value}
          <Button sx={{color: "#000000"}} onClick={() => handleClick(value + 1)}>+</Button>
        </>
      ) : (
        name
      )} */}
    </BtnWrapper>
  );
};

export default AddCustomBtn;
