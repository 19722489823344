import * as OrderType from "./orders.types";

// Fetched Received Orders
export const fetchReceivedOrdersStart = (type, field) => ({
  type: OrderType.FETCH_RECEIVED_ORDERS_START,
  payload: { type, field }
});

export const fetchReceivedOrdersSuccess = (result) => ({
  type: OrderType.FETCH_RECEIVED_ORDERS_SUCCESS,
  payload: result,
});

export const fetchReceivedOrdersFail = (error) => ({
  type: OrderType.FETCH_RECEIVED_ORDERS_FAILURE,
  payload: error,
});

// fetched Placed Orders
export const fetchPlacedOrdersStart = ( type, field ) => ({
  type: OrderType.FETCH_PLACED_ORDERS_START,
  payload:  { type, field, }
});

export const fetchPlacedOrdersSuccess = (result) => {
  console.log("fetchPlacedOrdersSuccess", result);
  return {
    type: OrderType.FETCH_PLACED_ORDERS_SUCCESS,
    payload: result,
  };
};

export const fetchPlacedOrdersFail = (error) => ({
  type: OrderType.FETCH_PLACED_ORDERS_FAILURE,
  payload: error,
});

// fetched Placed Orders
export const fetchOrdersOnbeHalfStart = (type, field) => ({
  type: OrderType.FETCH_ORDERS_ON_BEHALF_START,
  payload: { type, field }
});

export const fetchOrdersOnbeHalfSuccess = (result) => {
  console.log("fetchOrdersOnbeHalfSuccess", result);
  return {
    type: OrderType.FETCH_ORDERS_ON_BEHALF_SUCCESS,
    payload: result,
  };
};

export const fetchOrdersOnbeHalfFail = (error) => ({
  type: OrderType.FETCH_ORDERS_ON_BEHALF_FAILURE,
  payload: error,
});

// fetched Traders
export const fetchTradersStart = () => ({
  type: OrderType.FETCH_TRADERS_START,
});

export const fetchTradersSuccess = (result) => {
  console.log("fetchTradersSuccess", result);
  return {
    type: OrderType.FETCH_TRADERS_SUCCESS,
    payload: result,
  };
};

export const fetchTradersFail = (error) => ({
  type: OrderType.FETCH_TRADERS_FAILURE,
  payload: error,
});
// Apply Filter
export const changeApplyOrderFilter = (key, value, filterValue) => ({
  type: OrderType.ON_CHANGE_APPLY_ORDER_FILTER,
  payload: { key, value, filterValue },
});
  // clear order Filter
  export const changeApplyOrderClearFilter = (filterValue) => ({
    type: OrderType.ON_CHANGE_APPLY_ORDER_CLEAR_FILTER,
    payload: { 
  
     data:{ 
      order_id: [],
      item_name: "",
      trader_ids_list: [],
      from_date: null,
      to_date: null,
      amount_greater_than: 0,
      amount_less_than: 0,
      selectedDateRange: "",
      page: 1,
      limit: 10,}, 
    f_value:filterValue},
  });

  //createOrderSuccess
  //Create Order
export const createOrderStart = (selectedCardId, cardQuantities) => ({
  type: OrderType.CREATE_ORDER_START,
  payload: {selectedCardId, cardQuantities},
});

export const createOrderSuccess = (result) => ({
  type: OrderType.CREATE_ORDER_SUCCESS,
  payload: result,
});
export const createOrderFail = (error) => ({
  type: OrderType.CREATE_ORDER_FAILURE,
  payload: error,
})
