import * as TourScreenType from "./tourScreen.types";
// Get all tourScreenUsers List

export const fetchAllTourScreenUsersStart = (fromDate, toDate) => ({
    type: TourScreenType.GET_TOURSCREEN_USERS_LIST_START,
    payload: { fromDate, toDate }
  });
  
  export const fetchAllTourScreenUsersSuccess = (result) => ({
    type: TourScreenType.GET_TOURSCREEN_USERS_LIST_SUCCESS,
    payload: result,
  });
  
  export const fetchAllTourScreenUsersFail = (error) => ({
    type: TourScreenType.GET_TOURSCREEN_USERS_LIST_FAILURE,
    payload: error,
  });
  // Apply Filter
export const changeApplyTourScreenFilter = (key, value) => ({
  type: TourScreenType.ON_CHANGE_APPLY_TOUR_SCREEN_FILTER,
  payload: { key, value},
});