import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import ChartComponent from "../../../views/Admin/Chart";

// =================== Order Page ================= //
const Chart = () => {
  return <WithLayout layout={Admin} component={ChartComponent} />;
};


export default Chart;