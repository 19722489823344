import * as TradersActions from "./traders.actions";
import * as TradersTypes from "./traders.types";

import { tenantInstance } from "../../configs/axiosConfig";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import _ from "lodash";

// Fetch Users Listed Based on Roles
export function* fetchUsersBasedOnRolesAsync({ payload: { roleId, type, field } }: any) {
  let { retailerUsers, distributorUsers,filterValue, page,
    limit,} = yield select(
    (state) => state.traders
  );
  try {
    // Fetch user data from the API
    const { data } = yield tenantInstance.post(`/tenant/users/list`, {
      filters: {
        role_id: _.isArray(roleId) ? roleId : [roleId],
        f_name:filterValue==="retailer"? retailerUsers?.f_name:distributorUsers.f_name,
        l_name:filterValue==="retailer"? retailerUsers?.l_name:distributorUsers.l_name,
        email:filterValue==="retailer"? retailerUsers?.email:distributorUsers.email,
        mobile:filterValue==="retailer"? retailerUsers?.mobile:distributorUsers.mobile
      },
      pages: {
        page: filterValue==="retailer"? retailerUsers?.page :distributorUsers.page,
        limit: filterValue==="retailer"? retailerUsers?.limit :distributorUsers.limit,
      },
      sorting: [{
        type,
        field,
      }]
    });

    yield put(TradersActions.fetchUsersBasedOnRolesSuccess(roleId, data));
  } catch (error) {
    yield put(TradersActions.fetchUsersBasedOnRolesFail(error));
  }
}

export function* watchFetchUsersBasedOnRolesAsync() {
  yield takeLatest(
    TradersTypes.FETCH_USERS_BASED_ON_ROLE_START,
    fetchUsersBasedOnRolesAsync
  );
}

export function* traderSagas() {
  yield all([call(watchFetchUsersBasedOnRolesAsync)]);
}
