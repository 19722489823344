import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as PersonSalesReportType from "./personSalesReport.types"; 
import * as PersonSalesReportActions from "./personSalesReport.actions";
import { tenantInstance } from "../../configs/axiosConfig";
export function* getPersonSalesReportAsync({ payload }: any){
    let {user_id, name} = yield select((state) => state.personSalesReport);
    const { fromDate, toDate, limit, total, page, type, field, format_data, flag } = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/person-sales-report`, {
            filters: {
            user_id,
            name,
            format_data: flag,
            from_date: fromDate,
            to_date: toDate, 
            },
            pages:{
                page,
                limit,
            },
            sorting: [
              {
                type,
                field,
              }
            ]
        });
        yield put(PersonSalesReportActions.getPersonSalesReportListSuccess(data))
    }
    catch(error){
        yield put(PersonSalesReportActions.getPersonSalesReportListFail(error));
    }
}
export function* watchGetPersonSalesReportAsync(){
    yield takeLatest(
        PersonSalesReportType.GET_PERSON_SALES_REPORT_LIST_START,
        getPersonSalesReportAsync
    )
}
export function* personSalesReportSagas(){
    yield all([
        call(watchGetPersonSalesReportAsync)
    ]);
}