export const GET_EXCEL_FIELDS_START = "GET_EXCEL_FIELDS_START";
export const GET_EXCEL_FIELDS_SUCCESS = "GET_EXCEL_FIELDS_SUCCESS";
export const GET_EXCEL_FIELDS_FAILURE = "GET_EXCEL_FIELDS_FAILURE";

export const IMPORT_MAPPED_PRODUCTS_START = "IMPORT_MAPPED_PRODUCTS_START";
export const IMPORT_MAPPED_PRODUCTS_SUCCESS = "IMPORT_MAPPED_PRODUCTS_SUCCESS";
export const IMPORT_MAPPED_PRODUCTS_FAILURE = "IMPORT_MAPPED_PRODUCTS_FAILURE";

export const IMPORT_MAPPED_BEATS_START = "IMPORT_MAPPED_BEATS_START";
export const IMPORT_MAPPED_BEATS_SUCCESS = "IMPORT_MAPPED_BEATS_SUCCESS";
export const IMPORT_MAPPED_BEATS_FAILURE = "IMPORT_MAPPED_BEATS_FAILURE";

export const IMPORT_MAPPED_USERS_START = "IMPORT_MAPPED_USERS_START";
export const IMPORT_MAPPED_USERS_SUCCESS = "IMPORT_MAPPED_USERS_SUCCESS";
export const IMPORT_MAPPED_USERS_FAILURE = "IMPORT_MAPPED_USERS_FAILURE";