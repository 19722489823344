// Redux Imports
import { connect } from "react-redux";
import { fetchAllTourScreenUsersStart } from "../../../store/tourScreen/tourScreen.actions";

const mapStateToProps = (state) => ({
 
  tourScreen: state.tourScreen,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchAllTourScreenUsersStart: (fromDate, toDate) => dispatch(fetchAllTourScreenUsersStart(fromDate, toDate)),
  
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
