import {
  Box, Paper, InputAdornment, SelectChangeEvent, Stack, TextField, Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import SearchIcon from "../../../assets/images/search.svg";
import CustomButton from "../../../components/CustomButton";
import CreateIcon from "../../../assets/images/create.svg";
import container from "./BeatsAlt.container";
import BeatsTable from "./components/BeatsTable";
import _ from "lodash";
import BeatPopper from "./components/BeatPopper";
import BeatModalAlt from "./components/BeatModalAlt";
import { CustomPagination } from "../../../components/CustomTable/CustomTable";

const BeatsAlt = ({
  onFetchAllBeatsListStart,
  onTenantBeatsMoveTraderStart,
  beats: { allBeats,
    pages, remainingTraders,
    soloBeat, country, state, city
  },
  onFetchUsersBasedOnRolesStart,
  traders, 
  master,
  tenant,
  auth: { tenantProfile },
  onCreateTenantBeatStart,
  onFetchCountriesStart,
  onFetchStateByCountryIdStart,
  onFetchCityByStateIdStart,
  onFetchBeatsDataByIdStart,
  onfetchSoloBeatStart, 
  setOpenBeatModal, 
  onPushRetailerToBeatStart,
  selectedBeatId,
  onRemainingTradorsStart,
  beat_id,
}) => {
  // console.log("remainingTraders---->", remainingTraders);
  const [distributor, setDistributor] = React.useState("");

  const [rowsPerPage, setRowsPerPage] = React.useState(pages?.limit || 10);
  const [activePage, setActivePage] = React.useState<any>(1);
  const [noOfPages, setNoOfPages] = React.useState([]);

  const handleChange = (event: SelectChangeEvent) => {
    setDistributor(event.target.value as string);
  };

  React.useEffect(() => {
    onFetchAllBeatsListStart(1, 10, 'desc', 'id');
  }, []);

  const [activeBeat, setActiveBeat] = React.useState(null);

  React.useEffect(() => {
    if (!_.isEmpty(allBeats)) {
      const firstBeat: any = allBeats[0];
      // console.log("firstBeat", firstBeat);

      if (firstBeat) {
        setActiveBeat(firstBeat);
      }
    }
  }, [allBeats]);

  // const handleBeatChange = (index) => {
  //   if (!_.isEmpty(beatsDetails)) {
  //     const beats = Object.values(beatsDetails)[0];
  //     if (_.isArray(beats) && beats.length > index) {
  //       setActiveBeat(beats[index]);
  //     }
  //   }
  // };
  const [openBeatModal, setOpenBeataltModal] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setAnchorEl(value ? event.target : null);
  };

  const generatePageNumbers = (totalPages) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }
  React.useEffect(() => {
    const totalPages = Math.ceil(pages?.total/pages?.limit);
    const pages1 = generatePageNumbers(totalPages);
    setNoOfPages(pages1);
  }, [pages]);

  const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      onFetchAllBeatsListStart(activePage, event.target.value, 'desc', 'id');
  }
  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPage = Number(event.target.value);
      setActivePage(newPage);
      onFetchAllBeatsListStart(newPage, pages?.limit, 'desc', 'id');
  }
  const showPrevPage = () => {
      const nextPage = activePage - 1;
      onFetchAllBeatsListStart(nextPage, pages?.limit, 'desc', 'id');
      setActivePage(activePage - 1);
  }
  const showNextPage = () => {
      const nextPage = activePage + 1;
      onFetchAllBeatsListStart(nextPage, pages?.limit, 'desc', 'id');
      setActivePage(activePage + 1);
  }

  React.useEffect(() => {
    onFetchUsersBasedOnRolesStart([5, 6], 'desc', 'id');
  }, []);
  React.useEffect(() => {
    onRemainingTradorsStart();  
  }, []);

  return (
    <Fragment>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box flex={"30%"}>
            <Typography variant="h3">
              Retailers in {activeBeat ? activeBeat?.name : ""}
            </Typography>
            <Typography variant="subtitle1">
              {activeBeat?.beat_traders ? activeBeat?.beat_traders?.length : 0}{" "}
              retailers in this beat
            </Typography>
          </Box>
          <Stack direction={"row"} flex={"45%"} display={"flex"} spacing={2}>
            <TextField
              sx={{ flex: 0.6 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search" />
                  </InputAdornment>
                ),
              }}
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search Retailer"
              aria-describedby={id} 
            />
            {/* <Stack spacing={2} direction={"row"} flex={1}>
              <FormControl fullWidth>
                <InputLabel id="distributor-select-label-label">
                  Select Distributor
                </InputLabel>
                <Select
                  labelId="distributor-select-label"
                  id="distributor-select-label"
                  value={distributor}
                  label="Select Distributor"
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <ExpandMoreRoundedIcon {...props} />
                  )}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="secondary">
                <img src={SearchIconAlt} alt="Search" />
              </Button>
            </Stack> */}
          </Stack>
          <Box
            // flex={"25%"}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CustomButton
              name="Create Beat"
              // sx={{ width: "176px" }}
              icon={<img src={CreateIcon} alt="" />}
              handleClick={() => setOpenBeataltModal(true)}
            />
          </Box>
        </Box>
        <BeatsTable
          activeBeat={activeBeat}
          // setActiveBeat={setActiveBeat}
          // allBeats={allBeats}
          // onTenantBeatsMoveTraderStart={onTenantBeatsMoveTraderStart}
          // onFetchAllBeatsListStart={onFetchAllBeatsListStart}
          setOpenBeataltModal={setOpenBeataltModal} setActiveBeat={setActiveBeat} allBeats={allBeats} 
          beat_id = {beat_id}  
          remainingTraders = {remainingTraders}   
        />
      </Box>
      <BeatPopper
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        // traders={traders}
        inputValue={inputValue}
        id={id}
        onPushRetailerToBeatStart = {onPushRetailerToBeatStart}
        remainingTraders = {remainingTraders}
        activeBeat = {activeBeat}
        onFetchAllBeatsListStart = {onFetchAllBeatsListStart}
      />
      <BeatModalAlt
        openBeatModal={openBeatModal}
        setOpenBeataltModal={setOpenBeataltModal}
        soloBeat={soloBeat}
        master={master}
        // tenant={tenant}
        onCreateTenantBeatStart={onCreateTenantBeatStart}
        onFetchCountriesStart={onFetchCountriesStart}
        onFetchStateByCountryIdStart={onFetchStateByCountryIdStart}
        onFetchCityByStateIdStart={onFetchCityByStateIdStart}
        onFetchBeatsDataByIdStart={onFetchBeatsDataByIdStart}
        onfetchSoloBeatStart={onfetchSoloBeatStart}
        traders={traders}
        onFetchAllBeatsListStart = {onFetchAllBeatsListStart}
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <CustomPagination 
          count={pages?.total}
          rowsPerPage={rowsPerPage}
          page={activePage - 1}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          // pageNo={pages?.total}
          pageNo={activePage}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
          />
        </Paper>
    </Fragment>
  );
};

export default container(BeatsAlt);