import React from 'react';
import container from "./SettingList.container";
import _ from 'lodash';
import { Box, Grid, Typography, FormControl, FormLabel, MenuItem, Select, Paper } from '@mui/material';
// Project imports
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

const SettingDataList = ({ loading, settingData,onupdateSettingStart }) => {
    
  const formValuesInitial = {
    user_id: '',
    attendance: 0,
    notification: 0,
    order_on_behalf: 0
  };
  const [formValues, setFormValues] = React.useState(formValuesInitial);
  const [isEdit, setIsEdit] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState('');
  React.useEffect(() => {
    if (settingData && settingData.data && settingData.data.users_settings) {
      const usersSettings = settingData.data.users_settings;
      setFormValues({
        user_id: usersSettings.user_id,
        attendance: usersSettings.attendance,
        notification: usersSettings.notification,
        order_on_behalf: usersSettings.order_on_behalf
      });
    }
  }, [settingData]);
  const handleChange = (event) => {
    setSelectedOption(event.target.value as string);
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const navigate = useNavigate();
  const handleSave = () => {
    setIsEdit(false);
    navigate("/manage/users");
    onupdateSettingStart({
      user_id: formValues.user_id,
      attendance: formValues.attendance,
      notification: formValues.notification,
      order_on_behalf: formValues.order_on_behalf
    },() => {
      // Callback after saving, navigate to the desired page
      // navigate("/manage/users");
      console.log("Save Button Clicked");
    });
  };

  return (
    <>
      <Box>
        <Paper sx={{ width: "100%", mb: 2, padding: (theme) => theme.spacing(2), }}>
          <Typography variant="h3">Primary settings</Typography>
          {!isEdit && <CustomButton name="Edit" type="submit" handleClick={handleEdit} variant="outlined" sx={{ color: '#05ADAD', marginLeft: 'auto', display: "flex", width: "107px", height: "42px" }} />}
          {isEdit && <CustomButton name="Save" type="submit" handleClick={handleSave} variant="outlined" sx={{ color: '#05ADAD', marginLeft: 'auto', display: "flex", width: "107px", height: "42px" }} />}

          <FormControl sx={{ width: "230px", marginRight: "10px" }}>
              <FormLabel id="org" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500, }}>
                Attendance
              </FormLabel>
              {!isEdit && (
                <Typography variant="h3" sx={{ size: "20px", marginTop: "8px", }}>
                  {/* {formValues.attendance === 0 ? "No Attendance" : formValues.attendance === 1 ? "Attendance" : "Attendance & Capcture Image"} */}
                  {formValues.attendance === 0 ? "No Attendance" : formValues.attendance === 1 ? "Attendance" : "Attendance & Capture Image"}
                </Typography>
              )}
              {isEdit && (
                <Select value={formValues.attendance} onChange={handleChange} name="attendance">
                  <MenuItem value={0}>No Attendance</MenuItem>
                  <MenuItem value={1}>Attendance</MenuItem>
                  <MenuItem value={2}>Attendance & Capcture Image</MenuItem>
                </Select>
              )}
          </FormControl>
          <FormControl sx={{ width: '230px', marginRight: "10px" }}>
              <FormLabel id="org" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Notification
              </FormLabel>
              {!isEdit && (
                <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>
                  {formValues.notification === 0 ? "Alert" : "Silent"}
                </Typography>
              )}
              {isEdit && (
                <Select value={formValues.notification} onChange={handleChange} name="notification">
                  <MenuItem value={0}>Alert</MenuItem>
                  <MenuItem value={1}>Silent</MenuItem>
                </Select>
              )}
          </FormControl>
          <FormControl sx={{ width: '230px', marginRight: "10px" }}>
              <FormLabel id="org" sx={{ marginBottom: "8px", fontSize: "20px", fontWeight: 500 }}>
                Order on behalf
              </FormLabel>
              {!isEdit && (
                <Typography variant="h3" sx={{ size: "20px", marginTop: "8px" }}>
                  {formValues.order_on_behalf === 0 ? "Not Show Menu" : "Show Menu"}
                </Typography>
              )}
              {isEdit && (
                <Select value={formValues.order_on_behalf} onChange={handleChange} name="order_on_behalf">
                  <MenuItem value={0}>Not Show Menu</MenuItem>
                  <MenuItem value={1}>Show Menu</MenuItem>
                </Select>
              )}
          </FormControl>
        </Paper>
      </Box>

      
    </>
  )
};

export default container(SettingDataList);