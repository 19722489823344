import React, { Fragment, useState } from 'react';
import container from './Visit.container';
import { Box, Paper, TableCell, TableRow, Typography, Grid, Popover,
Divider, FormControlLabel, RadioGroup, FormLabel } from '@mui/material';
import VisitScreen from './components/VisitTable';
import moment from 'moment';
import { EnhancedTableToolbar } from '../../../components/CustomTable';
import VisitFilter from './components/VisitFilter';
import CustomButton from '../../../components/CustomButton';
import ExportIcon from "../../../assets/images/export.svg";
import { useDispatch } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import HeadColumn from "./components/HeadColumn";
import FilterIcon from "../../../assets/images/filter.svg";
import Calendar from './components/Calendar';
import { searchVisiDataName } from '../../../store/visitlist/visit.action';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Visits = ({onGetVisitListStart, onFetchReportListStart,
  visitScreen: {visitData, page_details}}) => {
  // const currentDate = moment().format("YYYY-MM-DD");
  const currentMonthStart = moment().startOf('month').format('YYYY-MM-DD');
  const currentMonthEnd = moment().endOf('month').format('YYYY-MM-DD');
  const [fromDate, setFromDate] = React.useState(currentMonthStart);
  const [toDate, setToDate] = React.useState(currentMonthEnd); 
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();

  const [customDateLabel, setCustomDateLabel] = useState('Custom Date Selection');

  const [customDatePopoverOpen, setCustomDatePopoverOpen] = useState(false);

  const handleCustomDatePopoverOpen = (event) => {
    setCustomDatePopoverOpen(true);
  };
  
  const handleCustomDatePopoverClose = () => {
    setCustomDatePopoverOpen(false);
  };
  const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
  ]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setCustomDateLabel(
      `${moment(ranges.selection.startDate).format('MM/DD/YYYY')} - ${moment(ranges.selection.endDate).format(
        'MM/DD/YYYY'
      )}`
    );
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };
  const handleFilter = () => {
    handleClose();
     if (dateRange.length === 1) {
        const selectedStartDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
        const selectedEndDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
        onGetVisitListStart(selectedStartDate, selectedEndDate);
        setFromDate(selectedStartDate);
        setToDate(selectedEndDate);
    }
};


  const handleClearFilter = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: "#008E8E", 
      },
    ]);
    setCustomDateLabel("Custom Date Selection");
    onGetVisitListStart(currentMonthStart, currentMonthEnd);
    setFromDate(currentMonthStart);
    setToDate(currentMonthEnd)
  };

  const handleSearchChange = (e) => {
    dispatch(searchVisiDataName("name", e));
    onGetVisitListStart(fromDate, toDate, 'asc', 'name');
  };
  // React.useEffect(() => {
  //   onGetVisitListStart();
  // }, []);

  return(
    <Fragment>
      <Box position={"relative"}>
      <Typography variant="h3">Just Visit Reports</Typography>
      <Box
          sx={{
            display: "none",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "25px",
          }}
        >
          <Typography variant="h3">Just Visit Reports</Typography>
          <CustomButton
            name="Export"
            sx={{ width: "122px" }}
            icon={<img src={ExportIcon} alt="" />}
          />
        </Box>
      </Box>

      <Paper sx={{ width: "100%", mt: 2}}>
        <EnhancedTableToolbar 
          numSelected={0}
          handleChange={handleSearchChange}

          tableHeaderComps = {
            <>
                <Box 
                    sx={{
                        width: "89px",
                        height: "29px",
                        padding: "18px 10px",
                        borderRadius: "4px",
                        border: "1px solid #45464E",
                        display: "flex",
                        alignItems: "center",
                        gap: "9px",
                        cursor: "pointer",
                        }}
                        onClick={handleClick}
                    >
                    <img src={FilterIcon} alt="filter" />
                    <Typography>Filter</Typography>
                </Box>
                <Popover 
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    >
                    <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                  Filter
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs = {12}>
                        <Grid item xs={12}>
                        <>
                                        <FormLabel id="name" sx={{ marginBottom: "8px" }}>
                                          Date Selection
                                        </FormLabel>
                                        <Typography
                                          sx={{ 
                                            ml: 2, mt: 1,
                                            width: "185px",
                                            height: "29px",
                                            padding: "18px 10px",
                                            borderRadius: "4px",
                                            border: "1px solid #45464E",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "9px",
                                            cursor: "pointer",
                                         }}
                                          onClick={handleCustomDatePopoverOpen}
                                        >
                                          {customDateLabel}
                                        </Typography>
                                        <Popover
                                        open={customDatePopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCustomDatePopoverClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        >
                                          <Box sx={{ padding: "24px 21px", maxWidth: 600, width: "100%" }}>
                                            <IconButton
                                              aria-label="close"
                                              onClick={handleCustomDatePopoverClose}
                                              sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                              }}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                            <div style={{ fontFamily: "Roboto, sans-serif", marginTop: 16 }}>
                                              <DateRangePicker
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="vertical"
                                                showDateDisplay={false}
                                                editableDateInputs={false}
                                              />
                                            </div>    
                                          </Box>
                                        </Popover>
                                      </>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <CustomButton
                            variant = "outlined"
                            name="Clear Filter"
                            handleClick={handleClearFilter}
                            sx={{ width: '100%', mt: "5px" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CustomButton
                            name="Filter"
                            handleClick={handleFilter}
                            sx={{ width: "100%", mt: "5px" }}
                          />
                        </Grid>
                    </Box>
                </Popover>
            </>
        }
        />
      </Paper>
      <Grid container>
        <Grid item xs={4} md={4}>
          <HeadColumn 
            onGetVisitListStart = {onGetVisitListStart}
            visitScreen = {{visitData, page_details}}
          />
        </Grid>
        <Grid item xs={8} md={8}>
          <Calendar
            fromDate = {fromDate}
            toDate = {toDate}
            onGetVisitListStart = {onGetVisitListStart}
            visitScreen = {{visitData, page_details}}
            onFetchReportListStart = {onFetchReportListStart}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}
export default container(Visits);

