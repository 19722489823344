import * as InvitationType from "./invitation.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  invitations: null,
  error: null,
  loading: false,
};

// Reducer function responsible for managing the authentication state
const invitationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case InvitationType.ADD_INVITATION_START:
    case InvitationType.LIST_INVITATION_START:
    case InvitationType.ACCEPT_INVITATION_START:
      return {
        ...state,
        loading: true,
      };

    case InvitationType.ADD_INVITATION_SUCCESS:
      return {
        ...state,
        invitations: payload,
        loading: false,
      };

    case InvitationType.LIST_INVITATION_SUCCESS:
      return {
        ...state,
        invitations: payload?.data?.invitations,
        loading: false,
      };

    case InvitationType.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case InvitationType.ADD_INVITATION_FAILURE:
    case InvitationType.LIST_INVITATION_FAILURE:
    case InvitationType.ACCEPT_INVITATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default invitationReducer;
