export const ADD_INVITATION_START = "ADD_INVITATION_START";
export const ADD_INVITATION_SUCCESS = "ADD_INVITATION_SUCCESS";
export const ADD_INVITATION_FAILURE = "ADD_INVITATION_FAILURE";

export const LIST_INVITATION_START = "LIST_INVITATION_START";
export const LIST_INVITATION_SUCCESS = "LIST_INVITATION_SUCCESS";
export const LIST_INVITATION_FAILURE = "LIST_INVITATION_FAILURE";

export const ACCEPT_INVITATION_START = "ACCEPT_INVITATION_START";
export const ACCEPT_INVITATION_SUCCESS = "ACCEPT_INVITATION_SUCCESS";
export const ACCEPT_INVITATION_FAILURE = "ACCEPT_INVITATION_FAILURE";
