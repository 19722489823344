export const GET_ORGANISATION_LIST_START =
  "GET_ORGANISATION_LIST_START";
export const GET_ORGANISATION_LIST_SUCCESS =
  "GET_ORGANISATION_LIST_SUCCESS";
export const GET_ORGANISATION_LIST_FAILURE =
  "GET_ORGANISATION_LIST_FAILURE";

export const CREATE_ORGANISATION_START = 
  "CREATE_ORGANISATION_START";
export const CREATE_ORGANISATION_SUCCESS =
  "CREATE_ORGANISATION_SUCCESS";
export const CREATE_ORGANISATION_FAILURE =
  "CREATE_ORGANISATION_FAILURE";