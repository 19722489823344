import * as React from 'react';
import { Box, Paper, Grid, Typography} from "@mui/material";
import moment from 'moment';
import Chart from 'react-google-charts';

const TodayAnalyticalView = ({onGetSalesReportTodayStart,
    salesReportDataToday: {salesReportDataToday}}) => {

  const [dataLoaded, setDataLoaded] = React.useState(false);
  const today = moment().format("YYYY-MM-DD");

  const monthlyDataArray = salesReportDataToday?.map((row) => {
    return row?.total_orders_value;
  });

  const userList = salesReportDataToday?.map((row) => {
    return row?.name;
  });

//   const xAxisData = Array.from({ length: salesReportDataToday?.total_orders_value }, (_, index) => index + 1);

  const chartData = [
    ['x', 'Sales'],
    ['0', 0],
    // ...xAxisData.map((item, index) => [`${userList[index]}`, monthlyDataArray[index] || 0,  ])
    ...userList.map((item, index) => [item, monthlyDataArray[index] || 0]),
  ]

  const chartData1 = [
    ['x', 'Sales'],
    ["1", 0],
    ["2", 0],
    ["3", 0],
    ["4", 0],
    ["5", 0]
  ]

  const marginValue = dataLoaded && salesReportDataToday.length > 0 ? "-53px" : "1px";

  const options = {
    title: "",
    curveType: "none",
    legend: { position: "none", },
    hAxis: { textPosition: "none", gridlines: { color: 'transparent' }  },
    vAxis: { textPosition: "none", gridlines: { color: 'transparent' }  },
    backgroundColor: 'transparent', 
    baselineColor: "transparent",
    series: {
      0: { color: "#2E5BE3", lineWidth: 3, type: 'area', areaOpacity: 0.3  }
    },
    chartArea: {
      width: "100%",
    }
  };

  function formatedNumber(number) {
    return number.toLocaleString('en-IN');
  }

  React.useEffect(() => {
    onGetSalesReportTodayStart(today, today);
  }, []);

  React.useEffect(() => {
    if (salesReportDataToday) {
      setDataLoaded(true);
    }
  }, [salesReportDataToday]);

  return(
    <Grid container>
      <Paper sx={{width: '100%', border: '1px solid #EAEFF5', borderRadius: "14px",}}>
        <Grid item md={12} xl = {12} lg = {12} xs = {12}>
          <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>Today's Sales</Typography>
        </Grid>

        {/* <Grid item md={12}>
          <Typography sx={{mt: "12px", ml: "10px", fontWeight: "bold", fontSize: "22px"}}>₹{salesReportDataToday != null ? formatedNumber(salesReportDataToday.toFixed(2)) : "0.00"}</Typography>
        </Grid> */}

        <Grid container
         sx={{height: "110px"}}
         >
          <Grid item md={2} xl = {2} lg = {2} xs = {2}>
              <Typography sx={{mt: "28px", ml: "10px", color: "#009E73", fontSize: "14px"}}>+17.7%</Typography>
          </Grid>

          <Grid item md={10} xl = {10} lg = {10} xs = {10} sx={{mt: marginValue}} >
            {dataLoaded && monthlyDataArray.length > 0 ? (
              <>
                <Chart
                  chartType="LineChart"
                  data={chartData}
                  options={options}
                />
              </>
            ) : (
              <Chart
                chartType="LineChart"
                data={chartData1}
                options={options}
              />
            )}
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

export default TodayAnalyticalView;