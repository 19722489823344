import React, {  useEffect, useState } from "react";
import moment from 'moment';
import { axisClasses } from '@mui/x-charts';
import { Grid, Paper, Typography, FormControl, Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { BarChart } from '@mui/x-charts/BarChart';

const YearlyBeatsPerformanceView = ({onYearlyBeatsListStart,
    reportByBeats: { yearlyBeatsList, yeralyGrandeTotalSales, yearlyIndividualMonthlySales}
}) => {
    const today = moment().format("YYYY-MM-DD");

    const startDate = moment(today).startOf('year').format("YYYY-MM-DD");
    const endDate = moment(today).endOf('year').format("YYYY-MM-DD");

    const distinctBeatNames = yearlyBeatsList.reduce((acc, item) => {
      if (!acc.includes(item.name)) {
        acc.push(item.name);
      }
      return acc;
    }, []);

    // const [selectedBeats, setSelectedBeat] = useState(distinctBeatNames.slice(0, 2));
    const [selectedBeats, setSelectedBeats] = useState(["Unjha 1", "Unjha 2"]);
    const [dataset, setDataset] = useState([]);

    const [loading, setLoading] = useState(true);

    const [yAxisData, setYAxisData] = useState([]);

    const chartSetting = {
      yAxis: [
        {
          // label: 'rainfall (mm)',
        },
      ],
      width: 440,
      height: 300,
      sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: 'translate(-20px, 0)',
        },
      },
    };
    
    
    const handleBeatChange = (event) => {
        const selectedBeats = event.target.value;
        setSelectedBeats(prevSelectedBeats => {
            const updatedSelectedBeats = prevSelectedBeats.length === 2
                ? [selectedBeats, prevSelectedBeats[1]]
                : [selectedBeats, ...prevSelectedBeats.slice(1)];
            updateDataset(updatedSelectedBeats);
            return updatedSelectedBeats;
        });
    };

    const updateDataset = (selectedBeats) => {
        const updatedDataset = yearlyBeatsList
            .filter(beat => selectedBeats.includes(beat.name))
            .map(beat => ({
                name: beat.name,
                data: beat.sales.map(sale => ({
                    month: sale.date,
                    value: sale.total_order_value
                }))
            }));

        
        setDataset(updatedDataset);
        setLoading(false); 
    };

    const valueFormatter = (value: number) => `${value}`;

    React.useEffect(() => {
        setLoading(true);
        onYearlyBeatsListStart(startDate, endDate);
    }, []);

    useEffect(() => {
        updateDataset(selectedBeats);
    }, [selectedBeats, yearlyBeatsList]);

    // console.log("dataset---->", dataset);
    return(
        <Grid item container xs={12} md={12}>
            <Paper sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px', borderRadius: "14px" }}>
                <Grid container item md={12}>
                    <Grid md={6}>
                        <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>Best Performance</Typography>
                    </Grid>
                    <Grid md={6}>
                        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                            <InputLabel id="demo-select-small-label">
                                {" "}
                                <Typography sx={{ color: "#1C1D22" }}>Compare Beats</Typography>
                            </InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedBeats}
                                onChange={handleBeatChange}
                                label="Compare Beats"
                            >
                                {distinctBeatNames.map((beatName, index) => (
                                    <MenuItem key={index} value={beatName}>
                                        {beatName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item md={12} sx={{mt: "10px"}}>
                    {loading ? (
                            <Typography>Loading...</Typography>
                        ) : (
                            <BarChart
                                dataset={dataset.flatMap(beat => beat.data.map(sale => ({ name: beat.name, month: sale.month, value: sale.value })))}
                                xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                                yAxis={[{ scaleType: 'linear', dataKey: 'value' }]}
                                series={dataset.map(beat => ({
                                    dataKey: 'value',//beat.name,
                                    label: beat.name,
                                    valueFormatter
                                }))}
                                {...chartSetting}
                                sx={{ paddingLeft: "15px" }}
                            />
                        )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default YearlyBeatsPerformanceView;