import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { StyledTab, StyledTabs, TabPanel } from "../../../components/CustomTab";
import container from "./Traders.container";
import RetailerTable from "./components/RetailerTable";
import DistributorTable from "./components/DistributorTable";

const Traders = ({
  onFetchUsersBasedOnRolesStart,
  traders: { retailerUsers, distributorUsers, loading },
  onChangeApplyTraderFilter,
}) => {
  const menuList = [
    {
      name: "Retailers",
      index: 0,
      role_id: 5,
    },
    {
      name: "Distributors",
      index: 1,
      role_id: 6,
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "25px",
            marginLeft: "25px",
          }}
        >
          <Typography variant="h3">Traders List</Typography>
        </Box>
        <Box sx={{ width: "100%", zIndex: 1 }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            {menuList?.map((list) => (
              <StyledTab label={list?.name} key={list?.index} />
            ))}
          </StyledTabs>

          <TabPanel value={value} index={0}>
            <RetailerTable
              loading = {loading}
              retailerUsers={retailerUsers}
              onFetchUsersBasedOnRolesStart={onFetchUsersBasedOnRolesStart}
              onChangeApplyTraderFilter={onChangeApplyTraderFilter}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DistributorTable
              distributorUsers={distributorUsers}
              onFetchUsersBasedOnRolesStart={onFetchUsersBasedOnRolesStart}
              onChangeApplyTraderFilter={onChangeApplyTraderFilter}
            />
          </TabPanel>
        </Box>
      </Box>
    </Fragment>
  );
};

export default container(Traders);
