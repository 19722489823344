import { utils } from "xlsx";

/**
 * Decodes the range string into a range object.
 * @param {string} range - The range string to decode.
 * @returns {object} The decoded range object.
 */
export function safe_decode_range(range) {
  var o = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
  var idx = 0,
    i = 0,
    cc = 0;
  var len = range.length;

  // Decode the column index
  for (idx = 0; i < len; ++i) {
    if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
    idx = 26 * idx + cc;
  }
  o.s.c = --idx;

  // Decode the row index
  for (idx = 0; i < len; ++i) {
    if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
    idx = 10 * idx + cc;
  }
  o.s.r = --idx;

  // Check if range string only contains a single cell
  if (i === len || range.charCodeAt(++i) === 58) {
    o.e.c = o.s.c;
    o.e.r = o.s.r;
    return o;
  }

  // Decode the ending column index
  for (idx = 0; i !== len; ++i) {
    if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
    idx = 26 * idx + cc;
  }
  o.e.c = --idx;

  // Decode the ending row index
  for (idx = 0; i !== len; ++i) {
    if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
    idx = 10 * idx + cc;
  }
  o.e.r = --idx;

  return o;
}

/**
 * Retrieves the header values from a sheet.
 * @param {object} sheet - The sheet object from which to retrieve the headers.
 * @returns {array} An array of header values.
 */
export function getHeaders(sheet: any) {
  var header = 0,
    offset = 1;
  var hdr: any = [];
  var o: any = {};

  // Check if sheet or range is not defined
  if (sheet == null || sheet["!ref"] == null) return [];

  var range = o.range !== undefined ? o.range : sheet["!ref"];
  var r;

  // Determine the header type and decode the range
  if (o.header === 1) header = 1;
  else if (o.header === "A") header = 2;
  else if (Array.isArray(o.header)) header = 3;

  switch (typeof range) {
    case "string":
      r = safe_decode_range(range);
      break;
    case "number":
      r = safe_decode_range(sheet["!ref"]);
      r.s.r = range;
      break;
    default:
      r = range;
  }

  if (header > 0) offset = 0;

  var rr = utils.encode_row(r.s.r);
  var cols = new Array(r.e.c - r.s.c + 1);

  // Iterate over the columns within the range and retrieve the header values
  for (var C = r.s.c; C <= r.e.c; ++C) {
    cols[C] = utils.encode_col(C);
    var val = sheet[cols[C] + rr];
    switch (header) {
      case 1:
        hdr.push(C);
        break;
      case 2:
        hdr.push(cols[C]);
        break;
      case 3:
        hdr.push(o.header[C - r.s.c]);
        break;
      default:
        if (val === undefined) continue;
        hdr.push(utils.format_cell(val));
    }
  }

  return hdr;
}
