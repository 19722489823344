import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import CustomizedDialog from "../../../../components/CustomDialog";

import CustomButton from "../../../../components/CustomButton";
import { Formik } from "formik";

const WEEKNAME = {
  0: "Mon",
  1: "Tue",
  2: "Wed",
  3: "Thu",
  4: "Fri",
  5: "Sat",
  6: "Sun",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRight: "1px solid #CFD3D4",
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: "1px solid #CFD3D4",
  },
}));

function createData(cellMapping: any[], data: any[]) {
  let list: any = [];

  // console.log(data)
  data.forEach((item: any) => {
    const obj: any = {};
    obj.name = item.name;
    obj.id = item.id;

    item.events.forEach((evt: any) => {
      obj[evt.date] = evt;
    });

    list.push(obj);
  });

  const list1: any[] = [];

  list.map((item: any) => {
    const list2: any[] = [];

    cellMapping.forEach((item1: any, idx) => {
      if (item1.name === "name") {
        list2.push({
          name: item[item1.name],
          user_id: item.id,
        });
      } else {
        list2.push({
          date: item1.name,
          event: item[item1.name],
          user_id: item.id,
        });
      }
    });

    list1.push(list2);
  });

  return list1;
}

const Calendar = ({
  onFetchAllBeatsListStart,
  tourPrograms,
  allBeats, pages,
  createOrUpdateTenantTourProgramStart,
  onOpenAlert,
  selectedDate,
  onListTenantTourProgramsListStart
}) => {
  const [open, setOpen] = useState(false);
  const [dateObject, setDateObject] = useState(selectedDate);

  useEffect(() => {
    setDateObject(selectedDate);
  }, [selectedDate]);
  useEffect(() => {
    if (open) {
      onFetchAllBeatsListStart(1, 270, 'desc', 'id');
    }
  }, [open]);

  const daysInMonthfn = () => {
    return dateObject.daysInMonth();
  };

  function pad(number: string | number, length: number) {
    let str = String(number);
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  }

  const cellMapping = [];
  cellMapping.push({
    name: "name",
    primary: "Salesman Name",
  });

  for (let d = 1; d <= daysInMonthfn(); d++) {
    const currYear = dateObject.format("YYYY");
    const currMonth = dateObject.format("M");

    const fullDate = currYear + "-" + pad(currMonth, 2) + "-" + pad(d, 2);

    const curr = moment(fullDate, "YYYY-MM-DD");

    // const weekDay: number = curr.weekday() - 1;
    let weekDay: number = curr.weekday(); 
    if (weekDay === 0) {
        weekDay = 7;
    }

    cellMapping.push({
      name: fullDate,
      primary: pad(d, 2),
      secondary: WEEKNAME[weekDay - 1], //WEEKNAME[weekDay],
    });
  }

  const headers = cellMapping.map((item) => {
    return (
      <th key={item.name}>
        {item.secondary && <span>{item.secondary}</span>}
        {item.primary}
      </th>
    );
  });

  const formattedData = tourPrograms
    ? tourPrograms.map((data) => ({
        id: data?.user_id,
        name: data?.name,
        events: data?.dates
          ? data?.dates
              .filter((d) => d?.beats !== null)
              .map((el) => {
              
               return  {
                
                id: el?.beats[0]?.beat_id,
                name: el?.beats[0]?.beat_name,
                date: el?.date,
              }})
          : [],
      }))
    : [];

  const data1 = createData(cellMapping, formattedData);

  return (
    <Fragment>
      <Formik
        initialValues={{
          user_id: null,
          date: null,
          beat_details: null,
          isUpdating: false,
        }}
        enableReinitialize
        onSubmit={(values, setFieldValue) => {
          createOrUpdateTenantTourProgramStart(
            {
              user_id: values?.user_id,
              dates: [
                {
                  date: values?.date,
                  beats: [
                    {
                      beat_id: values?.beat_details?.id,
                      beat_name: values?.beat_details?.name,
                    },
                  ],
                },
              ],
            },
            () => {
              setOpen(false);
              onOpenAlert(
                `Tour Program ${
                  values?.isUpdating ? "Updated" : "Added"
                } Successfully`,
                "success"
              );
            }
          );
          onListTenantTourProgramsListStart()
        }}
      >
        {({      
          submitForm,
          isSubmitting,
          setFieldValue,
          handleSubmit,
          values,
        }) => (
          <Fragment>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {headers?.map((header, index) => {
                          // console.log(header, "header");
                          return (
                            <StyledTableCell
                              sx={{
                                borderTop: "1px solid #CFD3D4",
                                minWidth: index === 0 ? "189px" : "auto",
                                width: "100% !important",
                              }}
                            >
                              {header}
                            </StyledTableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data1.map((item, idx) => {
                        return (
                          <TableRow key={`adf-${idx}`}>
                            {item.map((item1) => {
                              if (item1.name) {
                                return (
                                  <StyledTableCell key={item1.name}>
                                    {item1.name}
                                  </StyledTableCell>
                                );
                              } else if (!_.isUndefined(item1.event)) {
                                return (
                                  <Fragment key={item1.date}>
                                    {item1.event && (
                                      <StyledTableCell
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setOpen(true);
                                          setFieldValue(
                                            "user_id",
                                            item1?.user_id
                                          );

                                          setFieldValue(
                                            "beat_details",
                                            item1?.event
                                          );
                                          setFieldValue("date", item1?.date);
                                          setFieldValue("isUpdating", true);
                                        }}
                                      >
                                        {item1.event.name}
                                      </StyledTableCell>
                                    )}
                                  </Fragment>
                                );
                              } else {
                                return (
                                  <StyledTableCell
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setOpen(true);
                                      setFieldValue("user_id", item1?.user_id);
                                      setFieldValue("date", item1?.date);
                                      setFieldValue("isUpdating", false);
                                    }}
                                  />
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <CustomizedDialog
              open={open}
              maxWidth={"sm"}
              handleClose={() => setOpen(false)}
              fullWidth={true}
              title={
                <Typography variant="h3">
                  {values?.isUpdating ? "Update" : "Add"} Beat
                </Typography>
              }
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ mt: "28px" }}>
                  <Autocomplete
                    size="small"
                    id={"beat-list"}
                    options={allBeats?.length > 0 ? allBeats : []}
                    autoHighlight
                    clearIcon
                    value={
                      values?.isUpdating && values?.beat_details
                        ? values.beat_details
                        : null
                    }
                    getOptionLabel={(option: any) => option.name}
                    onChange={(e, newValue) => {
                      setFieldValue("beat_details", newValue);
                    }}
                    renderOption={(props, option) => (
                      <React.Fragment>
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="beats"
                        placeholder="Select Beat"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        onChange={() => {}}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
                  <CustomButton
                    name="Save"
                    handleClick={handleSubmit}
                    sx={{ width: "140px" }}
                  />
                </Grid>
              </Grid>
            </CustomizedDialog>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

export default Calendar;
