import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Avatar,
  InputAdornment,
  TextField,
  Popover
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../components/CustomTable";

import container from "./Attendance.container";
// import { changeApplyAttendanceScreenFilter } from "../../../store/attendance/attendance.actions";
import { useDispatch } from "react-redux";
// import { CustomPagination } from "../../../components/CustomTable/CustomTable";
import ExportIcon from "../../../assets/images/export.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import AttendanceFilter from "./components/AttendanceFilter";
import SearchIcon from "../../../assets/images/search.svg";
import CustomizedDialog from '../../../components/CustomDialog';

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  isShow: boolean;
  numeric: boolean;
  align?: string;
}

const AttendanceScreen = ({ data, limit, onFetchAttendanceUsersStart, attendanceScreen, 
  page:pageNo, beat_name, selectedDateRange, name, userNameFilter,filteredUsers,
  onSearchAttendanceUsersStart, onChangeApplyAttendaceFiler,
}) => {
  const navigate = useNavigate();

  type Order = "asc" | "desc";
  const headCells: readonly HeadCell[] = [
    {
      id: "username",
      numeric: false,
      disablePadding: true,
      label: "User Name",
      isShow: true,
    },
    {
      id: "check_in",
      numeric: false,
      disablePadding: false,
      label: "Check In",
      isShow: true,
    },
    {
      id: "che",
      numeric: false,
      disablePadding: false,
      label: "",
      isShow: true,
    },
    {
      id: "check_out",
      numeric: false,
      disablePadding: false,
      label: "Check Out",
      isShow: true,
    },
    {
      id: "break_timing",
      numeric: false,
      disablePadding: false,
      label: "Break Timing",
      isShow: true,
    },
    {
      id: "work_hours",
      numeric: false,
      disablePadding: false,
      label: "Work Hours",
      isShow: true,
    }
  ];
  const dispatch = useDispatch()
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("username" || "check_in");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [noOfPages, setNoOfPages] = React.useState([]);

  const currentDate = moment().format("YYYY-MM-DD");

  const [page, setPage] = React.useState(0);

  const [activePage, setActivePage] = React.useState<any>(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    attendanceScreen?.page_details?.limit
  );
  const isSelected = (name: any) => selected.indexOf(name) !== -1; 

  const [searchName, setSearchName] = useState("");

  const [dataAvailable, setDataAvailable] = useState(true);

  const [selectedButton, setSelectedButton] = useState('today');
  const [fromDate, setFromDate] = React.useState(currentDate);
  const [toDate, setToDate] = React.useState(currentDate); 

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const handleImageClick = (imageUrl) => {
  //   setSelectedImage(imageUrl);
  //   console.log()
  // };
  const handleImageClick = (event: any, imageUrl) => {
    setSelectedImage(imageUrl);
    setAnchorEl(event.currentTarget);
  };

const handleClose = () => {
    setAnchorEl(null);
};

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = attendanceScreen.data?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(
        attendanceScreen?.page_details?.total / attendanceScreen?.page_details?.limit
      );
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [attendanceScreen?.page_details?.total, attendanceScreen?.page_details?.limit]);

  // React.useEffect(() => {
  //   // onFetchAttendanceUsersStart(userNameFilter);
  //   onFetchAttendanceUsersStart(currentDate, currentDate);
  // }, []);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // console.log("Filter Clicked");
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  // const sortedData = attendanceScreen.data ? attendanceScreen.data.sort(getComparator(order, orderBy)) : [];
  const sortedData = attendanceScreen?.data?.data ? attendanceScreen?.data?.data.sort(getComparator(order, orderBy)) : [];
  // const sortedData = Array.isArray(attendanceScreen?.data?.data) ? attendanceScreen?.data?.data.sort(getComparator(order, orderBy)) : [];
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const emptyRows =
  page > 0
    ? Math.max(0, (1 + page) * rowsPerPage - attendanceScreen?.data.length)
    : 0;

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
    
  //   // onListTenantTourProgramsListStart(moment(date).format("YYYY-MM-DD"));
  // };

  const handleDateChange = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };


  const handleChange = (e) => {
    setSearchName(e.target.value); // Update the state with the new value
    dispatch(onSearchAttendanceUsersStart(e.target.value));
  }

  const handleSearchChange = (e) => {
    setSearchName(e.target.value);
    dispatch(onChangeApplyAttendaceFiler("username", e.target.value));
    onFetchAttendanceUsersStart(fromDate, toDate);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (sortedData.length === 0) {
        setDataAvailable(false);
      }
    }, 5000);

    return () => clearTimeout(timeout); 
  }, [sortedData]);

  const handleCalendarIconClick = () => {
    setIsDatePickerOpen(true);
  }
  const handleDatePickerChange = (date) => {
    setSelectedDate(date);
    setIsDatePickerOpen(false);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    onFetchAttendanceUsersStart(formattedDate, formattedDate)
  }
  React.useEffect(() => {
    onFetchAttendanceUsersStart(currentDate, currentDate);
    setAnchorEl(null);
    setSelectedButton('today')
  }, []);
  
  return (
    <Fragment>
      <Paper sx={{ width: "100%", }}>
        <TableContainer>
        <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {/* <Box sx={{ width: "130px", height: "29px", padding: "21px 10px", borderRadius: "4px", border: "1px solid #45464E", display: "flex", alignItems: "center", gap: "9px", marginLeft: '8px' }}>
          <Typography variant="subtitle1"><img src={CalendarIcon} alt="calendar" /> {currentDate}</Typography>
        </Box> */}
        <Box sx={{display: "flex"}}>
          <ButtonGroup
            color="secondary"
            size="small"
            sx={{
              "& > button": {
                textTransform: "none",
              },
            }}
            >
            <Button 
            variant={selectedButton === 'today' ? 'contained' : 'outlined'}
            sx={{ color: selectedButton === 'today' ? '#fff' : '#8B8D97' }}
              onClick={() => {
                const today = moment().format("YYYY-MM-DD");
                setFromDate(today);
                setToDate(today);
                onFetchAttendanceUsersStart(today, today);
                setSelectedButton('today')}}
                >
              Today
            </Button>
            <Button 
              variant={selectedButton === 'yesterday' ? 'contained' : 'outlined'}
              sx={{ color: selectedButton === 'yesterday' ? '#fff' : '#8B8D97' }} 
              onClick={() => {
              const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
              setFromDate(yesterday);
              setToDate(yesterday);
              onFetchAttendanceUsersStart(yesterday, yesterday);
              setSelectedButton('yesterday')
            }}>Yesterday</Button>
            <>
            </>
          </ButtonGroup>
          <Typography variant = "h5" sx={{ ml: 2, mt: 1 }}>Date: {fromDate} to {toDate}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <TextField
            placeholder="Search"
            id="outlined-size-small"
            size="small"
            sx={{ m: 1, minWidth: 180 }}
            value={searchName}
            onChange={(e) => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search" />
                </InputAdornment>
              ),
            }}
          /> */}
          <AttendanceFilter
            onFetchAttendanceUsersStart={onFetchAttendanceUsersStart}
            // name={name}
            // beat_name={beat_name}  
            // selectedDateRange={selectedDateRange}
            setSelectedButton = {setSelectedButton}
            onDateChange={handleDateChange}
          />
        </Box>
      </Box>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={attendanceScreen?.data?.length}
              headCells={headCells}
              isCheckBox={false}
            />

            <TableBody>
              {
              // sortedData?.length == 0 ? "No Data Found" : sortedData?.data?.map((row, index) => {
                sortedData.length > 0 ? (
                  sortedData.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                //
                const imageFileData = row?.image;
                  // console.log("imageFileData---->", imageFileData); 
                  let imageUrl;
                  try {
                    const byteCharacters = atob(imageFileData);
                    const byteArrays = [];
                  
                  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                  
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                      byteNumbers[i] = slice.charCodeAt(i);
                    }
                  
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                  }
                
                  const blob = new Blob(byteArrays, { type: "image/jpeg" });
                  imageUrl = URL.createObjectURL(blob);
                
                  } catch (error) {
                    // Handle the error
                    console.error("File decoding error:", error);
                  }
                //
                // console.log("attendanceScreen?.data---->", attendanceScreen?.data[0]);
                const beatRows = [];
                
                  beatRows.push(
                    <TableRow
                          hover
                          onClick={() => {}}
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          // key={`${attendanceScreen?.data[0].id}-${attendanceScreen?.data[0].date}-${attendanceScreen?.data[0].id}`}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                            sx={{ paddingLeft: "1rem" }}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {row.profileImageUrl == null ? (
                                <Avatar
                                  sx={{ width: 36, height: 36, cursor: "pointer" }}
                                  // src={row.image || '/path/to/default/image'}
                                  src = {imageUrl || '/path/to/default/image'}
                                  // onClick={() => navigate("/manage/user-profile")}
                                  onClick={(event) => handleImageClick(event, imageUrl)}
                                />
                              ) : (
                                <img
                                  // src={row.image}
                                  src= {imageUrl}
                                  alt="User Profile"
                                  style={{
                                    width: '32px',
                                    height: '32px',
                                    marginRight: '8px',
                                    borderRadius: '50%'
                                  }}
                                />
                              )}
                              <div> <span style={{ marginLeft: '8px' }}>{row.name}</span></div>
                            </div>
                          </TableCell>
                          {/* <TableCell align="left">{row.name}</TableCell> */}
                          {/* <TableCell align="left">{row.user_id}</TableCell> */}
                          <TableCell align="center">{row.start_time}</TableCell>
                          <TableCell align="left">-------</TableCell>
                          <TableCell align="center">{row.stop_time}</TableCell>
                          <TableCell align="center">{row.break_hours}</TableCell>
                          <TableCell align="center">{row.work_hours}</TableCell>
                        </TableRow>
                  )

                return beatRows; }) ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data Available</TableCell>
                  </TableRow>
                )
              }
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomizedDialog 
          open={open}
          handleClose={handleClose}
          title={
            <Typography sx={{ color: "#111" }} variant="h3">
               User Image
            </Typography>
          }
          maxWidth={"xs"}
          fullWidth={false}
            >
            
          <img
            src = {selectedImage}
            alt="User Profile"
            style={{
              width: '300px',
              height: '300px',
            }}
          />
        
        </CustomizedDialog>
      </Paper>
    </Fragment>
  );
};

export default container(AttendanceScreen);
