import * as SalesReportType from "./salesReport.types";

const INITIAL_STATE = {
  salesReportData: [],
  salesReportSummary: null,
  pageDetails: null,
  // page: 1,
  // limit: 10,
  salesReportDataToday: [],
  salesReportDataYesterday: [],
  salesReportDataMonthly: [],

  barCustomDateData: [],

  topOrdersData: [],
};

const salesReportReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type){
    case SalesReportType.GET_SALES_REPORT_LIST_START:
      return {
        ...state,
        loading: true,
      }

    case SalesReportType.GET_SALES_REPORT_LIST_SUCCESS: 
      return {
        ...state,
        salesReportData: payload?.data?.sales_report,
        salesReportSummary: payload?.data?.sales_report_summary,
        pageDetails: payload?.data?.page_details, 
        loading: false,
      }

    case SalesReportType.GET_SALES_REPORT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    // For Search
    case SalesReportType.SEARCH_SALES_REPORT_NAME:
      const { key, value } = action.payload;
      return {
        ...state,
        salesReportData: payload?.data?.sales_report,
        pageDetails: payload?.data?.page_details, 
        [key]: value,
      }

    // Today
    case SalesReportType.GET_SALES_REPORT_TODAY_START:
      return {
        ...state,
        loading: true,
      }

    case SalesReportType.GET_SALES_REPORT_TODAY_SUCCESS: 
      return {
        ...state,
        salesReportDataToday: payload?.data?.sales_report,
        loading: false,
      }

    case SalesReportType.GET_SALES_REPORT_TODAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    // Yesterday
    case SalesReportType.GET_SALES_REPORT_YESTERDAY_START:
      return {
        ...state,
        loading: true,
      }

    case SalesReportType.GET_SALES_REPORT_YESTERDAY_SUCCESS: 
      return {
        ...state,
        salesReportDataYesterday: payload?.data?.sales_report,
        loading: false,
      }

    case SalesReportType.GET_SALES_REPORT_YESTERDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    // Monthly
    case SalesReportType.GET_SALES_REPORT_MONTHLY_START:
      return {
        ...state,
        loading: true,
      }

    case SalesReportType.GET_SALES_REPORT_MONTHLY_SUCCESS: 
      return {
        ...state,
        salesReportDataMonthly: payload?.data?.sales_report,
        loading: false,
      }

    case SalesReportType.GET_SALES_REPORT_MONTHLY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    // Bar
    case SalesReportType.GET_ORDER_LIST_FOR_BAR_START:
        return {
          ...state,
          loading: true,
        }
    
    case SalesReportType.GET_ORDER_LIST_FOR_BAR_SUCCESS: 
      return {
        ...state,
        barCustomDateData: payload?.data?.sales_report,
        loading: false,
      }

    case SalesReportType.GET_ORDER_LIST_FOR_BAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    // Top Orders
    case SalesReportType.GET_HIGHEST_PLACED_ORDER_START:
        return {
          ...state,
          loading: true,
        }
    
    case SalesReportType.GET_HIGHEST_PLACED_ORDER_SUCCESS: 
      return {
        ...state,
        topOrdersData: payload?.data?.sales_report,
        loading: false,
      }

    case SalesReportType.GET_HIGHEST_PLACED_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    default: 
      return state;
  }
};
export default salesReportReducer;