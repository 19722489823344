import React from 'react';
import container from './ReportByDistributor.container';
import moment from 'moment';

const ReportByDistributorComponent = ({onGetReportByDistributorStart}) =>{
    
    const currentDate = moment().format("YYYY-MM-DD");
    React.useEffect(()=> {
        onGetReportByDistributorStart();
    }, [])
    return(
        <div>
            This is Report By Distributor Component
        </div>
    )
}
export default container(ReportByDistributorComponent);