import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { tenantInstance } from "../../configs/axiosConfig";
import * as ChartActions from "./chart.action";
import * as ChartType from './chart.types';
import moment from "moment";

//CHART List Data
export function* getChartListAsync({ payload }: any){
  let{selectedDateRange} = yield select((state) => state.chartScreen);
  const { startDate, endDate } = payload;
  try{
    const { data } = yield tenantInstance.post(`/tenants/visit/month-wise-user-calls`, {
      start_date: startDate,
      end_date: endDate,
        
    })
    yield put(ChartActions.getChartListSuccess(data));
  }
  catch (error) {
    yield put(ChartActions.getChartListFail(error));
  }
}

export function* watchGetChartListAsync() {
  yield takeLatest(
    ChartType.GET_CHART_LIST_START,
    getChartListAsync
  );
}
export function* chartSagas() {
  yield all([
    call(watchGetChartListAsync)
  ]);
}
