import {
  RECIEVE_ENABLE_API_DATA,
  RECIEVE_DISABLE_API_DATA,
  ON_CHANGE_APPLY_USERS_FILTER,
  FETCH_FILTERED_USERS_SUCCESS,
  FETCH_FILTERED_USERS_FAILURE,
  RECEIVE_DISABLE_DATA_FAILURE,
  RECEIVE_ENABLE_DATA_FAILURE,
  SEND_DISABLE_USER_FAILURE,
  SEND_ENABLE_USER_FAILURE,
  FETCH_FILTERED_DISABLE_USERS_FAILURE,
  FETCH_FILTERED_DISABLE_USERS_SUCCESS,
  ON_CHANGE_APPLY_DISABLE_USERS_FILTER,
  ON_CHANGE_APPLY_USER_CLEAR_FILTER,
  ON_CHANGE_APPLY_DISABLED_USER_CLEAR_FILTER,
  RESPONSE_ENABLE_USER,
  RESPONSE_DISABLE_USER,
  UPDATE_SOLO_USER_FAILURE,
  UPDATE_SOLO_USER_SUCCESS,
  UPDATE_SOLO_USER_START,
  FETCH_SETTING_DATA_START,
  FETCH_SETTING_DATA_SUCCESS,
  FETCH_SETTING_DATA_FAILURE,
  // SET_SELECTED_USER_CLICK_DATA,
  UPDATE_SETTING_START,
  UPDATE_SETTING_SUCCESS
} from "./users.types";
import * as UserTypes from "./users.types";
const INITIAL_STATE = {
  enabledUsers: {
    Users: [],
    pageDetails: {},
    page: 1,
    limit: 10,
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
  },
  disabledUsers: {
    Users: [],
    pageDetails: {},
    page: 1,
    limit: 10,
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
  },
  reportToData: [],
  loading: false,
};

const usersReducers = ( state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESPONSE_ENABLE_USER:
      const updatedUsers = state.disabledUsers.Users.filter(
        (c) => c.id !== payload?.data?.success[0])
      return {
        ...state,
        disabledUsers:{
          ...state.enabledUsers,
          pageDetails: payload?.data?.page_details,
          Users: updatedUsers,
        },

        loading: false,
      };
    case RESPONSE_DISABLE_USER:
      const updatedEnableUsers = state.disabledUsers.Users.filter(
        (c) => c.id !== payload?.data?.success[0])
      return {
        
        ...state,
        enabledUsers:{
          ...state.disabledUsers,
          pageDetails: payload?.data?.page_details,
          Users:updatedEnableUsers,
        },
        loading: false,
      };
    case ON_CHANGE_APPLY_USERS_FILTER:
      const { key, value } = payload;

      return {
        ...state,
        enabledUsers: {
          ...state.enabledUsers,
          [key]: value,
        },
      };
    case ON_CHANGE_APPLY_USER_CLEAR_FILTER:
      return {
        ...state,
        enabledUsers: {
          ...state.enabledUsers,
          ...payload,
        },
      };
    case FETCH_FILTERED_USERS_SUCCESS:
      return {
        ...state,
        enabledUsers: {
          ...state.enabledUsers,
          pageDetails: payload?.data?.page_details,
          Users: payload?.data?.users,
        },

        loading: false,
      };
    case ON_CHANGE_APPLY_DISABLE_USERS_FILTER:
      const { d_key, d_value } = payload;

      return {
        ...state,
        disabledUsers: {
          ...state.disabledUsers,
          [d_key]: d_value,
        },
      };
    case ON_CHANGE_APPLY_DISABLED_USER_CLEAR_FILTER:
      return {
        ...state,
        disabledUsers: {
          ...state.disabledUsers,
          ...payload,
        },
      };
    case FETCH_FILTERED_DISABLE_USERS_SUCCESS:
      return {
        ...state,
        disabledUsers: {
          ...state.disabledUsers,
          pageDetails: payload?.data?.page_details,
          Users: payload?.data?.users,
        },
        loading: false,
      };
    case UPDATE_SOLO_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SOLO_USER_SUCCESS:
    return {
      ...state,
      ...payload, // Make sure the 'user' property in the store is being updated with the new data
    };
    case UserTypes.GET_REPORT_TO_LIST_START: 
      return{
        ...state,
        loading: true,
      }
    case UserTypes.GET_REPORT_TO_LIST_SUCCESS:
      return{
        ...state,
        reportToData: payload?.data,
        loading: false,
      } 
    case FETCH_SETTING_DATA_START:
      return{
        ...state,
        loading: true,
    }
    case FETCH_SETTING_DATA_SUCCESS:
      // console.log("User Report---->", payload?.data)
      return {
        ...state,
        selectedSalesPersonId: payload.salesPersonId,
        data:payload?.data,
        // loading: false,
    };
    case FETCH_SETTING_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    // case SET_SELECTED_USER_CLICK_DATA:
    //   return {
    //     ...state,
    //     selectedSalesPersonId: payload.salesPersonId,
    //   }
    case UPDATE_SETTING_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SETTING_SUCCESS:
    return {
      ...state,
      ...payload, // Make sure the 'user' property in the store is being updated with the new data
    };
    case FETCH_FILTERED_USERS_FAILURE:
    case FETCH_FILTERED_DISABLE_USERS_FAILURE:
    case RECEIVE_DISABLE_DATA_FAILURE:
    case RECEIVE_ENABLE_DATA_FAILURE:
    case SEND_DISABLE_USER_FAILURE:
    case SEND_ENABLE_USER_FAILURE:
    case UPDATE_SOLO_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
export default usersReducers;