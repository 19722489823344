// Redux Imports
import { connect } from "react-redux";
import { changeApplyAttendaceFiler, fetchAttendanceUserStart, searchAttendanceUsersStart } from "../../../store/attendance/attendance.actions";

const mapStateToProps = (state) => {
  return{
    attendanceScreen: state.attendanceScreen,
  }
  
};

const mapDispatchToProps = (dispatch) => ({
  onFetchAttendanceUsersStart: (fromDate, toDate) => dispatch(fetchAttendanceUserStart(fromDate, toDate)),
  onSearchAttendanceUsersStart: (data1) => dispatch(searchAttendanceUsersStart(data1)),
  onChangeApplyAttendaceFiler: (name, value) => dispatch(changeApplyAttendaceFiler(name, value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
