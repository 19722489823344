export const FETCH_RECEIVED_ORDERS_START = "FETCH_RECEIVED_ORDERS_START";
export const FETCH_RECEIVED_ORDERS_SUCCESS = "FETCH_RECEIVED_ORDERS_SUCCESS";
export const FETCH_RECEIVED_ORDERS_FAILURE = "FETCH_RECEIVED_ORDERS_FAILURE";

export const FETCH_PLACED_ORDERS_START = "FETCH_PLACED_ORDERS_START";
export const FETCH_PLACED_ORDERS_SUCCESS = "FETCH_PLACED_ORDERS_SUCCESS";
export const FETCH_PLACED_ORDERS_FAILURE = "FETCH_PLACED_ORDERS_FAILURE";

export const FETCH_ORDERS_ON_BEHALF_START = "FETCH_ORDERS_ON_BEHALF_START";
export const FETCH_ORDERS_ON_BEHALF_SUCCESS = "FETCH_ORDERS_ON_BEHALF_SUCCESS";
export const FETCH_ORDERS_ON_BEHALF_FAILURE = "FETCH_ORDERS_ON_BEHALF_FAILURE";

export const FETCH_TRADERS_START = "FETCH_TRADERS_START";
export const FETCH_TRADERS_SUCCESS = "FETCH_TRADERS_SUCCESS";
export const FETCH_TRADERS_FAILURE = "FETCH_TRADERS_FAILURE";

export const ON_CHANGE_APPLY_ORDER_FILTER = "ON_CHANGE_APPLY_ORDER_FILTER";
export const ON_CHANGE_APPLY_ORDER_CLEAR_FILTER = "ON_CHANGE_APPLY_ORDER_CLEAR_FILTER";

export const CREATE_ORDER_START = "CREATE_ORDER_START";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
