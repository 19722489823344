import * as React from "react";

// Material-ui
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import DefaultProduct from "../../../../assets/images/defaultProduct.svg";

// Third party libraries
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

// Icons Import
import EditIcon from "../../../../assets/images/edit.svg";
import DelIcon from "../../../../assets/images/delete.svg";

// Project Imports
import CustomButton from "../../../../components/CustomButton";
import CustomizedDialog from "../../../../components/CustomDialog";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from "../../../../components/CustomTable";
import TableLoader, {
  CustomPagination,
} from "../../../../components/CustomTable/CustomTable";
import FilterMenu from "./FilterMenu";
import { useDispatch } from "react-redux";
import { createOrUpdateProductStart } from "../../../../store/inventory/inventory.actions";
import Loading from "../../../../assets/images/loading.svg";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
// function stableSort<T>(
//   array: readonly T[],
//   comparator: (a: T, b: T) => number
// ) {
//   const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis?.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis?.map((el) => el[0]);
// }

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  isShow: boolean;
  numeric: boolean;
}

export default function EnhancedTable({
  inventory: {
    filtered_products,
    pageDetails,
    products,
    loading,
    name,
    sku,
    product_id,
    categories,
    status,
    amount_greater_than,
    amount_less_than,
    page: pageNo,
    limit,
  },
  onProductDeleteStart,
  onFetchAllFilteredProductsStart,
  onChangeApplyProductFilter,
  role_details,
  onFetchAllTenantProductsStart,
}) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);

  const [delModal, setDelModal] = React.useState(null);

  const [noOfPages, setNoOfPages] = React.useState([]);
  const [activePage, setActivePage] = React.useState<any>(1);

  const headCells: readonly HeadCell[] = [
    {
      id: "image",
      numeric: false,
      disablePadding: true,
      label: "",
      isShow: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Product Name",
      isShow: true,
    },
    {
      id: "category_id",
      numeric: true,
      disablePadding: false,
      label: "Category",
      isShow: true,
    },
    {
      id: "sku",
      numeric: true,
      disablePadding: false,
      label: "SKU",
      isShow: true,
    },
    {
      id: "short_name",
      numeric: true,
      disablePadding: false,
      label: "Short Name",
      isShow: true,
    },

    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "Cost Price",
      isShow: true,
    },
    {
      id: "mrp",
      numeric: true,
      disablePadding: false,
      label: "MRP",
      isShow: true,
    },
    {
      id: "packsize",
      numeric: true,
      disablePadding: false,
      label: "Pack Size",
      isShow: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isShow: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      isShow: role_details?.permissions_details?.some(
        (permission) => permission.module === "product.update"
      ),
    },
  ];

  React.useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(pageDetails?.total / limit);
      const newPages = [];
      for (let i = 1; i <= totalPages; i++) {
        newPages.push(i);
      }
      setNoOfPages(newPages);
    };

    calculatePages();
  }, [pageDetails?.total, limit]);

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>, field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    dispatch(onChangeApplyProductFilter("page", event.target.value));
    setActivePage(Number(event.target.value));
    onFetchAllFilteredProductsStart(isAsc ? "desc" : "asc", 'name');
  };

  const showNextPage = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    dispatch(onChangeApplyProductFilter("page", activePage + 1, 'asc', 'name'));
    setActivePage(activePage + 1);
    onFetchAllFilteredProductsStart(isAsc ? "desc" : "asc", 'name');
  };

  const showPrevPage = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    dispatch(onChangeApplyProductFilter("page", activePage - 1));
    setActivePage(activePage - 1);
    onFetchAllFilteredProductsStart('asc', 'name');
  };

  React.useEffect(() => {
    if (limit) {
      setRowsPerPage(limit);
    }
  }, [limit]);

  const handleRequestSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);

    // Call the action creator with sorting parameters
    onFetchAllFilteredProductsStart(isAsc ? "asc" : "desc", field);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const sortedData = filtered_products ? filtered_products.sort(getComparator(order, orderBy)) : [];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filtered_products?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>, field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(onChangeApplyProductFilter("limit", event.target.value));
    onFetchAllFilteredProductsStart('asc', 'name');
    // console.log("event.target.value = " + event.target.value);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filtered_products.length)
      : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(products, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  const handleDelete = () => {
    onProductDeleteStart(
      {
        id: [delModal],
      },
      () => {
        setDelModal(null);
      }
    );
  };

  const navigate = useNavigate();

  const [action, setAction] = React.useState("");

  const handleChange = () => {
    setAction(action);
  };

  const [searchVal, setSearchVal] = React.useState("");

  React.useEffect(() => {
    onFetchAllFilteredProductsStart('asc', 'name');
  }, []);

  const exportToExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(filtered_products);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "filtered_products");
    // Buffer
    let buff = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "productsData.xlsx");
  };

  const [activeCount, setActiveCount] = React.useState(limit);
  const [productSearchValue, setProductSearchValue] = React.useState("");

  React.useEffect(() => {
    if (limit) {
      setActiveCount(limit);
    }
  }, [limit]);

  const handleMenuChange = (event) => {
    setActiveCount(event.target.value);
  };

  const dispatch = useDispatch();
  const handleSwitchChange = (row) => {
    dispatch(
      createOrUpdateProductStart(
        {
          ...row,
          is_active: row?.is_active ? false : true,
        },
        () => {
          // navigate("/manage/inventory");
        }
      )
    );
    onFetchAllFilteredProductsStart();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          // handleChange={(searchValue, field) => {
          //   console.log(searchValue);
          //   const isAsc = orderBy === field && order === "asc";
          //   setOrder(isAsc ? "desc" : "asc");
          //   setOrderBy(field);
          //   dispatch(onChangeApplyProductFilter("name", searchValue, isAsc ? "asc" : "desc", 'name'));
          //   onFetchAllFilteredProductsStart(isAsc ? "asc" : "desc", field); // Log the search value from EnhancedTableToolbar
          // }}
          handleChange={(searchValue, field) => {
            setProductSearchValue(searchValue);
            const isAsc = orderBy === field && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(field);
            dispatch(
              onChangeApplyProductFilter(
                "name",
                searchValue,
                isAsc ? "asc" : "desc",
                'name' // Pass 'field' for sorting
              )
            );
            onFetchAllFilteredProductsStart(isAsc ? "asc" : "desc", 'name');
          }}
          
          tableHeaderComps={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FilterMenu
                onChangeApplyProductFilter={onChangeApplyProductFilter}
                onFetchAllFilteredProductsStart={
                  onFetchAllFilteredProductsStart
                }
                name={name}
                sku={sku}
                product_id={product_id}
                categories={categories}
                status={status}
                amount_greater_than={amount_greater_than}
                amount_less_than={amount_less_than}
                products={filtered_products}
                onFetchAllTenantProductsStart={onFetchAllTenantProductsStart}
              />

              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">
                  {" "}
                  <Typography sx={{ color: "#1C1D22" }}>Bulk Action</Typography>
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={action}
                  label="Bulk Action"
                  onChange={handleChange}
                >
                  <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                  {/* <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          }
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={(event, property) => handleRequestSort(property)}
              rowCount={sortedData?.length}
              headCells={headCells}
            />
            {/* {loading ? (
              <React.Fragment>
                {[...Array(10)].map((_, index) => (
                  <TableLoader rows={headCells} key={index} />
                ))}
              </React.Fragment>
            ) : ( */}
              <TableBody>
                
                
              {loading && filtered_products.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headCells.length} align="center">
                    {/* Loading... */}
                    <img src={Loading}/>
                  </TableCell>
                </TableRow>
                ) : filtered_products.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                )
                // {/* { */}
                : (
                sortedData && sortedData?.length > 0 ? (
                  sortedData?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.filteredProducts}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.name)}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "0", width: "80px" }}>
                        {row?.image_id && (
                          <img
                            src={`data:image/jpeg;base64,${row?.image_files.file_encoded}`}
                            alt={DefaultProduct}
                            style={{ top: "6px", left: "50px", height: "70px", width: "auto", display: "block", margin: "0 auto", }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                        sx = {{width: "160px"}}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.category.name}</TableCell>
                      <TableCell align="center">{row.sku}</TableCell>
                      <TableCell align="center">{row.short_name}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">{row.mrp}</TableCell>
                      <TableCell align="center">{row.packsize}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          value="start"
                          control={
                            // <Switch
                            //   color="primary"
                            //   checked={row?.is_active}
                            //   onChange={() => !row?.is_active}
                            // />
                            <Switch
                              checked={row?.is_active}
                              onChange={() => handleSwitchChange(row)}
                            />
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          label={row?.is_active ? "Active" : "In-Active"}
                          labelPlacement="start"
                        />
                      </TableCell>
                      {role_details?.permissions_details?.some(
                        (permission) => permission.module === "product.update"
                      ) && (
                          <TableCell sx = {{borderTop: "1px solid #ffffff00",
                          borderLeft: "1px solid #ffffff00", borderRight: "1px solid #ffffff00",}}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(
                                    `/manage/inventory/products-update/${row?.id}`
                                  );
                                }}
                              >
                                <img src={EditIcon} alt="Edit" />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDelModal(row?.id);
                                }}
                                className="delete-icon-class"
                              >
                                <img src={DelIcon} alt="Del" />
                              </IconButton>
                            </Box>
                          </TableCell>
                        )}
                    </TableRow>
                  );
                  }) ) : 
                  (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center">
                        <img src={Loading}/>
                        </TableCell>
                    </TableRow>
                  )
                )}
                {/* } */}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
             {/* )} */}
          </Table>
        </TableContainer>
        

        <CustomPagination
          count={pageDetails?.total}
          rowsPerPage={rowsPerPage}
          page={activePage - 1}
          handleChangePage={() => { }}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          pageNo={pageNo}
          noOfPages={noOfPages}
          activePage={activePage}
          showPrevPage={showPrevPage}
          showNextPage={showNextPage}
        />
      </Paper>
      <CustomizedDialog
        open={delModal}
        handleClose={() => setDelModal(false)}
        title={"Delete Product"}
        maxWidth={"sm"}
      >
        <Box>
          <Typography variant="h3">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: (theme) => theme.spacing(4),
              justifyContent: "flex-end",
              marginTop: (theme) => theme.spacing(4),
            }}
          >
            <CustomButton
              variant="outlined"
              name="Cancel"
              handleClick={() => setDelModal(null)}
              sx={{ width: 110 }}
            />
            <CustomButton
              name="Delete"
              handleClick={handleDelete}
              sx={{ width: 110 }}
            />
          </Box>
        </Box>
      </CustomizedDialog>
    </Box>
  );
}
