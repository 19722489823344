import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import PersonSalesReportComponent from "../../../views/Admin/PersonSalesReport/PersonSalesReport.component";


// =================== Order Page ================= //
const PersonSalesReport = () => {
  return <WithLayout layout={Admin} component={PersonSalesReportComponent} />;
};


export default PersonSalesReport;