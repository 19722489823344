import * as ReStatisticalReportType from "./reStatisticalReport.types";

const INITITAL_STATE = {
    reStatisticalData: [],
    grandTotalSale: null,
    monthDateTotalSales: null,
    reStaPageDetails: null,
    
};

const reStatisticalReportReducer = (state = INITITAL_STATE, action) => {
    const { type, payload } = action;
    switch(type){
        case ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_START:
            return {
                ...state,
                loading: true,
            }

        case ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reStatisticalData: payload?.data?.data,
                grandTotalSale: payload?.data?.data?.grand_total_sale,
                monthDateTotalSales: payload?.data?.month_date_total_sales,
                reStaPageDetails: payload?.data?.page_details,
                loading: false,
            }

        case ReStatisticalReportType.GET_RESTATISTICAL_REPORT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        
        case ReStatisticalReportType.SEARCH_RESTATISTICAL_NAME: 
            const { key, value } = action.payload;
            return {
                ...state,
                reStatisticalData: payload?.data?.retailer_statistical_report,
                reStaPageDetails: payload?.data?.page_details,
                [key]: value,
            }
        default: 
            return state;
    }
    
}
export default reStatisticalReportReducer;