import React, { useState } from "react";

// Material-ui imports
import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Material-ui icons
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

// Third party libraries
import Dropzone from "react-dropzone";

// Icons imports
import UserIcon from "../../../assets/images/users.svg";
import DistributorIcon from "../../../assets/images/distributors.svg";
import RetailersIcon from "../../../assets/images/retailers.svg";
import ProductIcon from "../../../assets/images/product.svg";
import CategoryIcon from "../../../assets/images/categories.svg";
import BeatIcon from "../../../assets/images/beats.svg";

// Project imports
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

export default function Dashboard() {
  const importData = [
    {
      name: "Users",
      logo: <img src={UserIcon} alt="users" />,
      content: "Import user name, number and information for mapping the data",
    },
    // {
    //   name: "Distributors",
    //   logo: <img src={DistributorIcon} alt="distributors" />,
    //   content: "Import product details or information for mapping the data",
    // },
    // {
    //   name: "Retailers",
    //   logo: <img src={RetailersIcon} alt="retailers" />,
    //   content: "Import shop owner name, GST number and location information",
    // },
    {
      name: "Beats",
      logo: <img src={BeatIcon} alt="beats" />,
      content: "Import beat name and area details for mapping the beat data",
    },
    {
      name: "Categories",
      logo: <img src={CategoryIcon} alt="categories" />,
      content: "Import product category details for mapping the data",
    },
    {
      name: "Products",
      logo: <img src={ProductIcon} alt="products" />,
      content:
        "Import product code, name and other details for inventory management",
      ProductSampleFile: "import_products.xlsx",
    },
    

  ];

  const navigate = useNavigate();

  const handleDownload = (SampleFile) => {

    var ProductSampleFile = '';
    var data = [];
    if(SampleFile== 'Beats'){
       ProductSampleFile = "import_beat";
        data = [
        ['id', 'name', 'description', 'country_id', 'state_id', 'city_id', 'area'],
        ['1', 'beat1', 'testdescription1', '1', '1', '1', 'testarea'],
      ];
    }else if(SampleFile== 'Products'){
       ProductSampleFile = "import_products";
       data = [
        ['id', 'name', 'short_name', 'description', 'image_id', 'category_id', 'status_id', 'packsize', 'case_pack', 'variant', 'net_weight_in_grams', 'net_weight_per_cv_in_kg', 'sku', 'code', 'hsn', 'mrp', 'price', 'is_active', 'scheme_information', 'status', 'category', 'image_files'],
        ['167', 'SURTI UNDHIYA MASALA 15 G', 'SUDPATTY 15GM', '', '', '5', '', '10', '30.00', 'regular', '150.00', '0.15', 'SUDPATTY', 'None', '0910', '10', '8', 'TRUE', '{"quantity":"10","quantity_discount":"0","discount_quantity_included":"yes","is_incremental":"yes"}', '', '', ''],
      ];
    } 

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SampleSheet');

    // Generate a Blob from the workbook
    const excelBuffer = XLSX.write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(excelBlob);

    // Create a hidden download link and click it programmatically
    const link = document.createElement('a');
    link.href = url;
    link.download = ProductSampleFile;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary URL after the download is initiated
    window.URL.revokeObjectURL(url);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        // justifyContent: "space-evenly", remove when import other data
        gap: (theme) => theme.spacing(4),
      }}
    >
      {importData.map((data) => (
        <Box
          key={data.name}
          sx={{
            maxWidth: "326px",
            minHeight: "376px",
            width: "100%",
            padding: "13px",
            borderRadius: "12px",
            background: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data?.logo}

          <Typography
            variant="h3"
            sx={{
              fontSize: "22px",
              marginTop: "20px",
              marginBottom: "10px",
              fontWeight: 600,
              lineHeight: "27px",
              paddingTop: (theme) => theme.spacing(3),
            }}
          >
            {data.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            {data.content}
          </Typography>
          <CustomButton
            name={"Start an Import"}
            sx={{ marginTop: "20px", width: "70%" }}
            handleClick={() =>
              navigate(`/manage/dashboard/${data?.name.toLowerCase()}`)
            }
          />

          <Typography
            variant="caption"
            sx={{
              marginTop: "10px",
              marginBottom: "20px",
              lineHeight: "22px",
              cursor: 'pointer', // Set the cursor to indicate the link is clickable
              color: 'rgb(105, 117, 134)', // Set the color to indicate it's a link (you can use the appropriate color)
            }}
            onClick={() => handleDownload(data.name)} // Call the handleDownload function with the ProductSampleFile
          >
            Download sample file
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
