// Redux Imports
import { connect } from "react-redux";
import { fetchAllOrganisationStart } from "../../../store/organisation/organisation.actions";

const mapStateToProps = (state) => ({
 
  organisationScreen: state.organisationScreen,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchAllOrganisationStart: () =>
    dispatch(fetchAllOrganisationStart()),
  
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;