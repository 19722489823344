import * as React from 'react';
import { Box, Paper, Grid, Typography} from "@mui/material";
import moment from 'moment';
import Chart from 'react-google-charts';

const TodayAnalyticalView = ({onGetSalesReportDetailsTodayStart,
  salesReportDetailsToday: {salesReportDetailsDataToday, salesDetailsToday, totalSalesToday}}) => {

  const [dataLoaded, setDataLoaded] = React.useState(false);
  const today = moment().format("YYYY-MM-DD");

  const monthlyDataArray = salesReportDetailsDataToday?.map((row) => {
    return row?.total_sale;
  });

  const userList = salesReportDetailsDataToday?.map((row) => {
    return row?.user_name;
  });

  const xAxisData = Array.from({ length: salesDetailsToday?.total }, (_, index) => index + 1);

  // const chartData = [['x', 'Sales']];

  // if (dataLoaded) {
  //   if (monthlyDataArray.length > 0) {
  //     chartData.push(...xAxisData.map((item, index) => [`${userList[index]}`, monthlyDataArray[index] || 0]));
  //   } else {
  //     chartData.push(...xAxisData.map((item, index) => [`${userList[index]}`, '0']));
  //   }
  // }
  // const chartData = [
  //   ['x', 'Sales'],
  //   ['0', 0],
  //   ["1", 223],
  //   ["2", 32345],
  //   ["3", 1],
  //   ["4", 22],
  //   ["1", 26445],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["1", 5234],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 5434],
  //   ["2", 76],
  //   ["3", 77],
  //   ["4", 1234],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 800],
  //   ["4", 2],
  //   ["4", 25678],
  //   ["1", 5],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 54],
  //   ["4", 34],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["4", 2],
  //   ["1", 5],
  //   ["1", 223],
  //   ["2", 32345],
  //   ["3", 1],
  //   ["4", 22],
  //   ["1", 26445],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["1", 5234],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 5434],
  //   ["2", 76],
  //   ["3", 77],
  //   ["4", 1234],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 800],
  //   ["4", 2],
  //   ["4", 25678],
  //   ["1", 5],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 54],
  //   ["4", 34],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["4", 2],
  //   ["1", 5],
  //   ["1", 223],
  //   ["2", 32345],
  //   ["3", 1],
  //   ["4", 22],
  //   ["1", 26445],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["1", 5234],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 5434],
  //   ["2", 76],
  //   ["3", 77],
  //   ["4", 1234],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 800],
  //   ["4", 2],
  //   ["4", 25678],
  //   ["1", 5],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 54],
  //   ["4", 34],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["4", 2],
  //   ["1", 5],
  //   ["1", 223],
  //   ["2", 32345],
  //   ["3", 1],
  //   ["4", 22],
  //   ["1", 26445],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["1", 5234],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 5434],
  //   ["2", 76],
  //   ["3", 77],
  //   ["4", 1234],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 800],
  //   ["4", 2],
  //   ["4", 25678],
  //   ["1", 5],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 54],
  //   ["4", 34],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["4", 2],
  //   ["1", 5],
  //   ["1", 223],
  //   ["2", 32345],
  //   ["3", 1],
  //   ["4", 22],
  //   ["1", 26445],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["1", 5234],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 5434],
  //   ["2", 76],
  //   ["3", 77],
  //   ["4", 1234],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 800],
  //   ["4", 2],
  //   ["4", 25678],
  //   ["1", 5],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 54],
  //   ["4", 34],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["4", 2],
  //   ["1", 5],
  //   ["1", 223],
  //   ["2", 32345],
  //   ["3", 1],
  //   ["4", 22],
  //   ["1", 26445],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["1", 5234],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 5434],
  //   ["2", 76],
  //   ["3", 77],
  //   ["4", 1234],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 800],
  //   ["4", 2],
  //   ["4", 25678],
  //   ["1", 5],
  //   ["2", 7],
  //   ["3", 70],
  //   ["4", 45],
  //   ["1", 54],
  //   ["4", 34],
  //   ["1", 2],
  //   ["2", 190],
  //   ["3", 80],
  //   ["4", 2],
  //   ["4", 2],
  //   ["1", 5],
  //   ...xAxisData.map((item, index) => [`${userList[index]}`, monthlyDataArray[index] || 0,  ])
  // ];

  const chartData = [
    ['x', 'Sales'],
    ['0', 0],
    ...xAxisData.map((item, index) => [`${userList[index]}`, monthlyDataArray[index] || 0,  ])
  ]
  // console.log("chartData----->", chartData.length);

  const chartData1 = [
    ['x', 'Sales'],
    ["1", 0],
    ["2", 0],
    ["3", 0],
    ["4", 0],
    ["5", 0]
  ]

  const marginValue = dataLoaded && monthlyDataArray.length > 0 ? "-53px" : "1px";

  const options = {
    title: "",
    curveType: "none",
    legend: { position: "none", },
    hAxis: { textPosition: "none", gridlines: { color: 'transparent' }  },
    vAxis: { textPosition: "none", gridlines: { color: 'transparent' }  },
    backgroundColor: 'transparent', 
    baselineColor: "transparent",
    series: {
      0: { color: "#2E5BE3", lineWidth: 3, type: 'area', areaOpacity: 0.3  }
    },
    chartArea: {
      width: "100%",
    }
  };

  function formatedNumber(number) {
    return number.toLocaleString('en-IN');
  }

  React.useEffect(() => {
    onGetSalesReportDetailsTodayStart(today, today, 'asc', 'visit_time',);
    // onGetSalesReportDetailsTodayStart("2023-10-01", "2023-10-30", 'asc', 'visit_time');
    // onGetSalesReportDetailsTodayStart("2023-12-10", "2023-12-16", 'asc', 'visit_time');
    // onGetSalesReportDetailsTodayStart("2023-01-01", "2023-12-21", 'asc', 'visit_time');
  }, []);

  React.useEffect(() => {
    if (salesReportDetailsDataToday && salesDetailsToday) {
      setDataLoaded(true);
    }
  }, [salesReportDetailsDataToday, salesDetailsToday]);

  return(
    <Grid container>
      {/* sx={{ width: '100%', border: '1px solid #EAEFF5', mr: '5px', borderRadius: "14px"}} */}
      <Paper sx={{width: '100%', border: '1px solid #EAEFF5', borderRadius: "14px",}}>
        <Grid item md={12}>
          <Typography sx={{mt: "20px", ml: "10px", fontSize: "16px"}}>Today's Sales</Typography>
        </Grid>

        <Grid item md={12}>
          <Typography sx={{mt: "12px", ml: "10px", fontWeight: "bold", fontSize: "22px"}}>₹{totalSalesToday != null ? formatedNumber(totalSalesToday.toFixed(2)) : "0.00"}</Typography>
        </Grid>

        <Grid container
         sx={{height: "110px"}}
         >
          <Grid item md={2}>
              <Typography sx={{mt: "28px", ml: "10px", color: "#009E73", fontSize: "14px"}}>+17.7%</Typography>
          </Grid>

          <Grid item md={10} sx={{mt: marginValue}} >
            {dataLoaded && monthlyDataArray.length > 0 ? (
              <>
                <Chart
                  chartType="LineChart"
                  data={chartData}
                  options={options}
                />
              </>
            ) : (
              <Chart
                chartType="LineChart"
                data={chartData1}
                options={options}
              />
            )}
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

export default TodayAnalyticalView;