import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { tenantInstance } from "../../configs/axiosConfig";
import * as ItemStatisticalReportActions from "./itemStatisticalReport.action";
import * as ItemStatisticalReportType from "./itemStatisticalReport.types";

export function* getItemStatisticalReportListAsync( {payload}: any ) {
    let { name } = yield select((state) => state.itemStatisticalReport);
    const { fromDate, toDate, limit, total, page,} = payload;
    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/item-statistical-report`, {
            filters: {
                format_data: "date-wise",
                from_date: fromDate,
                to_date: toDate,//"2023-12",//toDate, //"2023-09-30",
                categories: [],
                status: true,
                name,
                // name: name ? name : "",
                sku:"",
                product_id:[],
                amount_greater_than: 0,
                amount_less_than: 0,
            },
            pages:{
                page,
                limit,
                // total,
            },
            sorting: [{
                type: 'asc',
                field: 'name'
            }]
        })
        yield put(ItemStatisticalReportActions.getItemStatisticalReportListSuccess(data));
    }
    catch(error){
        yield put(ItemStatisticalReportActions.getItemStatisticalReportListFail(error));
    }
}
export function* watchGetItemStatisticalReportListAsync(){
    yield takeLatest(
        ItemStatisticalReportType.GET_ITEM_STATISTICAL_REPORT_LIST_START,
        getItemStatisticalReportListAsync,
    )
}

// For Hollow Pie Chart (Yearly Data)
export function* yearlyItemListAysnc({payload}: any){
    const { fromDate, toDate, type, field } = payload;

    try{
        const { data } = yield tenantInstance.post(`/tenants/reports/item-statistical-report`, {
            filters: {
                format_data: "date-wise",
                from_date: fromDate,
                to_date: toDate,
            },
            sorting: [{
                type,
                field
            }]
        });
        yield put(ItemStatisticalReportActions.yearlyItemListSuccess(data));
    }
    catch(error){
        yield put(ItemStatisticalReportActions.yearlyItemListFail(error));
    }
}
export function* watchYearlyItemListAysnc(){
    yield takeLatest(
        ItemStatisticalReportType.YEARLY_ITEM_STATISTICAL_REPORT_LIST_START,
        yearlyItemListAysnc,
    )
}

export function* itemStatisticalReportSagas(){
    yield all([
        call(watchGetItemStatisticalReportListAsync),
        call(watchYearlyItemListAysnc),
    ])
}
