import React, { ReactNode } from "react";

// Mui imports
import { Box } from "@mui/material";

// Project imports
import Sidebar from "./components/Sidebar";
import container from "./Admin.container";

interface MainProps {
  children: ReactNode;
  auth: any;
  onChangeTenantStart?: any;
  onSignoutStart?: any;
}

// ====================== Main Admin Component ================= //
const Admin: React.FC<MainProps> = ({
  children,
  auth: { tenant_details, selectedTenant, tenantProfile,currentUser },
  onChangeTenantStart,
  onSignoutStart,
}) => {
  console.log(currentUser)
  return (
    <Box sx={{ background: "#F5F5F5", minHeight: "100vh", paddingTop: "20px" }}>
      <Sidebar
        children={children}
        tenant_details={tenant_details}
        selectedTenant={selectedTenant}
        onChangeTenantStart={onChangeTenantStart}
        onSignoutStart={onSignoutStart}
        tenantProfile={tenantProfile}
        currentUser={currentUser}
      />
    </Box>
  );
};

export default container(Admin);
