import * as React from "react";

// Material-ui
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

interface CustomAutoCompleteProps {
  options: any[];
  label: string;
  size?: "small" | "medium";
  variant?: any;
  name: string;
  defaultCountry?: any;
  setFieldValue?: any;
  handleAutoCompleteChange?: any;
  disabled?: boolean;
  multiple?: boolean;
}

export default function CustomAutoComplete({
  options,
  label,
  size = "medium",
  variant = "filled",
  name,
  setFieldValue,
  handleAutoCompleteChange,
  disabled,
  multiple = false,
}: CustomAutoCompleteProps) {
  // console.log("options", options, name);
  return (
    <Autocomplete
      multiple={multiple}
      id={label}
      size={size}
      options={options}
      autoHighlight
      clearIcon
      disabled={disabled}
      getOptionLabel={(option) =>
        option?.cityname ?? option?.name ?? option?.email ?? ""
      }
      onChange={(event: any, newValue) => {
        // console.log("add_trader", newValue);
        setFieldValue(name, multiple ? newValue : newValue?.id);
      }}
      renderOption={(props, option) => (
        <React.Fragment>
          <Box component="li" {...props}>
            {option?.name ?? option?.cityname ?? option?.email ?? ""}
          </Box>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          onChange={(e) => {
            console.log("onChange", e.target.value);
          }}
        />
      )}
    />
  );
}
