import * as OrdersType from "./orders.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  receivedOrders: [],
  placedOrders: [],
  ordersOnbeHalf: [],
  traders:[],
  receivedOrdersFilter: {
    order_id: [],
    item_name: "",
    trader_ids_list: [],
    from_date: null,
    to_date: null,
    amount_greater_than: 0,
    amount_less_than: 0,
    selectedDateRange: "",
    page: 1,
    limit: 10,
  },
  placedOrdersFilter: {
    order_id: [],
    item_name: "",
    trader_ids_list: [],
    from_date: null,
    to_date: null,
    amount_greater_than: 0,
    amount_less_than: 0,
    selectedDateRange: "",
    page: 1,
    limit: 10,
  },
  ordersOnbeHalfFilter: {
    order_id: [],
    item_name: "",
    trader_ids_list: [],
    from_date: null,
    to_date: null,
    amount_greater_than: 0,
    amount_less_than: 0,
    selectedDateRange: "",
    page: 1,
    limit: 10,
  },
  error: null,
  loading: false,
};

// Reducer function responsible for managing the authentication state
const ordersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case OrdersType.FETCH_RECEIVED_ORDERS_START:
    case OrdersType.FETCH_PLACED_ORDERS_START:
    case OrdersType.FETCH_ORDERS_ON_BEHALF_START:
      case OrdersType.FETCH_TRADERS_START:
    case OrdersType.CREATE_ORDER_START:
      return {
        ...state,
        loading: true,
      };

    case OrdersType.FETCH_RECEIVED_ORDERS_SUCCESS:
      return {
        ...state,
        receivedOrders: payload?.data,
        loading: false,
      };

    case OrdersType.FETCH_PLACED_ORDERS_SUCCESS:
      return {
        ...state,
        placedOrders: payload?.data,
        loading: false,
      };
      
    case OrdersType.FETCH_ORDERS_ON_BEHALF_SUCCESS:
      return {
        ...state,
        ordersOnbeHalf: payload?.data,
        loading: false,
      };
      case OrdersType.FETCH_TRADERS_SUCCESS:
        console.log("traders",payload)
        return {
          ...state,
          traders: payload?.data,
          loading: false,
        };
      case OrdersType.CREATE_ORDER_SUCCESS:
        return{
          ...state,
          traders: payload?.data,
          selectedCardId: payload?.selectedCardId,
          cardQuantities: payload?.cardQuantities?.value ,
          laoding: false,
        }

    case OrdersType.FETCH_RECEIVED_ORDERS_FAILURE:
    case OrdersType.FETCH_PLACED_ORDERS_FAILURE:
    case OrdersType.FETCH_ORDERS_ON_BEHALF_FAILURE:
      case OrdersType.FETCH_TRADERS_FAILURE:
    case OrdersType.CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case OrdersType.ON_CHANGE_APPLY_ORDER_FILTER:
      const { key, value, filterValue } = action.payload;

      if (filterValue === "received") {
        return {
          ...state,
          receivedOrdersFilter: {
            ...state.receivedOrdersFilter,
            [key]: value,
          },
        };
      } else if(filterValue === "orderonbehalf"){
        return {
          ...state,
          ordersOnbeHalfFilter: {
            ...state.ordersOnbeHalfFilter,
            [key]: value,
          },
        };
      }
      else {
        return {
          ...state,
          placedOrdersFilter: {
            ...state.placedOrdersFilter,
            [key]: value,
          },
        };
      }
      case OrdersType.ON_CHANGE_APPLY_ORDER_CLEAR_FILTER:
        const {  f_value } = action.payload;
  
        if (f_value === "received") {
          return {
            ...state,
            
            receivedOrdersFilter: {
              ...state.receivedOrdersFilter,
             ...action.payload.data
            },
          };
        }else if(f_value === "orderonbehalf"){
          return {
            ...state,
            ordersOnbeHalfFilter: {
              ...state.ordersOnbeHalfFilter,
              ...action.payload.data
            },
          };
        } else {
          return {
            ...state,
            placedOrdersFilter: {
              ...state.placedOrdersFilter,
              ...action.payload.data
            },
          };
        }
    default:
      return state;
  }
};

export default ordersReducer;
