import { connect } from "react-redux";
import {
  createOrganisationStart,
  // fetchAllFilteredProductsCategoryStart,
  // fetchAllTenantCategoriesStart,
  // fetchProductDetailsStart,
} from "../../../store/organisation/organisation.actions";

const mapStateToProps = (state) => ({
  inventory: state.inventory,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateOrganisationStart: (formData, cb) =>
    dispatch(createOrganisationStart(formData, cb)),
  // onFetchAllTenantCategoriesStart: () =>
  //   dispatch(fetchAllTenantCategoriesStart()),
  // onFetchProductDetailsStart: (id) => dispatch(fetchProductDetailsStart(id)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
