import React, { Fragment } from "react";

// Material-ui
import { Box, Typography } from "@mui/material";

// Project imports
import UserTable from "./components/UserTable";

import container from "./Users.container";
import { StyledTab, StyledTabs, TabPanel } from "../../../components/CustomTab";


const Users = ({
  onFetchEnableUsersStart,
  onFetchSettingDataStart,
  onFetchDisableUsersStart,
  user: { enabledUsers, disabledUsers, loading, reportToData },
  onupdateUserStart, user, onGetReportToListStart,
}) => {
  const enabledfilters = {
    f_name: enabledUsers?.f_name,
    l_name: enabledUsers?.l_name,
    email: enabledUsers?.email,
    mobile: enabledUsers?.mobile,
  };
  const disabledfilters = {
    f_name: disabledUsers?.f_name,
    l_name: disabledUsers?.l_name,
    email: disabledUsers?.email,
    mobile: disabledUsers?.mobile,
  };
  const menuList = [
    {
      name: "Enabled Users",
      index: 0,
    },
    {
      name: "Disabled Users",
      index: 1,
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "25px",
            marginLeft: "25px",
          }}
        >
          <Typography variant="h3">Users List</Typography>
        </Box>
        <Box sx={{ width: "100%", zIndex: 1 }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            {menuList?.map((list) => (
              <StyledTab label={list?.name} key={list?.index} />
            ))}
          </StyledTabs>

          {value === 0 && (
            <TabPanel value={value} index={value}>
              <UserTable
                onFetchUsersStart={onFetchEnableUsersStart}
                onFetchSettingDataStart={onFetchSettingDataStart}
                Users={enabledUsers}
                loading={loading}
                value={value}
                filters={enabledfilters}
                onupdateUserStart = {onupdateUserStart}
                user = {user}
                onFetchEnableUsersStart = {onFetchEnableUsersStart} 
                onFetchDisableUsersStart = {onFetchDisableUsersStart}
                onGetReportToListStart = {onGetReportToListStart}
                reportToData = {reportToData}
              />
            </TabPanel>
          )}
          {value === 1 && (
            <TabPanel value={value} index={value}>
              <UserTable
                onFetchUsersStart={onFetchDisableUsersStart}
                onFetchSettingDataStart={onFetchSettingDataStart}
                Users={disabledUsers}
                
                loading={loading}
               
                value={value}
                filters={disabledfilters}
                onupdateUserStart = {onupdateUserStart}
                user = {user}
                onFetchEnableUsersStart = {onFetchEnableUsersStart} 
                onFetchDisableUsersStart = {onFetchDisableUsersStart}
                onGetReportToListStart = {onGetReportToListStart}
                reportToData = {reportToData}
              />
            </TabPanel>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default container(Users);
