import React from "react";

//Material-ui imports
import { Button, SxProps, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

interface BtnWrapperProps {
//   id: string;
  name: string;
  variant?: "text" | "outlined" | "contained" | undefined;
  size?: "small" | "medium" | "large";
  handleClick?: any;
  sx?: SxProps<Theme>;
  icon?: any;
  type?: any;
  disabled?: boolean;
  categoryId: number;
}

const BtnWrapper = styled(Button)<{ size?: string }>(
  ({ theme, size, variant }) => ({
    borderRadius: "20px",
    fontSize: size === "small" ? "14px" : "16px",
    fontWeight: 500,
    lineHeight: size === "small" ? "17px" : "22px",
    color:
      variant === "contained"
        ? theme.palette.background.paper
        : "#000000",
    backgroundColor:
      variant === "contained" ? theme.palette.primary.dark : "transparent",
    textTransform: "capitalize",
    borderColor: 
        variant === "contained" ? theme.palette.primary.dark : "#ADADAD",
    padding: "11px 14px",

    // Hover Properties
    '&:hover': {
        color: variant === "contained" ? "#FFFFFF" : "#008E8E", // Change text color on hover
        backgroundColor: variant === "contained" ? theme.palette.primary.main : "#F4F5FA", // Change background color on hover
        borderColor: variant === "contained" ? theme.palette.primary.main : "#008E8E", // Change border color on hover
      },
  })
);

// ======================== Main Button Component ================= //
const CategoryCustomBtn = ({
//   id,
  name,
  variant = "contained",
  size = "medium",
  handleClick,
  sx,
  icon,
  type,
  disabled = false,
  categoryId,
}: BtnWrapperProps) => {
  return (
    <BtnWrapper
      variant={variant}
      size={size}
      sx={sx}
    //onClick={handleClick}
      onClick={() => handleClick(categoryId)}
      startIcon={icon ? icon : null}
      type={type}
      disabled={disabled}
    >
      {name}
    </BtnWrapper>
  );
};

export default CategoryCustomBtn;
