import { connect } from "react-redux";
import { fetchSettingDataStart,updateSettingStart } from "../../../store/users/users.action";

const mapStateToProps = (state) => ({
  settingData: state.settingData,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchSettingDataStart: (salesPersonId) => dispatch(fetchSettingDataStart(salesPersonId)),
  onupdateSettingStart: (value) => dispatch(updateSettingStart(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
