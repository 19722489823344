import * as MasterType from "./master.types";

// Fetch Roles
export const fetchRolesStart = () => ({
  type: MasterType.FETCH_ROLES_START,
});

export const fetchRolesSuccess = (result) => ({
  type: MasterType.FETCH_ROLES_SUCCESS,
  payload: result,
});

export const fetchRolesFail = (error) => ({
  type: MasterType.FETCH_ROLES_FAILURE,
  payload: error,
});

// Fetch Countries
export const fetchCountriesStart = () => ({
  type: MasterType.FETCH_COUNTRIES_START,
});

export const fetchCountriesSuccess = (result) => ({
  type: MasterType.FETCH_COUNTRIES_SUCCESS,
  payload: result,
});

export const fetchCountriesFail = (error) => ({
  type: MasterType.FETCH_COUNTRIES_FAILURE,
  payload: error,
});

// Get state by Country Id
export const fetchStateByCountryIdStart = (id) => ({
  type: MasterType.FETCH_STATE_BY_COUNTRY_ID_START,
  payload: { id },
});

export const fetchStateByCountryIdSuccess = (result) => ({
  type: MasterType.FETCH_STATE_BY_COUNTRY_ID_SUCCESS,
  payload: result,
});

export const fetchStateByCountryIdFail = (error) => ({
  type: MasterType.FETCH_STATE_BY_COUNTRY_ID_FAILURE,
  payload: error,
});

// Get City by State Id
export const fetchCityByStateIdStart = (id) => ({
  type: MasterType.FETCH_CITY_BY_STATE_ID_START,
  payload: { id },
});

export const fetchCityByStateIdSuccess = (result) => ({
  type: MasterType.FETCH_CITY_BY_STATE_ID_SUCCESS,
  payload: result,
});

export const fetchCityByStateIdFail = (error) => ({
  type: MasterType.FETCH_CITY_BY_STATE_ID_FAILURE,
  payload: error,
});

export const fetchBeatsDataByIdStart = (formData, cb) =>({
  type: MasterType.FETCH_BEATS_DATA_BY_ID_START,
  payload: { formData, cb },
});
export const fetchBeatsDataByIdSuccess = (result) => ({
  type: MasterType.FETCH_BEATS_DATA_BY_ID_SUCCESS,
  payload: result,
});
export const fetchBeatsDataByIdFail = (error) => ({
  type: MasterType.FETCH_BEATS_DATA_BY_ID_FAILURE,
  payload: error,
});