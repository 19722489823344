import * as organisationTypes from "./organisation.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  user_id: [],
  beat_name: "",
  name: "",
  dates: [],
  date: "",
  loading: false,
  error: null,
  from_date: null,
  to_date: null,
  selectedDateRange: "",
  page: 1,
  limit: 10,
  page_details: []
};
function organisationReducer(state = INITIAL_STATE, action) {
  // console.log(action.payload)
  const { type, payload } = action;
  switch (type) {

    case organisationTypes.GET_ORGANISATION_LIST_START:
      return {
        ...state,
        loading: true,
      };

    case organisationTypes.GET_ORGANISATION_LIST_SUCCESS:
      return {
        ...state,
        data: payload?.data?.tenants,
        loading: false,
      };
    case organisationTypes.GET_ORGANISATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case organisationTypes.CREATE_ORGANISATION_START:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
export default organisationReducer;
