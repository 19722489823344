import * as ReportByBeatsType from "./reportByBeats.types";

// For Table
export const getReportByBeatsListStart = (fromDate, toDate, page, limit, type, field, flag) => {
    return{
        type: ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_START,
        payload: { fromDate, toDate, page, limit, type, field, flag}
    }
};
export const getReportByBeatsListSuccess = (result) => ({
    type: ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_SUCCESS,
    payload: result,
});
export const getReportByBeatsListFail = (error) => ({
    type: ReportByBeatsType.GET_REPORT_BY_BEATS_LIST_FAILURE,
    payload: error,
});

// For Search
export const searchReportBeatName = (key, value) => ({
    type: ReportByBeatsType.SEARCH_REPORT_BEAT_NAME,
    payload: { key, value },
})

// For Yearly Data List
export const yearlyBeatsListStart = (fromDate, toDate) => ({
    type: ReportByBeatsType.YEARLY_BEATS_LIST_START,
    payload: { fromDate, toDate }
});
export const yearlyBeatsListSuccess = (result) => ({
    type: ReportByBeatsType.YEARLY_BEATS_LIST_SUCCESS,
    payload: result
});
export const yearlyBeatsListFail = (error) => ({
    type: ReportByBeatsType.YEARLY_BEATS_LIST_FAILURE,
    payload: error
})