import React from "react";

// Project Imports
import WithLayout from "../../../components/WithLayout";
import Admin from "../../../layouts/Admin";
import DashComp from "../../../views/Admin/Dashboard"; //Import with Sample File Dashboard
import DashboardView from "../../../views/Admin/Dashboard/DashboardView.component";

// ================= Main Dashboard ================= //
const Dashboard = () => {
  return <WithLayout layout={Admin} component={DashboardView} />;
};

export default Dashboard;
