import React from "react";

// Project Imports
import WithLayout from "../components/WithLayout";
import Main from "../layouts/Main";
import LoginComp from "../components/Auth/OTPLogin";

// ===================Main Login Page============================//
const login = () => {
  return <WithLayout layout={Main} component={LoginComp} />;
};

export default login;
