import * as UserTypes from "./users.types";


// // enable users list
// export  const reqeustEnableApiData = ()=>{
//     console.log('reqeustEnableApiData : Action')
//     return {type:REQUEST_ENABLE_API_DATA}
// }
// export  const recieveEnableApiData = (data)=>{
//     console.log('recieveEnableApiData : data',data)
//     return {type:RECIEVE_ENABLE_API_DATA,payload:data}
// }
// export const recieveEnableApiDataFail = (error) => ({
//   type: RECEIVE_ENABLE_DATA_FAILURE,
//   payload: error,
// });

// //disable users list
// export  const reqeustDisableApiData = ()=>{
//     console.log('reqeustDisableApiData : Action')
//     return {type:REQUEST_DISABLE_API_DATA}
// }
// export  const recieveDisableApiData = (data)=>{
//     console.log('recieveDisableApiData : Action',data)
//     return {type:RECIEVE_DISABLE_API_DATA,payload:data}
// }
// export const recieveDisableApiDataFail = (error) => ({
//   type: RECEIVE_DISABLE_DATA_FAILURE,
//   payload: error,
// });

//disable user from enable list
export const sendDisableUser = (data) => {
    // console.log("delete action called",data)
    return {
    
    type: UserTypes.SEND_DISABLE_USER,
    payload: data,
  }};
export const sendDisableUserSuccess = (data)=>{
    // console.log(data)
    return {type:UserTypes.RESPONSE_DISABLE_USER,payload:data}
}
export const sendDisableUserFail = (error) => ({
  type: UserTypes.SEND_DISABLE_USER_FAILURE,
  payload: error,
});
//enable user from disable list
export const sendEnableUser = (data) => {
    // console.log("delete action called",data)
    return {
    
    type: UserTypes.SEND_ENABLE_USER,
    payload: data,
  }};
export const sendEnableUserSuccess = (data)=>{
    // console.log(data)
    return {type:UserTypes.RESPONSE_ENABLE_USER,payload:data}
}
export const sendEnableUserFail = (error) => ({
  type: UserTypes.SEND_ENABLE_USER_FAILURE,
  payload: error,
});

//filter enabled User

export const fetchAllFilteredUsersStart = (field, type) => ({
    type: UserTypes.FETCH_FILTERED_USERS_START,
    payload: { field, type }
  });
  
  export const fetchAllFilteredUsersSuccess = (result) => {
    // console.log('recieveEnableApiData : data',result)
   return  {
    type: UserTypes.FETCH_FILTERED_USERS_SUCCESS,
    payload: result,
  }};
  
  export const fetchAllFilteredUsersFail = (error) => ({
    type: UserTypes.FETCH_FILTERED_USERS_FAILURE,
    payload: error,
  });
  export const changeApplyUserFilter = (key, value, type, field) => {
    // console.log("Key:", key);
    // console.log("Value:", value);
    // console.log("Type:", type);
    // console.log("Field:", field);
    return{
    type: UserTypes.ON_CHANGE_APPLY_USERS_FILTER,
    payload: { key, value, type, field }
    }
  };

  // clear enabled user Filter
export const changeApplyUserClearFilter = () => ({
  type: UserTypes.ON_CHANGE_APPLY_USER_CLEAR_FILTER,
  payload: { 

    f_name: "",
    email: "",
  
    l_name: "",
    mobile: "", },
});
  //filter enabled disabled User

export const fetchAllFilteredDisabledUsersStart = (field, type) => ({
  type: UserTypes.FETCH_FILTERED_DISABLE_USERS_START,
  payload: { field, type }
});

export const fetchAllFilteredDisabledUsersSuccess = (result) => ({
  type: UserTypes.FETCH_FILTERED_DISABLE_USERS_SUCCESS,
  payload: result,
});

export const fetchAllFilteredDisabledUsersFail = (error) => ({
  type: UserTypes.FETCH_FILTERED_DISABLE_USERS_FAILURE,
  payload: error,
});
export const changeApplyDisabledUserFilter = (d_key, d_value) => ({
  type: UserTypes.ON_CHANGE_APPLY_DISABLE_USERS_FILTER,
  payload: { d_key, d_value }
});
  // clear enabled user Filter
  export const changeApplyDisabledUserClearFilter = () => ({
    type: UserTypes.ON_CHANGE_APPLY_DISABLED_USER_CLEAR_FILTER,
    payload: { 
  
      f_name: "",
      email: "",
    
      l_name: "",
      mobile: "", },
  });
  // Update Solo User
export const updateUserStart = (formData) => ({
  
  type: UserTypes.UPDATE_SOLO_USER_START,
  payload: formData,
});

export const updateUserSuccess = (result) => ({
  type: UserTypes.UPDATE_SOLO_USER_SUCCESS,
  payload: result,
});

export const updateUsersFail = (error) => ({
  type: UserTypes.UPDATE_SOLO_USER_FAILURE,
  payload: error,
});

//Report To
export const getReportToListStart = (role) => ({
  type: UserTypes.GET_REPORT_TO_LIST_START,
  payload: { role },
});
export const getReportToListSuccess = (result) => ({
  type: UserTypes.GET_REPORT_TO_LIST_SUCCESS,
  payload: result,
});
export const getReportToListFail = (error) => ({
  type: UserTypes.GET_REPORT_TO_LIST_FAILURE,
  payload: error,
});

// Fetch User Setting
export const fetchSettingDataStart = (salesPersonId) => {
  // console.log("date---->", date);
  // console.log("salesPersonId---->", salesPersonId);
  return{type: UserTypes.FETCH_SETTING_DATA_START,
    payload: { salesPersonId },}
};
export const fetchSettingDataSuccess = (result) => {
  // console.log("result---->", result);
  return {type: UserTypes.FETCH_SETTING_DATA_SUCCESS,
  payload: result }
};
export const fetchSettingDataFail = (error) => {
  return{
    type: UserTypes.FETCH_SETTING_DATA_FAILURE,
    payload: error,
  }
};
export const selectedUserClickData = (salesPersonId) => ({
  type: UserTypes.SET_SELECTED_USER_CLICK_DATA,
  payload: {salesPersonId}
});
// Update User Setting
export const updateSettingStart = (formData) => ({

  type: UserTypes.UPDATE_SETTING_START,
  payload: formData,
});
export const updateSettingSuccess = (result) => ({
  type: UserTypes.UPDATE_SETTING_SUCCESS,
  payload: result,
});

export const updateSettingFail = (error) => ({
  type: UserTypes.UPDATE_SETTING_FAILURE,
  payload: error,
});