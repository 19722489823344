import { connect } from "react-redux";
import { getPersonSalesReportListStart } from "../../../store/personsalesreport/personSalesReport.actions";

const mapStateToProps = (state) => { 
  return{
    personSalesReport: state.personSalesReport,
  }
};

const mapDispatchToProps = (dispatch) => ({
    onGetPersonSalesReportListStart: (fromDate, toDate, page, limit, type, field, flag) => dispatch(getPersonSalesReportListStart(fromDate, toDate, page, limit, type, field, flag)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;