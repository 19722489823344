import * as InvitationType from "./invitation.types";

// Add invitation
export const addInvitationStart = (formData, cb) => ({
  type: InvitationType.ADD_INVITATION_START,
  payload: { formData, cb },
});

export const addInvitationSuccess = (result) => ({
  type: InvitationType.ADD_INVITATION_SUCCESS,
  payload: result,
});

export const addInvitationFail = (error) => ({
  type: InvitationType.ADD_INVITATION_FAILURE,
  payload: error,
});

// List invitation
export const listInvitationStart = () => ({
  type: InvitationType.LIST_INVITATION_START,
});

export const listInvitationSuccess = (result) => ({
  type: InvitationType.LIST_INVITATION_SUCCESS,
  payload: result,
});

export const listInvitationFail = (error) => ({
  type: InvitationType.LIST_INVITATION_FAILURE,
  payload: error,
});

// Accept invitation
export const acceptInvitationStart = () => ({
  type: InvitationType.ACCEPT_INVITATION_START,
});

export const acceptInvitationSuccess = (result) => ({
  type: InvitationType.ACCEPT_INVITATION_SUCCESS,
  payload: result,
});

export const acceptInvitationFail = (error) => ({
  type: InvitationType.ACCEPT_INVITATION_FAILURE,
  payload: error,
});
