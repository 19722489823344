import * as React from "react";

// material-ui
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
  Autocomplete,
  Avatar,
  // Badge,
  Breadcrumbs,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";

import { Link } from "react-router-dom";

// material-ui icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";

// Icons Import --------------------------------
import ActOrdersIcon from "../../../assets/images/dashboard/actBag.svg";
import ActDashboardIcon from "../../../assets/images/dashboard/actDashboard.svg";
import ActBeatsIcon from "../../../assets/images/dashboard/actGroup.svg";
import ActInventoryIcon from "../../../assets/images/dashboard/actInventory.svg";
import ActInviteIcon from "../../../assets/images/dashboard/actInvite.svg";
// import ActStoreIcon from "../../../assets/images/dashboard/actStore.svg";
import ActCustomersIcon from "../../../assets/images/dashboard/act_User.svg";
import OrdersIcon from "../../../assets/images/dashboard/bag.svg";
import DashboardIcon from "../../../assets/images/dashboard/dashboard.svg";
import BeatsIcon from "../../../assets/images/dashboard/group.svg";
// import HeadphoneIcon from "../../../assets/images/dashboard/headphones.svg";
import InventoryIcon from "../../../assets/images/dashboard/inventory.svg";
import InviteIcon from "../../../assets/images/dashboard/invite.svg";
import LogoutIcon from "../../../assets/images/dashboard/logout.svg";
import NotificationIcon from "../../../assets/images/dashboard/notification.svg";
// import StoreIcon from "../../../assets/images/dashboard/store.svg";
import CustomersIcon from "../../../assets/images/dashboard/user.svg";
import TourIcon from "../../../assets/images/dashboard/tour.svg";
import ActTourIcon from "../../../assets/images/dashboard/actTour.svg";
import JustVisitIcon from "../../../assets/images/dashboard/justVisit.svg";
import ActJustVisitIcon from "../../../assets/images/dashboard/actJustVisit.svg";
import Logo from "../../../assets/images/logo_alt.svg";
import ActReportIcon from "../../../assets/images/dashboard/actreports.svg";
import ReportIcon from "../../../assets/images/dashboard/report.svg";
import AttendanceTimeIcon from "../../../assets/images/dashboard/attendanceTime.svg"
import ActAttendanceTimeIcon from "../../../assets/images/dashboard/actAttendanceTime.svg"
import OrganisationIcon from "../../../assets/images/dashboard/organisation.svg"
import ActOrganisationIcon from "../../../assets/images/dashboard/actOrganisation.svg"

import Collapse from '@mui/material/Collapse';

// third party library
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  gap: theme.spacing(1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: theme.palette.background.paper,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  maxHeight: "102px",
  display: "flex",
  justifyContent: "center",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const CustomBadge = styled(Badge)(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     fontSize: "10px",
//     fontWeight: 400,
//     height: 30,
//     minWidth: 30,
//     borderRadius: "50%",
//   },
// }));

interface MiniDrawerProps {
  children: React.ReactNode;
  tenant_details: any[];
  selectedTenant: object;
  onChangeTenantStart: any;
  onSignoutStart: any;
  tenantProfile: any;
  currentUser: any;
}


// =============|| Main Sidebar Component ================= //
export default function MiniDrawer({
  children,
  tenant_details,
  currentUser,
  selectedTenant,
  onChangeTenantStart,
  onSignoutStart,
  tenantProfile: { role_details },
}: MiniDrawerProps) {
  // console.log(selectedTenant['tenant_image']
  //   ?.file)
  // console.log("role_details---->", role_details);
  const imageFileData = currentUser["profile_image"]?.file_encoded;
  let imageUrl;

  try {
    const byteCharacters = atob(imageFileData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" });
    imageUrl = URL.createObjectURL(blob);
    console.log(imageUrl);

    // Continue with further processing or usage of the blob
  } catch (error) {
    // Handle the error
    console.error("File decoding error:", error);
  }

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [expandedSubItems, setExpandedSubItems] = React.useState<number[]>([]);
  const [isArrowUp, setIsArrowUp] = React.useState(false);

  // const [pageTitle, setPageTitle] = React.useState(""); 

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const toggleSubItems = (itemId: number) => {
    setExpandedSubItems((prevExpandedSubItems) => {
      if (prevExpandedSubItems.includes(itemId)) {
        setIsArrowUp(false); 
        return prevExpandedSubItems.filter((id) => id !== itemId);
      } else {
        setIsArrowUp(true);
        return [...prevExpandedSubItems, itemId];
      }
    });
  };
  // const toggleSubItems = (itemId: number) => {
  //   setExpandedSubItems((prevExpandedSubItems) => {
  //     if (prevExpandedSubItems.includes(itemId)) {
  //       return prevExpandedSubItems; // Don't remove the item from the list
  //     } else {
  //       return [...prevExpandedSubItems, itemId]; // Add the item to the list
  //     }
  //   });
  // };

  // Nav Items to be shown in Sidebar
  const NavItems = [
    {
      id: 1,
      name: "Dashboard",
      icon: <img src={DashboardIcon} alt="dashboard" />,
      actIcon: <img src={ActDashboardIcon} alt="dashboard" />,
      path: "/manage/dashboard",
      showCount: false,
      isVisible: true,
    },
    {
      id: 2,
      name: "Products",
      icon: <img src={InventoryIcon} alt="inventory" />,
      actIcon: <img src={ActInventoryIcon} alt="inventory" />,
      path: "/manage/inventory",
      showCount: false,
      isVisible: true,
    },
    {
      id: 3,
      name: "Users",
      icon: <img src={CustomersIcon} alt="customer" />,
      actIcon: <img src={ActCustomersIcon} alt="customer" />,
      path: "/manage/users",
      showCount: false,
      isVisible: role_details?.permissions_details?.some(
        (permission) => permission.module === "user.read"
      ),
    },
    {
      id: 4,
      name: "Traders",
      icon: <img src={CustomersIcon} alt="trader" />,
      actIcon: <img src={ActCustomersIcon} alt="trader" />,
      path: "/manage/traders",
      showCount: false,
      isVisible: role_details?.permissions_details?.some(
        (permission) => permission.module === "traders.read"
      ),
    },
    {
      id: 5,
      name: "Orders",
      icon: <img src={OrdersIcon} alt="order" />,
      actIcon: <img src={ActOrdersIcon} alt="order" />,
      path: "/manage/orders",
      showCount: false,
      isVisible: role_details?.permissions_details?.some(
        (permission) => permission.module === "order.read"
      ),
    },
    {
      id: 6,
      name: "Beats",
      icon: <img src={BeatsIcon} alt="beats" />,
      actIcon: <img src={ActBeatsIcon} alt="beats" />,
      path: "/manage/new-beats",
      showCount: false,
      isVisible: role_details?.permissions_details?.some(
        (permission) => permission.module === "beat.read"
      ),
    },
    // {
    //   id: 66,
    //   name: "Beats",
    //   icon: <img src={BeatsIcon} alt="beats" />,
    //   actIcon: <img src={ActBeatsIcon} alt="beats" />,
    //   path: "/manage/beats",
    //   showCount: false,
    //   isVisible: role_details?.permissions_details?.some(
    //     (permission) => permission.module === "beat.read"
    //   ),
    // },
    // {
    //   id: 7,
    //   name: "Manage Store",
    //   icon: <img src={StoreIcon} alt="store" />,
    //   actIcon: <img src={ActStoreIcon} alt="store" />,
    //   path: "/manage/store",
    //   isVisible: true,
    // },
    {
      id: 8,
      name: "Invite",
      icon: <img src={InviteIcon} alt="invite" />,
      actIcon: <img src={ActInviteIcon} alt="invite" />,
      path: "/manage/invite",
      showCount: false,
      isVisible: true,
    },
    {
      id: 9,
      name: "Tour Plan",
      icon: <img src={TourIcon} alt="tour" />,
      actIcon: <img src={ActTourIcon} alt="tour" />,
      path: "/manage/tour-plan",
      showCount: false,
      isVisible: role_details?.permissions_details?.some(
        (permission) => permission.module === "tour_program.read"
      ),
    },
    {
      id: 10,
      name: "Just Visits List",
      icon: <img src={JustVisitIcon} alt="justvisit" />,
      actIcon: <img src={ActJustVisitIcon} alt="justvisit" />,
      path: "/manage/visit-list",
      showCount: false,
      isVisible: true,
      // isVisible: role_details?.permissions_details?.some(
      //   (permission) => permission.module === "tour_program.read"
      // ),
    },
    {
      id: 11,
      name: "Attendance",
      icon: <img src={AttendanceTimeIcon} alt="attendance" />,
      actIcon: <img src={ActAttendanceTimeIcon} alt="attendance" />,
      path: "/manage/attendance-&-time",
      showCount: false,
      isVisible: true,
      // isVisible: role_details?.permissions_details?.some(
      //   (permission) => permission.module === "tour_program.read"
      // ),
    },
    {
      id: 13,
      name: "Organisation",
      icon: <img src={OrganisationIcon} alt="organisation" />,
      actIcon: <img src={ActOrganisationIcon} alt="organisation" />,
      path: "/manage/organisation",
      showCount: false,
      isVisible: true,
      // isVisible: role_details?.permissions_details?.some(
      //   (permission) => permission.module === "tour_program.read"
      // ),
    },
    {
      id: 12,
      name: "Reports",
      icon: <img src={ReportIcon} alt="reports" />,
      actIcon: <img src={ActReportIcon} alt="reports" />,
      path: "/manage/reports",
      showCount: false,
      isVisible: true,
      subItems: [
        {
          id: 60,
          name: "Graphical Reports",
          // icon: <img src={LineIcon} alt="reports" />,
          path: "/manage/chart/graphical-report",
          isVisible: true,
        },
        {
          id: 61,
          name: "Sales Reports",
          // icon: <img src={LineIcon} alt="reports" />,
          path: "/manage/sales-reports",
          isVisible: true,
        },
        {
          id: 62,
          name: "Retailers' Statistical Reports",
          // icon: <img src={LineIcon} alt="reports" />,
          path: "/manage/retailers-statistical-reports",
          isVisible: true,
        },
        {
          id: 63,
          name: "Person Sales Report",
          path: "/manage/person-sales-report",
          isVisible: true,
        },
        {
          id: 64,
          name: "Report By Beats",
          path: "/manage/report-by-beats",
          isVisible: true,
        },
        // {
        //   id: 65,
        //   name: "Report By Distributor",
        //   path: "/manage/report-by-distributor",
        //   isVisible: true,
        // },
        {
          id: 66,
          name: "Sales Report Details",
          path: "/manage/sales-report-details",
          isVisible: true,
        },
        {
          id: 67,
          name: "Item Statistical Report",
          path: "/manage/item-statistical-report",
          isVisible: true,
        },

        // {
        //   id: 62,
        //   name: "Attendance Reports",
        //   // icon: <img src={LineIcon} alt="reports" />,
        //   path: "/manage/reports/attendance-reports",
        //   isVisible: true,
        // },
      ],
      showSubItems: false, // Add this property
      dropdownArrowStyles: {
        // marginLeft: '5px', // Adjust this value to reduce the space
        display: 'inline-flex',
      }
    },
  ];

  const handleChange = (value) => {
    // console.log("tesst", value);
    onChangeTenantStart(value, () => {
      window.location.reload();
    });
  };

  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;
  const routeParts = pathname.split("/").slice(2);

  const handleLogout = () => {
    onSignoutStart(() => {
      navigate("/login");
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">
              {!_.isEmpty(routeParts) &&
                routeParts[routeParts?.length - 1]
                  .split("-")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
            </Typography>

            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={theme.spacing(1)}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  background: theme.palette.success.light,
                }}
                size="small"
              >
                <Autocomplete
                  sx={{ width: 150 }}
                  options={tenant_details ?? []}
                  autoHighlight
                  value={selectedTenant}
                  disableClearable
                  onChange={(e, value) => {
                    console.log("value", value);
                    handleChange(value);
                  }}
                  getOptionLabel={(option: any) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
              <IconButton>
                <img src={NotificationIcon} alt="notification" />
              </IconButton>
              {imageUrl == null && (
                <Avatar
                  sx={{ width: 36, height: 36, cursor: "pointer" }}
                  src={imageUrl || '/path/to/default/image'}
                  // alt={selectedTenant["tenant_image"]?.name ?? ""}
                  onClick={() => navigate("/manage/user-profile")}
                />
              )}
            </Stack>
          </Box>
        </Toolbar>
        <Box sx={{ borderTop: "1px solid #F1F3F9", padding: "4px 20px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to="/manage/dashboard"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: 400 }}>
                Home
              </Typography>
            </Link>
            {routeParts?.length > 0 &&
              routeParts?.map((route, index) => {
                const capitalizedRoute = route
                  .split("-")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");

                return (
                  <Link
                    to={pathname.split("/").slice(0, 3).join("/")}
                    key={index}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 400,
                        "&::first-letter": {
                          textTransform: "capitalize",
                        },
                      }}
                    >
                      {capitalizedRoute}
                    </Typography>
                  </Link>
                );
              })}
          </Breadcrumbs>
        </Box>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={Logo} alt="pulseplay" height={50} width={186} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List sx={{ padding: open ? "31px" : "31px 6px" }}>
          {NavItems.filter((item) => item.isVisible).map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 3,
                  borderRadius: "12px",
                  marginBottom: "3px",
                  backgroundColor: location?.pathname.includes(item?.path)
                    ? theme.palette.secondary.main
                    : "#fff",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                onClick={() => {
                  if (item.subItems) {
                    // Toggle the visibility of sub-items

                    // item.showSubItems = !item.showSubItems;
                    toggleSubItems(item.id);
                  } else {
                    // Handle regular item click
                    navigate(`${item.path}`);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {location?.pathname.includes(item?.path)
                    ? item?.actIcon
                    : item?.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item?.name}
                  primaryTypographyProps={{
                    color: `${location?.pathname.includes(item?.path)
                        ? theme.palette.background.paper
                        : (theme) => theme.darkText
                      } !important`,
                    variant: "h5",
                  }}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {item.subItems && (
                  <IconButton
                    onClick={(e) => { toggleSubItems(item.id); e.stopPropagation();}}
                    sx={item.dropdownArrowStyles}
                    style={{ display: open ? 'inline-flex' : 'none' }}
                  >
                    {/* {expandedSubItems.includes(item.id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )} */}
                    {isArrowUp ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </ListItemButton>

              {item.subItems && (
                // <Collapse in={expandedSubItems.includes(item.id)} timeout="auto" unmountOnExit>
                <Collapse in={expandedSubItems.includes(item.id)} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        disablePadding
                        sx={{
                          display: "block",
                          minHeight: 40,
                          marginBottom: "3px",
                          // borderRadius: "8px",
                          borderLeft: location?.pathname.includes(item?.path)
                          ? `4px solid #D9D9D9` // Add a border for selected items
                          : "none", // No border for unselected items
                          backgroundColor: location?.pathname.includes(subItem?.path)
                            ? "#F2F2F8" // Selected item background color
                            : "#FFFFFF", // Unselected item background color
                          borderRadius: location?.pathname.includes(subItem?.path)
                            ? "12px" // Rounded corners for selected item
                            : "0px", // No rounded corners for unselected items
                          "&:hover": {
                            backgroundColor: "#F2F2F8", // Background color on hover
                            borderRadius: "12px", // Rounded corners on hover
                          },
                          
                        }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 50,
                            // width: `${subItem.name.length * 10}px`,
                            px: 5,
                            justifyContent: open ? "initial" : "center",
                            borderRadius: "12px",
                            paddingLeft: open ? '13px' : '5px', 
                          }}
                          // onClick={() => navigate(subItem.path)}
                          onClick={(e) => { e.stopPropagation();
                            navigate(subItem.path);
                          }}
                        >
                          <ListItemText
                            primary={subItem?.name}
                            primaryTypographyProps={{
                              color: location?.pathname.includes(subItem?.path)
                                ? "#000000" // Selected item font color
                                : "#FFFFFF", // Unselected item font color (white)
                              // variant: "h6",
                              fontSize: "13px",
                            }}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </ListItem>
          ))}
        </List>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "flex-end",
            padding: open ? "31px" : "31px 6px",
          }}
        >
          {/* <ListItem
            disablePadding
            sx={{
              display: "block",
              background: "rgba(94, 99, 102, 0.1)",
              borderRadius: "16px",
              marginBottom: "20px",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={HeadphoneIcon} alt="Contact" />
              </ListItemIcon>
              <ListItemText
                primary={"Contact Support"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleLogout}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={LogoutIcon} alt="Contact" />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}