// React-redux
import { connect } from "react-redux";
import { getChartListStart } from "../../../store/chart/chart.action";

const mapStateToProps = (state) => ({
  chartScreen: state.chartScreen,
});

const mapDispatchToProps = (dispatch) => ({
  onGetChartListStart: (startDate, endDate) => dispatch(getChartListStart(startDate, endDate)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
