export const VIEW_TENANT_USERS_START = "VIEW_TENANT_USERS_START";
export const VIEW_TENANT_USERS_SUCCESS = "VIEW_TENANT_USERS_SUCCESS";
export const VIEW_TENANT_USERS_FAILURE = "VIEW_TENANT_USERS_FAILURE";

export const FETCH_TENANT_ROLES_START = "FETCH_TENANT_ROLES_START";
export const FETCH_TENANT_ROLES_SUCCESS = "FETCH_TENANT_ROLES_SUCCESS";
export const FETCH_TENANT_ROLES_FAILURE = "FETCH_TENANT_ROLES_FAILURE";

export const FETCH_SOLO_TENANT_TOUR_PROGRAM_START = "FETCH_SOLO_TENANT_TOUR_PROGRAM_START";
export const FETCH_SOLO_TENANT_TOUR_PROGRAM_SUCCESS = "FETCH_SOLO_TENANT_TOUR_PROGRAM_SUCCESS";
export const FETCH_SOLO_TENANT_TOUR_PROGRAM_FAILURE = "FETCH_SOLO_TENANT_TOUR_PROGRAM_FAILURE";

export const LIST_TENANT_TOUR_PROGRAMS_START = "LIST_TENANT_TOUR_PROGRAMS_START";
export const LIST_TENANT_TOUR_PROGRAMS_SUCCESS = "LIST_TENANT_TOUR_PROGRAMS_SUCCESS";
export const LIST_TENANT_TOUR_PROGRAMS_FAILURE = "LIST_TENANT_TOUR_PROGRAMS_FAILURE";

export const CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_START = "CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_START";
export const CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_SUCCESS = "CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_SUCCESS";
export const CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_FAILURE = "CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_FAILURE";