import * as TenantTypes from "./tenant.types";

// Initial state object for the authentication reducer
const INITIAL_STATE = {
  tenantUsers: null,
  tenantRoles: [],
  soloTourProgram: {},
  tourPrograms: [],
  error: null,
  loading: false,
};

// Reducer function responsible for managing the authentication state
const tenantReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TenantTypes.VIEW_TENANT_USERS_START:
    case TenantTypes.FETCH_TENANT_ROLES_START:
    case TenantTypes.FETCH_SOLO_TENANT_TOUR_PROGRAM_START:
    case TenantTypes.LIST_TENANT_TOUR_PROGRAMS_START:
    case TenantTypes.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_START:
      return {
        ...state,
        loading: true,
      };

    case TenantTypes.VIEW_TENANT_USERS_SUCCESS:
      return {
        ...state,
        tenantUsers: payload?.data,
        loading: false,
      };

    case TenantTypes.FETCH_TENANT_ROLES_SUCCESS:
      return {
        ...state,
        tenantRoles: payload?.data?.role,
        loading: false,
      };

    case TenantTypes.FETCH_SOLO_TENANT_TOUR_PROGRAM_SUCCESS:
      return {
        ...state,
        soloTourProgram: payload,
        loading: false,
      };

    case TenantTypes.LIST_TENANT_TOUR_PROGRAMS_SUCCESS:
      return {
        ...state,
        tourPrograms: payload?.data?.tour_program,
        loading: false,
      };

    case TenantTypes.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_SUCCESS:
      const updatedTourPrograms = state.tourPrograms.map((program) => {
        if (program.user_id === payload?.data[0]?.user_id) {
          program.dates.forEach((date) => {
            if (date.date === payload?.data[0].dates[0].date) {
              date.beats = payload?.data[0].dates[0].beats; // Assign the beats array directly
            }
          });
        }
        return program;
      });

      return {
        ...state,
        tourPrograms: updatedTourPrograms,
        loading: false,
      };

    case TenantTypes.VIEW_TENANT_USERS_FAILURE:
    case TenantTypes.FETCH_TENANT_ROLES_FAILURE:
    case TenantTypes.FETCH_SOLO_TENANT_TOUR_PROGRAM_FAILURE:
    case TenantTypes.LIST_TENANT_TOUR_PROGRAMS_FAILURE:
    case TenantTypes.CREATE_OR_UPDATE_TENANT_TOUR_PROGRAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default tenantReducer;
