import { connect } from "react-redux";
import {
  fetchAllBeatsListStart,
  tenantBeatsMoveTraderStart,
  fetchSoloBeatStart,
  createTenantBeatStart,
  deleteTenantBeatStart,
  pushRetailersToBeatStart,
  remainingTradorsStart,
} from "../../../store/beats/beats.actions";
import { fetchUsersBasedOnRolesStart } from "../../../store/traders/traders.actions";
import {
  fetchCityByStateIdStart,
  fetchCountriesStart,
  fetchStateByCountryIdStart,
  fetchBeatsDataByIdStart, 
  
} from "../../../store/masters/master.actions";
const mapStateToProps = (state) => {
  // console.log("state---->", state);
  return {
    beats: state.beats,
    traders: state.traders,
    master: state.master,
    auth: state.auth,
    //selectedBeatId: state.beats.selectedBeatId, 
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTenantBeatsMoveTraderStart: (from, to, id, cb) =>
    dispatch(tenantBeatsMoveTraderStart(from, to, id, cb)),
  onFetchUsersBasedOnRolesStart: (roleId, type, field) =>
    dispatch(fetchUsersBasedOnRolesStart(roleId, type, field)),
  onFetchAllBeatsListStart: (page, limit, type, field) => dispatch(fetchAllBeatsListStart(page, limit, type, field)),
  onFetchCountriesStart: () => dispatch(fetchCountriesStart()),
  onFetchStateByCountryIdStart: (id) =>
    dispatch(fetchStateByCountryIdStart(id)),
  onFetchCityByStateIdStart: (id) => dispatch(fetchCityByStateIdStart(id)),
  onFetchBeatsDataByIdStart: ( formData, cb ) => dispatch(fetchBeatsDataByIdStart(formData, cb)),
  onfetchSoloBeatStart: (id) => dispatch(fetchSoloBeatStart(id)),
  onCreateTenantBeatStart: (formData, cb) =>
  dispatch(createTenantBeatStart(formData, cb)),
  onDeleteTenantBeatStart: (formData, cb) =>
  dispatch(deleteTenantBeatStart(formData, cb)),
  onPushRetailerToBeatStart: (selectedBeatId, traders) => dispatch(pushRetailersToBeatStart(selectedBeatId, traders)),
  onRemainingTradorsStart: () => dispatch(remainingTradorsStart()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
