import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import _ from "lodash";

import {
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Avatar } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import container from "./Tenants.container";

import DefaultTenant from "../../../assets/images/default_tenant.svg";

import { styled } from "@mui/material/styles";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import OrganisationDefaultIMG from "../../../assets/images/organisation-default.svg";

const TenantWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "16px",
  justifyContent: "center",
  maxWidth: "200px",
  height: "200px",
  width: "100%",
  alignItems: "center",
}));

const Tenants = ({
  onChangeTenantStart,
  onAcceptTenantInvitationStart,
  auth: { tenant_details },
}) => {
  const imgFile = [];
  // console.log(tenant_details);

  // Iterate over each object in the array
  for (let i = 0; i < tenant_details?.length; i++) {
    const tenant_detail_img = tenant_details[i]?.tenant_image;

    // Extract the desired file properties and add them to the new array
    try {
      const { file_encoded } = tenant_detail_img;
      const imageFileData = file_encoded;

      const byteCharacters = atob(imageFileData);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters?.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "image/jpeg" });

      // Generate a URL for the blob object
      const imageUrl = URL.createObjectURL(blob);
      imgFile.push(imageUrl);

      // Continue with further processing or usage of the imageUrl
    } catch (error) {
      // Handle the error
      console.error("Image decoding error:", error);
    }
  }

  const [selectedTenant, setSelectedTenant]: any = React.useState();

  const navigate = useNavigate();

  const handleTenant = () => {
    onChangeTenantStart(selectedTenant, () => {
      navigate("/manage/dashboard");
    });
  };
  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: "4%",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          textAlign: "center",
          padding: (theme) => theme.spacing(4),
        }}
      >
        <Stack spacing={1.2} mb={4}>
          <Typography variant="h2" sx={{ color: "#1A3048" }}>
            Select Tenants
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: "15px", lineHeight: "25px" }}
          >
            Select a Tenant for seamless order <br /> placement and prompt
            delivery.
          </Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: (theme) => theme.spacing(3),
          }}
        >
          {tenant_details &&
            tenant_details.map((tenant, index) => (
              <TenantWrapper
                key={index}
                onClick={() => setSelectedTenant(tenant)}
                sx={{
                  border: `1px solid ${
                    selectedTenant?.id === tenant?.id ? "#008E8E" : "#D0DBEA"
                  }`,
                }}
              >
                <img
                  src={imgFile[index]}
                  alt={tenant?.profile_images?.name}
                  style={{ height: "78px", width: "78px", objectFit: "cover" }}
                />
                <Typography
                  sx={{ margin: "10px 0", textTransform: "capitalize" }}
                >
                  {tenant?.name ?? ""}
                </Typography>

                {tenant?.is_invitation_pending && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button>
                      <Box>
                        <CancelOutlinedIcon
                          color="disabled"
                          sx={{ fontSize: "30px" }}
                        />
                        <Typography
                          sx={{
                            color: "#9E9E9E",
                            fontSize: "11px",
                            textTransform: "capitalize",
                          }}
                        >
                          Decline
                        </Typography>
                      </Box>
                    </Button>

                    <Button
                      onClick={() => onAcceptTenantInvitationStart(tenant?.id)}
                    >
                      <Box>
                        <CheckCircleOutlinedIcon
                          color="success"
                          sx={{ fontSize: "30px" }}
                        />
                        <Typography
                          sx={{
                            color: "#9E9E9E",
                            fontSize: "11px",
                            textTransform: "capitalize",
                          }}
                        >
                          Accept
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                )}
              </TenantWrapper>
            ))}
        </Box>
        {!_.isEmpty(selectedTenant) &&
          !selectedTenant?.is_invitation_pending && (
            <CustomButton
              name="Continue"
              sx={{ mt: 4, maxWidth: "180px", width: "100%" }}
              handleClick={handleTenant}
            />
          )}
      </Card>
    </Container>
  );
};
export default container(Tenants);
