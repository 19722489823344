import React, {Fragment, useState, } from 'react';
import { Grid, Box } from "@mui/material";

import container from './DashboardView.container';
import YearlyBeatsPerformanceView from '../ReportByBeats/components/YearlyBeatsPerformanceView';
import HollowPieChartView from '../ItemStatisticalReport/components/HollowPieChartView';
import TopSellingProducts from '../ItemStatisticalReport/components/TopSellingProductsView';

import TodayAnalyticalView from '../SalesReportDetails/components/TodayAnalyticalView';
import YesterdayAnalyticalView from '../SalesReportDetails/components/YesterdayAnalyticalView';
import MonthlyAnalyticalView from '../SalesReportDetails/components/MonthlyAnalyticalView';

import CustomViewButton from '../../../components/CustomViewButton';
import Dashboard from './Dashboard.component';

const DashboardView = ({onGetSalesReportDetailsTodayStart, onGetSalesReportDetailsYesterdayStart,
    onGetSalesReportDetailsMonthlyStart, 

    salesReportDetails: {salesReportDetailsDataToday, salesDetailsToday, totalSalesToday,
        salesReportDetailsDataYesterday, salesDetailsYesterday, totalSalesYesterday,
        salesReportDetailsDataMonthly, salesDetailsMonthly, totalSalesMonthly,
    },
    onYearlyBeatsListStart,
    reportByBeats: { yearlyBeatsList, yeralyGrandeTotalSales, yearlyIndividualMonthlySales},

    onYearlyItemListStart,
    itemStatisticalReport: {yearlyItemList}
}) => {
    const [currentView, setCurrentView] = useState('analytics');

    const handleViewChange = (view) => {
      setCurrentView(view);
    }

    return( 
      <Grid container>
        <Grid lg = {12}>
        <Fragment>
          <Box sx = {{display: "flex", mb: 2}}>
            <CustomViewButton
              label="Analytics View"
              onClick={() => handleViewChange('analytics')}
              isActive={currentView === 'analytics'}
            />
            <CustomViewButton
              label="Sample File"
              onClick={() => handleViewChange('fileImport')}
              isActive={currentView === 'fileImport'}
            />
          </Box>
        </Fragment>
        </Grid>
        {currentView === 'analytics' && (
          <>
          <Grid container lg={8} mr={"15px"}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                      <TodayAnalyticalView
                        onGetSalesReportDetailsTodayStart={onGetSalesReportDetailsTodayStart}
                        salesReportDetailsToday={{salesReportDetailsDataToday, salesDetailsToday, totalSalesToday,}}
                      />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <YesterdayAnalyticalView
                      onGetSalesReportDetailsYesterdayStart={onGetSalesReportDetailsYesterdayStart}
                      salesReportDetailsYesterday={{salesReportDetailsDataYesterday, salesDetailsYesterday, totalSalesYesterday}}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MonthlyAnalyticalView
                      onGetSalesReportDetailsMonthlyStart={onGetSalesReportDetailsMonthlyStart}
                      salesReportDetailsDataMonthly = {{salesReportDetailsDataMonthly, salesDetailsMonthly, totalSalesMonthly}}
                    />
                  </Grid>
              </Grid>

              <Grid container mt={"10px"} spacing={2}>
                  <Grid item xs = {12} md = {7}>
                      <YearlyBeatsPerformanceView 
                          onYearlyBeatsListStart = {onYearlyBeatsListStart}
                          reportByBeats = {{yearlyBeatsList, yeralyGrandeTotalSales, yearlyIndividualMonthlySales}}
                      />
                  </Grid>
                  <Grid item xs = {12} md = {5}>
                      <HollowPieChartView 
                          // onYearlyItemListStart = {onYearlyItemListStart}
                          // itemStatisticalReport = {{yearlyItemList}}
                      />
                  </Grid>
              </Grid>
          </Grid>

          <Grid container lg={4} spacing={2}>
              <TopSellingProducts
                onYearlyItemListStart = {onYearlyItemListStart}
                itemStatisticalReport = {{yearlyItemList}}
              />
          </Grid>
          </>
        )}
        {currentView === 'fileImport' && ( 
          <Dashboard />
        )}
      </Grid>
    )
}

export default container(DashboardView);